import React from "react";

function UserAvatar({ user }) {
  return (
    <>
      <div className="user-avatar">
        {!user?.profileImgUrl ? (
          <>{user?.firstName?.charAt(0)}</>
        ) : (
          <img src={user?.profileImgUrl} alt="avatar"/>
        )}
      </div>
    </>
  );
}

export default UserAvatar;
