import React from "react";
import ContentLoader from "react-content-loader"

const MobilePredictionListLoader = (props) => (
    <ContentLoader
        speed={2}
        // width={780}
        // height={250}
        viewBox="0 0 400 350"
        backgroundColor="#F9F9FB"
        foregroundColor="#F9F9FB"
        {...props}
    >
        <circle cx="25" cy="31" r="23" />
        <rect x="55" y="12" rx="4" ry="4" width="120" height="18" />
        <rect x="55" y="36" rx="4" ry="4" width="90" height="15" />
        <rect x="5" y="65" rx="4" ry="4" width="385" height="230" />
        <rect x="5" y="310" rx="5" ry="5" width="65" height="20" />
        <rect x="330" y="310" rx="5" ry="5" width="60" height="20" />
    </ContentLoader>

)
export default MobilePredictionListLoader;