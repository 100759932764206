import axios from "axios";
import React, { useEffect, useState } from "react";
import apiService from "../services/apiService";
import { Environment } from "../environment";
import { eventBus } from "../services/events";
import { CONSTANT } from "../common/constant";

const Avatar = ({
  editProfile,
  updateProfile,
  profilePicVaildation,
  handleEditProfileDeletebutton,
}) => {
  const BASE_API_URL = process.env.REACT_APP_baseUrl + "/auth/uploadImage";
  const [userProfile, setUSerProfile] = useState({});
  const [showProfilePic, setShowProfilePic] = useState(true);
  const [reload, setReload] = useState(false);
  eventBus.on("DELETE_PROFILE_PIC", () => {
    setShowProfilePic(false);
  });

  useEffect(() => {
    if (editProfile.profileImgUrl !== "" && editProfile.profileImgUrl) {
      setShowProfilePic(true);
    }
    handleEditProfileDeletebutton();
  }, [editProfile, reload]);

  const userprofile = async () => {
    const res = await apiService.getUserProfile();
    setUSerProfile(res);
  };
  const handleUploadAvatar = (e) => {
    if (e.target.files) {
      const file = e.target.files;
      profilePicVaildation(file[0]);
      const data = {
        image: file[0],
      };
      eventBus.emit(CONSTANT.SHOW_LOADER);
      axios
        .post(BASE_API_URL, data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          setUSerProfile((oldState) => {
            return { ...oldState, profileImgUrl: response.data };
          });
          updateProfile(response.data);
        })
        .catch((error) => {
          console.log("error..", error);
        });
      eventBus.emit(CONSTANT.HIDE_LOADER);
    }
  };
  useEffect(() => {
    userprofile();
  }, []);
  return (
    <>
      <div
        className="profile-avtar-circle editprofile-avatar rounded-circle rounded-profile-circle d-flex align-items-center justify-content-center"
        alt="Avatar"
        onChange={handleUploadAvatar}
      >
        <input type="file" name="file" className="avatar-upload" />
        <div className="camera-background">
          <img src="Camera.svg" className="camera-img" />
        </div>
        <div>
          {editProfile?.profileImgUrl || userProfile?.profileImgUrl ? (
            <img
              src={editProfile?.profileImgUrl || userProfile?.profileImgUrl}
              value={userProfile?.profileImgUrl || editProfile?.profileImgUrl}
              className="uploaded-img"
              width="110px"
              height="110px"
              style={{ display: "block" }}
            />
          ) : (
            <p className="useName-profile-letter d-flex align-items-center justify-content-center">
              {userProfile?.firstName?.charAt(0)}
            </p>
          )}
        </div>
      </div>
    </>
  );
};
export default Avatar;
