import { io } from "socket.io-client";

class SocketService {
    BASE_API_URL = process.env.REACT_APP_Url;
    socket;
    constructor() { }
    async init() {
        this.socket = io(this.BASE_API_URL,
            {
                extraHeaders: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                }
            })
    }
    // async emit(topic, data) {
    //     if (this.socket) this.socket.emit(topic, JSON.stringify(data))
    // }
    async subscribe(topic, fn) {
        const mySocket = this.socket
        // console.log("mySocket:::", mySocket)
        return new Promise(function (resolve, reject) {
            if (!mySocket) return reject("No alive socket connection found")
            mySocket.off(topic, fn)
            mySocket.on(topic, fn)
            return resolve(true)
        })
    }
}

const obj = new SocketService()
obj.init()
export default obj
