import { use } from "i18next";
import React, { useEffect } from "react";
import "../inputCheckbox/inputCheckbox.css";
const InputCheckbox = (props) => {
  useEffect(() => {}, [props]);
  return (
    <>
      <label className="container-checkbox">
        <input
          type="checkbox"
          onChange={props.onChange}
          id={props.id}
          name={props.checkBoxName}
          checked={props.isChecked}
        />
        {/* {props.checked ? <input type="checkbox" onChange={props.onChange} checked /> : <input type="checkbox" onChange={props.onChange} />} */}
        <span className="checkmark"></span>
      </label>
    </>
  );
};
export default InputCheckbox;
