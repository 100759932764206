import React from "react";
import { useTranslation } from "react-i18next";

const NoPredictionList = ({isFollowing}) => {
    const { t, i18n } = useTranslation();

  return (
    <div className="no-follow-container">
      <img className="no-follow-img" src="/Ticket-Star.svg" alt=""></img>
      <div className="no-follow-text-wraper">
        <h1 className="no-follow-title">
          {isFollowing
            ? t("emptyPageText.noFollowingPredictionsTitle")
            : t("emptyPageText.noFilteredPredictionsTitle")}
        </h1>
        <span className="no-follow-text no-predictions-text">
          {isFollowing
            ? t("emptyPageText.noFollowingPredictionsText")
            : t("emptyPageText.noFilteredPredictionsText")}
        </span>
      </div>
    </div>
  );
};

export default NoPredictionList;
