import React from "react";
import { useMediaQuery } from "react-responsive";

import "./style.css";

const RewardsLoader = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const renderMobileSubItems = () => {
    return (
      <>
        <div className="skeleton-placeholder-light rewards-loader-subitem_img"></div>
        <div className="skeleton-placeholder-light rewards-loader-subitem_title"></div>
        <div className="rewards-loader-subitem_top-info-wrapper">
            <div className="skeleton-placeholder-light rewards-loader-subitem_info"></div>
            <div className="skeleton-placeholder-light rewards-loader-subitem_info"></div>
        </div>
        <div className="skeleton-placeholder-light rewards-loader-subitem_info"></div>
      </>
    );
  };

  const renderSubItems = () => {
    return (
      <>
        <div className="skeleton-placeholder-light rewards-loader-subitem_img"></div>
        <div className="rewards__loader-sub-item_title-wrapper">
          <div className="skeleton-placeholder-light rewards-loader-subitem_title"></div>
          <div className="rewards-loader-subitem_info-section">
            <div className="rewards-loader-subitem_info-item1">
              <div className="skeleton-placeholder-light rewards-loader-info-item-title"></div>
              <div className="skeleton-placeholder-light rewards-loader-info-item-value"></div>
            </div>
            <div className="rewards-loader-subitem_info-item2">
              <div className="skeleton-placeholder-light rewards-loader-info-item-title"></div>
              <div className="skeleton-placeholder-light rewards-loader-info-item-value"></div>
            </div>
            <div className="rewards-loader-subitem_info-item3">
              <div className="skeleton-placeholder-light rewards-loader-info-item-title"></div>
              <div className="skeleton-placeholder-light rewards-loader-info-item-value"></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="rewards__page-wrapper">
      <div className="rewards__main-section">
        <div className="rewards__top-total-section">
          <div className="rewards__top-total-left left-section-skeleton">
            <div className="rewards-loader-left-top">
              <div className="rewards-loader-left-top-title">
                <div className="skeleton-placeholder-dark rewards-loader-left-top-text"></div>
                <div className="skeleton-placeholder-dark rewards-loader-left-top-description"></div>
              </div>
              <div className="skeleton-placeholder-dark rewards-loader-left-top-img"></div>
            </div>
            <div className="rewards-loader-left-bottom">
              <div className="skeleton-placeholder-dark rewards-loader-left-bottom-img"></div>
              <div className="skeleton-placeholder-dark rewards-loader-left-bottom-txt"></div>
            </div>
          </div>
          <div className="rewards__top-total-right">
            <div className="rewards__top-total-right-rewards-item reward-exclusive">
              <div className="rewards__top-total-right-rewards-item-title">
                <div className="skeleton-placeholder-light rewards-loader-total-title"></div>
              </div>
              <div className="rewards__top-total-right-rewards-item-total">
                <div className="skeleton-placeholder-light rewards-loader-total-count"></div>
              </div>
            </div>
            <div className="rewards__top-total-right-all-rewards_wrapper">
              <div className="rewards__top-total-right-rewards-item reward-analyst">
                <div className="rewards__top-total-right-rewards-item-title">
                  <div className="skeleton-placeholder-light rewards-loader-total-title"></div>
                </div>
                <div className="rewards__top-total-right-rewards-item-total">
                  <div className="skeleton-placeholder-light rewards-loader-total-count"></div>
                </div>
              </div>
              <div className="rewards__top-total-right-rewards-item reward-collector">
                <div className="rewards__top-total-right-rewards-item-title">
                  <div className="skeleton-placeholder-light rewards-loader-total-title"></div>
                </div>
                <div className="rewards__top-total-right-rewards-item-total">
                  <div className="skeleton-placeholder-light rewards-loader-total-count"></div>
                </div>
              </div>
              <div className="rewards__top-total-right-rewards-item reward-influencer">
                <div className="rewards__top-total-right-rewards-item-title">
                  <div className="skeleton-placeholder-light rewards-loader-total-title"></div>
                </div>
                <div className="rewards__top-total-right-rewards-item-total">
                  <div className="skeleton-placeholder-light rewards-loader-total-count"></div>
                </div>
              </div>
              <div className="rewards__top-total-right-rewards-item">
                <div className="rewards__top-total-right-rewards-item-title">
                  <div className="skeleton-placeholder-light rewards-loader-total-title"></div>
                </div>
                <div className="rewards__top-total-right-rewards-item-total">
                  <div className="skeleton-placeholder-light rewards-loader-total-count"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rewards__list-section">
          <div className="rewards__list-item">
          <div className="rewards__list-item-category">
              <div className="skeleton-placeholder-dark rewards-category-name"></div>
            </div>
            {Array(1)
              .fill(0)
              .map((item, index) => {
                return (
                  <div className="rewards__category-sub-item" key={index}>
                    {isMobile ? renderMobileSubItems() : renderSubItems()}
                  </div>
                );
              })}
            <div className="rewards__list-item-category">
              <div className="skeleton-placeholder-dark rewards-category-name"></div>
            </div>
            {Array(4)
              .fill(0)
              .map((item, index) => {
                return (
                  <div className="rewards__category-sub-item" key={index}>
                    {isMobile ? renderMobileSubItems() : renderSubItems()}
                  </div>
                );
              })}
            <div className="rewards__list-item-category">
              <div className="skeleton-placeholder-dark rewards-category-name"></div>
            </div>
            {Array(2)
              .fill(0)
              .map((item, index) => {
                return (
                  <div className="rewards__category-sub-item" key={index}>
                    {isMobile ? renderMobileSubItems() : renderSubItems()}
                  </div>
                );
              })}
            <div className="rewards__list-item-category">
              <div className="skeleton-placeholder-dark rewards-category-name"></div>
            </div>
            {Array(2)
              .fill(0)
              .map((item, index) => {
                return (
                  <div className="rewards__category-sub-item" key={index}>
                    {isMobile ? renderMobileSubItems() : renderSubItems()}
                  </div>
                );
              })}
            <div className="rewards__list-item-category">
              <div className="skeleton-placeholder-dark rewards-category-name"></div>
            </div>
            {Array(1)
              .fill(0)
              .map((item, index) => {
                return (
                  <div className="rewards__category-sub-item" key={index}>
                    {isMobile ? renderMobileSubItems() : renderSubItems()}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardsLoader;
