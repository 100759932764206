import React from "react";
import ContentLoader from "react-content-loader"

const SportLeagueModelLoader = (props) => (
    <ContentLoader
        speed={2}
        // width={450}
        // height={295}
        viewBox="0 0 450 295"
        backgroundColor="#F9F9FB"
        foregroundColor="#F9F9FB"
        {...props}
    >
        <circle cx="20" cy="20" r="15" />
        <rect x="40" y="15" rx="4" ry="4" width="300" height="8" />

        <circle cx="20" cy="70" r="15" />
        <rect x="40" y="65" rx="4" ry="4" width="300" height="8" />

        <circle cx="20" cy="120" r="15" />
        <rect x="40" y="115" rx="4" ry="4" width="300" height="8" />

        <circle cx="20" cy="170" r="15" />
        <rect x="40" y="165" rx="4" ry="4" width="300" height="8" />

        <circle cx="20" cy="220" r="15" />
        <rect x="40" y="215" rx="4" ry="4" width="300" height="8" />

        <circle cx="20" cy="270" r="15" />
        <rect x="40" y="265" rx="4" ry="4" width="300" height="8" />

        {/* <circle cx="20" cy="320" r="15" />
        <rect x="40" y="315" rx="4" ry="4" width="300" height="8" /> */}

        {/* <circle cx="20" cy="370" r="15" />
        <rect x="40" y="365" rx="4" ry="4" width="300" height="8" /> */}

    </ContentLoader>
)
export default SportLeagueModelLoader;