import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
import { formatPrice, prepareFullName } from "../../../helpers/formater.js";

import UserAvatar from "../../UserAvatar";

function LeaderboardGridBody({ users, isMobile }) {
  const navigate = useNavigate();

  const [sports, setSports] = useState(
    JSON.parse(localStorage.getItem("allSportData"))
  );
  const [language, setLanguage] = useState(localStorage.getItem("language"));

  const showPopularSport = (popularSport) => {
    let sportName = "-";
    if (!popularSport?.sport_id) {
      return <span className="tag empty">-</span>;
    }
    
    if (sports) {
      const currentSport = sports.find((sport) => sport.id === popularSport.sport_id);
      if (currentSport) {
        sportName =
          language === "ua" ? currentSport.name_ua : currentSport.name_en;
      }
    }
    return <span className="tag">{sportName}</span>;
  };

  const calculateSPC = (wonPrediction, lostPrediction) => {
    let spc = 0;
    if (!wonPrediction) return "-";

    let totalPrediction = wonPrediction + lostPrediction;
    spc = (wonPrediction / totalPrediction) * 100;

    return spc.toFixed(1) + "%";
  };

  const currentUser = JSON.parse(localStorage.getItem("profile"));

  return (
    <>
      {users.map((user, index) => {
        const imagePath = `/Leaderboard-img/${user.place}.svg`;
        let userLink = `/profile/${user._id}`;
        if (user._id.toString() === currentUser?._id) {
          userLink = '/userprofile';
        }
    
        if (localStorage.getItem("language") === "en") {
          userLink = `/en${userLink}`;
        }
        
        return (
          <tr
            className={
              user._id === currentUser?._id ? "table-row active" : "table-row"
            }
            key={index}
          >
            <td className="user-place-cell">
              {user.place <= 3 ? (
                <img src={imagePath} alt="avatar"></img>
              ) : (
                user.place
              )}
            </td>
            <td>
              <a
                className="user__info"
                href={userLink}
              >
                <UserAvatar user={user.userInfo} />
                <div className="user__info-text">
                  <span className="user__info-name">
                    {prepareFullName(user.userInfo)}
                  </span>
                  {user?.userInfo?.userName ? (
                    <span className="user__info-nick">
                      @{user?.userInfo?.userName}
                    </span>
                  ) : null}
                </div>
              </a>
            </td>
            {!isMobile && (
              <>
                <td>{user.totalPredictions}</td>
                <td>{user.avgWonCoef ? user.avgWonCoef.toFixed(2) : 0}</td>
                <td>
                  <span className="badge">
                    {calculateSPC(
                      user.totalWonPredictions,
                      user.totalLostPredictions
                    )}
                  </span>
                </td>
                <td>{showPopularSport(user.popularSport)}</td>
              </>
            )}
            <td className="coins-cell">{formatPrice(user.totalEarnedCoins)}</td>
          </tr>
        );
      })}
    </>
  );
}

export default LeaderboardGridBody;
