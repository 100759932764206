import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import ProductCard from "./ProductCard";
import NavigationBar from "../Navbar";

import HomePageFooter from "../molecule/homepage/HomePageFooter/HomePageFooter";
import HomePageHeader from "../molecule/homepage/HomePageHeader/HomePageHeader";

import InputButton from "../molecule/InputButton";
import Model from "../molecule/model/Model";
import InputField from "../molecule/InputField";

import apiService from "../../services/apiService";
import { eventBus } from "../../services/events";
import { CONSTANT } from "../../common/constant";
import { isValidEmail } from "../../helpers/validator";

import "../Marketplace/marketPlace.css";

const Marketplace = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [purchaseProductModel, setPurchaseProductModel] = useState(false);
  const [deleteProductModel, setDeleteProductModel] = useState(false);
  const [addEditProductModel, setAddEditProductModel] = useState(false);
  const [predictionModel, setPredictionModel] = useState(false);

  const [statusData, setStatusData] = useState("Active");
  const [titleStatusData, setTitleStatusdata] = useState({
    title: "",
    price: 0,
  });

  const [loginEmail, setLoginEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");

  const [error, setError] = useState("form-error-line-hide");
  const [errorClass, setErrorClass] = useState("mb-3");
  const [validationErrorMsg, setValigationError] = useState(false);

  const [dropDownHideShow, setDropDownHideShow] = useState(false);
  const [uploadedImageData, setUploadedImageData] = useState("");
  const [isEditData, setIsEditData] = useState(false);
  const [imageName, setImageName] = useState("");
  const [allProductData, setAllProductData] = useState([]);
  const [countData, setCountData] = useState(0);
  const [selectedProductData, setSelectedProductData] = useState({});
  const [coins, setCoins] = useState(localStorage.getItem("coinData"));

  const handleEmail = async (e) => {
    e.preventDefault();
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.getUserName({ email: loginEmail });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res.data) {
      navigate("/login", {
        state: {
          email: loginEmail,
          userName: userName,
          userEmail: userEmail,
          name: res.data.userName,
          profileImgUrl: res?.data.profileImgUrl,
        },
      });
    } else {
      setErrorClass("mb-3 form-error");
      setError("form-error-line-show");
    }
  };

  const handleLoginEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      // username
      setUserName(e.target.value);
    } else {
      // email
      setUserEmail(e.target.value);
    }
    setLoginEmail(e.target.value);
    if (e.target.value == "") {
      setErrorClass("mb-3");
      setError("form-error-line-hide");
    }
  };

  const handleAllProductData = async () => {
    const res = await apiService.getAllProducts();
    setAllProductData(res);
  };

  useEffect(() => {
    handleAllProductData();
  }, [countData]);

  const handleInputOnChangeData = (event) => {
    const { name, value } = event.target;
    if (name == "price") {
      const numberRegex = /^[0-9]+$/;
      if (numberRegex.test(value) || value == "") {
        setValigationError(false);
      } else {
        setValigationError(true);
      }
    }
    setTitleStatusdata((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePurchaseProductModel = (data) => {
    if (localStorage.getItem("token")) {
      setSelectedProductData(data);
      setPurchaseProductModel(true);
    } else {
      setPredictionModel(true);
    }
  };

  // delete product
  const handleDeleteProductModel = (data) => {
    setSelectedProductData(data);
    setDeleteProductModel(true);
  };

  const handleDeleteProdictData = async (id) => {
    setDeleteProductModel(false);
    const res = await apiService.deleteProductData(id);
    if (res) {
      setCountData(countData + 1);
    }
  };
  // add edit product
  const handleAddEditProductModel = async (btnName, item) => {
    setAddEditProductModel(true);
    if (btnName == "addProductBtn") {
      setUploadedImageData("");
      setImageName("");
      setSelectedProductData({});
      setTitleStatusdata({
        title: "",
        price: 0,
      });
      setStatusData("Active");
      // setIsStatusSelect(false);
      setIsEditData(false);
    }
    if (btnName == "editBtn") {
      setSelectedProductData(item);
      setTitleStatusdata({
        title: item.title,
        price: item.price,
      });
      setStatusData(item.status);
      setUploadedImageData(item.productImgUrl);
      setImageName(item.localImgUrl);
      setIsEditData(true);
    }
  };

  const handleEditProductData = async () => {
    const body = {
      localImgUrl: imageName,
      title: titleStatusData.title,
      price: parseInt(titleStatusData.price),
      status: statusData,
      productImgUrl: uploadedImageData,
    };
    setAddEditProductModel(false);
    const response = await apiService.editProduct(
      body,
      selectedProductData._id
    );
    if (response) {
      setCountData(countData + 1);
    }
  };

  const handlePurchaseData = async () => {
    setPurchaseProductModel(false);
    const res = await apiService.purchaseProduct(selectedProductData._id);
    if (res) {
      setCountData(countData + 1);
      setCoins(coins - selectedProductData.price);
    }
  };
  // add product
  const handleAddProductData = async () => {
    const body = {
      localImgUrl: imageName,
      title: titleStatusData.title,
      price: parseInt(titleStatusData.price),
      status: statusData,
      productImgUrl: uploadedImageData,
    };
    setAddEditProductModel(false);
    const response = await apiService.addProduct(body);
    if (response) {
      setCountData(countData + 1);
    }
  };
  // image upload
  const handleUploadImageData = async (e) => {
    setImageName(e.target?.files[0]?.name);
    const data = {
      image: e.target.files[0],
    };
    const res = await apiService.uploadImage(data);
    setUploadedImageData(res);
  };
  const handleDeleteImageData = () => {
    setImageName("");
    setUploadedImageData("");
  };
  const handleStatusData = (data) => {
    setStatusData(data);
    setDropDownHideShow(false);
  };
  const handleStatusDropDown = () => {
    if (dropDownHideShow) {
      setDropDownHideShow(false);
    } else {
      setDropDownHideShow(true);
    }
  };

  useEffect(() => {
    document.body.classList.add("notification-tab-content");
  }, []);

  return (
    <>
      <Helmet>
        <title>{t("Marketplace.metaTitle")}</title>
        <meta name="description" content={t("Marketplace.metaDescription")} />
        <meta name="og:title" content={t("Marketplace.metaSocialTitle")} />
        <meta
          name="og:description"
          content={t("Marketplace.metaDescription")}
        />

        <meta name="og:url" content="https://capperauditor.com/marketplace" />
        <meta name="twitter:title" content={t("Marketplace.metaSocialTitle")} />
        <meta
          name="twitter:description"
          content={t("Marketplace.metaDescription")}
        />
        <link
          rel="alternate"
          href="<https://capperauditor.com/marketplace>"
          hreflang="uk"
        />
        <link
          rel="alternate"
          href="<https://capperauditor.com/en/marketplace>"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="<https://capperauditor.com/marketplace>"
          hreflang="x-default"
        />
      </Helmet>
      <div>
        {localStorage.getItem("token") && localStorage.getItem("profile") ? (
          <NavigationBar countData={countData} />
        ) : (
          <HomePageHeader />
        )}
        <div className="Marketplace-all-section">
          {/* top */}
          <div className="Marketplace-backgroung-color-section">
            <div className="Marketplace-container h-100">
              <div className="row align-items-center  h-100 Marketplace-top-banner">
                <div className="col-lg-7 col-md-7 col-12 px-0 position-relative">
                  <div className="gradiant-single-border">
                    <img src="/Marketplace-img/Line.png" alt="" />
                  </div>
                  <div className="marketplace-left-div">
                    <h1 className="title pb-3">
                      {t("Marketplace.MarketplaceTitle").toUpperCase()}
                    </h1>
                    <div className="sub-title">
                      {" "}
                      {t("Marketplace.MarketplaceSubTitle")}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-5 col-12 p-0 d-flex justify-content-center position-relative">
                  <div className="Marketplace-banner-image">
                    <img
                      src="/Marketplace-img/banner.png"
                      className="banner-image-data"
                      alt=""
                    />
                  </div>
                  <div className="Marketplace-banner-image-gradiant"></div>
                </div>
              </div>
            </div>
          </div>
          {/* center part */}
          <div className="product-card-section">
            <div className="product-card-container">
              <div className="row">
                {allProductData?.map((item, index) => (
                  <div
                    className="col-lg-4 col-md-6 col-12 product-Data-card mb-3 px-2 position-relative"
                    key={index}
                  >
                    {apiService.getProfileFromStorage()?.role[0] === "Admin" ? (
                      <div className="d-flex predition-edit-delete-user">
                        {/* edit */}
                        <div className="tooltip-edit-div">
                          <div className="edit-button-tooltip">
                            {t("Marketplace.editProductTooltip")}
                          </div>
                          <div
                            className="icon-container"
                            onClick={() =>
                              handleAddEditProductModel("editBtn", item)
                            }
                          >
                            <svg
                              width="24"
                              className="ps-0 edit-icon"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                className="permission-icon-path"
                                d="M11.492 2.78906H7.753C4.678 2.78906 2.75 4.96606 2.75 8.04806V16.3621C2.75 19.4441 4.669 21.6211 7.753 21.6211H16.577C19.662 21.6211 21.581 19.4441 21.581 16.3621V12.3341"
                                stroke="#7F87B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                className="permission-icon-path"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M8.82666 10.921L16.2997 3.44799C17.2307 2.51799 18.7397 2.51799 19.6707 3.44799L20.8877 4.66499C21.8187 5.59599 21.8187 7.10599 20.8877 8.03599L13.3787 15.545C12.9717 15.952 12.4197 16.181 11.8437 16.181H8.09766L8.19166 12.401C8.20566 11.845 8.43266 11.315 8.82666 10.921Z"
                                stroke="#7F87B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                className="permission-icon-path"
                                d="M15.1641 4.60254L19.7301 9.16854"
                                stroke="#7F87B3"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                        {/* delete */}
                        <div className="d-flex ps-2 tooltip-delete-user-div">
                          <div className="delete-user-button-tooltip">
                            {t("Marketplace.deleteProductTooltip")}
                          </div>
                          <div
                            className="icon-container"
                            onClick={() => handleDeleteProductModel(item)}
                          >
                            <img
                              src="/delete-user-img.svg"
                              className="change-permision"
                              width="24px"
                              height="24px"
                              alt=""
                            />
                            <img
                              src="/delete-user-hover-img.svg"
                              className=""
                              width="24px"
                              height="24px"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <ProductCard
                      item={item}
                      handlePurchaseProductModel={handlePurchaseProductModel}
                      coins={coins}
                    />
                  </div>
                ))}

                {apiService.getProfileFromStorage()?.role[0] === "Admin" ? (
                  <div className="col-lg-4 col-md-6 col-12 product-Data-card mb-3 px-2 position-relative">
                    <div className="product-card-div">
                      <div className="gift-image d-flex justify-content-center">
                        <img src="/Marketplace-img/Gift 1.svg" alt="" />
                      </div>
                      <InputButton
                        type={"secondary"}
                        addBtnClass={"add-product-btn"}
                        inputLabel={t("Marketplace.addProductBtn")}
                        onClick={() =>
                          handleAddEditProductModel("addProductBtn")
                        }
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <HomePageFooter />

        {/* edit / addproduct model */}
        <Model
          isModel={addEditProductModel}
          modelClassName={"edit-add-product-model"}
          hideModel={() => setAddEditProductModel(false)}
          modelTitle={
            isEditData
              ? t("Marketplace.EditPoroduct")
              : t("Marketplace.addProductTitle")
          }
        >
          <InputField
            inputLabel={t("Marketplace.addModelTitle")}
            defaultValue={titleStatusData.title}
            name={"title"}
            onChange={handleInputOnChangeData}
            labelClass="mt-0 inputfield-label"
            inputType="text"
            inputPlaceHolder={t("Marketplace.ProductPlaceHolder")}
          />
          <InputField
            inputLabel={t("Marketplace.PriceTitle")}
            name={"price"}
            defaultValue={titleStatusData.price}
            onChange={handleInputOnChangeData}
            labelClass="mt-3 inputfield-label"
            inputType="text"
            inputPlaceHolder={t("Marketplace.PricePlaceHoldrer")}
            isError={validationErrorMsg}
            errorMsg={t("Marketplace.onluNumricValueMsg")}
          />
          {/* status drop down */}
          <div>
            <div className="pt-3 pb-1 purchase-status-title">
              {t("Marketplace.Status")}
            </div>
            <div className="sortby-dropdown">
              <div
                className="d-flex p-3 selected-fliter ignore-dropdown"
                onClick={handleStatusDropDown}
              >
                <div className="filter-name d-flex align-items-center">
                  {statusData === "Active"
                    ? t("Marketplace.statusPlaceHolderActive")
                    : t("Marketplace.statusPlaceHolderSoldout")}
                </div>
                <div>
                  {dropDownHideShow ? (
                    <img className="ps-3" src="up.svg" alt="" />
                  ) : (
                    <img className="ps-3" src="down.svg" alt="" />
                  )}
                </div>
              </div>
              <ul className="fliter-type ignore-dropdown">
                <div
                  className="list-drop-down"
                  style={{
                    display: dropDownHideShow ? "block" : "none",
                  }}
                >
                  <li
                    className="d-flex filter-menu"
                    onClick={() => handleStatusData("Active")}
                  >
                    <div className="filter-name">
                      {t("Marketplace.statusPlaceHolderActive")}
                    </div>
                  </li>
                  <li
                    className="d-flex filter-menu"
                    onClick={() => handleStatusData("Sold out")}
                  >
                    <div className="filter-name ">
                      {t("Marketplace.statusPlaceHolderSoldout")}
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </div>
          {/* image upload */}
          <div className={`mt-3`}>
            {imageName == "" ? (
              ""
            ) : (
              <div className="imagepathName pb-2">{imageName}</div>
            )}
            <div className="upload-image-button">
              <div
                className=""
                style={{ display: imageName == "" ? "block" : "none" }}
              >
                <input
                  type="file"
                  className="upload-image-input-feild"
                  onChange={handleUploadImageData}
                />
                {t("Marketplace.UploadPhotoBtn")}
              </div>
              <div
                style={{ display: imageName == "" ? "none" : "block" }}
                className=""
                onClick={handleDeleteImageData}
              >
                {t("Marketplace.DeletephotobTN")}
              </div>
            </div>
          </div>
          <div className="delete-sportModel-Buttons">
            <InputButton
              type={"secondary"}
              inputLabel={t("profile.saveCancel")}
              addBtnClass={"deleteDiscardBtn"}
              onClick={() => setAddEditProductModel(false)}
            />

            {isEditData ? (
              <InputButton
                type={"primary"}
                inputLabel={t("setting.saveChanges")}
                addBtnClass={"deleteConfirmBtn"}
                onClick={handleEditProductData}
                disabled={
                  titleStatusData.title == "" ||
                  titleStatusData.price == "" ||
                  validationErrorMsg ||
                  uploadedImageData == ""
                }
              />
            ) : (
              <InputButton
                type={"primary"}
                inputLabel={t("Marketplace.AddProduct")}
                addBtnClass={"deleteConfirmBtn"}
                onClick={handleAddProductData}
                disabled={
                  titleStatusData.title == "" ||
                  titleStatusData.price == "" ||
                  validationErrorMsg ||
                  uploadedImageData == ""
                }
              />
            )}
          </div>
        </Model>
        {/* purchase product model */}
        <Model
          isModel={purchaseProductModel}
          modelClassName={"edit-delete-buynow-product-prediction-model"}
          hideModel={() => setPurchaseProductModel(false)}
        >
          {Object.keys(selectedProductData)?.length > 0 ? (
            <div>
              <div className="d-flex justify-content-center pb-4">
                <img
                  src={selectedProductData.productImgUrl}
                  width="300px"
                  height="300px"
                  alt=""
                />
              </div>
              <div className="successfully-message text-center pb-2">
                {t("Marketplace.purchaseSuccessfullMsg")}
              </div>
              <div className="decription-message text-center pb-4">
                {t("Marketplace.purchaseMsg1")}
                <span className="main-imformation">
                  {" "}
                  "{selectedProductData.title}"{" "}
                </span>
                {t("Marketplace.purchaseMsg2")}
                <span className="main-imformation">
                  {" "}
                  {apiService.getProfileFromStorage()?.email}{" "}
                </span>
                {t("Marketplace.purchaseMsg3")}
                <span> support@capperauditor.com</span>
              </div>
              <div className="d-flex justify-content-center">
                <InputButton
                  type="primary"
                  addBtnClass={"thankYou-btn purchaseModek-btn"}
                  onClick={handlePurchaseData}
                  inputLabel={t("wallet.OkThanks")}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </Model>

        {/* delete product model */}
        <Model
          isModel={deleteProductModel}
          modelClassName={"edit-delete-prediction-model"}
          hideModel={() => setDeleteProductModel(false)}
          modelTitle={t("Marketplace.DeleteProductTitle")}
        >
          {Object.keys(selectedProductData).length > 0 ? (
            <div>
              <div className="delete-prediction-message">
                {t("Marketplace.DeleteProductMessage")}
                <span className="deleteProduct-name">
                  {" "}
                  “{selectedProductData.title}”{" "}
                </span>
                ?
              </div>

              <div className="delete-sportModel-Buttons">
                <InputButton
                  type={"secondary"}
                  inputLabel={t("error.avatarDeleteDiscard")}
                  addBtnClass={"deleteDiscardBtn"}
                  onClick={() => setDeleteProductModel(false)}
                />
                <InputButton
                  type={"primary"}
                  inputLabel={t("error.avatarDeleteConfirm") + "."}
                  addBtnClass={"deleteConfirmBtn"}
                  onClick={() =>
                    handleDeleteProdictData(selectedProductData._id)
                  }
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </Model>

        {/* login model */}
        <Model
          isModel={predictionModel}
          modelClassName={"home-page-signupPage"}
          hideModel={() => setPredictionModel(false)}
          modelTitle={t("homePageSigninPage.SignIn")}
        >
          <div className="userLoginModel">
            {/* --- signinCapperauditor top --- */}
            <div className="top">
              <div className="btn-containt">
                <button
                  type="button"
                  className="btn  btn-google-facebook btn-google"
                  onClick={() => {
                    window.location.href = process.env.REACT_APP_googleOAuthUrl;
                  }}
                >
                  <span className="signup-icon">
                    <i className="fab fa-google" />
                  </span>
                  {t("homePageSigninPage.isGoogleBtn")}
                </button>
                <button
                  type="button"
                  className="btn btn-google-facebook btn-facebook"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_facebookOAuthUrl;
                  }}
                >
                  <span className="signup-icon">
                    <i
                      className="fab fa-facebook-f"
                      style={{ color: "#4267B2" }}
                    ></i>
                  </span>
                  {t("homePageSigninPage.isFabookBtn")}
                </button>
              </div>
              <div className="text-divider">{t("homePageSigninPage.or")}</div>
            </div>
            {/* ------ signincapperauditor form ------  */}
            <div className="bottom">
              <form>
                <div className={errorClass}>
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label form-input-label"
                  >
                    {t("homePageSigninPage.emailorusername")}
                  </label>
                  <input
                    type="email"
                    className="form-control form-input-field"
                    id="InputEmail"
                    defaultValue={""}
                    onChange={handleLoginEmail}
                    placeholder={t("homePageSigninPage.EnteremailPlaceHolder")}
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      color: "#7F87B3",
                    }}
                  />
                  <p style={{ fontSize: "14px" }} className={error}>
                    {t("SignIn.Youremailorusernameisincorrect")}
                  </p>
                </div>
                <div className="mb-4">
                  <button
                    className="form-control form-input-field-button"
                    id="Inputbutton"
                    onClick={handleEmail}
                    disabled={loginEmail == ""}
                    style={{
                      pointerEvents: loginEmail == "" ? "none" : "auto",
                    }}
                  >
                    {t("homePageSigninPage.Continue")}
                  </button>
                </div>
              </form>
              {/* ------- signincapperauditor footer -----  */}
              <div className="signin-footer">
                <span
                  className="signincapperauditor-bottom"
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    lineHeight: "20px",
                  }}
                >
                  {t("homePageSigninPage.subLine1")}{" "}
                  <Link
                    to="/signup"
                    style={{
                      color: "#4C5480",
                      fontWeight: "600",
                      lineHeight: "24px",
                    }}
                  >
                    {t("homePageSigninPage.subLine2")}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </Model>
      </div>
    </>
  );
};

export default Marketplace;
