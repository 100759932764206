import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { Environment } from "../../environment";
import { eventBus } from "../../services/events";
import { CONSTANT } from "../../common/constant";
import { AuthUtils } from "../../AuthUtils";

import { isValidEmail, passValidation } from "../../helpers/validator";
import apiService from "../../services/apiService";

import Header from "../Header";
import "../../css/signup.css";

const Signup = () => {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [signUpEmail, setSignupEmail] = useState("");
  const [password, setSignupPassword] = useState("");
  const [type, setType] = useState("password");
  const [isValidateSuccess, setIsValidateSuccess] = useState(false);
  const [errorClass, setErrorClass] = useState("mb-3");
  const [error, setError] = useState("");
  const [checkValidation, setCheckValidation] = useState({
    length: "fa fa-check",
    uppercase: "fa fa-check",
    lowercase: "fa fa-check",
    specialChar: "fa fa-check",
    number: "fa fa-check",
  });
  // const navigate = useNavigate()

  useEffect(() => {
    // AuthUtils();
  }, []);

  // console.log("handleAuthPage ::")
  const handleChange = (e) => {
    setSignupPassword(e.target.value);
  };

  const handleEmail = async (e) => {
    // const data = signUpEmail;
    if (!isValidEmail(e.target.value)) {
      setErrorClass("mb-3 form-error");
      setError(t("signUp.InvalidMsg"));
    } else {
      setErrorClass("mb-3");
      setError("");
      setSignupEmail(e.target.value);
    }
    if (e.target.value.length === 0) {
      setErrorClass("mb-3");
      setError("");
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.signUp({
      email: signUpEmail,
      password,
      language: localStorage.getItem("language"),
    });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res.data) {
      navigate("/checkmail", {
        state: {
          email: signUpEmail,
        },
      });
    } else {
      setErrorClass("mb-3 form-error");
      setError(t("signUp.alreadyExistMsg"));
    }
    if (signUpEmail == "") {
      setErrorClass("mb-3 form-error");
      setError(t("signUp.fieldRequired"));
    }
  };

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  useEffect(() => {
    document.title = t("signUp.tabTitle");
    const element = document.querySelector("meta[name='description']");
    element.setAttribute("content", t("signUp.tabDecription"));
  }, []);


  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>{t("signUp.tabTitle")}</title>
        <meta name="description" content={t("signUp.tabDecription")} />
      </Helmet> */}
      <Header />
      <section className="form-section">
        <script>
          {`fbq('track', 'CompleteRegistration', {
            value: 1,
            currency: 'UAH',
          })`}
        </script>
        <div className="container-511 d-flex justify-content-center align-items-center">
          <div className="card signup-main-card w-100">
            <div className="card-body p-0">
              {/* ----- signup top ----- */}
              <div className="top">
                <div className="card-title  signup-card-title mb-4">
                  <p className="mb-2 signUp-page-title">{t("signUp.title")}</p>
                  <p className="signup-subtitle mb-0">{t("signUp.subTitle")}</p>
                </div>

                <div className="btn-containt">
                  <button
                    type="button"
                    className="btn  btn-google-facebook btn-google"
                    onClick={() => {
                      window.location.href =
                        process.env.REACT_APP_googleOAuthUrl;
                    }}
                  >
                    <span className="signup-icon">
                      <i className="fab fa-google" />
                    </span>
                    {t("signUp.isGoogle")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-google-facebook btn-facebook"
                    onClick={() => {
                      window.location.href =
                        process.env.REACT_APP_facebookOAuthUrl;
                    }}
                  >
                    <span className="signup-icon">
                      <i
                        className="fab fa-facebook-f"
                        style={{ color: "#4267B2" }}
                      ></i>
                    </span>
                    {t("signUp.isFaceBook")}
                  </button>
                </div>
                <div className="text-divider"> {t("signUp.or")}</div>
              </div>
              {/* ------ signup form ------  */}
              <div className="bottom">
                <form>
                  <div className={errorClass}>
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label form-input-label"
                    >
                      {t("signUp.Youremail")}
                    </label>
                    <input
                      type="email"
                      className="form-control form-input-field"
                      id="exampleInputEmail1"
                      name="email"
                      placeholder="user@gmail.com"
                      // value={signUpEmail}
                      onChange={handleEmail}
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        color: "#7F87B3",
                      }}
                    />
                    <p style={{ color: "#d14343", fontSize: "14px" }}>
                      {error}
                    </p>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword"
                      className="form-label form-input-label"
                    >
                      {t("signUp.Password")}
                    </label>
                    <div className="input-icons mb-3">
                      <input
                        className="input-field form-control form-input-field signupSuggation"
                        type={type}
                        id="exampleInputPassword"
                        name="password"
                        placeholder={t("signUp.Enteryourpassword")}
                        onChange={handleChange}
                        onKeyUp={(e) =>
                          passValidation(
                            e,
                            setCheckValidation,
                            setIsValidateSuccess
                          )
                        }
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          color: "#7F87B3",
                        }}
                      />
                      <span className="toggle-icon" onClick={handleToggle}>
                        <i
                          className={
                            type === "password"
                              ? "fa fa-eye-slash"
                              : "fa fa-eye"
                          }
                        ></i>
                      </span>
                      <div
                        className="password-suggestion"
                        style={
                          password
                            ? isValidateSuccess
                              ? { display: "none" }
                              : { display: "block" }
                            : { display: "none" }
                        }
                        // style={{ display: "block" }}
                      >
                        <p className="mb-2">
                          {" "}
                          {t("tooltip.Mustcontainatleast")}
                        </p>
                        <ul className="list-unstyled mb-0">
                          <li>
                            <i className={checkValidation.length}></i>{" "}
                            {t("tooltip.characters")}
                          </li>
                          <li>
                            <i className={checkValidation.lowercase}></i>{" "}
                            {t("tooltip.lowercasecharacter")}
                          </li>
                          <li>
                            <i className={checkValidation.uppercase}></i>{" "}
                            {t("tooltip.uppercasecharacter")}
                          </li>
                          <li>
                            <i className={checkValidation.number}></i>{" "}
                            {t("tooltip.number")}
                          </li>
                          <li>
                            <i className={checkValidation.specialChar}></i>{" "}
                            {t("tooltip.specialcharacter")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 button-responsive">
                    <button
                      className="form-control form-input-field-button"
                      onClick={handleSignup}
                      disabled={
                        signUpEmail === "" ||
                        isValidateSuccess === false ||
                        password === "" ||
                        error !== ""
                      }
                      style={{
                        pointerEvents:
                          signUpEmail === "" ||
                          isValidateSuccess === false ||
                          password === "" ||
                          error !== ""
                            ? "none"
                            : "auto",
                      }}
                    >
                      {t("signUp.Continue")}
                    </button>
                  </div>
                </form>
                {/* ---- footer ----- */}
                <div className="register-footer">
                  <p className="register-footer mb-0">
                    <span className="register-footer">
                      {t("signUp.Alreadyaccount")}
                      <Link
                        to="/signin"
                        className="login-link"
                        style={{ color: "#4C5480" }}
                      >
                        {t("signUp.Login")}
                      </Link>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------- footer --------- */}
        <div className="signup-footer text-center mt-3">
          <span className="signup-footer">
            {t("signUp.PrivacyPolicyLine")}
            <Link to="/privacypolicy" className="signup-footer-link ms-1">
              {t("signUp.PrivacyPolicy")}
            </Link>{" "}
          </span>
        </div>
      </section>
    </>
  );
};
export default Signup;
