import React, { useEffect, useState } from "react";
import "./css/inputfeild.css";
import { useTranslation } from "react-i18next";
import { dynamicActivate } from "../../i18n";
import InputButton from "./InputButton";

const InputField = (props) => {
  const { t, i18n } = useTranslation();
  const [inputClass, setInputClass] = useState("form-group");
  const [type, setType] = useState("password");
  const [disabledInput, setDisableInput] = useState(
    "form-control inputfield-tab"
  );
  const [showPass, setShowPassword] = useState(false);
  const handleToggle = () => {
    if (showPass === false) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  };
  useEffect(() => {
    if (props.isError) {
      setInputClass(inputClass + " form-error");
    } else {
      setInputClass("form-group");
    }
    if (props.disabled) {
      setDisableInput(disabledInput + " disable-inputfield");
    } else {
      setDisableInput("form-control inputfield-tab");
    }
    // console.log("disabledInput::", disabledInput)
  }, [props]);

  return (
    <>
      <div className={props.inputClass || "inputfield-container"}>
        <div className="inputfield">
          <div className={inputClass}>
            <div className={props.labelClass || "inputfield-label"}>
              {props.inputLabel}
            </div>
            <div className="input-toggle">
              <input
                ref={props.ref}
                type={
                  props.inputType == "password"
                    ? showPass
                      ? "text"
                      : "password"
                    : props.inputType
                }
                name={props.name}
                className={disabledInput}
                defaultValue={props.defaultValue}
                placeholder={props.inputPlaceHolder}
                onChange={props.onChange}
                disabled={props.disabled}
              />
              <span
                className="eye-toggle-icon"
                onClick={handleToggle}
                style={{ display: props.passShowBtn ? "block" : "none" }}
              >
                <i
                  className={
                    showPass == false ? "fa fa-eye-slash" : "fa fa-eye"
                  }
                ></i>
              </span>
            </div>
            <div
              style={{ display: props.isError ? "block" : "none" }}
              className="error-msg"
            >
              {props.errorMsg}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default InputField;
