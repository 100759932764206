import React from "react";

import "./style.css";

const TournamentTabLoader = () => {
  return (
    <div>
      <div className="fullscreen-addPredictionTable">
        <div className="all-tab-scroll" id="all-tab-scroll">
          <div className="champions-league-table">
            <table width="100%">
              <colgroup>
                <col span="1" style={{ width: "40%" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
              </colgroup>
              <thead>
                <tr className="champions-table-Header">
                  <th className="champions-leaue-country-name">
                    <div className="d-flex align-items-center">
                      <div className="leagues-skeleton-grid-placeholder-title img"></div>
                      <div className="leagues-skeleton-grid-placeholder-title text"></div>
                    </div>
                  </th>
                  <th>
                    <div>1</div>
                  </th>
                  <th>
                    <div>X</div>
                  </th>
                  <th>
                    <div>2</div>
                  </th>
                  <th className="champions-add-btn">+</th>
                </tr>
              </thead>
              <tbody className="champions-leaue-score-body">
                {Array(2)
                  .fill(0)
                  .map((item, index) => {
                    return (
                      <tr key={index} className="leauge-alldata-name-score">
                        <td className="leaue-score champions-leaue-first-score champions-leaue-score-name">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex champions-league-detail w-100">
                              <div className="champions-leaue-score-date-time">
                                <div className="champions-leaue-score-date">
                                  <div className="leagues-skeleton-grid-placeholder date"></div>
                                </div>
                                <div className="champions-leaue-score-timer">
                                  <div className="leagues-skeleton-grid-placeholder time"></div>
                                </div>
                              </div>
                              <div className="logo-leaueName-containt">
                                <div className="champions-leaue-name champions-top d-flex">
                                  <div className="team-name">
                                    <div className="leagues-skeleton-grid-placeholder circle"></div>
                                    <div className="leagues-skeleton-grid-placeholder team1"></div>
                                  </div>
                                </div>
                                <div className="champions-leaue-name d-flex">
                                  <div className="team-name">
                                    <div className="leagues-skeleton-grid-placeholder circle"></div>
                                    <div className="leagues-skeleton-grid-placeholder team2"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="leaue-score champions-leaue-score-data-1">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                        <td className="leaue-score champions-leaue-score-data-X">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                        <td className="leaue-score champions-leaue-score-data-2">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                        <td className="leaue-score champions-leaue-last-score">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <table width="100%">
              <colgroup>
                <col span="1" style={{ width: "40%" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
              </colgroup>
              <thead>
                <tr className="champions-table-Header">
                  <th className="champions-leaue-country-name">
                    <div className="d-flex align-items-center">
                      <div className="leagues-skeleton-grid-placeholder-title img"></div>
                      <div className="leagues-skeleton-grid-placeholder-title text"></div>
                    </div>
                  </th>
                  <th>
                    <div>1</div>
                  </th>
                  <th>
                    <div>X</div>
                  </th>
                  <th>
                    <div>2</div>
                  </th>
                  <th className="champions-add-btn">+</th>
                </tr>
              </thead>
              <tbody className="champions-leaue-score-body">
                {Array(4)
                  .fill(0)
                  .map((item, index) => {
                    return (
                      <tr key={index} className="leauge-alldata-name-score">
                        <td className="leaue-score champions-leaue-first-score champions-leaue-score-name">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex champions-league-detail w-100">
                              <div className="champions-leaue-score-date-time">
                                <div className="champions-leaue-score-date">
                                  <div className="leagues-skeleton-grid-placeholder date"></div>
                                </div>
                                <div className="champions-leaue-score-timer">
                                  <div className="leagues-skeleton-grid-placeholder time"></div>
                                </div>
                              </div>
                              <div className="logo-leaueName-containt">
                                <div className="champions-leaue-name champions-top d-flex">
                                  <div className="team-name">
                                    <div className="leagues-skeleton-grid-placeholder circle"></div>
                                    <div className="leagues-skeleton-grid-placeholder team1"></div>
                                  </div>
                                </div>
                                <div className="champions-leaue-name d-flex">
                                  <div className="team-name">
                                    <div className="leagues-skeleton-grid-placeholder circle"></div>
                                    <div className="leagues-skeleton-grid-placeholder team2"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="leaue-score champions-leaue-score-data-1">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                        <td className="leaue-score champions-leaue-score-data-X">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                        <td className="leaue-score champions-leaue-score-data-2">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                        <td className="leaue-score champions-leaue-last-score">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <table width="100%">
              <colgroup>
                <col span="1" style={{ width: "40%" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
                <col span="1" style={{ width: "47px" }} />
              </colgroup>
              <thead>
                <tr className="champions-table-Header">
                  <th className="champions-leaue-country-name">
                    <div className="d-flex align-items-center">
                      <div className="leagues-skeleton-grid-placeholder-title img"></div>
                      <div className="leagues-skeleton-grid-placeholder-title text"></div>
                    </div>
                  </th>
                  <th>
                    <div>1</div>
                  </th>
                  <th>
                    <div>X</div>
                  </th>
                  <th>
                    <div>2</div>
                  </th>
                  <th className="champions-add-btn">+</th>
                </tr>
              </thead>
              <tbody className="champions-leaue-score-body">
                {Array(5)
                  .fill(0)
                  .map((item, index) => {
                    return (
                      <tr key={index} className="leauge-alldata-name-score">
                        <td className="leaue-score champions-leaue-first-score champions-leaue-score-name">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex champions-league-detail w-100">
                              <div className="champions-leaue-score-date-time">
                                <div className="champions-leaue-score-date">
                                  <div className="leagues-skeleton-grid-placeholder date"></div>
                                </div>
                                <div className="champions-leaue-score-timer">
                                  <div className="leagues-skeleton-grid-placeholder time"></div>
                                </div>
                              </div>
                              <div className="logo-leaueName-containt">
                                <div className="champions-leaue-name champions-top d-flex">
                                  <div className="team-name">
                                    <div className="leagues-skeleton-grid-placeholder circle"></div>
                                    <div className="leagues-skeleton-grid-placeholder team1"></div>
                                  </div>
                                </div>
                                <div className="champions-leaue-name d-flex">
                                  <div className="team-name">
                                    <div className="leagues-skeleton-grid-placeholder circle"></div>
                                    <div className="leagues-skeleton-grid-placeholder team2"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="leaue-score champions-leaue-score-data-1">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                        <td className="leaue-score champions-leaue-score-data-X">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                        <td className="leaue-score champions-leaue-score-data-2">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                        <td className="leaue-score champions-leaue-last-score">
                          <div className="leagues-skeleton-grid-placeholder"></div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentTabLoader;
