import React from "react";
import { Accordion } from "react-bootstrap";
import './Accordion.css'

const HomePageAccordion = (props) => {
    return (
        <>
            <div className="home-page-Accordion">
                <Accordion defaultActiveKey={props.EventKey}>
                    <Accordion.Item>
                        <Accordion.Header><div className="d-flex">
                            <div className="home-accordion-Header">{props.accordionHeader}</div>
                        </div>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="home-accordion-decription">
                                {props.accordionDecription} {props.addSpan ? props.addSpan : ""}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </div>
        </>
    )
}
export default HomePageAccordion;