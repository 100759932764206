import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/resetpassword.css";
import apiService from "../services/apiService";
import Header from "./Header";
import { eventBus } from "../services/events";
import { CONSTANT } from "../common/constant";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { AuthUtils } from "../AuthUtils";
import { useEffect } from "react";

const ResetPassword = () => {
  const { t, i18n } = useTranslation();
  const [resetEmail, setResetemail] = useState("");
  const [errorClass, setErrorClass] = useState("mb-3");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const isValidEmail = (email) => {
    const regexEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regexEmail.test(email);
  };
  useEffect(() => {
    AuthUtils()
  }, [])
  const handleEmail = async (e) => {
    // const data = signUpEmail;

    setResetemail(e.target.value);
    if (e.target.value.length == 0) {
      setErrorClass("mb-3");
      setError("");
    }
  };
  useEffect(() => {
    if (localStorage.getItem("token")) {
      navigate("/userprofile")
    }
  }, [])
  const handleResetEmail = async (e) => {
    e.preventDefault();
    if (!isValidEmail(resetEmail)) {
      setErrorClass("mb-3 form-error");
      setError(t("resetPassWord.errorMsg"));
    } else {
      setErrorClass("mb-3");
      setError("");
    }
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.resetPassword({ email: resetEmail });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res.data) {
      navigate("/returnlogin", {
        state: {
          email: resetEmail,
        },
      });
    }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("resetPassWord.tabTitle")}</title>
      </Helmet>
      <Header />
      <section className="">
        <div className="container resetpassword-container">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="card resetpassword-card">
              <div className="card-body p-0">
                {/* ------ avatar ----- */}
                <div className="resetpassword-avatar">
                  <div
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      width: "64px",
                      height: "64px",
                      backgroundColor: "#F2F3F7",
                    }}
                    alt="Avatar"
                  >
                    <img src="Password.svg" />
                  </div>
                </div>
                <div className="card-title mt-4">
                  <div className=" text-center">
                    <p className="mb-2 reset-title">
                      {t("resetPassWord.resendEmailTitle")}
                    </p>

                    <p
                      className="text-center mt-2 mb-4 reset-subtitle"
                      style={{ fontSize: "16px", color: "#7F87B3" }}
                    >
                      {t("resetPassWord.decriptMsg")}
                    </p>
                  </div>
                </div>
                <form>
                  <div className={errorClass}>
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label form-input-label"
                    >
                      {t("resetPassWord.Email")}
                    </label>
                    <input
                      type="email"
                      className="form-control form-input-field"
                      id="InputEmail"
                      aria-describedby="emailHelp"
                      placeholder={t("resetPassWord.Enteryouremailaddress")}
                      // value={resetEmail}
                      onChange={handleEmail}
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        color: "#7F87B3",
                      }}
                    />
                    {error && (
                      <p style={{ color: "#d14343", fontSize: "14px" }}>
                        {error}
                      </p>
                    )}
                  </div>
                  <div className="">
                    <button
                      className="form-control mb-0 form-input-field-button"
                      id="exampleInputbutton"
                      onClick={handleResetEmail}
                      disabled={resetEmail == "" || error == ""}
                      style={{
                        pointerEvents: resetEmail == "" ? "none" : "auto",
                      }}
                    >
                      {t("resetPassWord.Resetpassword")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ResetPassword;
