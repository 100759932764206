import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { Environment } from "../../environment";
import { eventBus } from "../../services/events";
import { CONSTANT } from "../../common/constant";
import { isValidEmail } from "../../helpers/validator";

import apiService from "../../services/apiService";

import Header from "../Header";
import "../../css/signinCapperauditor.css";

const SigninCapperauditor = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [loginEmail, setLoginEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [errorClass, setErrorClass] = useState("mb-3");
  const [error, setError] = useState("");

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    if (localStorage.getItem("token")) {
      const res = await apiService.getUserName({ email: loginEmail });
      if (res.data?.isVerifyEmail) {
        navigate("/userprofile");
      }
    }
  };

  const handleEmail = async (e) => {
    e.preventDefault();
    eventBus.emit(CONSTANT.SHOW_LOADER);

    const res = await apiService.getUserName({ email: loginEmail });

    if (res.data?.isGoogle) {
      setErrorClass("mb-3 form-error");
      setError(t("SignIn.isGoogleMsg"));
    } else if (res.data?.isFacebook) {
      setErrorClass("mb-3 form-error");
      setError(t("SignIn.isFaceBookMsg"));
    } else {
      eventBus.emit(CONSTANT.HIDE_LOADER);
      console.log(res.data)
      if (res.data) {
        if (res.data?.isVerifyEmail) {
          navigate("/login", {
            state: {
              email: loginEmail,
              userName: userName,
              userEmail: userEmail,
              name: res.data.userName,
              profileImgUrl: res?.data.profileImgUrl,
            },
          });
        } else {
          navigate("/checkmail", {
            state: {
              email: loginEmail,
            },
          });
        }
      } else {
        setErrorClass("mb-3 form-error");
        setError(t("SignIn.Youremailorusernameisincorrect"));
      }
    }
  };

  const handleLoginEmail = (e) => {
    setError("");
    if (!isValidEmail(e.target.value)) {
      // username
      setUserName(e.target.value);
    } else {
      // email
      setUserEmail(e.target.value);
    }
    setLoginEmail(e.target.value);
    if (e.target.value == "") {
      setErrorClass("mb-3");
      setError("");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("SignIn.tabTitle")}</title>
      </Helmet>
      <Header />
      <section className="form-section">
        <div className="container-511 d-flex justify-content-center align-items-center">
          <div className="card signup-main-card w-100">
            <div className="card-body p-0">
              {/* --- signinCapperauditor top --- */}
              <div className="top">
                <div
                  className="card-title signinCapperauditor-card-title"
                  style={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    fontWeight: "700",
                    textAlign: "center",
                  }}
                >
                  {t("SignIn.SignintoCapperauditor")}
                </div>

                <div className="btn-containt">
                  <button
                    type="button"
                    className="btn  btn-google-facebook btn-google"
                    onClick={() => {
                      window.location.href =
                        process.env.REACT_APP_googleOAuthUrl;
                    }}
                  >
                    <span className="signup-icon">
                      <i className="fab fa-google" />
                    </span>
                    {t("SignIn.ContinuewithGoogle")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-google-facebook btn-facebook"
                    onClick={() => {
                      window.location.href =
                        process.env.REACT_APP_facebookOAuthUrl;
                    }}
                  >
                    <span className="signup-icon">
                      <i
                        className="fab fa-facebook-f"
                        style={{ color: "#4267B2" }}
                      ></i>
                    </span>
                    {t("SignIn.ContinuewithFacebook")}
                  </button>
                </div>
                <div className="text-divider">{t("SignIn.or")}</div>
              </div>
              {/* ------ signincapperauditor form ------  */}
              <div className="bottom">
                <form>
                  <div className={errorClass}>
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label form-input-label"
                    >
                      {t("SignIn.Youremailorusername")}
                    </label>
                    <input
                      type="email"
                      className="form-control form-input-field"
                      id="InputEmail"
                      onChange={handleLoginEmail}
                      placeholder={t("SignIn.Enteryouremailusername")}
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        color: "#7F87B3",
                      }}
                    />
                    <p style={{ fontSize: "14px" }}>{error}</p>
                  </div>
                  <div className="mb-4">
                    <button
                      className="form-control form-input-field-button"
                      id="Inputbutton"
                      onClick={handleEmail}
                      disabled={loginEmail === "" || error !== ""}
                      style={{
                        pointerEvents: loginEmail === "" ? "none" : "auto",
                      }}
                    >
                      {t("SignIn.Continue")}
                    </button>
                  </div>
                </form>
                {/* ------- signincapperauditor footer -----  */}
                <div className="signin-footer">
                  <span
                    className="signincapperauditor-bottom"
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      lineHeight: "20px",
                    }}
                  >
                    {t("SignIn.Donthaveaccount")}
                    <a
                      href="/signup"
                      className="ps-1"
                      style={{
                        color: "#4C5480",
                        fontWeight: "600",
                        lineHeight: "24px",
                      }}
                    >
                      {t("SignIn.SignUp")}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default SigninCapperauditor;
