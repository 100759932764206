import React from "react";

import "./style.css";

const TournamentTabLoaderMobile = () => {
  return (
    <div>
      <div className="smallScreen-addPredictionTable">
        <div className="all-tab-scroll" id="all-tab-scroll">
          <div className="champions-league-table">
            <div className="w-100 skeleton-header">
              <div className="d-flex align-items-center">
                <div className="leagues-skeleton-grid-placeholder-title img"></div>
                <div className="leagues-skeleton-grid-placeholder-title text"></div>
              </div>
            </div>
            {Array(3)
              .fill(0)
              .map((item, index) => {
                return (
                  <div key={index} className="champions-table-containts">
                    <div className="champions-leaue-score-date-time ">
                      <div className="champions-leaue-score-date-skeleton pb-3">
                        <div className="leagues-skeleton-grid-placeholder date-time"></div>
                      </div>
                    </div>
                    <div
                      className="d-flex champions-league-detail w-100"
                      style={{ maxWidth: "100%" }}
                    >
                      <div className="logo-leaueName-containt">
                        <div className="champions-leaue-name-skeleton champions-top">
                          <div className="leagues-skeleton-grid-placeholder circle"></div>
                          <div className="leagues-skeleton-grid-placeholder team1"></div>
                        </div>
                        <div className="champions-leaue-name-skeleton">
                          <div className="leagues-skeleton-grid-placeholder circle"></div>
                          <div className="leagues-skeleton-grid-placeholder team2"></div>
                        </div>
                      </div>
                    </div>
                    <div className="odds-grid-1">
                      <div className="leagues-skeleton-grid-placeholder odd"></div>
                      <div className="leagues-skeleton-grid-placeholder odd"></div>
                      <div className="leagues-skeleton-grid-placeholder odd"></div>
                      <div className="leagues-skeleton-grid-placeholder odd"></div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="champions-league-table">
            <div className="w-100 skeleton-header">
              <div className="d-flex align-items-center">
                <div className="leagues-skeleton-grid-placeholder-title img"></div>
                <div className="leagues-skeleton-grid-placeholder-title text"></div>
              </div>
            </div>
            {Array(4)
              .fill(0)
              .map((item, index) => {
                return (
                  <div key={index} className="champions-table-containts">
                    <div className="champions-leaue-score-date-time ">
                      <div className="champions-leaue-score-date-skeleton pb-3">
                        <div className="leagues-skeleton-grid-placeholder date-time"></div>
                      </div>
                    </div>
                    <div
                      className="d-flex champions-league-detail w-100"
                      style={{ maxWidth: "100%" }}
                    >
                      <div className="logo-leaueName-containt">
                        <div className="champions-leaue-name-skeleton champions-top">
                          <div className="leagues-skeleton-grid-placeholder circle"></div>
                          <div className="leagues-skeleton-grid-placeholder team1"></div>
                        </div>
                        <div className="champions-leaue-name-skeleton">
                          <div className="leagues-skeleton-grid-placeholder circle"></div>
                          <div className="leagues-skeleton-grid-placeholder team1"></div>
                        </div>
                      </div>
                    </div>
                    <div className="odds-grid-1">
                      <div className="leagues-skeleton-grid-placeholder odd"></div>
                      <div className="leagues-skeleton-grid-placeholder odd"></div>
                      <div className="leagues-skeleton-grid-placeholder odd"></div>
                      <div className="leagues-skeleton-grid-placeholder odd"></div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentTabLoaderMobile;
