import React from "react";

import "./style.css";

const GamePageLoader = () => {
  return (
    <div className="extra-odds-page">
      <div>
        {/* header --------- */}

        <div className="extra-Champions-League">
          <div className="back-btn">
            <img src="Arrow---Left.svg" alt=""></img>
          </div>
          <div className="Champions-League-name">
            <span className="game-title">
              <div className="game-skeleton-grid-placeholder-title img"></div>
              <div className="game-skeleton-grid-placeholder-title text"></div>
            </span>
          </div>
        </div>
        <div className="teamName-data-time">
          <div className="match-date-time">
            <div className="game-skeleton-grid-placeholder date"></div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="d-flex align-items-center eventListOpp_1_name">
              <div className="team-1-name-sklt ">
                <div className="game-skeleton-grid-placeholder opp-name"></div>
              </div>
              <span
                style={{
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
              >
                <div className="game-skeleton-grid-placeholder opp-img"></div>
              </span>
            </div>

            <div className="minus-btn">-</div>

            <div className="d-flex align-items-center eventListOpp_2_name">
              <span
                style={{
                  marginLeft: "16px",
                  marginRight: "16px",
                }}
                className=""
              >
                <div className="game-skeleton-grid-placeholder opp-img"></div>
              </span>
              <div className="team-2-name-sklt">
                <div className="game-skeleton-grid-placeholder opp-name"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-predictions">
          <div className="game-skeleton-grid-placeholder title"></div>
          <div className="predictions-container">
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
          </div>
          <div className="game-skeleton-grid-placeholder title2"></div>
          <div className="predictions-container">
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
          </div>
        </div>

        <div className="prediction-table">
          <div className="game-skeleton-grid-placeholder title"></div>
          <div className="odds-grid">
            <div></div>
            <div className="game-skeleton-grid-placeholder odd-title"></div>
            <div className="game-skeleton-grid-placeholder odd-title"></div>
            
            <div className="odd-wrapper">
              <div className="game-skeleton-grid-placeholder odd-coef"></div>
            </div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            
            <div className="odd-wrapper">
              <div className="game-skeleton-grid-placeholder odd-coef"></div>
            </div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            
            <div className="odd-wrapper">
              <div className="game-skeleton-grid-placeholder odd-coef"></div>
            </div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
          </div>
        </div>

        <div className="main-predictions">
          <div className="game-skeleton-grid-placeholder title"></div>
          <div className="predictions-container">
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
          </div>
          <div className="predictions-container">
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
          </div>
          <div className="predictions-container">
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
          </div>
        </div>

        <div className="main-predictions">
          <div className="game-skeleton-grid-placeholder title"></div>
          <div className="predictions-container">
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
          </div>
          <div className="predictions-container">
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
            <div className="game-skeleton-grid-placeholder odd"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GamePageLoader;
