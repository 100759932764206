import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import "./css/inputbutton.css";

const InputButton = (props) => {
  const [buttonStyle, setButtonStyle] = useState("input-button");
  useEffect(() => {
    if (props.type === "primary") {
      if (props.addBtnClass) {
        setButtonStyle("input-button" + " " + props.addBtnClass);
      } else {
        setButtonStyle("input-button");
      }
    }
    if (props.type === "secondary") {
      if (props.addBtnClass) {
        setButtonStyle("secondary-btn" + " " + props.addBtnClass);
      } else {
        setButtonStyle("secondary-btn");
      }
    }
    if (props.type === "icon-button") {
      if (props.addBtnClass) {
        setButtonStyle("icon-button" + " " + props.addBtnClass);
      } else {
        setButtonStyle("icon-button");
      }
    }
    if (props.type === "filter-button") {
      if (props.addBtnClass) {
        setButtonStyle("icon-button" + " " + props.addBtnClass);
      } else {
        setButtonStyle("icon-button");
      }
    }
    if (props.type === "edit-delete-btn") {
      if (props.addBtnClass) {
        setButtonStyle("icon-button" + " " + props.addBtnClass);
      } else {
        setButtonStyle("icon-button");
      }
    }
  }, [props]);

  return (
    <>
      <div
        className={props.addMainClass}
        style={{ pointerEvents: props.pointerEventsStyle && "none" }}
      >
        {props.disabled ? (
          <div className="searchbarLocked-tooltip filter-button-tooltip">
            {props.lockSearchBarMsg}
          </div>
        ) : (
          ""
        )}
        <button
          disabled={props.disabled || false}
          className={buttonStyle}
          id={props.id && props.id}
          onClick={props.onClick}
          // style={{ minWidth: props.style && handleSportLineMinWidth(props.style), maxWidth: props.style && handleSportMaxWidth(props.style) }}
        >
          <div
            className={`d-flex ${
              props.addinnerClass
                ? props.addinnerClass
                : "justify-content-center"
            } align-items-center w-100`}
          >
            {props.type == "icon-button" ||
            props.type == "edit-delete-btn" ||
            props.type == "filter-button"
              ? props.addSpan
              : props.btnImg && (
                  <span className="copybtn-span">
                    <img src={props.btnImg} alt="" />
                  </span>
                )}
            <div className="sportline-filter-text-overflow">
              {props.showLoader ? (
                <Spinner animation="border" variant="secondary" />
              ) : (
                props.inputLabel
              )}
            </div>
          </div>
          {props.addEditBtn ? props.addEditBtn : ""}
          {props.addRemoveBtn ? props.addRemoveBtn : ""}
          {props.filterCloseIcon ? props.filterCloseIcon : ""}
        </button>
      </div>
    </>
  );
};
export default InputButton;
