export const isValidUserName = (username, t) => {
  // const regexLetter = /^[ A-Za-z0-9_]*$/;
  const regexLetter = /^[a-z0-9_A-Z ]*$/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  let nameValidation = "";
  let isValid = true;
  if (specialCharRegExp.test(username)) {
    nameValidation = t("register.noSymbollOnlyCharMsg");
    isValid = false;
    return { nameValidation, isValid };
  }
  if (!regexLetter.test(username)) {
    nameValidation = t("register.min5Max15ErrorMsg");
    isValid = false;
    return { nameValidation, isValid };
  }
  if (username.length < 5 || username.length > 20) {
    nameValidation = t("register.lengthCountMsg");
    isValid = false;
    return { nameValidation, isValid };
  }

  return {
    nameValidation,
    isValid: true,
  };
};

export const isValidFullName = (name, t) => {
  const regexFilter = /^[a-zA-Zа-яА-ЯіїєґҐІЇЄ\s]*$/;

  let nameValidation = "";
  let isValid = true;

  if (!regexFilter.test(name) || name.length < 3) {
    nameValidation = t("register.NameonlyLettersMsg");
    isValid = false;
    return { nameValidation, isValid };
  }

  return {
    nameValidation,
    isValid: true,
  };
};

export const isValidName = (name, type, t) => {
  const regexLetter = /^[ A-Za-z]*$/;
  const digitsRegExp = /(?=.*?[ 0-9])/;
  const specialCharRegExp = /(?=.*?[#()?!@$%^&*-])/;
  const uaRegexLetter = /^[a-zA-Z\u0400-\u04FF]+$/;
  let nameValidation = "";
  let isValid = true;
  if (digitsRegExp.test(name) || specialCharRegExp.test(name)) {
    nameValidation = nameValidation =
      type === "lastName"
        ? t("register.userNameonlyLettersMsg")
        : t("register.NameonlyLettersMsg");
    isValid = false;
    return { nameValidation, isValid };
  }
  if (name.length < 3 || name.length > 18 || !uaRegexLetter.test(name)) {
    nameValidation =
      type === "lastName"
        ? t("register.lastmin3Max15ErrorMsg")
        : t("register.Namemin3Max15ErrorMsg");
    isValid = false;
    return { nameValidation, isValid };
  }
  return {
    nameValidation,
    isValid: true,
  };
};

export const passValidation = (e, setCheckValidation, setIsValidateSuccess) => {
  const value = e.target.value;
  const passwordInputFeildName = e.target.name;
  if (passwordInputFeildName === "password") {
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{12,}/;
    const uppercasePassword = uppercaseRegExp.test(value);
    const lowercasePassword = lowercaseRegExp.test(value);
    const digitsPassword = digitsRegExp.test(value);
    const specialCharPassword = specialCharRegExp.test(value);
    const minLengthPassword = minLengthRegExp.test(value);
    if (minLengthPassword) {
      setCheckValidation((oldState) => {
        return { ...oldState, length: "fa fa-check text-green" };
      });
    } else {
      setCheckValidation((oldState) => {
        return { ...oldState, length: "fa fa-check" };
      });
    }
    if (lowercasePassword) {
      setCheckValidation((oldState) => {
        return { ...oldState, lowercase: "fa fa-check text-green" };
      });
    } else {
      setCheckValidation((oldState) => {
        return { ...oldState, lowercase: "fa fa-check" };
      });
    }
    if (uppercasePassword) {
      setCheckValidation((oldState) => {
        return { ...oldState, uppercase: "fa fa-check text-green" };
      });
    } else {
      setCheckValidation((oldState) => {
        return { ...oldState, uppercase: "fa fa-check" };
      });
    }
    if (digitsPassword) {
      setCheckValidation((oldState) => {
        return { ...oldState, number: "fa fa-check text-green" };
      });
    } else {
      setCheckValidation((oldState) => {
        return { ...oldState, number: "fa fa-check" };
      });
    }
    if (specialCharPassword) {
      setCheckValidation((oldState) => {
        return { ...oldState, specialChar: "fa fa-check text-green" };
      });
    } else {
      setCheckValidation((oldState) => {
        return { ...oldState, specialChar: "fa fa-check" };
      });
    }

    if (
      uppercasePassword &&
      lowercasePassword &&
      minLengthPassword &&
      digitsPassword &&
      specialCharPassword
    ) {
      setIsValidateSuccess(true);
    } else {
      setIsValidateSuccess(false);
    }
  }
};

export const isValidEmail = (email) => {
  const regexEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return regexEmail.test(email);
};
