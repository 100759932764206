import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InputButton from "../molecule/InputButton";

const ProductCard = ({ item, handlePurchaseProductModel, coins }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="product-card-div">
      <div className="d-flex justify-content-center pb-4">
        <img src={item.productImgUrl} className="product-div" alt="" />
      </div>
      <div>
        <div className="product-name pb-3">{item.title}</div>
        <div className="d-flex align-items-center justify-content-between">
          {/* cost */}
          <div>
            <div className="costTitle-name pb-1">
              {t("Marketplace.costTitle")}
            </div>
            <div className="d-flex align-items-center">
              <img src="/Marketplace-img/cost-image.svg" alt="" />
              <div className="ps-2 product-cost-amount">{item?.price}</div>
            </div>
          </div>
          {item.status === "Active" ? (
            <InputButton
              type={"primary"}
              inputLabel={t("Marketplace.BuyNowBtn")}
              addBtnClass={"MarketplaceBtn"}
              onClick={() => handlePurchaseProductModel(item)}
              disabled={
                coins
                  ? item.price >= coins
                  : false
              }
              lockSearchBarMsg={
                <div>
                  {t("Marketplace.disableBtnTooltip")}{" "}
                  <a
                    href="/wallet"
                    style={{
                      color: "#F6E7AD",
                      cursor: "pointer",
                      textDecoration: "none",
                    }}
                  >
                    {t("Marketplace.toolTipHear")}
                  </a>
                </div>
              }
              addMainClass={"filter-button-lock-tooltip"}
            />
          ) : (
            <InputButton
              type={"primary"}
              inputLabel={t("Marketplace.soldOutBtn")}
              addBtnClass={"MarketplaceBtn sold-out-btn"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
