import React, { useEffect } from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputField from '../../../molecule/InputField';
import InputButton from '../../../molecule/InputButton';
import apiService from '../../../../services/apiService';

const GenaralSetting = () => {
    const { t, i18n } = useTranslation();
    const [pageRefresh, setPageRefresh] = useState(0);
    const [getAdminData, setAdminData] = useState({})
    const [signupReward, setSignUpReward] = useState("")
    const getSignUpReward = async () => {
        const res = await apiService.getSignUpReward();
        setAdminData(res)
    }
    useEffect(() => {
        getSignUpReward()
    }, [pageRefresh])
    const handleSignUpReward = async () => {
        const res = await apiService.updateSignUpReward(signupReward)
        if (res) {
            setPageRefresh(pageRefresh + 1)
        }

    }
    return (
        <>
            <div>
                <div className="users-top-div">
                    <div className="User-panel-title pb-1">
                        {t("admin.GeneralsettingsTitle")}
                    </div>
                    <div className="Let-check-status">{t("admin.GeneralsettingsSubTitle")}</div>
                </div>
                <div>
                    <InputField inputLabel={t("admin.Signupreward")} defaultValue={getAdminData.SignUpReward} onChange={(e) => setSignUpReward(e.target.value)} inputType="text" inputPlaceHolder={""} />
                </div>
                <InputButton
                    type={"primary"}
                    inputLabel={t("setting.saveChanges")}
                    addBtnClass={"deleteConfirmBtn"}
                    onClick={handleSignUpReward}
                    disabled={signupReward == ""}
                />
            </div>
        </>
    )
}

export default GenaralSetting
