import React from "react";
import "../horizontalForm/HorizontalFeild.css";
import { Form, InputGroup } from "react-bootstrap";
import { useEffect } from "react";
const HorizontalFeild = (props) => {
  useEffect(() => {}, [props]);
  return (
    <>
      <div>
        <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
          Username
        </Form.Label>
        <InputGroup className="mb-2">
          <InputGroup.Text style={{ paddingRight: props.inputStyle && "26px" }}>
            {props.inputLabel}
          </InputGroup.Text>
          <Form.Control
            id="inlineFormInputGroup"
            placeholder="Username"
            onChange={props.onChange}
            value={props.value ? props.value : ""}
          />
        </InputGroup>
      </div>
    </>
  );
};
export default HorizontalFeild;
