import Recat, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/apiService';
import { eventBus } from '../services/events';
import { CONSTANT } from "../common/constant"

const VerifyEmail = () => {
    const navigate = useNavigate();
    const url = new URL(window.location.href);
    let token = url.searchParams.get("token");

    const handleEmailVerify = async () => {
        eventBus.emit(CONSTANT.SHOW_LOADER)
        const res = await apiService.verifyEmail(token);
        eventBus.emit(CONSTANT.HIDE_LOADER)
        if (res.data) {
            navigate("/signin")
        }
    }
    useEffect(() => {
        handleEmailVerify()
    }, [])
    return (
        <>
        </>
    )
}
export default VerifyEmail;