import React from "react";
import { useMediaQuery } from "react-responsive";

import "./style.css";

const WalletRewardsLoader = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const renderMobileSubItems = () => {
    return (
      <>
        <div className="skeleton-placeholder-light rewards-loader-subitem_img"></div>
        <div className="skeleton-placeholder-light rewards-loader-subitem_title"></div>
        <div className="rewards-loader-subitem_top-info-wrapper">
          <div className="skeleton-placeholder-light rewards-loader-subitem_info"></div>
          <div className="skeleton-placeholder-light rewards-loader-subitem_info"></div>
        </div>
        <div className="skeleton-placeholder-light rewards-loader-subitem_info"></div>
      </>
    );
  };

  const renderSubItems = () => {
    return (
      <>
        <div className="skeleton-placeholder-light rewards-loader-subitem_img"></div>
        <div className="rewards__loader-sub-item_title-wrapper">
          <div className="skeleton-placeholder-light rewards-loader-subitem_title"></div>
          <div className="rewards-loader-subitem_info-section rewards-loader-subitem_info-wallet-section">
            <div className="rewards-loader-subitem_info-item1">
              <div className="skeleton-placeholder-light rewards-loader-info-item-title"></div>
              <div className="skeleton-placeholder-light rewards-loader-info-item-value"></div>
            </div>
            <div className="rewards-loader-subitem_info-item2 wallet-rewards-ifo-item wallet-rewards-item-progress">
              <div className="skeleton-placeholder-light rewards-loader-info-item-title"></div>
              <div className="skeleton-placeholder-light rewards-loader-info-item-value wallet-rewards-info-item-progress"></div>
            </div>
            <div className="rewards-loader-subitem_info-item3">
              <div className="skeleton-placeholder-light rewards-loader-info-item-title"></div>
              <div className="skeleton-placeholder-light rewards-loader-info-item-value"></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="wallet-rewards-items">
        {Array(4)
          .fill(0)
          .map((item, index) => {
            return (
              <div
                className="wallet-rewards-item_wrapper wallet-rewards-item-loader"
                key={index}
              >
                <div className="rewards__category-sub-item">
                  {isMobile ? renderMobileSubItems() : renderSubItems()}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default WalletRewardsLoader;
