import React from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { clearData, getSportEvents } from "../../../store/betSlice";

const NoGamePage = ({ setShowMobilePredictionNotification }) => {
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({});

  const dispatch = useDispatch();

  const handleClick = () => {
    setSearchParams(
      (prev) => {
        prev.set("key", "home");
        prev.delete("country");
        prev.delete("sport");
        prev.delete("league");
        prev.delete("game");
        return prev;
      },
      { replace: true }
    );

    setShowMobilePredictionNotification(true);
    dispatch(clearData());
  };

  const handleBack = () => {
    setSearchParams(
      (prev) => {
        prev.delete("game");
        return prev;
      },
      { replace: true }
    );

    setShowMobilePredictionNotification(true);
    dispatch(clearData());
  };

  return (
    <div className="no-game-container">
      <img
        className="no-game-arrow"
        src="/Arrow---Left.svg"
        alt="no-game-arrow"
        onClick={handleBack}
      ></img>
      <img className="no-game-img" src="/no-game.svg" alt="no-game"></img>
      <div className="no-game-text-wrapper">
        <div className="no-game-title">{t("noGamePage.title")}</div>
        <div className="no-game-text">{t("noGamePage.text")}</div>
      </div>
      <div className="no-game-btn" onClick={handleClick}>
        {t("noGamePage.btn")}
      </div>
    </div>
  );
};

export default NoGamePage;
