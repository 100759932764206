import { useEffect } from "react";
import NavigationBar from "../../Navbar";
import HomePageHeader from "../homepage/HomePageHeader/HomePageHeader";
import { useTranslation } from "react-i18next";
import {
  Link,
  json,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import InputButton from "../InputButton";
import Model from "../model/Model";
import { useState } from "react";
import HorizontalFeild from "../horizontalForm/HorizontalFeild";
import { CONSTANT } from "../../../common/constant";
import { eventBus } from "../../../services/events";
import apiService from "../../../services/apiService";
import moment from "moment";
import PredictionsTable from "./PredictionsTable";
// import PerfectPredictorTable from "./PerfectPredictorTable";

const TournamentDetailPage = () => {
  const pageSize = 10;
  let { giveAwayid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [errorClass, setErrorClass] = useState("mb-3");
  const [error, setError] = useState("form-error-line-hide");
  const [loginEmail, setLoginEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [isGiveAways, setIsGiveAways] = useState(false);
  const [ConfirmSubscription, setConfirmSubscription] = useState(false);
  const [ismakePrediction, setIsMakePrediction] = useState(false);
  const [isUSerList, setIsUserList] = useState(false);
  const [SubscriptionModelTitle, setSubscriptionModelTitle] = useState("");
  const [SubscriptionModelBtn, setSubscriptionModelBtn] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [isGetGiveaway, setGetGiveaway] = useState({});
  const [instagramUserName, setInstagramUserName] = useState("");
  const [TiktokUserName, setTiktokUserName] = useState("");
  const [isConfirmSubscription, setIsConfirmSubscription] = useState(false);
  const [loginUser, setLoginUser] = useState({});
  const [correctScoreActive, setCorrectScoreActive] = useState("");
  const [countData, setCountData] = useState(0);
  const [getCorrectScoreData, setgetCorrectScoreData] = useState({});
  const [isTeamWinner, setIsTeamWinner] = useState(false);
  const [PerfectPredictors, setPerfectPredictors] = useState([]);
  const [winnerAndOtherArray, setWinnerAndOtherArray] = useState([]);
  const [isUserWinner, setisUserWinner] = useState(false);
  // getournamentData
  const getGiveaway = async () => {
    const res = await apiService.getGiveaway(giveAwayid);
    res.correctScore?.map((item) => {
      item?.predictions?.map((i) => {
        if (i.userId._id == localStorage.getItem("userId")) {
          item.isUser = true;
        }
        // else {
        //   item.isUser = false;
        // }
      });
      if (item.winningScore == true) {
        setPerfectPredictors(item?.predictions);
        setIsTeamWinner(true);
      }
    });
    let currentTimeInSeconds = Math.floor(Date.now() / 1000);
    if (res.giveawayDetail?.game_start <= currentTimeInSeconds) {
      res.isEventStart = true;
    }
    if (res) {
      setGetGiveaway(res);
    }
  };
  const getWinnersAndOtherParticipateOfWinners = async () => {
    const res = await apiService.getWinnersAndOtherParticipateOfWinners(
      giveAwayid
    );
    setWinnerAndOtherArray(res);
  };
  const incrementCount = () => {
    setCountData(countData + 1);
  };
  useEffect(() => {
    getWinnersAndOtherParticipateOfWinners();
  }, [countData, location.state]);

  useEffect(() => {
    getGiveaway();
  }, [countData, location.state, navigate]);

  const getUserProfile = async () => {
    const res = await apiService.getUserProfile();
    setLoginUser(res);
  };
  useEffect(() => {
    getUserProfile();
  }, [countData]);
  const handleLanguageLocalization = (data) => {
    var localLocale = moment.unix(data);
    localLocale.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
    return (
      localLocale.format("MMMM D").charAt(0).toUpperCase() +
      localLocale.format("MMMM D").slice(1)
    );
  };
  // userlist popup
  const handleMorePredictionModel = async (length, data) => {
    if (length > 0) {
      setIsUserList(true);
      const res = await apiService.getpredictionParticipate(
        data.oc_pointer,
        isGetGiveaway._id
      );
      setgetCorrectScoreData(res);
    } else {
      setIsUserList(false);
    }
  };

  const handleMorePredictionModelHide = () => {
    setIsUserList(false);
  };
  // confirmation model
  const handleConfirmSubscriptionBtn = async () => {
    setSubscriptionModelTitle(
      t("giveAways.ConfirmSubscription.ConfirmSubscriptionTitle")
    );
    setSubscriptionModelBtn(
      t("giveAways.ConfirmSubscription.ConfirmSubscriptionTitle")
    );
    setConfirmSubscription(true);
  };
  const saveConfirmSubscription = async () => {
    const body = {
      instagramUserName: instagramUserName,
      TiktokUserName: TiktokUserName,
    };
    if (ConfirmSubscription) {
      const res = await apiService.confirmSubscription(body);
      if (res) {
        if (
          !isEdit ||
          loginUser.instagramUserName == "" ||
          loginUser.tikTokUserName == ""
        ) {
          setIsConfirmSubscription(true);
        } else {
          setCountData(countData + 1);
        }
      }
    }
    setConfirmSubscription(false);
  };

  // edit username model
  const handleEditUsernameBtn = async () => {
    setSubscriptionModelTitle(
      t("giveAways.ConfirmSubscription.EditUsernamesTitle")
    );
    setSubscriptionModelBtn(
      t("giveAways.ConfirmSubscription.SaveUsernamesBtn")
    );
    setInstagramUserName(loginUser.instagramUserName);
    setTiktokUserName(loginUser.tikTokUserName);
    setIsEdit(true);
    setConfirmSubscription(true);
  };

  useEffect(() => {
    document.body.classList.add("userprofile-containt");
    function loading() {
      document.querySelectorAll(".bar").forEach(function (current) {
        let startWidth = 0;
        const endWidth = current.dataset.size;
        const interval = setInterval(frame, 0);
        function frame() {
          if (startWidth >= endWidth) {
            clearInterval(interval);
          } else {
            startWidth++;
            current.style.width = `${endWidth}%`;
            // current.firstElementChild.innerText = `${startWidth}%`;
          }
        }
      });
    }
    setTimeout(loading, 1000);
  }, [ismakePrediction]);

  const handleEmail = async (e) => {
    e.preventDefault();
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.getUserName({ email: loginEmail });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res.data) {
      navigate("/login", {
        state: {
          email: loginEmail,
          userName: userName,
          userEmail: userEmail,
          name: res.data.userName,
          profileImgUrl: res?.data.profileImgUrl,
        },
      });
    } else {
      setErrorClass("mb-3 form-error");
      setError("form-error-line-show");
    }
  };
  const isValidEmail = (email) => {
    const regexEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regexEmail.test(email);
  };
  const handleLoginEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      // username
      setUserName(e.target.value);
    } else {
      // email
      setUserEmail(e.target.value);
    }
    setLoginEmail(e.target.value);
    if (e.target.value == "") {
      setErrorClass("mb-3");
      setError("form-error-line-hide");
    }
  };
  const handleGiveAwaysHide = () => {
    setIsGiveAways(false);
  };

  // handle correct score
  const handleCorrectScoreActive = (event, odds) => {
    setCorrectScoreActive(odds);
  };

  const handleMakePredictionBtn = async () => {
    const res = await apiService.makePrediction(
      correctScoreActive,
      isGetGiveaway._id
    );
    if (res) {
      setCountData(countData + 1);
      setIsMakePrediction(true);
    }
  };
  // navigate publick profile
  const handleUserProfile = async (item, pageNumber = 0) => {
    const res = await apiService.getPredictionOrderByUserId(
      item._id,
      pageSize,
      pageNumber
    );
    if (item._id.toString() == localStorage.getItem("userId")) {
      navigate(`/userprofile`);
    } else {
      navigate(`/profile/${item._id}`, {
        state: res,
        length: res?.length,
      });
    }
  };
  return (
    <>
      {localStorage.getItem("token") && localStorage.getItem("profile") ? (
        <NavigationBar />
      ) : (
        <HomePageHeader />
      )}

      <section>
        <div className="tournament-detail-page-section">
          {/* top */}
          <div className="container-1240 tournament-detail-page-top">
            <div className="d-flex justify-content-between mt-4 tournament-detail-page-top-mobile-responsive">
              {/* left */}
              <div className="w-100 me-4">
                {/* button */}
                <div
                  className="GiveAways-icon-btn d-flex"
                  onClick={() => navigate("/Giveaways")}
                >
                  <img src="/chevron-right.svg" className="back-btn" />
                  <span>{t("navbar.Giveaways")}</span>
                </div>
                {/* tournament-team-detail */}
                <div>
                  {/* tournament-name */}
                  <div>
                    <div className="Participants-card-point text-center">
                      {apiService.getProfileFromStorage()?.Language == "en" ||
                        localStorage.getItem("language") == "en"
                        ? isGetGiveaway.giveawayDetail?.tournament_name_en
                          ? isGetGiveaway.giveawayDetail?.tournament_name_en
                          : isGetGiveaway.giveawayDetail?.tournament_name
                        : isGetGiveaway.giveawayDetail?.tournament_name}
                    </div>
                    <div className="tournament-detail-sport-name text-center">
                      {apiService.getProfileFromStorage()?.Language == "en" ||
                        localStorage.getItem("language") == "en"
                        ? isGetGiveaway.giveawayDetail?.sport_name_en
                          ? isGetGiveaway.giveawayDetail?.sport_name_en
                          : isGetGiveaway.giveawayDetail?.sport_name
                        : isGetGiveaway.giveawayDetail?.sport_name}
                    </div>
                  </div>
                  {/* team 1 2 details */}
                  <div className="tournament-detail-teams-detail">
                    {/* opps_team1-detail */}
                    <div className="d-flex align-items-center flex-column set-opps_team1-2-avatar-name">
                      <div className="opps_team1-avatar">
                        <img
                          src={
                            process.env.REACT_APP_teamLogo +
                            isGetGiveaway?.giveawayDetail?.opp_1_icon
                          }
                          className="opps_team1-image"
                        />
                      </div>
                      <div className="tournament-opps_team1-name text-center">
                        {apiService.getProfileFromStorage()?.Language == "en" ||
                          localStorage.getItem("language") == "en"
                          ? isGetGiveaway?.giveawayDetail?.opp_1_name_en
                            ? isGetGiveaway?.giveawayDetail?.opp_1_name_en
                            : isGetGiveaway?.giveawayDetail?.opp_1_name
                          : isGetGiveaway?.giveawayDetail?.opp_1_name}
                      </div>
                    </div>
                    {/* team time-scrore */}
                    <div className="team-time-scrore-div">
                      <div className="time">
                        {" "}
                        {moment
                          .unix(isGetGiveaway?.giveawayDetail?.game_start)
                          .format("HH : mm")}
                      </div>
                      <div className="date pb-3">
                        {" "}
                        {handleLanguageLocalization(
                          isGetGiveaway?.giveawayDetail?.game_start
                        )}
                      </div>
                      {/* desktop */}
                      <div className=" justify-content-between team-score-count-box">
                        <div className="team-score-box ">
                          {isGetGiveaway.giveawayDetail?.game_score
                            ? isGetGiveaway.giveawayDetail?.game_score
                              .split("(")[0]
                              .split(":")[0]
                            : "-"}
                        </div>
                        <div className="team-score-box ">:</div>
                        <div className="team-score-box ">
                          {isGetGiveaway.giveawayDetail?.game_score
                            ? isGetGiveaway.giveawayDetail?.game_score
                              .split("(")[0]
                              .split(":")[1]
                            : "-"}
                        </div>
                      </div>
                      {/* mobile */}
                      <div className="mobile-team-score-box">
                        {isGetGiveaway.giveawayDetail?.game_score
                          ? isGetGiveaway.giveawayDetail?.game_score.split(
                            "("
                          )[0]
                          : //   .split(":")[0] +
                          // ":" +
                          // isGetGiveaway.giveawayDetail?.game_score
                          //   .split("(")[0]
                          //   .split(":")[1]
                          "- : -"}
                      </div>
                    </div>
                    {/* opps_team2-detail */}
                    <div className="d-flex align-items-center flex-column set-opps_team1-2-avatar-name">
                      <div className="opps_team1-avatar">
                        <img
                          src={
                            process.env.REACT_APP_teamLogo +
                            isGetGiveaway?.giveawayDetail?.opp_2_icon
                          }
                          className="opps_team1-image"
                        />
                      </div>
                      <div className="tournament-opps_team2-name text-center">
                        {apiService.getProfileFromStorage()?.Language == "en" ||
                          localStorage.getItem("language") == "en"
                          ? isGetGiveaway?.giveawayDetail?.opp_2_name_en
                            ? isGetGiveaway?.giveawayDetail?.opp_2_name_en
                            : isGetGiveaway?.giveawayDetail?.opp_2_name
                          : isGetGiveaway?.giveawayDetail?.opp_2_name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* right */}
              <div className="Reward-Participants-card">
                <div className="tournament-detail-Reward-card mb-3 mt-sm-4">
                  <div className="pb-2">{t("giveAways.Reward")}</div>
                  <div className="Participants-card-point pb-3">
                    ₴ {isGetGiveaway?.reward}
                  </div>
                  <div>
                    {isGetGiveaway.numberOfWinners} winners/
                    {isGetGiveaway?.reward / isGetGiveaway.numberOfWinners} UAH
                  </div>
                </div>
                <div className="tournament-detail-Participants-card">
                  <div className="pb-2">{t("giveAways.Participants")}</div>
                  <div className="Participants-card-point pb-3">
                    {isGetGiveaway.participants?.length}
                  </div>
                  {isGetGiveaway.participants?.length > 0 ? (
                    <div className="participant-div Participants-avatar d-flex">
                      {isGetGiveaway.participants?.map((data, index) => (
                        <div key={index}>
                          {data?.profileImgUrl == "" ? (
                            <span className="username-avatar-data">
                              {data?.firstName?.charAt(0)}
                            </span>
                          ) : (
                            <img
                              src={data?.profileImgUrl}
                              className="Participants"
                              height="36px"
                              width="36px"
                            />
                          )}
                        </div>
                      ))}

                      {isGetGiveaway?.participants.length >= 12 && (
                        <span className="Participants-avatar-data">+321</span>
                      )}
                    </div>
                  ) : (
                    <div className="emptyMsg">{t("giveAways.emptyMsg")}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* bottom */}
        </div>
        <div className="container-1240 tournament-detail-page-bottom">
          <div className="Take-giveaway-Rules-card d-flex justify-content-between align-items-start">
            <div className="w-100 me-4 mt-4">
              {/* right - side */}
              {/* prediction event has started as user /*/}
              <div
                className="Take-giveaway-card w-100"
                style={{
                  display: isGetGiveaway.isEventStart
                    ? "none"
                    : isTeamWinner
                      ? "none"
                      : apiService?.getProfileFromStorage()?.role[0] == "User"
                        ? "block"
                        : "none",
                }}
              >
                <div className="Take-giveaway-title mb-1">
                  {t("giveAways.TakePartTitle")}
                </div>
                <div className="TakePartSubTitle">
                  {t("giveAways.TakePartSubTitle")}
                </div>
                <div className="mt-4">
                  <div className="d-flex">
                    {/* toggle icon */}
                    <div className="">
                      <div>
                        <img
                          src={
                            isConfirmSubscription ||
                              (loginUser?.tikTokUserName &&
                                loginUser?.instagramUserName)
                              ? "/_Step icon base confirm.svg"
                              : "/_Step icon base_dark.svg"
                          }
                        />
                        {/* <img src="/_Step icon base confirm.svg" /> */}
                      </div>
                      <div
                        className="toggle-line"
                        // style={{ minHeight: "18px" }}
                        style={{
                          minHeight: isConfirmSubscription
                            ? "18px"
                            : loginUser?.tikTokUserName &&
                              loginUser?.instagramUserName
                              ? "189px"
                              : "115px",
                        }}
                      ></div>
                      <div className="position-absolute">
                        <img
                          src={
                            ismakePrediction
                              ? "/_Step icon base confirm.png"
                              : isConfirmSubscription ||
                                (loginUser?.tikTokUserName &&
                                  loginUser?.instagramUserName)
                                ? "/_Step icon base_dark.png"
                                : "/_Step icon base_light.png"
                          }
                        />
                        {/* <img src="/_Step icon base confirm.svg" /> */}
                      </div>
                    </div>
                    <div className="w-100">
                      {/* social-media progressbar */}
                      <div className="ms-3">
                        <div
                          className={`social-media-title ${isConfirmSubscription ? "pt-1" : "pt-0"
                            }`}
                        >
                          {t("giveAways.socialMedia")}
                        </div>
                        <div
                          style={{
                            display:
                              isConfirmSubscription ||
                                (loginUser?.tikTokUserName &&
                                  loginUser?.instagramUserName)
                                ? "none"
                                : "block",
                          }}
                        >
                          <div className="TakePartSubTitle">
                            {t("giveAways.socialMediaSubTitle")}
                          </div>
                          {/* social-media-icon btn */}
                          <div className="social-media-icon mt-3">
                            <a
                              href="https://www.instagram.com/capperauditor/"
                              target="_blank"
                            >
                              <img
                                src="/Instagram giveaway.svg"
                                className="me-3 Subscription-instgram"
                              />
                            </a>
                            <a
                              href="https://www.tiktok.com/@capperauditor"
                              target="_blank"
                            >
                              <img src="/Tiktok giveaway.svg" />
                            </a>
                          </div>

                          {/* setConfirmSubscription-btn */}
                          <InputButton
                            type="secondary"
                            addBtnClass="EditUsernamesBtn mt-3"
                            inputLabel={t("giveAways.ConfirmSubscriptionBtn")}
                            onClick={handleConfirmSubscriptionBtn}
                          />
                        </div>
                        {/* edit username */}
                        <div
                          className=""
                          style={{
                            display:
                              loginUser?.tikTokUserName &&
                                loginUser?.instagramUserName
                                ? "block"
                                : "none",
                          }}
                        >
                          <div className="TakePartSubTitle">
                            {t("giveAways.socialMediaSubTitle")}
                          </div>
                          {/* social-media-icon btn */}
                          <div className="social-media-icon mt-3">
                            <a
                              href="https://www.instagram.com/capperauditor/"
                              target="_blank"
                            >
                              <img
                                src="/Instagram giveaway.svg"
                                className="me-3 Subscription-instgram"
                              />
                            </a>
                            <a
                              href="https://www.tiktok.com/@capperauditor"
                              target="_blank"
                            >
                              <img src="/Tiktok giveaway.svg" />
                            </a>
                          </div>
                          {/* username of insta and tik-tok */}
                          <div className="username-insta-tiktok mt-3">
                            <div className="mb-2 insta-username">
                              Instagram:
                              <a
                                href={`http://instagram.com/${loginUser?.instagramUserName ||
                                  loginUser?.userName
                                  }/`}
                                style={{ textDecoration: "none" }}
                                target="_blank"
                              >
                                {" "}
                                <span className="name">
                                  {loginUser?.instagramUserName}
                                </span>
                              </a>
                            </div>
                            <div className="insta-username">
                              Tiktok:
                              <a
                                href={`http://tiktok.com/${loginUser?.tikTokUserName ||
                                  loginUser?.userName
                                  }`}
                                style={{ textDecoration: "none" }}
                                target="_blank"
                              >
                                <span className="name">
                                  {loginUser?.tikTokUserName}
                                </span>
                              </a>
                            </div>
                          </div>
                          {/* Edit usernames */}

                          <InputButton
                            type="secondary"
                            addBtnClass="EditUsernamesBtn mt-3"
                            inputLabel={t("giveAways.EditUsernamesBtn")}
                            onClick={handleEditUsernameBtn}
                          />
                        </div>
                      </div>
                      {/* makePrediction-bar */}
                      <div className="mt-4 ms-3 me-lg-4 me-md-4">
                        <div
                          className={`makePrediction-title ${isConfirmSubscription ? "pt-1" : "pt-0"
                            }`}
                        >
                          {t("giveAways.makeprediction")}
                        </div>
                        <div
                          style={{
                            display:
                              isConfirmSubscription ||
                                (loginUser?.tikTokUserName &&
                                  loginUser?.instagramUserName)
                                ? "block"
                                : "none",
                          }}
                        >
                          <div className="makePrediction-SubTitle">
                            {t("giveAways.makePredictionSubTitle")}
                          </div>
                          {ismakePrediction ? (
                            <div className="makePrediction-progressbar mt-3">
                              <div className="wrapper">
                                <div className="row mt-3">
                                  {isGetGiveaway.correctScore?.map(
                                    (item, index) => (
                                      <div
                                        className="col-lg-6 col-sm-12 px-2 mb-2"
                                        key={index}
                                      >
                                        <div className="progress-bar">
                                          <div
                                            className={
                                              item.predictions
                                                ? item.isUser
                                                  ? `bar user-bar`
                                                  : `bar`
                                                : `bar`
                                            }
                                            data-size={JSON.stringify(
                                              (100 * item.predictions?.length) /
                                              isGetGiveaway.participants
                                                ?.length
                                            )}
                                          ></div>
                                          <div
                                            className="d-flex justify-content-between flex-wrap"
                                            style={{ zIndex: 1 }}
                                          >
                                            <div className="progressbar-oc-group-name">
                                              {item.oc_name}
                                            </div>
                                            <div
                                              className="more-or-count-predictions "
                                              onClick={() =>
                                                handleMorePredictionModel(
                                                  item.predictions?.length,
                                                  item
                                                )
                                              }
                                            >
                                              {item?.predictions
                                                ? item.isUser
                                                  ? `Your and ${item?.predictions
                                                    ?.length - 1
                                                  } more predictions`
                                                  : item?.predictions?.length +
                                                  " " +
                                                  "predictions"
                                                : "0 predictions"}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            // make prediction data
                            <div>
                              <div className="row mt-3">
                                {isGetGiveaway.correctScore?.map(
                                  (item, index) => (
                                    <div
                                      className="col-sm-12 col-lg-6 px-2"
                                      key={index}
                                    >
                                      <div
                                        onClick={() =>
                                          handleCorrectScoreActive(
                                            item,
                                            item.oc_pointer
                                          )
                                        }
                                        className="prediction-data-tab"
                                        style={{
                                          backgroundColor:
                                            correctScoreActive ==
                                              item.oc_pointer
                                              ? "#F7FDFD"
                                              : "#ffffff",
                                          border:
                                            correctScoreActive ==
                                              item.oc_pointer
                                              ? "1px solid #0D393A"
                                              : "1px solid #F2F3F7",
                                        }}
                                      >
                                        {item.oc_name}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              <div>
                                <InputButton
                                  type="primary"
                                  addBtnClass={"makePrediction-btn"}
                                  disabled={correctScoreActive == ""}
                                  inputLabel={t("giveAways.MakepredictionBtn")}
                                  onClick={handleMakePredictionBtn}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* winner */}
              {/* only user and guest */}
              <div
                className="w-100"
                style={{ display: isTeamWinner ? "block" : "none" }}
              >
                {/* only guest */}
                <div className="winner">
                  <div className="Take-giveaway-title mb-1">
                    {t("giveAways.Winner.WinnerTitle")}
                  </div>
                  <div className="TakePartSubTitle">
                    {t("giveAways.Winner.WinnerSubTitle")}
                  </div>
                  {/* winner team card */}
                  <div className="row winner-team-card mt-4">
                    {winnerAndOtherArray?.winnerArray?.map((item, index) => (
                      <div
                        className="col-lg-4 col-md-6 col-sm-12 mb-md-2 mb-sm-2 mb-2 mb-lg-0"
                        key={index}
                      >
                        <div className="Winner-card d-flex justify-content-between align-items-center">
                          <div className="Winner-team-contains d-flex">
                            <div className="">
                              {item.userId?.profileImgUrl !== "" ? (
                                <img
                                  src={item.userId?.profileImgUrl}
                                  className="Winner-team-img"
                                  width="44px"
                                  height="44px"
                                />
                              ) : (
                                <div className="Winner-team-avatar">
                                  {item.userId.userName?.charAt(0)}
                                </div>
                              )}
                            </div>
                            <div className=" ps-2">
                              <div className="Winner-team-name">
                                {item.userId.firstName +
                                  " " +
                                  item.userId.lastName}
                              </div>
                              <div className="Winner-team-username">
                                @{item.userId.userName}
                              </div>
                            </div>
                          </div>
                          <div>
                            <img src="/trophy.svg" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {/* noPerfectPredictorsline */}

                {winnerAndOtherArray?.winnerArray?.length == 0 &&
                  winnerAndOtherArray?.otherParticipateOfWinnersArray?.length ==
                  0 ? (
                  <div className="noPerfectPredictorsline">
                    {t("giveAways.PerfectPredictors.noPerfectPredictors")}
                  </div>
                ) : (
                  ""
                )}
                {/* perfect prediction */}
                <div>
                  <div className="Take-giveaway-title mb-1">
                    {t("giveAways.PerfectPredictors.PerfectPredictorsTitle")}
                  </div>
                  <div className="TakePartSubTitle">
                    {t("giveAways.PerfectPredictors.PerfectPredictorsSubTitle")}
                  </div>
                  {/* perfect-prediction card */}
                  <div
                    className="row perfect-prediction-card mt-4 "
                    style={{
                      display:
                        !localStorage.getItem("token") ||
                          apiService.getProfileFromStorage().role[0] == "User"
                          ? "flex"
                          : "none",
                    }}
                  >
                    {/* only two  perfect-prediction*/}
                    {winnerAndOtherArray?.otherParticipateOfWinnersArray
                      ?.length <= 0 ? (
                      <div className="noPerfectPredictorsline">
                        {t(
                          "giveAways.PerfectPredictors.onlyTwoPerfectPredictorsLine"
                        )}
                      </div>
                    ) : (
                      winnerAndOtherArray?.otherParticipateOfWinnersArray?.map(
                        (item, index) => (
                          <div
                            className="col-lg-4 col-md-6 col-sm-12 mb-md-2 mb-sm-2 mb-2 mb-lg-2"
                            key={index}
                          >
                            <div className="prediction-card d-flex justify-content-between align-items-center">
                              <div className="perfect-prediction-team-contains d-flex">
                                <div className="">
                                  {item.userId?.profileImgUrl !== "" ? (
                                    <img
                                      src={item.userId?.profileImgUrl}
                                      className="perfect-prediction-team-img"
                                      width="44px"
                                      height="44px"
                                    />
                                  ) : (
                                    <div className="Winner-team-avatar">
                                      {item.userId?.userName.charAt(0)}
                                    </div>
                                  )}
                                </div>
                                <div className="ps-2">
                                  <div className="perfect-prediction-team-name">
                                    {item.userId.firstName +
                                      " " +
                                      item.userId.lastName}
                                  </div>
                                  <div className="perfect-prediction-team-username">
                                    @{item.userId.userName}
                                  </div>
                                </div>
                              </div>
                              <div className="badge-div d-flex align-items-center">
                                <div className="badge-dot me-1"></div>
                                <span className="badge-score">2:0</span>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
              {/* only admin */}
              <div
                style={{
                  display:
                    apiService?.getProfileFromStorage()?.role[0] == "Admin"
                      ? "block"
                      : "none",
                }}
              >
                <div className="w-100">
                  <div style={{ display: isTeamWinner ? "none" : "block" }}>
                    <div className="Take-giveaway-title mb-1">
                      {t("giveAways.Participants")}
                    </div>
                    <div className="TakePartSubTitle">
                      {t(
                        "giveAways.PerfectPredictors.PerfectPredictorsSubTitle"
                      )}
                    </div>
                  </div>
                  <div className="Participants-table mt-4">
                    <PredictionsTable
                      PerfectPredictors={PerfectPredictors}
                      isGetGiveawayId={isGetGiveaway._id}
                      incrementCount={incrementCount}
                      winnerArray={winnerAndOtherArray?.winnerArray}
                    />
                  </div>
                </div>
              </div>
              {/* prediction event has started as guest / admin */}
              <div
                className={`w-100 me-4 ${apiService?.getProfileFromStorage()?.role[0] == "Admin"
                    ? `mt-4`
                    : "mt-0"
                  }`}
                style={{
                  display:
                    apiService?.getProfileFromStorage()?.role[0] == "User"
                      ? "none"
                      : "block",
                }}
              >
                <div className="d-flex justify-content-between w-100 ">
                  <div>
                    <div className="Take-giveaway-title mb-1">
                      {isTeamWinner
                        ? t("giveAways.AllPredictions.AllPredictionsTitle")
                        : t("giveAways.predictions.predictionsTitle")}
                    </div>
                    <div className="TakePartSubTitle">
                      {isTeamWinner
                        ? t("giveAways.AllPredictions.AllPredictionsSubTitle")
                        : t("giveAways.predictions.predictionsSubTitle")}
                    </div>
                  </div>
                  {!localStorage.getItem("token") && !isTeamWinner && (
                    <InputButton
                      type="primary"
                      inputLabel={t("giveAways.predictions.JoinGiveawayBtn")}
                      addBtnClass="makePrediction-joingiveawayBtn mt-0"
                      onClick={() => setIsGiveAways(true)}
                    />
                  )}
                </div>
                <div className="makePrediction-progressbar mt-3">
                  <div className="wrapper">
                    <div className="row mt-3">
                      {isGetGiveaway.correctScore?.map((item, index) => (
                        <div
                          className="col-lg-6 col-sm-12 px-2 mb-2"
                          key={index}
                        >
                          <div className="progress-bar">
                            <div
                              className={
                                item.predictions
                                  ? item.isUser
                                    ? `bar user-bar`
                                    : `bar`
                                  : `bar`
                              }
                              data-size={JSON.stringify(
                                (100 * item.predictions?.length) /
                                isGetGiveaway.participants?.length
                              )}
                            ></div>
                            <div
                              className="d-flex justify-content-between flex-wrap"
                              style={{ zIndex: 1 }}
                            >
                              <div className="d-flex">
                                {/* if guest */}
                                <div className="progressbar-oc-group-name">
                                  {item.oc_name}
                                </div>
                                {!localStorage.getItem("token") &&
                                  item.winningScore && (
                                    <img src="/trophy.svg" className="ps-2" />
                                  )}
                              </div>
                              <div className="d-flex">
                                {apiService?.getProfileFromStorage()?.role[0] ==
                                  "Admin" &&
                                  item.winningScore && (
                                    <img src="/trophy.svg" className="pe-2" />
                                  )}
                                <div
                                  className="more-or-count-predictions "
                                  onClick={() =>
                                    handleMorePredictionModel(
                                      item.predictions?.length,
                                      item
                                    )
                                  }
                                >
                                  {item?.predictions
                                    ? item.isUser
                                      ? `Your and ${item?.predictions?.length - 1
                                      } more predictions`
                                      : item?.predictions?.length +
                                      " " +
                                      "predictions"
                                    : "0 predictions"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* prediction event has started as user */}
              <div
                className={`w-100 me-4 hhhhhhhhh ${isGetGiveaway.isEventStart ? `mt-0` : `mt-4`
                  }`}
                style={{
                  display:
                    apiService?.getProfileFromStorage()?.role[0] == "Admin" ||
                      !localStorage.getItem("token")
                      ? "none"
                      : isGetGiveaway.isEventStart
                        ? "block"
                        : apiService?.getProfileFromStorage()?.role[0] ==
                          "User" && isTeamWinner
                          ? "block"
                          : "none",
                }}
              >
                <div className="d-flex justify-content-between w-100 ">
                  <div>
                    <div className="Take-giveaway-title mb-1">
                      {isTeamWinner
                        ? t("giveAways.AllPredictions.AllPredictionsTitle")
                        : t("giveAways.predictions.predictionsTitle")}
                    </div>
                    <div className="TakePartSubTitle">
                      {isTeamWinner
                        ? t("giveAways.AllPredictions.AllPredictionsSubTitle")
                        : t("giveAways.predictions.predictionsSubTitle")}
                    </div>
                  </div>
                </div>
                <div className="makePrediction-progressbar mt-3">
                  <div className="wrapper">
                    <div className="row mt-3">
                      {isGetGiveaway.correctScore?.map((item, index) => (
                        <div
                          className="col-lg-6 col-sm-12 px-2 mb-2"
                          key={index}
                        >
                          <div className="progress-bar">
                            <div
                              className={
                                item.predictions
                                  ? item.isUser
                                    ? `bar user-bar`
                                    : `bar`
                                  : `bar`
                              }
                              data-size={JSON.stringify(
                                (100 * item.predictions?.length) /
                                isGetGiveaway.participants?.length
                              )}
                            ></div>
                            <div></div>
                            <div
                              className="d-flex justify-content-between flex-wrap"
                              style={{ zIndex: 1 }}
                            >
                              <div className="d-flex">
                                {/* if guest */}
                                <div className="progressbar-oc-group-name">
                                  {item.oc_name}
                                </div>
                                {apiService?.getProfileFromStorage()?.role[0] ==
                                  "User" &&
                                  item.winningScore && (
                                    <img src="/trophy.svg" className="ps-2" />
                                  )}
                              </div>
                              <div className="d-flex">
                                {/* {localStorage.getItem("token") && (
                                  <img src="/trophy.svg" className="pe-2" />
                                )} */}
                                <div
                                  className="more-or-count-predictions "
                                  onClick={() =>
                                    handleMorePredictionModel(
                                      item.predictions?.length,
                                      item
                                    )
                                  }
                                >
                                  {item?.predictions
                                    ? item.isUser
                                      ? `Your and ${item?.predictions?.length - 1
                                      } more predictions`
                                      : item?.predictions?.length +
                                      " " +
                                      "predictions"
                                    : "0 predictions"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ---rules--- */}
            <div className="giveaway-Rules-card mt-4">
              <div className="title mb-4">{t("giveAways.RulesTitle")}</div>
              <div className="d-flex align-items-baseline mb-3">
                <span className="Ellipse-design">
                  <img src="/Ellipse.svg" />
                </span>
                {t("giveAways.Rules.firstRule")}
              </div>
              <div className="d-flex align-items-baseline mb-3">
                <span className="Ellipse-design">
                  <img src="/Ellipse.svg" />
                </span>
                {t("giveAways.Rules.secondRule")}
              </div>
              <div className="d-flex align-items-baseline">
                <span className="Ellipse-design">
                  <img src="/Ellipse.svg" />
                </span>
                {t("giveAways.Rules.thirdRule")}
              </div>
            </div>

            {/* confirm subcription model */}
            <Model
              isModel={ConfirmSubscription}
              hideModel={() => setConfirmSubscription(false)}
              modelTitle={SubscriptionModelTitle}
              modelSubTitle={t(
                "giveAways.ConfirmSubscription.ConfirmSubscriptionSubTitle"
              )}
              modelClassName={"ConfirmSubscription-model"}
            >
              <div className="mt-3">
                {/* instragram - usename */}
                <div>
                  <div className="model-instragram-title d-flex">
                    <img src="/Instagram-black.svg" />
                    <div className="title ms-1">Instagram</div>
                  </div>
                  <div className="instagram-tiktok-inputFeild mt-1">
                    <HorizontalFeild
                      value={instagramUserName}
                      inputLabel="https://instagram.com/"
                      onChange={(e) => setInstagramUserName(e.target.value)}
                    />
                  </div>
                </div>
                {/* tiktok - usename */}
                <div>
                  <div className="model-instragram-title d-flex mt-3">
                    <img src="/Tiktok-black.svg" />
                    <div className="title ms-1">TikTok</div>
                  </div>
                  <div className="instagram-tiktok-inputFeild mt-1">
                    <HorizontalFeild
                      inputLabel="https://tiktok.com/@"
                      inputStyle={true}
                      value={TiktokUserName}
                      onChange={(e) => setTiktokUserName(e.target.value)}
                    />
                  </div>
                </div>
                {/* group-button */}
                <div className="d-flex justify-content-between">
                  <InputButton
                    type="secondary"
                    addBtnClass="subscription-Cancel-btn"
                    inputLabel={t("profile.saveCancel")}
                    onClick={() => setConfirmSubscription(false)}
                  />
                  <InputButton
                    type="primary"
                    addBtnClass="subscription-Cancel-btn"
                    inputLabel={SubscriptionModelBtn}
                    onClick={saveConfirmSubscription}
                    disabled={
                      isEdit
                        ? TiktokUserName == "" && instagramUserName == ""
                        : TiktokUserName == "" || instagramUserName == ""
                    }
                  />
                </div>
              </div>
            </Model>

            {/* Correct Score userList model */}
            <Model
              isModel={isUSerList}
              hideModel={handleMorePredictionModelHide}
              modelClassName="userList-model"
              modelTitle={
                <span>
                  {getCorrectScoreData.oc_name}
                  <span className="userList-model-subtitle">
                    {" "}
                    ({getCorrectScoreData.predictions?.length}{" "}
                    {t("profile.predictions")})
                  </span>
                </span>
              }
            >
              <div className="userList-model-scroll">
                {getCorrectScoreData?.predictions?.map((item, index) => (
                  <div
                    className="userList-model-content d-flex justify-content-between align-items-center"
                    key={index}
                  >
                    <div className="userList-model-name-avatar d-flex align-items-center">
                      <div className="userList-model-avatar">
                        {item.userId.profileImgUrl !== "" ? (
                          <img
                            src={item.userId.profileImgUrl}
                            width="32px"
                            height="32px"
                          />
                        ) : (
                          <div className="userList-avatar ">
                            {item.userId?.userName.charAt(0)}
                          </div>
                        )}
                      </div>
                      <div
                        className="userList-model-name ps-2"
                        onClick={() => handleUserProfile(item.userId)}
                      >
                        {item.userId.firstName + " " + item.userId.lastName}
                      </div>
                    </div>
                    <div className="userList-model-dateTime">
                      {moment.unix(item?.createdAt).format("DD/MM/YYYY")}{" "}
                      {moment.unix(item?.createdAt).format("HH: mm")}
                    </div>
                  </div>
                ))}
              </div>
            </Model>

            {/* login model  */}
            <Model
              isModel={isGiveAways}
              modelClassName={"home-page-signupPage"}
              hideModel={handleGiveAwaysHide}
              modelTitle={t("homePageSigninPage.SignIn")}
            >
              <div className="userLoginModel">
                {/* --- signinCapperauditor top --- */}
                <div className="top">
                  <div className="btn-containt">
                    <button
                      type="button"
                      className="btn  btn-google-facebook btn-google"
                      onClick={() => {
                        window.location.href =
                          process.env.REACT_APP_googleOAuthUrl;
                      }}
                    >
                      <span className="signup-icon">
                        <i className="fab fa-google" />
                      </span>
                      {t("homePageSigninPage.isGoogleBtn")}
                    </button>
                    <button
                      type="button"
                      className="btn btn-google-facebook btn-facebook"
                      onClick={() => {
                        window.location.href =
                          process.env.REACT_APP_facebookOAuthUrl;
                      }}
                    >
                      <span className="signup-icon">
                        <i
                          className="fab fa-facebook-f"
                          style={{ color: "#4267B2" }}
                        ></i>
                      </span>
                      {t("homePageSigninPage.isFabookBtn")}
                    </button>
                  </div>
                  <div className="text-divider">
                    {t("homePageSigninPage.or")}
                  </div>
                </div>
                {/* ------ signincapperauditor form ------  */}
                <div className="bottom">
                  <form>
                    <div className={errorClass}>
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label form-input-label"
                      >
                        {t("homePageSigninPage.emailorusername")}
                      </label>
                      <input
                        type="email"
                        className="form-control form-input-field"
                        id="InputEmail"
                        onChange={handleLoginEmail}
                        placeholder={t(
                          "homePageSigninPage.EnteremailPlaceHolder"
                        )}
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          color: "#7F87B3",
                        }}
                      />
                      <p style={{ fontSize: "14px" }} className={error}>
                        {t("SignIn.Youremailorusernameisincorrect")}
                      </p>
                    </div>
                    <div className="mb-4">
                      <button
                        className="form-control form-input-field-button"
                        id="Inputbutton"
                        onClick={handleEmail}
                        disabled={loginEmail == ""}
                        style={{
                          pointerEvents: loginEmail == "" ? "none" : "auto",
                        }}
                      >
                        {t("homePageSigninPage.Continue")}
                      </button>
                    </div>
                  </form>
                  {/* ------- signincapperauditor footer -----  */}
                  <div className="signin-footer">
                    <span
                      className="signincapperauditor-bottom"
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        lineHeight: "20px",
                      }}
                    >
                      {t("homePageSigninPage.subLine1")}{" "}
                      <Link
                        to="/signup"
                        style={{
                          color: "#4C5480",
                          fontWeight: "600",
                          lineHeight: "24px",
                        }}
                      >
                        {t("homePageSigninPage.subLine2")}
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </Model>
          </div>
        </div>
      </section>
    </>
  );
};
export default TournamentDetailPage;
