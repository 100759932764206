import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import "./ReadMoreLessText.css";

const ReadMoreLessText = ({ text }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const textRef = useRef(null);

  const [showFullText, setShowFullText] = useState(false);
  const [isOverLines, setIsOverLines] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseInt(
        window.getComputedStyle(textRef.current).lineHeight
      );
      
      const lines = isMobile ? 5 : 3;
      const maxHeight = lineHeight * lines;

      if (textRef.current.scrollHeight > maxHeight) {
        setIsOverLines(true);
      }
    }
  }, []);

  const toggleFullText = () => {
    setShowFullText(!showFullText);
  };

  return (
    <div className="read-more-less_text-container">
      <div
        ref={textRef}
        className={`read-more-less_text ${
          showFullText ? "read-more-less_full-text" : ""
        }`}
      >
        {text}
      </div>
      {!showFullText && isOverLines && (
        <button className="read-more-less_btn" onClick={toggleFullText}>
          {t("readMore")}
        </button>
      )}
    </div>
  );
};

export default ReadMoreLessText;
