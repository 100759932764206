import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LoadingComponent from './molecule/loader/LoadingComponent';

function Header(props) {
  // useEffect(() => {
  //   const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  //   if (!localStorage.getItem("language")) {
  //     // if (timeZone == "Europe/Kyiv") {
  //     //   return localStorage.setItem("language", "ua");
  //     // } else {
  //     //   return localStorage.setItem("language", "en");
  //     // }
  //     return localStorage.setItem("language", "ua");
  //   }
  // }, [])

  return (
    <div className="container">
      <header>
        <nav style={{ pointerEvents: props.isPointerEvent && "none" }}>
          <Link to="/" className="desktop-logo">
            <img src="/navbar-logo.svg" alt="Logo" />
          </Link>
        </nav>
      </header>
      <LoadingComponent />
    </div>

  );
}

export default Header;


