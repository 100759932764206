import React from "react";
import { Accordion } from "react-bootstrap";
import InputButton from "../InputButton";
import InputCheckbox from "../inputCheckbox/InputCheckbox";
import Model from "../model/Model";
import SearchBar from "../searchBar/SearchBar";
import { useTranslation } from "react-i18next";
import apiService from "../../../services/apiService";
import { useState } from "react";
import { eventBus } from "../../../services/events";
import { CONSTANT } from "../../../common/constant";
import { useEffect } from "react";
import AddPrediction from "../Prediction/AddPrediction";
import SportLeagueModelLoader from "../../Custom loader/SportLeagueModelLoader";
const _ = require("lodash");

const FeedPrediction = (props) => {
  useEffect(() => {}, [props.countData]);
  const { t, i18n } = useTranslation();
  const [popularLeagues, setPopularLeagues] = useState([]);
  const [popularLeaguesHideShow, setPopularLeaguesHideShow] = useState(
    "popular-leagues-container-show"
  );
  const [predictionModel, setPredictionModle] = useState(props.show);
  const [modelLabel, setModelLabel] = useState(t("profile.selectSport"));
  const [sportContains, setSportContains] = useState(
    "sport-container sport-container-show"
  );
  const [leagueesContains, setLeagueesContains] = useState(
    "leaues-country-container leagues-country-container-hide"
  );
  const [sportData, setSportData] = useState([]);
  const [countryLeaguesData, setCountryLeaguesData] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [addSomePredictionsModel, setSomeAddPredictionModel] = useState(
    "add-prediction-model-hide"
  );
  const [isCheck, setIsCheck] = useState([]);
  const [isCountryCheck, setIsCountryCheck] = useState([]);
  const [emptyMsgShow, setEmptyMsgShow] = useState(
    "empty-error-msg empty-error-msg-hide"
  );
  const [leagueEmptyMsgShow, setLeagueEmptyMsgShow] = useState(
    "empty-error-msg empty-error-msg-hide"
  );
  const [sportId, setSportId] = useState("");
  const [selectedButton, setSelectedButton] = useState(true);
  const [sportName_ua, setsportName_ua] = useState("");
  const [sportName, setSportName] = useState("");
  const [allLeaguesData, setAllLeaguesData] = useState([]);
  const [addPredictionsModel, setAddPredictionModle] = useState(false);
  const [showLeaugeLoader, setShowLeaugeLoader] = useState(true);
  const arr = [...isCheck, ...isCountryCheck];

  useEffect(() => {}, [props.makePrediction]);
  const handlePredictionShow = async () => {
    if (
      localStorage.getItem("prediction-list") &&
      JSON.parse(localStorage.getItem("prediction-list")).length > 0
    ) {
      setSomeAddPredictionModel("add-prediction-model-show");
      setAddPredictionModle(true);
      setPredictionModle(false);
    } else {
      const res = await apiService.getFavSport();
      if (
        localStorage.getItem("prediction-list") &&
        JSON.parse(localStorage.getItem("prediction-list")).length > 0
      ) {
        setSomeAddPredictionModel("add-prediction-model-show");
        setAddPredictionModle(true);
        setPredictionModle(false);
      } else {
        setPredictionModle(true);
      }
    }
    if (localStorage.getItem("prediction-list")) {
      if (JSON.parse(localStorage.getItem("prediction-list")).length < 1) {
        handleSportShowModel();
      }
    }
  };

  useEffect(() => {}, [props.makePrediction, props.gameId]);
  useEffect(() => {
    if (props.show) {
      handlePredictionShow();
    }
  }, [props.show]);
  const handlePredictionHide = () => {
    setPredictionModle(false);
  };
  const getSportData = async () => {
    eventBus.emit(CONSTANT.SHOW_LOADER);
    let res = await apiService.getAllSports({});
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (localStorage.getItem("prediction-list")) {
      const data = JSON.parse(localStorage.getItem("prediction-list"));
      const localStorageSportId = data.map((item) => item.sportId);
      res = res?.filter((sp) => {
        if (!localStorageSportId.includes(sp.id)) {
          return sp;
        }
      });
    }
    setSportData(res);
    props.increamentUserCount();
  };
  useEffect(() => {
    getSportData();
  }, [sportId]);

  const handleAddPrediction = async () => {
    let leageData = _.uniq([...isCheck, ...isCountryCheck]);
    const predicationData = {
      sportId: sportId,
      sportName: sportName,
      leagues: leageData,
      activeKey: true,
      sportName_ua: sportName_ua,
    };
    if (localStorage.getItem("prediction-list")) {
      var items = [];
      items = JSON.parse(localStorage.getItem("prediction-list"));
      let isExist = false;
      items = items.map((i) => {
        if (i.sportId !== sportId) {
          i.activeKey = false;
        } else {
          i.leagues = predicationData.leagues;
          i.activeKey = true;
          isExist = true;
        }
        return i;
      });
      if (!isExist) {
        items.push(predicationData);
        localStorage.setItem("prediction-list", JSON.stringify(items));
      } else {
        localStorage.setItem("prediction-list", JSON.stringify(items));
      }
    } else {
      localStorage.setItem(
        "prediction-list",
        JSON.stringify([predicationData])
      );
    }
    await apiService.createFavSport({
      list: JSON.parse(localStorage.getItem("prediction-list")),
    });
    setSomeAddPredictionModel("add-prediction-model-show");
    setAddPredictionModle(true);
    setPredictionModle(false);
  };
  const handleLeagueDataShow = async (item) => {
    setPredictionModle(true);
    setAddPredictionModle(false);
    setSportContains("sport-container sport-container-hide");
    setLeagueesContains(
      "leaues-country-container leagues-country-container-show"
    );
    setSelectedButton(false);
    setSportId(item.sportId);
    setIsCheck(item.leagues);
    // setIsCountryCheck([]);
    setModelLabel(t("profile.EditLeagues"));
    setIsCountryCheck(item.leagues);
    const res = await apiService.getLeagues({
      id: item.sportId,
      language: apiService.getProfileFromStorage().Language,
    });
    if (res) {
      setShowLeaugeLoader(false);
    }
    if (res?.popularLeagues) {
      setPopularLeagues(res.popularLeagues);
    }
    eventBus.emit(CONSTANT.HIDE_LOADER);
    for (const data in res?.leaguseByCountries) {
      arr.push(res?.leaguseByCountries[data]);
      setAllLeaguesData((oldState) => [
        ...oldState,
        ...res.leaguseByCountries[data].leagues,
      ]);
    }
    setCountryLeaguesData(arr);
    // handlesports(item.sportId, item.sportName, item.sportName_ua, true);
    // setIsCheck(item.leagues)
  };
  const handleAllLeagueDataPOpup = () => {
    handleSelectedSportLeagueData();
    setPredictionModle(true);
    setAddPredictionModle(false);
    setSportContains("sport-container sport-container-hide");
    setLeagueesContains(
      "leaues-country-container leagues-country-container-show"
    );
    setIsCheck([]);
    setIsCountryCheck([]);
    setModelLabel(t("profile.selectLeague"));
  };
  const hadleSelectSportsBtn = () => {
    setModelLabel(t("profile.selectSport"));
    setIsCheck([]);
    setSportContains("sport-container sport-container-show");
    setLeagueesContains(
      "leaues-country-container leagues-country-container-hide"
    );
  };
  const handleLeagueSearch = async (e) => {
    const arr = [];
    const res = await apiService.getLeagues({
      id: sportId,
      search: e.target.value,
      language: apiService.getProfileFromStorage().Language,
    });

    for (const data in res.leaguseByCountries) {
      arr.push(res.leaguseByCountries[data]);
      setAllLeaguesData((oldState) => [
        ...oldState,
        ...res.leaguseByCountries[data].leagues,
      ]);
    }
    if (e.target.value == "") {
      setPopularLeagues(res.popularLeagues);
    } else {
      setPopularLeagues([]);
    }
    setCountryLeaguesData(arr);
    if (arr.length < 1 && e.target.value != "") {
      setLeagueEmptyMsgShow("empty-error-msg empty-error-msg-show");
      setPopularLeaguesHideShow("popular-leagues-container-hide");
    } else {
      setLeagueEmptyMsgShow("empty-error-msg empty-error-msg-hide");
      setPopularLeaguesHideShow("popular-leagues-container-show");
    }
  };
  const handleSportSearch = async (e) => {
    let res = await apiService.getAllSports({
      search: e.target.value,
    });
    if (localStorage.getItem("prediction-list")) {
      let items = JSON.parse(localStorage.getItem("prediction-list"));
      let itemsIds = items.map((i) => i.sportId);
      res = res.filter((i) => {
        if (!itemsIds.includes(i.id)) return i;
      });
    }
    setSportData(res);
    if (res.length < 1 && e.target.value != "") {
      setEmptyMsgShow("empty-error-msg empty-error-msg-show");
    } else {
      setEmptyMsgShow("empty-error-msg empty-error-msg-hide");
    }
  };
  const handlesports = async (
    sport_id,
    sportName,
    sportName_ua,
    isEdit = false
  ) => {
    setSportName(sportName);
    setSportId(sport_id);
    setsportName_ua(sportName_ua);
    if (
      leagueesContains ==
      "leaues-country-container leagues-country-container-show"
    ) {
      handleSelectedSportLeagueData();
      setLeagueEmptyMsgShow("empty-error-msg empty-error-msg-hide");
    }
    if (!isEdit) {
      setModelLabel(t("profile.selectLeague"));
    } else {
      setModelLabel(t("profile.EditLeagues"));
    }
    setSportContains("sport-container sport-container-hide");
    setLeagueesContains(
      "leaues-country-container leagues-country-container-show"
    );
    setSportContains("sport-container sport-container-hide");
    setLeagueesContains(
      "leaues-country-container leagues-country-container-show"
    );
  };
  const handleSelectedSportLeagueData = async () => {
    const arr = [];
    eventBus.emit(CONSTANT.SHOW_LOADER);
    if (sportId !== "") {
      const res = await apiService.getLeagues({
        id: sportId,
        language: apiService.getProfileFromStorage().Language,
      });
      if (res) {
        setShowLeaugeLoader(false);
      }
      if (res?.popularLeagues) {
        setPopularLeagues(res.popularLeagues);
      }
      eventBus.emit(CONSTANT.HIDE_LOADER);
      for (const data in res?.leaguseByCountries) {
        arr.push(res?.leaguseByCountries[data]);
        setAllLeaguesData((oldState) => [
          ...oldState,
          ...res.leaguseByCountries[data].leagues,
        ]);
      }
      setCountryLeaguesData(arr);
    }
  };
  useEffect(() => {
    handleSelectedSportLeagueData();
  }, [sportId]);
  const handleAllCheckBox = () => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(popularLeagues.map((item) => item.id.toString()));
    popularLeagues.map((item) => {
      allLeaguesData.push(item);
    });
    setIsCountryCheck(
      allLeaguesData.map((item) => {
        return item.id.toString();
      })
    );
    if (isCheckAll) {
      setIsCheck([]);
      setIsCountryCheck([]);
    }
  };
  const handleCheckBox = (e) => {
    const { id, checked } = e.target;
    setIsCheck(() => [...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  const handleCountryCheckbox = (e) => {
    const { id, checked } = e.target;
    setIsCountryCheck([...isCountryCheck, id]);
    if (!checked) {
      setIsCountryCheck(isCountryCheck.filter((item) => item !== id));
    }
  };
  const handleAddPredictionHide = () => {
    setAddPredictionModle(false);
  };
  const handleSportShowModel = () => {
    setPredictionModle(true);
    setAddPredictionModle(false);
    setSportContains("sport-container sport-container-show");
    if (sportContains === "sport-container sport-container-show") {
      getSportData();
      setEmptyMsgShow("empty-error-msg empty-error-msg-hide");
    }
    setLeagueesContains(
      "leaues-country-container leagues-country-container-hide"
    );
    setModelLabel(t("profile.selectSport"));
    setSportId("");
    setIsCheck([]);
    setIsCountryCheck([]);
  };
  return (
    <>
      <div className="llll">
        <Model
          isModel={predictionModel}
          modelClassName={"add-predication-model"}
          hideModel={handlePredictionHide}
          modelTitle={modelLabel}
          modelSkipSport={hadleSelectSportsBtn}
        >
          <div className="sports-leagues-country-container">
            {/* sports-list */}
            <div className={sportContains}>
              <SearchBar
                inputPlaceHolder={t("profile.search")}
                onChange={handleSportSearch}
              />
              <div className="sports-scroll">
                <div className="sports-main-container">
                  {sportData?.map((item, index) => (
                    <div
                      className="sports-containter"
                      key={index}
                      onClick={() =>
                        handlesports(item.id, item.name, item.name_ua)
                      }
                    >
                      <div className="d-flex">
                        <div className="sports-icon">
                          <img src={"sport-logo/" + item.id + ".svg"} />
                        </div>
                        <div className="sports-name">
                          {apiService.getProfileFromStorage()?.Language == "ua"
                            ? item.name_ua
                              ? item.name_ua
                              : item.name
                            : item.name}
                        </div>
                      </div>
                      <div className="sports-toggle-btn">
                        <img src="arrow-Right.svg" />
                      </div>
                    </div>
                  ))}
                  <div className={emptyMsgShow}>
                    No results that match your criteria
                  </div>
                </div>
              </div>
            </div>

            {/* leaguees - country container  */}

            <div className={leagueesContains}>
              <SearchBar
                inputPlaceHolder={t("profile.search")}
                onChange={handleLeagueSearch}
              />
              {showLeaugeLoader ? (
                <div className="league-model-loader">
                  <SportLeagueModelLoader />
                </div>
              ) : (
                <div className="leagues-country-scroll pb-3">
                  <div className="leagues-all-leagues-container">
                    <div className={popularLeaguesHideShow}>
                      <div className="all-leagues">
                        <div className="all-leagues-containts">
                          {t("profile.allLeagues")}
                        </div>
                        <InputCheckbox
                          onChange={handleAllCheckBox}
                          id={"selectAll"}
                          isChecked={isCheckAll}
                          checkBoxName={"selectAll"}
                        />
                      </div>
                      {/* leagues-list */}
                      <div className="popular-league-section">
                        {popularLeagues.length > 0 ? (
                          <div>
                            <div className="popular-leagues-title">
                              {t("profile.popularLeagues")}
                            </div>
                            {popularLeagues.map((item, index) => (
                              <div className="popular-leagues" key={index}>
                                <div className="d-flex">
                                  <div className="leagues-image">
                                    <img
                                      src={`/country-logo/${item.country_id}.png`}
                                    />
                                  </div>
                                  <div className="leagues-name">
                                    {item.name}
                                  </div>
                                </div>
                                <InputCheckbox
                                  onChange={handleCheckBox}
                                  id={item.id}
                                  checkBoxName={item.name}
                                  isChecked={isCheck.includes(
                                    item.id.toString()
                                  )}
                                />
                              </div>
                            ))}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="model-country-title">
                        {t("profile.countries")}
                      </div>
                    </div>
                    {/* country-list */}
                    <div className="accordion-country-container">
                      <Accordion>
                        {countryLeaguesData.map((item, index) =>
                          item.id ? (
                            <Accordion.Item
                              eventKey={"countryId-" + item.id}
                              key={index}
                            >
                              <Accordion.Header>
                                <div className="d-flex">
                                  <div className="model-country-icon">
                                    <img
                                      src={"country-logo/" + item.id + ".png"}
                                    />
                                  </div>
                                  <div className="model-country-name">
                                    {item.name}
                                  </div>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                {item.leagues.map((data, index) => (
                                  <div
                                    className=""
                                    id="countryDropDownId"
                                    key={index}
                                  >
                                    <div className="country-leaguees-containts">
                                      <div className="country-leaguees-name">
                                        {data.name}
                                      </div>
                                      <InputCheckbox
                                        onChange={handleCountryCheckbox}
                                        id={data.id}
                                        checkBoxName={data.name}
                                        isChecked={isCountryCheck.includes(
                                          data.id.toString()
                                        )}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </Accordion.Body>
                            </Accordion.Item>
                          ) : (
                            ""
                          )
                        )}
                      </Accordion>
                    </div>
                    <div className={leagueEmptyMsgShow}>
                      No results that match your criteria
                    </div>
                  </div>
                </div>
              )}
              <div className="model-footer-part">
                <div className="selectSport-button-div">
                  {selectedButton ? (
                    <InputButton
                      type="icon-button"
                      onClick={hadleSelectSportsBtn}
                      inputLabel={t("profile.selectSport")}
                      addSpan={
                        <span>
                          <img src="arrow-Left.svg" />
                        </span>
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
                <InputButton
                  type="primary"
                  inputLabel={t("profile.showOddsLine")}
                  addBtnClass={"show-odds-line"}
                  disabled={
                    isCheck.length <= 0 &&
                    isCountryCheck.length <= 0 &&
                    !isCheckAll
                      ? true
                      : false
                  }
                  onClick={handleAddPrediction}
                />
              </div>
            </div>
          </div>
        </Model>
        <div className={addSomePredictionsModel}>
          <div className="prediction-list-model-body">
            {addPredictionsModel ? (
              <AddPrediction
                gameId={props.gameId}
                getSportData={getSportData}
                countData={props.countData}
                isModel={addPredictionsModel}
                increamentUserCount={props.increamentUserCount}
                editLeaugeModel={handleLeagueDataShow}
                hideModel={handleAddPredictionHide}
                sportId={sportId}
                addSportShowModel={handleSportShowModel}
                array={[...isCheck, ...isCountryCheck]}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default FeedPrediction;
