import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthUtils } from "../AuthUtils";
import Header from "./Header";

const ReturnLogin = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const location = useLocation();

  useEffect(() => {
    setEmail(location.state.email);
    AuthUtils()
  }, []);
  const handleReturnLogin = () => {
    navigate("/signin");
  };
  
  return (
    <>
      <Header />
      <section className="form-section">
        <div className="">
          <div className=" d-flex justify-content-center align-items-center">
            <div className="card returnlogin-card">
              <div className="card-body p-0">
                {/* ------ avatar ----- */}
                <div className="checkmail-avatar">
                  <div
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      width: "64px",
                      height: "64px",
                      backgroundColor: "#F2F3F7",
                    }}
                    alt="Avatar"
                  >
                    <img src="mail.svg" />
                  </div>
                </div>
                <div className="card-title returnlogin-card-title">
                  <div
                    className=" text-center mt-4"
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      lineHeight: "24px",
                    }}
                  >
                    {t("returnToLogin.CheckemailTitle")}
                  </div>
                  <p
                    className="text-center mt-2 mb-4"
                    style={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      fontWeight: "300",
                      color: "#7F87B3",
                    }}
                  >
                    {t("returnToLogin.returnToLoginSubTitle1")}
                    <span
                      className="ms-1"
                      style={{ cursor: "pointer", fontWeight: "700" }}
                    >
                      {email}
                    </span>{" "}
                    {t("returnToLogin.returnToLoginSubTitle2")}
                  </p>
                  <p
                    className="text-center mt-2 mb-4"
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      lineHeight: "20px",
                      color: "#7F87B3",
                    }}
                  >
                    {t("returnToLogin.decriptionTitle")}
                  </p>
                  <div className="">
                    <button
                      className="form-control form-input-field-button"
                      id="exampleInputbutton"
                      onClick={handleReturnLogin}
                    >
                       {t("returnToLogin.returnToLoginBtn")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ReturnLogin;
