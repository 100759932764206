import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import InputButton from "../InputButton";
import "../model/model.css";
const Model = (props) => {
  const { t, i18n } = useTranslation();
  useEffect(() => { }, [props]);
  return (
    <>
      <Modal
        show={props.isModel}
        onHide={props.hideModel}
        className={props.modelClassName}
      >
        <div className="model-extra-css">
          <Modal.Header className="align-items-baseline">
            <Modal.Title className="model-title">
              {props.modelTitle}
              {props.modelSubTitle && (
                <div className="modelSubTitle-ConfirmSubscription">
                  {props.modelSubTitle}
                </div>
              )}
            </Modal.Title>
            <div className="model-close-btn" onClick={props.hideModel}>
              <span>
                <img
                  src="/close-squere-btn.svg"
                  alt="close"
                  className="close-button close-squere-button"
                />
              </span>
            </div>
          </Modal.Header>
          {props.children}
        </div>
      </Modal>
    </>
  );
};
export default Model;
