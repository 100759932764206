import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InputCheckbox from "../inputCheckbox/InputCheckbox";
import apiService from "../../../services/apiService";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const PredictionsTable = (props) => {
  const { t, i18n } = useTranslation();
  const pageSize = 10;
  const navigate = useNavigate();
  useEffect(() => {}, [props]);
  const handleIsParticipants = async (e, id, winner) => {
    if (winner) {
      const body = {
        removeWinnerUserId: id,
      };
      const res = await apiService.confirmWinner(props.isGetGiveawayId, body);
      if (res) {
        props.incrementCount();
      }
    } else {
      const body = {
        userId: id,
      };
      const res = await apiService.confirmWinner(props.isGetGiveawayId, body);
      if (res) {
        props.incrementCount();
      }
    }
  };
  // navigate publick profile
  const handleUserProfile = async (item, pageNumber = 0) => {
    const res = await apiService.getPredictionOrderByUserId(
      item._id,
      pageSize,
      pageNumber
    );
    if (item._id.toString() == localStorage.getItem("userId")) {
      navigate(`/userprofile`);
    } else {
      navigate(`/profile/${item._id}`, {
        state: res,
        length: res?.length,
      });
    }
  };
  return (
    <>
      <div>
        <div className="Top-predictions-tabletitle">
          <div className="Participants-table-user">
            {t("giveAways.ParticipantsTable.UserTitle")}
          </div>
          <div className="Participants-table-Instagram ">
            {t("giveAways.ParticipantsTable.InstagramTitle")}
          </div>
          <div className="Participants-table-TikTok">
            {t("giveAways.ParticipantsTable.TikTokTitle")}
          </div>
          <div className="Participants-table-Confirm-winner">
            {t("giveAways.ParticipantsTable.ConfirmwinnerTitle")}
          </div>
        </div>
        {props?.PerfectPredictors?.length < 1 ? (
          <div className="noPerfectPredictorsline mt-4 text-center">
            {t("giveAways.PerfectPredictors.onlyTwoPerfectPredictorsLine")}
          </div>
        ) : (
          <div>
            {props.PerfectPredictors?.map((item, index) => (
              <div className="Top-predictions-main-row" key={index}>
                <div className="predictions-table-row d-flex align-items-center">
                  <div className="d-flex Top-predictions-teams-bitton-div align-items-center">
                    {/* user */}
                    <div className="Participants-table-user-contains pe-4 d-flex align-items-center justify-content-between">
                      <div className="d-flex">
                        <div className="">
                          {item.userId?.profileImgUrl !== "" ? (
                            <img
                              src={item.userId?.profileImgUrl}
                              className="Winner-team-img"
                              width="44px"
                              height="44px"
                            />
                          ) : (
                            <div className="Winner-team-avatar">
                              {item.userId?.userName.charAt(0)}
                            </div>
                          )}
                        </div>
                        <div className=" ps-2">
                          <div
                            className="Winner-team-name"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleUserProfile(item.userId)}
                          >
                            {item.userId.firstName + " " + item.userId.lastName}
                          </div>
                          <div className="Winner-team-username">
                            @{item.userId.userName}
                          </div>
                        </div>
                      </div>
                      {/* <div className="badge-dot"></div> */}
                    </div>
                    {/* insta */}
                    <div className="d-flex mobile-Winner-div justify-content-between">
                      <span className="mobile-Winner-team-name ">
                        {" "}
                        {t("giveAways.ParticipantsTable.InstagramTitle")}
                      </span>{" "}
                      <a
                        href={`http://instagram.com/${
                          item.userId?.instagramUserName ||
                          item.userId?.userName
                        }/`}
                        style={{ textDecoration: "none" }}
                        target="_blank"
                      >
                        <div className="Participants-table-Instagram-contains">
                          @{item.userId?.instagramUserName}
                        </div>
                      </a>
                    </div>
                    {/* tiktik */}
                    <div className="d-flex mobile-Winner-div justify-content-between">
                      <span className="mobile-Winner-team-name ">
                        {" "}
                        {t("giveAways.ParticipantsTable.TikTokTitle")}
                      </span>{" "}
                      <a
                        href={`http://tiktok.com/${
                          item.userId?.tikTokUserName || item.userId?.userName
                        }`}
                        style={{ textDecoration: "none" }}
                        target="_blank"
                      >
                        <div className="Participants-table-tiktok-contains">
                          @{item.userId?.tikTokUserName}
                        </div>
                      </a>
                    </div>
                    {/* Confirm-winner */}
                    <div className="d-flex mobile-Winner-div justify-content-between">
                      <span className="mobile-Winner-team-name ">
                        {" "}
                        {t("giveAways.ParticipantsTable.ConfirmwinnerTitle")}
                      </span>{" "}
                      <div className="Participants-table-Confirm-winner-contains d-flex justify-content-center">
                        <InputCheckbox
                          onChange={(e) =>
                            handleIsParticipants(
                              e,
                              item.userId._id,
                              item.isWinner
                            )
                          }
                          id={item.userId._id}
                          isChecked={
                            item.isWinner == true ? item.isWinner : false
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
export default PredictionsTable;
