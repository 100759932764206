import React, { useEffect } from "react";

import "./ad.css";

const Ad = () => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);
  
  return (
    <div className="ad-wrapper">
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-adtest="on"
        data-ad-client="ca-pub-6075380238860109"
        data-ad-slot="4608097297"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};

export default Ad;
