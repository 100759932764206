import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  STATUS_COMPLETED,
  STATUS_FAILED,
  STATUS_LOADING,
  STATUS_NOT_STARTED,
} from "../consts/StatusConst";
import { changeLanguage } from "i18next";

export const getSports = createAsyncThunk(
  "bet/getSports",
  async function (params, { rejectWithValue }) {
    let url = `${process.env.REACT_APP_BET_URL}sports/line/ua`;

    try {
      // console.log(`start fetching sports ${new Date()}`);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Package: process.env.REACT_APP_BET_PACKAGE,
        },
      });

      const records = await response.json();

      if (records.status !== 1) {
        throw new Error(`Server error. ${records.body}`);
      }

      return records.body;
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getSportEvents = createAsyncThunk(
  "bet/getSportEvents",
  async function (params, { rejectWithValue }) {
    let url = `${process.env.REACT_APP_baseUrl}/sportline/getSportEvents?type=${params.activeSportKey}`;
    
    if(params.activeSport && params.activeLeague){
      url = `${process.env.REACT_APP_baseUrl}/sportline/getSportEvents?type=${params.activeSportKey}&sport=${params.activeSport}&league=${params.activeLeague}`;
    }

    try {
      // console.log(
      //   `start fetching sport events for: "${
      //     params.activeSportKey
      //   }" - ${new Date()}`
      // );
      const response = await fetch(url, {
        method: "GET",
      });

      const records = await response.json();

      // if (params.activeSportKey !== "home" && records.status !== 1) {
      //   throw new Error(`Server error. ${records.body}`);
      // }

      return records;
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getEvent = createAsyncThunk(
  "bet/getEvent",
  async function (params, { rejectWithValue, dispatch }) {
    let sportKey =
      params.activeSportKey === "home" ? "line" : params.activeSportKey;

    const lang = localStorage.getItem("language") || "ua";
    // console.log(
    //   `start fetching event id: "${params.eventId}" - ${new Date()}`
    // );

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BET_URL}event/${params.eventId}/sub/${sportKey}/${lang}`,
        {
          method: "GET",
          headers: {
            Package: process.env.REACT_APP_BET_PACKAGE,
          },
        }
      );

      const records = await response.json();
      return records.body;
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    }
  }
);

export const getLeagues = createAsyncThunk(
  "bet/getLeagues",
  async function (params, { rejectWithValue, dispatch }) {
    console.log(
      `start fetching leagues for: "${params.activeSportKey}" - ${new Date()}`
    );
    let url = `${process.env.REACT_APP_baseUrl}/sportline/getTournaments?type=${params.activeSportKey}`;

    try {
      const response = await fetch(url, {
        method: "GET",
      });

      const records = await response.json();
      return records;
    } catch (error) {
      console.log(error.message);
      return rejectWithValue(error.message);
    }
  }
);

const betSlice = createSlice({
  name: "bet",
  initialState: {
    lang: "ua",
    event: [],
    sports: [],
    leagues: [],
    sportEvents: [],
    sortedLeaguesKeys: [],
    predictions: [],
    odds: 0,
    sportEventsStatus: STATUS_LOADING,
    eventLoadingStatus: STATUS_LOADING,
    leaguesLoadingStatus: STATUS_LOADING,
    sportsLoadingStatus: STATUS_LOADING,
    error: null,
  },
  reducers: {
    clearSportEvents(state) {
      console.log("clear sport events");
      state.sportEvents = [];
    },
    clearEvent(state) {
      console.log("clear event");
      state.event = [];
    },
    clearData(state) {
      console.log("clear data");
      state.leagues = [];
      state.sportEvents = [];
      state.event = [];
    },
    setPredictions(state, action) {
      state.predictions = action.payload;
    },
    setLanguage(state, action) {
      state.lang = action.payload;
    },
  },
  extraReducers: (builder) => {
    //getSports
    builder.addCase(getSports.pending, (state) => {
      if (state.sports.length === 0) {
        state.sportEventsStatus = STATUS_LOADING;
      }
      state.error = null;
    });
    builder.addCase(getSports.fulfilled, (state, action) => {
      state.sports = action.payload;
      state.sportsLoadingStatus = STATUS_COMPLETED;
    });
    builder.addCase(getSports.rejected, (state, action) => {
      state.sportsLoadingStatus = STATUS_FAILED;
      state.error = action.payload;
    });
    //getSportEvents
    builder.addCase(getSportEvents.pending, (state) => {
      if (state.sportEvents.length === 0) {
        state.sportEventsStatus = STATUS_LOADING;
      }
      state.error = null;
    });
    builder.addCase(getSportEvents.fulfilled, (state, action) => {
      state.sportEvents = action.payload;
      state.sportEventsStatus = STATUS_COMPLETED;
    });
    builder.addCase(getSportEvents.rejected, (state, action) => {
      state.sportEventsStatus = STATUS_FAILED;
      state.error = action.payload;
    });
    //getEvent
    builder.addCase(getEvent.pending, (state) => {
      if (!state.event.game_id) {
        state.eventLoadingStatus = STATUS_LOADING;
      }
      state.error = null;
    });
    builder.addCase(getEvent.fulfilled, (state, action) => {
      state.event = action.payload;
      state.eventLoadingStatus = STATUS_COMPLETED;
    });
    builder.addCase(getEvent.rejected, (state, action) => {
      state.eventLoadingStatus = STATUS_FAILED;
      state.error = action.payload;
    });
    //getLeagues
    builder.addCase(getLeagues.pending, (state) => {
      if (state.leagues.length === 0) {
        state.leaguesLoadingStatus = STATUS_LOADING;
      }
      state.error = null;
    });
    builder.addCase(getLeagues.fulfilled, (state, action) => {
      state.leagues = action.payload;
      state.leaguesLoadingStatus = STATUS_COMPLETED;
    });
    builder.addCase(getLeagues.rejected, (state, action) => {
      state.leaguesLoadingStatus = STATUS_FAILED;
      state.error = action.payload;
    });
  },
});

export const { clearSportEvents, setPredictions, clearData, clearEvent, setLanguage } = betSlice.actions;

export default betSlice.reducer;
