import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import apiService from "../../services/apiService";
import { Link } from "react-router-dom";
import Model from "../molecule/model/Model";
import UserRewardsLoader from "../CustomSkeletonLoaders/UserRewardsLoader";
import ToolTip from "../General/ToolTip/ToolTip";

const UserRewardsSection = ({ userId }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const [user, setUser] = useState({});
  const [rewards, setRewards] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getRewards();
  }, []);

  const getRewards = async () => {
    setIsLoad(false);
    setRewards([]);
    let user = localStorage.getItem("userId");
    if (userId) {
      user = userId;
      const response = await apiService.getOtheruserProfile(userId);
      setUser(response);
    }
    const rewardsRes = await apiService.getUserCurrentRewards(user);

    setRewards(rewardsRes);

    setIsLoad(true);
  };

  const renderEmptyRewardsMsg = () => {
    return (
      <div className="no-rewards-wrapper">
        <div className="no-rewards-img-wrapper">
          <img src="/rewards/Achievements.svg" alt="" />
        </div>
        <div className="no-rewards-text">
          <div className="no-rewards-title">
            {user?.firstName}
            {userId
              ? t("rewards.noRewardUserTitle")
              : t("rewards.noRewardTitle")}
          </div>
          <div className="no-rewards-sub-title">
            {userId
              ? t("rewards.noRewardUserSubTitle")
              : t("rewards.noRewardSubTitle")}
          </div>
        </div>
        {!userId && (
          <Link
            className="no-rewards-btn"
            to={
              localStorage.getItem("language") === "ua"
                ? "/achievements"
                : "/en/achievements"
            }
          >
            {t("rewards.openRewards")}
          </Link>
        )}
      </div>
    );
  };

  return (
    <div className="user-rewards-section">
      {!isLoad ? (
        <UserRewardsLoader />
      ) : (
        <>
          {rewards.length === 0 ? (
            renderEmptyRewardsMsg()
          ) : (
            <div className="user-rewards-wrapper">
              {!isTablet && (
                <div className="user-rewards-title">
                  {t("rewards.reward")} ({rewards.length})
                </div>
              )}
              <div className="user-rewards-list">
                {rewards?.map((item, index) => {
                  if (!isTablet && index > 5) return;
                  return (
                    <div className="user-rewards-list-item">
                      <div className="user-rewards-item-img">
                        <img src={`/rewards/${item.reward}.svg`} alt="" />
                        <div className="user-reward-level">
                          <ToolTip
                            text={`${item.level}  ${t("rewards.level")}`}
                          >
                            <span className="level-span">{item.level}</span>
                          </ToolTip>
                        </div>
                      </div>
                      <div className="user-rewards-item-name">
                        {localStorage.getItem("language") === "ua"
                          ? item.name
                          : item.name_en}
                      </div>
                    </div>
                  );
                })}
              </div>
              {!isTablet && rewards.length > 6 && (
                <div className="wallet-rewards__show-all-wrapper">
                  <div
                    className="wallet-rewards__show-all-btn"
                    onClick={() => setShowModal(true)}
                  >
                    {t("rewards.showAllRewards")}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}

      <Model
        isModel={showModal}
        modelClassName={"user-rewards-modal"}
        hideModel={() => setShowModal(false)}
        modelTitle={t("rewards.reward")}
      >
        <div className="user-rewards">
          <div className="user-rewards-list">
            {rewards?.map((item) => {
              return (
                <div className="user-rewards-list-item">
                  <div className="user-rewards-item-img">
                    <img src={`/rewards/${item.reward}.svg`} alt="" />
                    <div className="user-reward-level">
                      <ToolTip text={`${item.level}  ${t("rewards.level")}`}>
                        <span className="level-span">{item.level}</span>
                      </ToolTip>
                    </div>
                  </div>
                  <div className="user-rewards-item-name">
                    {localStorage.getItem("language") === "ua"
                      ? item.name
                      : item.name_en}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Model>
    </div>
  );
};

export default UserRewardsSection;
