import React, { useState } from "react";
import { Frame, Loading } from "@shopify/polaris";
import { eventBus } from "../../../services/events";
import { CONSTANT } from "../../../common/constant";

const LoadingComponent = () => {
  const [showLoader, setShowLoader] = useState(false);
  eventBus.off(CONSTANT.HIDE_LOADER, () => {
    setShowLoader(false);
  });
  eventBus.off(CONSTANT.SHOW_LOADER, () => {
    setShowLoader(true);
  });
  eventBus.on(CONSTANT.HIDE_LOADER, () => {
    setShowLoader(false);
  });
  eventBus.on(CONSTANT.SHOW_LOADER, () => {
    setShowLoader(true);
  });
  return (
    <>
      <div className="">
        <Frame style={{ height: "100px" }}>{showLoader && <Loading />}</Frame>
      </div>
    </>
  );
};

export default LoadingComponent;
