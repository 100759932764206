import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import apiService from "../../../services/apiService";
import InputButton from "../InputButton";
import "./betslip.css";
import { CONSTANT } from "../../../common/constant";
import { eventBus } from "../../../services/events";
import Model from "../model/Model";
import ErrorPopup, { CustomNotification } from "../ErrorPopup/ErrorPopup";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const BetSlip = (props) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [loginEmail, setLoginEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [errorClass, setErrorClass] = useState("mb-3");
  const [error, setError] = useState("form-error-line-hide");
  const [predictionModel, setPredictionModle] = useState(false);
  const [totle, setTotle] = useState(0);
  const [refreshCount, setRefreshCount] = useState(1);
  const [predicationLIst, setPredictionList] = useState([]);
  const [bitSlipDescription, setBitSlipDescription] = useState("");
  const [addLoader, setAddLoader] = useState(false);
  const [addDecriptiondiv, setAddDescriptionDiv] = useState(false);
  const [coinData, setCoinData] = useState(null);
  const [isNumberValue, setIsNumberValue] = useState(false);
  const [isMinimumValue, setIsMinimumValue] = useState(false);
  const [isFundValue, setIsFundValue] = useState(false);
  const [oddsType, setOddsType] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsgTitle, setErrorMsgTitle] = useState("");
  const [isErrorPopup, setIsErrorPopup] = useState(false);
  const [pageCountData, setPageCountData] = useState(0);

  const handleEmail = async (e) => {
    e.preventDefault();
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.getUserName({ email: loginEmail });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res.data) {
      navigate("/login", {
        state: {
          email: loginEmail,
          userName: userName,
          userEmail: userEmail,
          name: res.data.userName,
          profileImgUrl: res?.data.profileImgUrl,
        },
      });
    } else {
      setErrorClass("mb-3 form-error");
      setError("form-error-line-show");
    }
  };
  const isValidEmail = (email) => {
    const regexEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regexEmail.test(email);
  };
  // useEffect(() => {}, [errorMsgTitle, isErrorPopup]);
  const handleLoginEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      // username
      setUserName(e.target.value);
    } else {
      // email
      setUserEmail(e.target.value);
    }
    setLoginEmail(e.target.value);
    if (e.target.value == "") {
      setErrorClass("mb-3");
      setError("form-error-line-hide");
    }
  };

  const handlePredictionHide = () => {
    setPredictionModle(false);
  };

  const predictionList = () => {
    if (localStorage.getItem("createPrediction")) {
      const response = apiService.getPredictionFromStorage();
      let number = 1;
      response.map((item) => {
        number = number * parseFloat(item.oc_rate);
      });
      props.setTotalPredictionCount(number)
      setTotle(number);
      setPredictionList(response);

      const allLive = response.every((record) => record.oddsType == "live");
      const allLine = response.every((record) => record.oddsType == "line");

      if (allLive) {
        setOddsType("live");
      } else if (allLine) {
        setOddsType("line");
      } else {
        setOddsType("-");
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      predictionList();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [refreshCount, props.countData]);
  
  const handleRemovePrediction = async (id) => {
    let allData = JSON.parse(localStorage.getItem("createPrediction"));
    allData = allData.filter((i, index) => {
      if (i.game_id == id) {
        var spliced = allData.splice(index, 1);
      }
      localStorage.setItem("createPrediction", JSON.stringify(allData));
      setRefreshCount((old) => old + 1);
      props.incrementCount();
    });
  };
  const handleClearAllOdds = async () => {
    localStorage.setItem("createPrediction", JSON.stringify([]));
    setPredictionList([]);
    // props.incrementCount();
  };
  const handleBitSlipPredictionDescription = (e) => {
    setBitSlipDescription(e.target.value);
  };
  const handleMyPredoctionList = () => {
    navigate("/");

  };
  const handlePlacePrediction = async () => {
    let lastOddsMap = JSON.parse(localStorage.getItem("lastOddsMap"));
    setPageCountData(pageCountData + 1);
    if (localStorage.getItem("token")) {
      setAddLoader(true);
      //addtotal count
      let odds = JSON.parse(localStorage.getItem("createPrediction"));
      let totalOdds = 1;
      odds.map((i) => {
        totalOdds *= i.oc_rate.toFixed(2);
      });
      const data = {
        odds: odds,
        totalOdds: totalOdds.toFixed(2),
        betTotal: coinData,
        betType: oddsType,
        comments: bitSlipDescription,
      };
      const res = await apiService.createPredictionOrder(
        data,
        props?.selectedTab
      );
      const errorErr = [];
      if (res) {
        props.incrementCount();
        props.getTotalBetsCoinData();
        setAddLoader(false);
        res.filter((item) => {
          if (item.fullErrorCode != 0) {
            if (item.fullErrorCode == 3) {
              errorErr.push({
                title: t("feed.ErrorMainTitle"),
                description: t("feed.ErrorTooltipMsgsubTitle"),
                flag: "isErrorPopup",
              });
            } else if (item.fullErrorCode == 99) {
              errorErr.push({
                title: t("feed.ErrorMainTitle"),
                description: t("feed.ErrorTooltipMsgsubTitle"),
                flag: "isErrorPopup",
              });
            } else {
              errorErr.push({
                title: t("feed.ErrorMainTitle"),
                description: t("feed.ErrorTooltipMsgsubTitle"),
                flag: "isErrorPopup",
              });
            }
          } else {
          }
        });
        if (errorErr.length > 0) {
          //here you need to show all error in one pop up
          NotificationManager.info(
            CustomNotification(
              errorErr[0].title,
              "",
              errorErr[0].flag,
              "",
              res
            ),
            null,
            5000
          );
        } else {
          NotificationManager.info(
            CustomNotification(
              t("feed.succesTooltipMsgTitle"),
              t("feed.succesTooltipMsgSubTitle"),
              "isSuccessPopup",
              handleMyPredoctionList
            ),
            null,
            5000
          );

          handleClearAllOdds();
          props.hideBetSlip();
          // setErrorMsgTitle(t("feed.succesTooltipMsgTitle"));
          // setIsErrorPopup(false);
        }
      }
    } else {
      setPredictionModle(true);
    }
  };

  const handleBetCoins = (event) => {
    const numberRegex = /^[0-9]+$/;
    const { value } = event.target;
    if (numberRegex.test(value) || value === "") {
      if (parseInt(value) < 10) {
        setIsMinimumValue(true);
      } else {
        setIsMinimumValue(false);
      }
      if (parseInt(props.userCoins) < parseInt(value)) {
        setIsFundValue(true);
      } else {
        setIsFundValue(false);
      }
      setCoinData(value);
    } else {
      setIsNumberValue(true);
    }
  };
  const handleMaxValue = () => {
    setCoinData(props.userCoins);
    setIsMinimumValue(false);
    setIsFundValue(false);
  };

  const handleHidePopup = () => {
    eventBus.emit(CONSTANT.HIDE_POPUP);
  };
  const handleArrowImage = (change) => {
    if (change > 0) {
      return "/win arrow up.svg";
    } else if (change < 0) {
      return "/lose arrow down.svg";
    } else {
      return "";
    }
  };

  return (
    <>
      <ErrorPopup
      // handleHidePopup={handleHidePopup}
      // handleMyPredoctionList={handleMyPredoctionList}
      // isErrorPopup={isErrorPopup}
      // errorMsgTitle={errorMsgTitle}
      // pageCountData={pageCountData}
      />
      <Card>
        <Card.Body>
          <div className="addPrediction-right-panel-container">
            <div className="d-flex justify-content-between addPrediction-panel-farme">
              <div className="addPrediction-prediction-title align-items-end">
                <span>
                  <img src="/ticket.svg" className="pe-1" />
                </span>{" "}
                {/* {props.betSlipTitle
                  ? props.betSlipTitle
                  : t("profile.predictions")}{" "} */}
                {t("sportLine.newBetslipTitle")}
                <span
                  className="predictionData-Count"
                  style={{
                    display:
                      props.mediumBetSlip || props.mobileBetSlip
                        ? "block"
                        : "none",
                  }}
                >
                  {predicationLIst.length}
                </span>
              </div>

              {props.mediumBetSlip ? (
                <div onClick={props.hideBetSlip} style={{ cursor: "pointer" }}>
                  <img src="/double right.svg" style={{ transform: "rotate(90deg)" }} />
                </div>
              ) : (
                ""
              )}
            </div>
            {/* betSlip containt */}
            {predicationLIst.length < 1 ? (
              <div className="d-flex justify-content-center prediction-slip-section">
                <div className="prediction-slip-container">
                  <div className="prediction-slip-avatar">
                    <img src="/Ticket-Star.svg"></img>
                  </div>
                  <div className="prediction-slip-empty-msg">
                    {t("sportLine.newBetslipsubTitle")}
                  </div>
                  <div className="add-prediction-betslip">
                    {t("profile.addBitSlip")}{" "}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="betSlip-title">
                  {predicationLIst.length <= 1 ? (
                    <div>{t("profile.Single")}</div>
                  ) : (
                    <div className="d-flex">
                      {t("profile.Parlay")}
                      <span
                        style={{
                          color: "#7F87B3",
                          display:
                            props.mediumBetSlip || props.mobileBetSlip
                              ? "none"
                              : "flex",
                        }}
                      >
                        {" "}
                        (
                      </span>
                      <span
                        style={{
                          color: "#7F87B3",
                          display:
                            props.mediumBetSlip || props.mobileBetSlip
                              ? "none"
                              : "flex",
                        }}
                      >
                        {predicationLIst.length}
                      </span>
                      <span
                        style={{
                          color: "#7F87B3",
                          display:
                            props.mediumBetSlip || props.mobileBetSlip
                              ? "none"
                              : "flex",
                        }}
                      >
                        )
                      </span>
                    </div>
                  )}
                  <div
                    className="betSlip-ClearAll"
                    onClick={handleClearAllOdds}
                  >
                    {t("profile.ClearAll")}
                  </div>
                </div>
                <div className="betSlip-scroll">
                  {predicationLIst?.map((item, index) => (
                    <div
                      className="d-flex justify-content-between odds-details"
                      key={index}
                    >
                      <div className="d-flex">
                        <div
                          className="odds-remove-btn"
                          onClick={() => handleRemovePrediction(item.game_id)}
                        >
                          <span>
                            <img src="./remove.svg" />
                          </span>
                        </div>
                        <div>
                          <div className="full-time-result-containt">
                            {item.oc_group_name === "1x2"
                              ? "Full-time result"
                              : item.oc_group_name}{" "}
                            <span> — {item.oc_name} </span>
                          </div>
                          <div className="selected-team-name">
                            {apiService.getProfileFromStorage()?.Language ==
                              "en"
                              ? item.opp_1_name_en
                                ? item.opp_1_name_en
                                : item.opp_1_name
                              : item.opp_1_name}{" "}
                            —{" "}
                            {apiService.getProfileFromStorage()?.Language ==
                              "en"
                              ? item.opp_2_name_en
                                ? item.opp_2_name_en
                                : item.opp_2_name
                              : item.opp_2_name}
                          </div>
                        </div>
                      </div>
                      <div
                        className="odds-score d-flex"
                        style={{
                          color:
                            item.change > 0
                              ? "#208B8E"
                              : item.change < 0
                                ? "#D14343"
                                : "",
                        }}
                      >
                        <img
                          src={handleArrowImage(item.change)}
                          className="pe-1"
                        />
                        {parseFloat(item.oc_rate).toFixed(2)}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="total-odds-section">
                  <div className="total-odds">{t("profile.TotalOdds")}</div>
                  <div className="total-score">
                    {parseFloat(totle).toFixed(2)}
                  </div>
                </div>
                {/* Bet Total */}
                {localStorage.getItem("token") && (
                  <div className="Bet-total-coin-section">
                    <div className="Bet-total-title d-flex">
                      <div className="total-odds pe-2">
                        {t("sportLine.BetTotal")}
                      </div>
                      {/* <img src="/alert-circle.svg" /> */}
                    </div>
                    {/* input */}
                    <div className="Bet-total-coin-container">
                      <div className="d-flex justify-content-end Bet-total-input-field-inner-content ">
                        <div className="CAP-title">CAP</div>
                        <div className="Max-title" onClick={handleMaxValue}>
                          {t("sportLine.max")}
                        </div>
                      </div>
                      <input
                        type="text"
                        className="Bet-total-input-field form-control"
                        value={coinData}
                        onChange={handleBetCoins}
                        placeholder={t("sportLine.Minimum10")}
                      />
                    </div>
                    {/* Available: 1400 CAP */}
                    <div className="available-coin-tooltip-section">
                      {/* serve */}
                      <div className="available-coin-serve d-flex align-itmes-center">
                        <div className="Available-title">
                          {t("sportLine.Available")}:{" "}
                        </div>
                        <div className="coin-count ps-2">
                          {props.userCoins} CAP
                        </div>
                      </div>
                      {/* tooltip */}
                      {isFundValue && (
                        <div className="getCPA-coin-tooltip d-flex align-items-center justify-content-between mt-2">
                          <div className="icon-description-section d-flex align-items-center">
                            <img src="/CAP coin.svg" />
                            <div className="title ps-1">
                              {t("sportLine.HowCoinsDescription")}
                            </div>
                          </div>
                          {/* learn more */}
                          <div className="Learn-more">
                            {t("sportLine.LearnMore")}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* Add description */}
                <div
                  className={`${localStorage.getItem("token") && "pt-4"
                    } Add-description-section`}
                >
                  <div
                    className={`predication-decription-section ${addDecriptiondiv
                      ? "transition-div show"
                      : "transition-div"
                      }`}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="predication-decription-title">
                        {t("profile.PredictionDescription")}
                        <span className="sub-title">
                          {" "}
                          ({t("sportLine.optional")})
                        </span>
                      </div>
                      <div
                        className="saveCancel-decription-data"
                        onClick={() => setAddDescriptionDiv(false)}
                      >
                        {t("profile.saveCancel")}
                      </div>
                    </div>
                    <form>
                      <textarea
                        name="bio"
                        cols="40"
                        className="form-textarea betSlip-textArea"
                        placeholder={t("feed.betSlipTextAreaPlaceHolder")}
                        onChange={handleBitSlipPredictionDescription}
                      ></textarea>
                    </form>
                  </div>
                  {addDecriptiondiv ? (
                    ""
                  ) : (
                    <div
                      className="d-flex Add-description-hover"
                      onClick={() => setAddDescriptionDiv(true)}
                    >
                      <div className="title">
                        + {t("sportLine.AddDescription")}{" "}
                      </div>
                      <div className="sub-title ps-1">
                        {" "}
                        ({t("sportLine.optional")})
                      </div>
                    </div>
                  )}
                  {/* button */}
                  <button
                    type="button"
                    className="place-prediction-btn"
                    onClick={() => handlePlacePrediction("error-tab")}
                    disabled={
                      localStorage.getItem("token")
                        ? !coinData ||
                        isMinimumValue ||
                        isFundValue ||
                        addLoader
                        : false
                    }
                  // disabled
                  >
                    <span className="d-flex">
                      {addLoader ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        <div>
                          <div>{t("profile.PlacePrediction")}</div>
                          <div>
                            {isMinimumValue
                              ? t("sportLine.minimumStake")
                              : isFundValue
                                ? `${t("sportLine.notEnoughFund") +
                                " " +
                                props.userCoins +
                                " CAP"
                                }`
                                : ""}
                          </div>
                        </div>
                      )}
                    </span>
                  </button>

                  {/* tooltip */}
                  <div
                    className={`alert-success ${showAlert ? "visible" : "hidden"
                      }`}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        {/* <img src="/prediction slipsuccess.svg" /> */}
                        <img src="/prediction slipError.svg" />
                        {/* center */}
                        <div className="tooltip-center-part">
                          <div className="title pb-2">
                            {/* {t("feed.succesTooltipMsgTitle")} */}
                            {t("feed.ErrorTooltipMsgTitle")}
                          </div>
                          <div className="sub-title">
                            {/* {t("feed.succesTooltipMsgSubTitle")} */}
                            {t("feed.ErrorTooltipMsgsubTitle")}
                          </div>
                        </div>
                      </div>
                      {/* close btn*/}
                      <div className="model-close-btn">
                        <span>
                          <img
                            src="/close-squere-btn.svg"
                            alt="close"
                            className="close-button close-squere-button"
                            onClick={() => setShowAlert(false)}
                          />
                        </span>
                      </div>
                    </div>
                    {/* <InputButton
                      type="primary"
                      inputLabel={t("feed.myPredictionBtn")}
                      addBtnClass="go-to-Predictionlist"
                      onClick={handleMyPredoctionList}
                    /> */}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Card.Body>
      </Card>

      {/* login model  */}
      <Model
        isModel={predictionModel}
        modelClassName={"home-page-signupPage"}
        hideModel={handlePredictionHide}
        modelTitle={t("homePageSigninPage.SignIn")}
      >
        <div className="userLoginModel">
          {/* --- signinCapperauditor top --- */}
          <div className="top">
            <div className="btn-containt">
              <button
                type="button"
                className="btn  btn-google-facebook btn-google"
                onClick={() => {
                  window.location.href = process.env.REACT_APP_googleOAuthUrl;
                }}
              >
                <span className="signup-icon">
                  <i className="fab fa-google" />
                </span>
                {t("homePageSigninPage.isGoogleBtn")}
              </button>
              <button
                type="button"
                className="btn btn-google-facebook btn-facebook"
                onClick={() => {
                  window.location.href = process.env.REACT_APP_facebookOAuthUrl;
                }}
              >
                <span className="signup-icon">
                  <i
                    className="fab fa-facebook-f"
                    style={{ color: "#4267B2" }}
                  ></i>
                </span>
                {t("homePageSigninPage.isFabookBtn")}
              </button>
            </div>
            <div className="text-divider">{t("homePageSigninPage.or")}</div>
          </div>
          {/* ------ signincapperauditor form ------  */}
          <div className="bottom">
            <form>
              <div className={errorClass}>
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label form-input-label"
                >
                  {t("homePageSigninPage.emailorusername")}
                </label>
                <input
                  type="email"
                  className="form-control form-input-field"
                  id="InputEmail"
                  defaultValue={""}
                  onChange={handleLoginEmail}
                  placeholder={t("homePageSigninPage.EnteremailPlaceHolder")}
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "#7F87B3",
                  }}
                />
                <p style={{ fontSize: "14px" }} className={error}>
                  {t("SignIn.Youremailorusernameisincorrect")}
                </p>
              </div>
              <div className="mb-4">
                <button
                  className="form-control form-input-field-button"
                  id="Inputbutton"
                  onClick={handleEmail}
                  disabled={loginEmail == ""}
                  style={{
                    pointerEvents: loginEmail == "" ? "none" : "auto",
                  }}
                >
                  {t("homePageSigninPage.Continue")}
                </button>
              </div>
            </form>
            {/* ------- signincapperauditor footer -----  */}
            <div className="signin-footer">
              <span
                className="signincapperauditor-bottom"
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "20px",
                }}
              >
                {t("homePageSigninPage.subLine1")}{" "}
                <Link
                  to="/signup"
                  style={{
                    color: "#4C5480",
                    fontWeight: "600",
                    lineHeight: "24px",
                  }}
                >
                  {t("homePageSigninPage.subLine2")}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </Model>
    </>
  );
};
export default BetSlip;
