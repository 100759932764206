import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import apiService from "../../../services/apiService";
import moment from "moment";
import { useNavigate } from "react-router";
const NotificationModal = (props) => {
  const { t, i18n } = useTranslation();
  const pageSize = 10;
  const navigate = useNavigate();
  const [currPage, setCurrPage] = useState(0);
  const [showNotificationData, setShowNotificationData] = useState({});
  const loginUserId = localStorage.getItem("userId");
  const handleNotificationDetail = async () => {
    if (props.id !== "") {
      const res = await apiService.getNotificationDetail(props.id);
      res.isReturnOdds = false;
      if (res) {
        setShowNotificationData(res);
      }
      res?.predictionId?.odds?.map((item) => {
        if (item.result.status == 3) {
          res.isReturnOdds = true;
        }
      });
    }
  };

  const handlePredictionListType = (i) => {
    if (i?.betType) {
      if (i?.betType == "live") {
        return (
          <span className="live-type-box-div">{t("profile.allLive")}</span>
        );
      } else if (i?.betType == "line") {
        return t("feed.PreGame");
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };
  const handleFollow = async (id) => {
    const res = await apiService.followUser(id);
  };
  const getOddsMultiply = (odds) => {
    let od = 1;
    for (let i of odds) {
      od = od * parseFloat(i.oc_rate.toFixed(2));
    }
    return parseFloat(od.toFixed(2));
  };
  useEffect(() => {
    handleNotificationDetail();
  }, [props.id]);
  const handleUserProfile = async (item, pageNumber = currPage || 0) => {
    const res = await apiService.getPredictionOrderByUserId(
      item._id,
      pageSize,
      pageNumber
    );
    if (item._id.toString() == loginUserId) {
      navigate(`/userprofile`);
    } else {
      navigate(`/profile/${item._id}`, {
        state: res,
        length: res?.length,
      });
    }
  };

  const handlePredictionLanguageLocalization = (data) => {
    var localLocale = moment.unix(data);
    localLocale.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
    return (
      localLocale.format("MMMM D").charAt(0).toUpperCase() +
      localLocale.format("MMMM D").slice(1)
    );
  };
  const calculateReturnsRate = (odds) => {
    let od = 1;
    for (let i of odds) {
      if (i.result.statusText == "Returned") {
      } else {
        od = od * parseFloat(i.oc_rate.toFixed(2));
      }
    }
    return parseFloat(od.toFixed(2));
  };
  const handleOddsReturnData = (item) => {
    let totalOdds = 1;
    item?.odds?.map((i) => {
      totalOdds *= i?.oc_rate.toFixed(2);
    });
    if (showNotificationData?.isReturnOdds) {
      const y = item?.totalOdds == 1 ? "0.0" : calculateReturnsRate(item?.odds);
      const x = item?.totalOdds + " / " + y;
      return x;
    } else {
      return totalOdds.toFixed(2);
    }
  };
  return (
    <>
      <Modal
        className="notification-modal notifiction-model-container"
        show={props.isModel}
        onHide={props.hide}
      >
        <Modal.Header>
          <Modal.Title>
            <div className="notificationModel-title">
              {showNotificationData?.title
                ? localStorage.getItem("language") == "en"
                  ? showNotificationData.title?.en
                  : showNotificationData.title?.ua
                : ""}
            </div>
            <div className="notificationModel-time">
              {moment.unix(showNotificationData.timestamp).format("YYYY-MM-DD")}{" "}
              {moment.unix(showNotificationData.timestamp).format("HH:mm:ss")}
            </div>
          </Modal.Title>
          <div className="model-close-btn" onClick={props.hide}>
            <span>
              <img
                src="/close-squere-btn.svg"
                alt="close"
                className="close-button close-squere-button"
              />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="">
            {/* pending-message */}
            {showNotificationData.message ? (
              <div className="Not-calculated-prediction">
                {showNotificationData.message !== ""
                  ? localStorage.getItem("language") == "en"
                    ? showNotificationData.message?.en
                    : showNotificationData.message?.ua
                  : ""}
              </div>
            ) : (
              ""
            )}
            {/* like/dislike prediction  */}
            {showNotificationData.notificationKey == 2 ||
              showNotificationData.notificationKey == 3 ||
              showNotificationData.notificationKey == 4 ? (
              <div
                className={
                  showNotificationData.notificationKey == 4
                    ? "likedPredictionList-user"
                    : "likedPredictionList-user mb-3"
                }
              >
                <div className="user-detailWholikedPrediction">
                  <div className="navbar-avtar notification-avt rounded-circle d-flex align-items-center justify-content-center">
                    {showNotificationData.secondUserId?.profileImgUrl !== "" ? (
                      <img
                        src={showNotificationData.secondUserId?.profileImgUrl}
                        className="user-image"
                        width="44px"
                        height="44px"
                        alt="document"
                        style={{ borderRadius: "50px" }}
                      />
                    ) : (
                      <p className="navbar-letter">
                        {showNotificationData?.secondUserId?.fullName?.charAt(
                          0
                        ) ||
                          showNotificationData?.secondUserId?.userName?.charAt(
                            0
                          )}
                      </p>
                    )}
                  </div>
                  <span
                    className="user-name"
                    onClick={() =>
                      handleUserProfile(showNotificationData?.secondUserId)
                    }
                  >
                    {showNotificationData?.secondUserId.fullName}
                  </span>
                  {/* like msg  */}
                  {showNotificationData.notificationKey == 2 ? (
                    <span className="description">
                      {t("notificationMOdel.likeDecriptMsg")}
                    </span>
                  ) : (
                    ""
                  )}

                  {/* dislike msg */}
                  {showNotificationData.notificationKey == 3 ? (
                    <span className="description">
                      {t("notificationMOdel.dislikeDecriptMsg")}
                    </span>
                  ) : (
                    ""
                  )}

                  {/* following -user */}
                  {showNotificationData.notificationKey == 4 ? (
                    <span className="description">
                      {t("notificationMOdel.followingMsg")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                {/* like icon */}
                {showNotificationData.notificationKey == 2 ? (
                  <img src="/like.svg" />
                ) : (
                  ""
                )}

                {/* dislike icon */}
                {showNotificationData.notificationKey == 3 ? (
                  <img src="/comment.svg" />
                ) : (
                  ""
                )}

                {/* following-icon */}
                {showNotificationData.notificationKey == 4 ? (
                  <img
                    src="/notificationAdd User.svg"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => handleFollow(showNotificationData.userId)}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}

            {/* prediction-data */}
            {showNotificationData.notificationKey == 4 ? (
              ""
            ) : (
              <div>
                {/* <div
                                                className="placeOrder-total-odds"
                                                style={{
                                                    backgroundColor: showNotificationData.predictionId?.BetHeadDetail
                                                        ? showNotificationData.predictionId?.BetHeadDetail?.statusText === "Won"
                                                            ? "#0D393A"
                                                            : showNotificationData.predictionId?.BetHeadDetail?.statusText === "Lost"
                                                                ? "#D14343"
                                                                : showNotificationData.predictionId?.BetHeadDetail?.statusText === "Returned"
                                                                    ? "#EBCC4F"
                                                                    : "#F2F3F7"
                                                        : "",
                                                    color: showNotificationData.predictionId?.BetHeadDetail?.statusText
                                                        ? showNotificationData.predictionId?.BetHeadDetail?.statusText === "Pending"
                                                            ? "#131520"
                                                            : "#ffffff"
                                                        : "#131520",
                                                }}
                                            >
                                                {getOddsMultiply(showNotificationData.predictionId?.odds)}

                                            </div> */}

                {/* odds list */}
                <div className="prediction-all-oddsData-contains prediction-list-model-scroll">
                  <div className="model-prdiction-card">
                    {showNotificationData.predictionId ? (
                      showNotificationData.predictionId?.odds.map(
                        (item, index) => (
                          <>
                            <div
                              key={index}
                              className="model-prediction-team-data"
                            >
                              <div className="both-team-league-time-sport d-flex pb-3">
                                <span className="d-flex align-items-center">
                                  <img
                                    src={
                                      "/sport-logo/" + item.sport_id + ".svg"
                                    }
                                    width="16px"
                                    height="16px"
                                  />
                                </span>
                                {apiService.getProfileFromStorage()?.Language ==
                                  "en"
                                  ? item.sport_name_en
                                    ? item.sport_name_en
                                    : item.sport_name
                                  : item.sport_name}{" "}
                                .{" "}
                                <span className="earning-coin-tournament_name">
                                  {apiService.getProfileFromStorage()
                                    ?.Language == "en"
                                    ? item.tournament_name_en
                                      ? item.tournament_name_en
                                      : item.tournament_name
                                    : item.tournament_name}{" "}
                                </span>
                                |
                                {/* {moment.unix(item.game_start).format("MMMM D")}, */}{" "}
                                <span className="ps-2 d-flex">
                                  {handlePredictionLanguageLocalization(
                                    item.game_start
                                  )}
                                  ,
                                  {moment
                                    .unix(item.game_start)
                                    .format("HH: mm")}
                                </span>
                              </div>
                              <div className="d-flex align-items-center justify-content-between ">
                                <div className="d-flex align-items-center oc_teams_details">
                                  <div className=" both-team-logo-odds-data d-flex justify-content-between align-items-center">
                                    <div className="both-Team-logo">
                                      <span className="team1-logo">
                                        <img
                                          src={
                                            process.env.REACT_APP_teamLogo +
                                            item?.opp_1_icon
                                          }
                                          height="33.42px"
                                          width="33.42px"
                                          style={{ borderRadius: "50px" }}
                                        />
                                      </span>
                                      <span className="team2-logo">
                                        <img
                                          className="team-2-logo"
                                          src={
                                            process.env.REACT_APP_teamLogo +
                                            item?.opp_2_icon
                                          }
                                          height="33.42px"
                                          width="33.42px"
                                          style={{ borderRadius: "50px" }}
                                        />
                                      </span>
                                    </div>
                                    <div className="mobile-screen-odds-data d-flex align-items-center">
                                      <div className="single-prediction-result">
                                        {item?.result.score
                                          ? item?.result.score?.split("(")[0]
                                          : "-:-"}
                                      </div>
                                      <div
                                        className="single-prediction-result ms-3"
                                        style={{
                                          backgroundColor: item.result
                                            ? item.result?.statusText === "Won"
                                              ? "#EEFBFB"
                                              : item.result?.statusText ===
                                                "Lost"
                                                ? "#FCE8DE"
                                                : item.result?.statusText ===
                                                  "Returned"
                                                  ? "#FCF7E4"
                                                  : "#F2F3F7"
                                            : "",
                                          color: item.result
                                            ? item.result?.statusText === "Won"
                                              ? "#208B8E"
                                              : item.result?.statusText ===
                                                "Lost"
                                                ? "#D14343"
                                                : item.result?.statusText ===
                                                  "Returned"
                                                  ? "#C09E16"
                                                  : "#131520"
                                            : "",
                                        }}
                                      >
                                        {item.oc_rate?.toFixed(2)}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="both-team-contains">
                                    <div className="both-team-name d-flex align-items-center">
                                      <div>
                                        <span>
                                          {apiService.getProfileFromStorage()
                                            ?.Language == "en"
                                            ? item?.opp_1_name_en
                                              ? item?.opp_1_name_en
                                              : item?.opp_1_name
                                            : item?.opp_1_name}
                                        </span>
                                        <span>-</span>
                                        <span>
                                          {apiService.getProfileFromStorage()
                                            ?.Language == "en"
                                            ? item?.opp_2_name_en
                                              ? item?.opp_2_name_en
                                              : item?.opp_2_name
                                            : item?.opp_2_name}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="both-team-league-name d-flex">
                                      {item.oc_group_name === "1x2"
                                        ? "Full-time result"
                                        : item.oc_group_name}
                                      <span className="px-1"> - </span>
                                      <span className="both-team-name">
                                        {item.oc_name}
                                      </span>
                                    </div>
                                    <div></div>
                                  </div>
                                </div>
                                <div className="large-screen-odds-data d-flex align-items-center">
                                  <div className="single-prediction-result">
                                    {item?.result.score
                                      ? item?.result.score?.split("(")[0]
                                      : "-:-"}
                                  </div>
                                  <div
                                    className="single-prediction-result ms-3"
                                    style={{
                                      backgroundColor: item.result
                                        ? item.result?.statusText === "Won"
                                          ? "#EEFBFB"
                                          : item.result?.statusText === "Lost"
                                            ? "#FCE8DE"
                                            : item.result?.statusText ===
                                              "Returned"
                                              ? "#FCF7E4"
                                              : "#F2F3F7"
                                        : "",
                                      color: item.result
                                        ? item.result?.statusText === "Won"
                                          ? "#208B8E"
                                          : item.result?.statusText === "Lost"
                                            ? "#D14343"
                                            : item.result?.statusText ===
                                              "Returned"
                                              ? "#C09E16"
                                              : "#131520"
                                        : "",
                                    }}
                                  >
                                    {item.oc_rate?.toFixed(2)}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {showNotificationData.predictionId?.odds?.length ==
                              1 ? (
                              <div className="single-user-data">
                                <div className="row prediction-slip-odds-stakedCoin-possibleWin-type-status-div d-flex px-3">
                                  {/* odds */}
                                  <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-odd-div">
                                    <div className="odds-title">
                                      {t("feed.TotalOdds")}
                                    </div>
                                    <div className="data">
                                      {showNotificationData.predictionId?.totalOdds?.toFixed(
                                        2
                                      )}
                                    </div>
                                  </div>
                                  {/* Staked coins */}
                                  <div className="col-sm-4  col-4 col-lg-4 col-md-4 predictionlist-Staked-coins">
                                    <div className="predictionlist-coins-title">
                                      {t("feed.StakedCoins")}
                                    </div>
                                    <div className="data">
                                      {showNotificationData.predictionId
                                        ?.betTotal
                                        ? showNotificationData.predictionId
                                          ?.betTotal
                                        : "-"}
                                    </div>
                                  </div>
                                  {/* possible win */}
                                  <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-possible-win">
                                    <div className="win-title">
                                      {t("feed.PossibleWin")}
                                    </div>
                                    <div className="data">
                                      {!showNotificationData.predictionId
                                        ?.totalOdds ||
                                        !showNotificationData.predictionId
                                          ?.betTotal
                                        ? "-"
                                        : (
                                          showNotificationData.predictionId?.totalOdds?.toFixed(
                                            2
                                          ) *
                                          showNotificationData.predictionId
                                            ?.betTotal
                                        ).toFixed(2)}
                                    </div>
                                  </div>
                                  {/* type */}
                                  <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-type-div">
                                    <div className="type-title">
                                      {t("feed.Type")}
                                    </div>
                                    <div className="data">
                                      {handlePredictionListType(
                                        showNotificationData.predictionId
                                      )}
                                    </div>
                                  </div>
                                  {/* status */}
                                  <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-status-div">
                                    <div className="status-title">
                                      {t("feed.Status")}
                                    </div>
                                    <div
                                      className="data"
                                      style={{
                                        backgroundColor: showNotificationData
                                          .predictionId?.BetHeadDetail
                                          ? showNotificationData.predictionId
                                            ?.BetHeadDetail?.statusText ===
                                            "Won"
                                            ? "#EEFBFB"
                                            : showNotificationData.predictionId
                                              ?.BetHeadDetail?.statusText ===
                                              "Lost"
                                              ? "#F9DADA"
                                              : showNotificationData.predictionId
                                                ?.BetHeadDetail?.statusText ===
                                                "Returned"
                                                ? "#FCF7E4"
                                                : "#F2F3F7"
                                          : "",
                                        color: showNotificationData.predictionId
                                          ?.BetHeadDetail?.statusText
                                          ? showNotificationData.predictionId
                                            ?.BetHeadDetail?.statusText ===
                                            "Won"
                                            ? "#0D393A"
                                            : showNotificationData.predictionId
                                              ?.BetHeadDetail?.statusText ===
                                              "Lost"
                                              ? "#D14343"
                                              : showNotificationData.predictionId
                                                ?.BetHeadDetail?.statusText ===
                                                "Returned"
                                                ? "#C09E16"
                                                : "#131520"
                                          : "#131520",
                                      }}
                                    >
                                      {showNotificationData.predictionId
                                        ?.BetHeadDetail?.statusText
                                        ? showNotificationData.predictionId
                                          ?.BetHeadDetail?.statusText ===
                                          "Won"
                                          ? t("feed.modelWon")
                                          : showNotificationData.predictionId
                                            ?.BetHeadDetail?.statusText ===
                                            "Lost"
                                            ? t("feed.modelLost")
                                            : showNotificationData.predictionId
                                              ?.BetHeadDetail?.statusText ===
                                              "Returned"
                                              ? t("feed.modelReturn")
                                              : t("feed.modelPending")
                                        : t("feed.modelPending")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </>
                        )
                      )
                    ) : (
                      <div className="mt-3 text-center">-</div>
                    )}
                  </div>
                </div>
                {showNotificationData.predictionId?.odds?.length == 1 ? (
                  ""
                ) : (
                  <div className="dddddd">
                    <div className="row prediction-slip-odds-stakedCoin-possibleWin-type-status-div d-flex">
                      {/* odds */}
                      <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-odd-div">
                        <div className="odds-title">{t("feed.TotalOdds")}</div>
                        <div className="data d-flex align-items-center">
                          {showNotificationData.predictionId
                            ? handleOddsReturnData(
                              showNotificationData.predictionId
                            )
                            : "-"}
                          {showNotificationData?.isReturnOdds ? (
                            <div className="ps-1 alert-circle-tooltip-div">
                              <div className="alert-circle-image-tooltip">
                                {t("feed.tooltipMsg")}
                              </div>
                              <span className="alert-circle-image d-flex align-items-center">
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M11.709 10.795L11.568 10.874C10.92 11.236 10.691 11.422 10.696 11.74C10.701 12.154 10.37 12.494 9.956 12.5H9.946C9.536 12.5 9.201 12.171 9.196 11.76C9.179 10.492 10.216 9.912 10.835 9.566L10.976 9.486C11.545 9.173 11.829 8.739 11.829 8.193C11.829 7.29 11.094 6.556 10.191 6.556C9.272 6.556 8.552 7.275 8.552 8.193C8.552 8.607 8.216 8.943 7.802 8.943C7.388 8.943 7.052 8.607 7.052 8.193C7.052 6.463 8.46 5.056 10.191 5.056C11.921 5.056 13.329 6.463 13.329 8.193C13.329 9.296 12.753 10.22 11.709 10.795ZM9.947 14.96C9.533 14.96 9.197 14.639 9.197 14.225V14.195C9.197 13.781 9.533 13.445 9.947 13.445C10.361 13.445 10.697 13.781 10.697 14.195C10.697 14.609 10.361 14.96 9.947 14.96ZM10 0.5C4.761 0.5 0.5 4.762 0.5 10C0.5 15.238 4.761 19.5 10 19.5C15.238 19.5 19.5 15.238 19.5 10C19.5 4.762 15.238 0.5 10 0.5Z"
                                    fill="#BFC3D9"
                                  />
                                </svg>
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* Staked coins */}
                      <div className="col-sm-4  col-4 col-lg-4 col-md-4 predictionlist-Staked-coins">
                        <div className="predictionlist-coins-title">
                          {t("feed.StakedCoins")}
                        </div>
                        <div className="data">
                          {showNotificationData.predictionId?.betTotal
                            ? showNotificationData.predictionId?.betTotal
                            : "-"}
                        </div>
                      </div>
                      {/* possible win */}
                      <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-possible-win">
                        <div className="win-title">{t("feed.PossibleWin")}</div>
                        <div className="data">
                          {!showNotificationData.predictionId?.totalOdds ||
                            !showNotificationData.predictionId?.betTotal
                            ? "-"
                            : (
                              showNotificationData.predictionId?.totalOdds?.toFixed(
                                2
                              ) * showNotificationData.predictionId?.betTotal
                            ).toFixed(2)}
                        </div>
                      </div>
                      {/* type */}
                      <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-type-div">
                        <div className="type-title">{t("feed.Type")}</div>
                        <div className="data">
                          {handlePredictionListType(
                            showNotificationData.predictionId
                          )}
                        </div>
                      </div>
                      {/* status */}
                      <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-status-div">
                        <div className="status-title">{t("feed.Status")}</div>
                        <div
                          className="data"
                          style={{
                            backgroundColor: showNotificationData.predictionId
                              ?.BetHeadDetail
                              ? showNotificationData.predictionId?.BetHeadDetail
                                ?.statusText === "Won"
                                ? "#EEFBFB"
                                : showNotificationData.predictionId
                                  ?.BetHeadDetail?.statusText === "Lost"
                                  ? "#F9DADA"
                                  : showNotificationData.predictionId
                                    ?.BetHeadDetail?.statusText === "Returned"
                                    ? "#FCF7E4"
                                    : "#F2F3F7"
                              : "",
                            color: showNotificationData.predictionId
                              ?.BetHeadDetail?.statusText
                              ? showNotificationData.predictionId?.BetHeadDetail
                                ?.statusText === "Won"
                                ? "#0D393A"
                                : showNotificationData.predictionId
                                  ?.BetHeadDetail?.statusText === "Lost"
                                  ? "#D14343"
                                  : showNotificationData.predictionId
                                    ?.BetHeadDetail?.statusText === "Returned"
                                    ? "#C09E16"
                                    : "#131520"
                              : "#131520",
                          }}
                        >
                          {showNotificationData.predictionId?.BetHeadDetail
                            ?.statusText
                            ? showNotificationData.predictionId?.BetHeadDetail
                              ?.statusText === "Won"
                              ? t("feed.modelWon")
                              : showNotificationData.predictionId?.BetHeadDetail
                                ?.statusText === "Lost"
                                ? t("feed.modelLost")
                                : showNotificationData.predictionId?.BetHeadDetail
                                  ?.statusText === "Returned"
                                  ? t("feed.modelReturn")
                                  : t("feed.modelPending")
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default NotificationModal;
