import React, { useState, useEffect } from "react";

const Timer = ({ endTime, setIsRewardOver }) => {
  const [remainingTime, setRemainingTime] = useState(endTime - Date.now());

  useEffect(() => {
    const timerID = setInterval(() => {
      const diff = endTime - Date.now();
      if(diff <=0){
        setIsRewardOver(true)
      }
      setRemainingTime(diff);
    }, 1000);

    return () => clearInterval(timerID);
  }, [endTime]);

  const millisecondsInDay = 1000 * 60 * 60 * 24;
  const millisecondsInHour = 1000 * 60 * 60;
  const millisecondsInMinute = 1000 * 60;

  const days = Math.floor(remainingTime / millisecondsInDay);
  const hours = ("0" + Math.floor((remainingTime % millisecondsInDay) / millisecondsInHour)).slice(-2);
  const minutes = ("0" + Math.floor((remainingTime % millisecondsInHour) / millisecondsInMinute)).slice(-2);
  const seconds = ("0" + Math.floor((remainingTime % millisecondsInMinute) / 1000)).slice(-2);

  return (
    <>
      {localStorage.getItem("language") === "ua" ? (
        <p>
          {days}д : {hours}г : {minutes}хв : {seconds}с
        </p>
      ) : (
        <p>
          {days}d : {hours}h : {minutes}m : {seconds}s
        </p>
      )}
    </>
  );
};

export default Timer;
