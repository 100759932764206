import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import NavigationBar from "../Navbar";
import HomePageHeader from "../../components/molecule/homepage/HomePageHeader/HomePageHeader";
import "../../css/profile.css";
import apiService from "../../services/apiService";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { dynamicActivate } from "../../i18n";
import ShareModel from "../molecule/shareModel/ShareModel";
import { Helmet } from "react-helmet";
import { Environment } from "../../environment";
import PredictionList from "../molecule/prediction-list/PredictionList";
import ChartSummary from "../molecule/Chart/ChartSummary";
import PredictionListCustomLoader from "../Custom loader/PredictionListCustomLoader";
import MobilePredictionListLoader from "../Custom loader/MobilePredictionListLoader";
import MyLoader from "../Custom loader/CustomLoaderuserProfile";
import Model from "../molecule/model/Model";
import { eventBus } from "../../services/events";
import { CONSTANT } from "../../common/constant";
import { prepareFullName } from "../../helpers/formater.js";
import HomePageFooter from "../molecule/homepage/HomePageFooter/HomePageFooter";
import FollowingModal from "../General/FollowingModal.js";
import { useMediaQuery } from "react-responsive";
import UserRewardsSection from "../Rewards/UserRewardsSection.js";
import PredictionsListLoader from "../CustomSkeletonLoaders/PredictionsListLoader.js";
// dynamicActivate(localStorage.getItem("language"));

const Profile = () => {
  const pageSize = 10;
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const [currPage, setCurrPage] = useState(0);
  const [pageState, setPageState] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  let { getUsername } = useParams();
  const { t, i18n } = useTranslation();

  const [activeTab, setActiveTab] = useState("predictions");

  const [otherUserData, setOtherUserData] = useState({});
  const [follow, setFollow] = useState([]);
  const [userFollow, setUserFollow] = useState("");
  const [following, setFollowing] = useState([]);
  const [showFollowerModel, setShowFollowerModel] = useState(false);
  const [showFollowingModel, setShowFollowingModel] = useState(false);
  const [doRef, setDoRef] = useState(false);
  const loginUserId = localStorage.getItem("userId");
  const [showShareProfileModel, setShowShareProfileModel] = useState(false);
  const [otherUserPredictionData, setOtherUserPredictionData] = useState([]);
  const [betsDataCount, setBetsDataCount] = useState(0);
  const [initialIndex, setInitialIndex] = useState(0);
  const [predictionData, setPredictionData] = useState(true);
  const [predictionTitleColor, setPredictionTitleColor] =
    useState("predictions-active");
  const [analyticsTitleColor, setAnalyticsTitleColor] = useState("analytics");
  const [analyticsData, setAnalyticsData] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [showPredictionLoader, setShowPredictionLoader] = useState(true);
  const [countData, setCountData] = useState(0);
  const [predictionModel, setPredictionModle] = useState(false);
  const [errorClass, setErrorClass] = useState("mb-3");
  const [error, setError] = useState("form-error-line-hide");
  const [loginEmail, setLoginEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const handleEmail = async (e) => {
    e.preventDefault();
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.getUserName({ email: loginEmail });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res.data) {
      navigate("/login", {
        state: {
          email: loginEmail,
          userName: userName,
          userEmail: userEmail,
          name: res.data.userName,
        },
      });
    } else {
      setErrorClass("mb-3 form-error");
      setError("form-error-line-show");
    }
  };

  const isValidEmail = (email) => {
    const regexEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regexEmail.test(email);
  };

  const handleLoginEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      // username
      setUserName(e.target.value);
    } else {
      // email
      setUserEmail(e.target.value);
    }
    setLoginEmail(e.target.value);
    if (e.target.value == "") {
      setErrorClass("mb-3");
      setError("form-error-line-hide");
    }
  };

  const handlePredictionHide = () => {
    setPredictionModle(false);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const getLoginUserName = url.pathname?.split("/")[2];
    if (getLoginUserName === apiService.getProfileFromStorage()?.userName) {
      navigate("/userprofile");
    }
  }, []);

  // ----- follower Model funtion ----

  const handlefollowerShow = () => {
    setShowFollowerModel(true);
  };
  const increamentUserCount = () => {
    setCountData(countData + 1);
  };
  const handlefollowerHide = () => {
    setShowFollowerModel(false);
  };

  // ------ following model function -----
  const handlefollowingShow = () => {
    setShowFollowingModel(true);
  };
  const handlefollowingHide = () => {
    setShowFollowingModel(false);
  };
  // -------- Share profile function ------

  const handleShareProfileShow = () => {
    setShowShareProfileModel(true);
  };
  const handleShareProfileHide = () => {
    setShowShareProfileModel(false);
  };
  const handleUnFollow = async (id) => {
    const res = await apiService.unfollowUser(id);
    const response = await apiService.getOtheruserProfile(getUsername);
    setOtherUserData(response);
    setDoRef(true);
  };
  const handlefollow = async (id, pageNumber = currPage || 0) => {
    if (localStorage.getItem("token")) {
      const res = await apiService.followUser(id);
      const response = await apiService.getOtheruserProfile(getUsername);
      setOtherUserData(response);
      if (res) {
        setDoRef(true);
      }
    } else {
      setPredictionModle(true);
    }
  };

  let id = "";

  const handleUserProfile = async (item, pageNumber = currPage || 0) => {
    handlefollowingHide();
    handlefollowerHide();
    const res = await apiService.getPredictionOrderByUserId(
      item._id,
      pageSize,
      pageNumber
    );
    // setShowFollowingModel(false);
    if (item._id.toString() == loginUserId) {
      navigate(`/userprofile`);
    } else {
      navigate(`/profile/${item._id}`, {
        state: res,
        length: res.length,
      });
    }
    setDoRef(true);
  };
  const [userName, setUserName] = useState("");
  const [fistName, setFirstName] = useState("");
  const [publicUserId, setPublicUserId] = useState("");

  const otherUserProfile = async () => {
    setDoRef(false);
    setUserFollow("");
    // const response = await apiService.getUserProfile();

    let response;
    if (localStorage.getItem("profile")) {
      response = JSON.parse(localStorage.getItem("profile"));
    } else {
      response = await apiService.getUserProfile();
    }
    console.log(getUsername);
    const res = await apiService.getOtheruserProfile(getUsername);
    id = res?._id;
    if (res) {
      setShowLoader(false);
    }
    setPublicUserId(id);
    getPrediction(id);
    setOtherUserData(res);
    setUserName(res?.userName);
    setFirstName(res?.firstName);

    const newFollower = [];
    res?.followers?.map((fol) => {
      fol.isFollowed = false;
      response?.following?.map((fl) => {
        if (fol._id.toString() === fl._id) {
          fol.isFollowed = true;
        }
      });
      newFollower.push(fol);
    });
    setFollow(newFollower);
    if (response?.following) {
      for (let matchdata of response?.following) {
        if (matchdata._id == id) {
          let userFollowers = "following";
          setUserFollow(() => userFollowers);
          break;
        }
      }
    }
    const newFollowing = [];
    res?.following.map((fol) => {
      fol.isFollowed = false;
      response?.following?.map((fl) => {
        if (fol._id.toString() === fl._id.toString()) {
          fol.isFollowed = true;
        }
      });
      newFollowing.push(fol);
    });
    setFollowing(newFollowing);
  };

  const getPrediction = async (userId, pageNumber = currPage || 0) => {
    if (localStorage.getItem("token")) {
      const res = await apiService.getPredictionOrderByUserId(
        userId,
        pageSize,
        pageNumber
      );

      if (pageNumber == 0) {
        setOtherUserPredictionData(res.getPredictionOrderByUserId);
      } else {
        setOtherUserPredictionData((oldState) => [
          ...oldState,
          ...res.getPredictionOrderByUserId,
        ]);
      }
      setPageState(Math.ceil(res.totalPredictionOrder / pageSize));
      setBetsDataCount(res?.totalPredictionOrder);
      if (res) {
        setShowPredictionLoader(false);
      }
      if (pageState == currPage + 1) {
        setShowMessage(t("feed.showMessage"));
      }
      if (res) {
        setShowPredictionLoader(false);
      }
    } else {
      const res = await apiService.getPredictionOrderByUserId(
        userId,
        pageSize,
        pageNumber
      );
      setOtherUserPredictionData(res.getPredictionOrderByUserId?.slice(0, 5));
      setBetsDataCount(res.totalPredictionOrder);
      if (res) {
        setShowPredictionLoader(false);
      }
    }
  };
  const ShowMorePredictionsData = async () => {
    setCurrPage(currPage + 1);
  };
  // const ShowMorePredictionsData = async () => {
  //   let diff = betsDataCount - (initialIndex + 10)
  //   const res = await apiService.getPredictionOrderByUserId(publicUserId);
  //   if (initialIndex < betsDataCount) {
  //     setOtherUserPredictionData((oldState) => [...oldState, ...res?.slice(initialIndex, diff < 10 ? initialIndex + diff : initialIndex + 10)]);
  //   }
  //   if (betsDataCount - (initialIndex + 10) > 10) {
  //     setInitialIndex(initialIndex + 10)
  //   } else {
  //     setInitialIndex(initialIndex + diff)
  //   }
  //   if (diff < 10) {
  //     setShowMessage(t("feed.showMessage"))
  //   }
  // };
  // const ShowMorePredictionsData = async () => {
  //   const res = await apiService.getPredictionOrderByUserId(publicUserId);
  //   setOtherUserPredictionData(res);
  //   setShowMessage(t("feed.showMessage"));
  // };

  const handlePredictionTabData = () => {
    setPredictionData(true);
    setPredictionTitleColor("predictions-active");
    setAnalyticsTitleColor("analytics");
    if (analyticsData) {
      setAnalyticsData(false);
    }
  };
  const handleAnalyticsData = () => {
    setAnalyticsTitleColor("analytics-active");
    setPredictionTitleColor("predictions");
    setAnalyticsData(true);
    if (predictionData) {
      setPredictionData(false);
    }
  };
  useEffect(() => {
    document.body.classList.add("userprofile-containt");
    otherUserProfile();
  }, [doRef, countData, currPage]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {prepareFullName(otherUserData) +
            " (@" +
            otherUserData?.userName +
            ")" +
            " | Predictions: " +
            betsDataCount || 0 + "| Capperauditor"}
        </title>
        <description>{otherUserData?.bio || ""}</description>
        <meta
          property="og:url"
          content={
            process.env.REACT_APP_shareLinkUrl +
            "profile/" +
            otherUserData?.userName
          }
        />
        <meta property="og:description" content={otherUserData?.bio || ""} />
        <meta
          property="og:title"
          content={
            prepareFullName(otherUserData) +
              " (@" +
              otherUserData?.userName +
              ")" +
              " | Predictions: " +
              betsDataCount || 0 + "| Capperauditor"
          }
        />
        <meta
          property="og:image"
          content={
            otherUserData?.profileImgUrl ||
            process.env.REACT_APP_shareLinkUrl + "navbar-logo.svg"
          }
        />
      </Helmet>
      {localStorage.getItem("token") ? <NavigationBar /> : <HomePageHeader />}
      <div className="profile">
        <section
          className={`profile-section ${
            localStorage.getItem("token") ? "" : "notLogedin-user"
          }`}
        >
          <div className="profile-container container-1240">
            {showLoader ? (
              <MyLoader />
            ) : (
              <div className="">
                <div className="profile-top-part mt-0">
                  <div
                    className="profile-avtar-circle rounded-circle rounded-profile-circle d-flex align-items-center justify-content-center"
                    alt="Avatar"
                  >
                    <div>
                      {otherUserData?.profileImgUrl !== "" ? (
                        <img
                          src={otherUserData?.profileImgUrl}
                          value={otherUserData?.profileImgUrl}
                          className="uploaded-img"
                          width="110px"
                          height="110px"
                          style={{ display: "block" }}
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                      {otherUserData?.profileImgUrl == "" ? (
                        <p className="useName-profile-letter d-flex align-items-center justify-content-center">
                          {fistName?.charAt(0)}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <p className="useName-profile-letter d-flex align-items-center justify-content-center">
                    {userName.charAt(0)}
                  </p> */}
                  </div>
                  <div className="userprofile-details d-flex justify-content-between">
                    <div className="userProfile-share ">
                      <div className="user-fullname">
                        {prepareFullName(otherUserData)}
                      </div>
                      <div className="user-username">
                        <p className="pb-3">@{otherUserData?.userName}</p>
                      </div>
                      {userFollow == "following" ? (
                        <div>
                          <button
                            className="btn-following-profile"
                            lang={localStorage.getItem("language")}
                            onClick={() => handleUnFollow(otherUserData?._id)}
                          >
                            <span>{t("profile.following")}</span>
                          </button>
                        </div>
                      ) : (
                        <div>
                          <button
                            className="btn-follow"
                            onClick={() => handlefollow(otherUserData?._id)}
                          >
                            {t("profile.follow")}
                          </button>
                        </div>
                      )}
                    </div>
                    <div
                      className="share-button"
                      onClick={handleShareProfileShow}
                    >
                      <svg
                        width="24"
                        height="21"
                        viewBox="0 0 24 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M16.6667 14.6301C16.2985 14.6301 16 14.9286 16 15.2968V18.5926C16 19.3676 15.4042 19.9635 14.6667 19.9635H2.66667C1.93042 19.9635 1.33333 19.3664 1.33333 18.6301V6.63014C1.33333 5.89389 1.93042 5.29681 2.66667 5.29681H7.33333C7.7 5.29681 8 4.99681 8 4.63014C8 4.26347 7.7 3.96347 7.33333 3.96347H2.66667C1.19375 3.96347 0 5.15931 0 6.63014V18.5926C0 20.0651 1.19375 21.2593 2.66667 21.2593L14.6667 21.2968C16.1396 21.2968 17.3333 20.1026 17.3333 18.6301V15.2968C17.3333 14.9301 17.0333 14.6301 16.6667 14.6301ZM23.7625 5.45097L17.0958 0.155141C16.8146 -0.0805258 16.3942 -0.0440674 16.1571 0.239766C15.9214 0.522308 15.9592 0.943099 16.2417 1.17852L21.4917 5.29681H15.3333C11.2542 5.29681 8 8.55097 8 12.6301V13.9635C8 14.3301 8.3 14.6301 8.66667 14.6301C9.03333 14.6301 9.33333 14.3301 9.33333 13.9635V12.6301C9.33333 9.32181 12.025 6.63014 15.3333 6.63014H21.4917L16.2375 10.7851C15.955 11.0208 15.9172 11.4414 16.1529 11.7239C16.2875 11.8801 16.475 11.9635 16.6667 11.9635C16.8177 11.9635 16.9688 11.9127 17.0938 11.8085L23.7604 6.47518C23.9125 6.35097 24 6.15931 24 5.92597C24 5.69264 23.9125 5.58014 23.7625 5.45097Z"
                          fill="#7F87B3"
                          className="share-path"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="row profile-center-part">
                  <div
                    className="col followers-detail"
                    onClick={handlefollowerShow}
                  >
                    <div className="followers-count">
                      {otherUserData?.followers?.length}
                    </div>
                    <div className="followers">{t("profile.followers")}</div>
                  </div>
                  <div
                    className="col followers-detail "
                    onClick={handlefollowingShow}
                  >
                    <div className="followers-count">
                      {otherUserData?.following?.length}
                    </div>
                    <div className="followers">{t("profile.following")}</div>
                  </div>
                  <div className="col followers-detail">
                    <div className="followers-count">{betsDataCount}</div>
                    <div className="followers">{t("profile.predictions")}</div>
                  </div>
                </div>
                <div className="profile-end-part">
                  <Link to="#" className="show-bio-data">
                    {otherUserData?.bio}
                  </Link>
                </div>
              </div>
            )}
          </div>
        </section>
        <section className="profile-bottom-section container-1240">
          <div className="profile-content ">
            <div className="left-panel">
              <div className="user-profile-tabs">
                {/* prediction-tab */}
                <div
                  className={
                    activeTab === "predictions"
                      ? "user-profile-tab active"
                      : "user-profile-tab"
                  }
                  onClick={() => setActiveTab("predictions")}
                >
                  <div className="tab-symbol">
                    <img src="/profileImg/list.svg" alt="" />
                  </div>
                  <div>{t("profile.predictions")}</div>
                </div>
                <div
                  className={
                    activeTab === "analytics"
                      ? "user-profile-tab active"
                      : "user-profile-tab"
                  }
                  onClick={() => setActiveTab("analytics")}
                >
                  <div className="tab-symbol">
                    <img src="/profileImg/stat.svg" alt="" />
                  </div>
                  <div>{t("profile.analytics")}</div>
                </div>
                {isTablet && (
                  <div
                    className={
                      activeTab === "reward"
                        ? "user-profile-tab active"
                        : "user-profile-tab"
                    }
                    onClick={() => setActiveTab("reward")}
                  >
                    <div className="tab-symbol">
                      <img src="/profileImg/Achievements.svg" alt="" />
                    </div>
                    <div>{t("rewards.reward")}</div>
                  </div>
                )}
              </div>
            </div>
            {/* prediction-tab Data */}
            {showPredictionLoader && isMobile ? (
              <div className="">
                <div className="mobile-predictionlistLoader">
                  <MobilePredictionListLoader />
                </div>
              </div>
            ) : (
              <div
                className="main-content-section"
                style={{
                  display: activeTab === "predictions" ? "flex" : "none",
                }}
              >
                <div className="prediction-div">
                  {showPredictionLoader && <PredictionsListLoader />}
                  <div
                    className="pb-3"
                    style={{
                      display: betsDataCount > 0 ? "block" : "none",
                    }}
                  >
                    <PredictionList
                      betsData={otherUserPredictionData}
                      countData={countData}
                      currPage={currPage}
                      pageSize={pageSize}
                      increamentUserCount={increamentUserCount}
                      onClick={ShowMorePredictionsData}
                      showMessage={showMessage}
                      totalBetsData={betsDataCount}
                    />
                  </div>
                  <div
                    className="add-predictions"
                    style={{
                      display: betsDataCount > 0 ? "none" : "block",
                      marginBottom: "100px",
                    }}
                  >
                    <div className="d-flex justify-content-center">
                      <div
                        className="predictions-avtar-circle rounded-circle rounded-profile-circle d-flex align-items-center justify-content-center"
                        alt="Avatar"
                      >
                        <img
                          src="/document.svg"
                          alt="document"
                          width="33.33px"
                          height="33.33px"
                        />
                      </div>
                    </div>
                    <p className="p-published text-center">
                      {t("profile.noPredictionYetProfile")}
                    </p>
                    <div className="d-flex justify-content-center add-prediction-btn-div">
                      <button
                        className="btn-add-prediction"
                        // onClick={handlePredictionShow}
                      >
                        {t("profile.addPredictions")}
                      </button>
                    </div>
                  </div>
                </div>

                {!isTablet && <UserRewardsSection userId={getUsername} />}
              </div>
            )}
            {showPredictionLoader ? (
              <div className="">
                <div className="mobile-predictionlistLoader mt-3">
                  <MobilePredictionListLoader />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {/* analytics-tab-data  */}
          <div
            style={{ display: activeTab === "analytics" ? "block" : "none" }}
          >
            <div className="analyticsData-type">
              <ChartSummary id={publicUserId} />
            </div>
          </div>

          {isTablet && (
            <div style={{ display: activeTab === "reward" ? "block" : "none" }}>
              <UserRewardsSection userId={getUsername} />
            </div>
          )}
        </section>

        {/* follower model */}

        {/* follower model */}
        <FollowingModal
          loginUserId={loginUserId}
          items={follow}
          showModal={showFollowerModel}
          handleHide={handlefollowerHide}
          handleUnFollow={handleUnFollow}
          handleUserProfile={handleUserProfile}
          handleFollow={handlefollow}
          isFollower={true}
        />

        {/* following model */}
        <FollowingModal
          loginUserId={loginUserId}
          items={following}
          showModal={showFollowingModel}
          handleHide={handlefollowingHide}
          handleUnFollow={handleUnFollow}
          handleUserProfile={handleUserProfile}
        />
        {/* share profile model */}
        <Modal
          show={showShareProfileModel}
          onHide={handleShareProfileHide}
          className="shareprofile-model"
        >
          <Modal.Header>
            <Modal.Title className="model-title">
              {t("profile.shareProfile")}
            </Modal.Title>
            <div className="model-close-btn" onClick={handleShareProfileHide}>
              <span>
                {" "}
                <img
                  src="/close-squere-btn.svg"
                  alt="close"
                  className="close-button"
                />
              </span>
            </div>
          </Modal.Header>
          <ShareModel isUserName={otherUserData._id} />
        </Modal>

        {/* login model  */}
        <Model
          isModel={predictionModel}
          modelClassName={"home-page-signupPage"}
          hideModel={handlePredictionHide}
          modelTitle={t("homePageSigninPage.SignIn")}
        >
          <div className="userLoginModel">
            {/* --- signinCapperauditor top --- */}
            <div className="top">
              <div className="btn-containt">
                <button
                  type="button"
                  className="btn  btn-google-facebook btn-google"
                  onClick={() => {
                    window.location.href = process.env.REACT_APP_googleOAuthUrl;
                  }}
                >
                  <span className="signup-icon">
                    <i className="fab fa-google" />
                  </span>
                  {t("homePageSigninPage.isGoogleBtn")}
                </button>
                <button
                  type="button"
                  className="btn btn-google-facebook btn-facebook"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_facebookOAuthUrl;
                  }}
                >
                  <span className="signup-icon">
                    <i
                      className="fab fa-facebook-f"
                      style={{ color: "#4267B2" }}
                    ></i>
                  </span>
                  {t("homePageSigninPage.isFabookBtn")}
                </button>
              </div>
              <div className="text-divider">{t("homePageSigninPage.or")}</div>
            </div>
            {/* ------ signincapperauditor form ------  */}
            <div className="bottom">
              <form>
                <div className={errorClass}>
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label form-input-label"
                  >
                    {t("homePageSigninPage.emailorusername")}
                  </label>
                  <input
                    type="email"
                    className="form-control form-input-field"
                    id="InputEmail"
                    onChange={handleLoginEmail}
                    placeholder={t("homePageSigninPage.EnteremailPlaceHolder")}
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      color: "#7F87B3",
                    }}
                  />
                  <p style={{ fontSize: "14px" }} className={error}>
                    {t("SignIn.Youremailorusernameisincorrect")}
                  </p>
                </div>
                <div className="mb-4">
                  <button
                    className="form-control form-input-field-button"
                    id="Inputbutton"
                    onClick={handleEmail}
                    disabled={loginEmail == ""}
                    style={{
                      pointerEvents: loginEmail == "" ? "none" : "auto",
                    }}
                  >
                    {t("homePageSigninPage.Continue")}
                  </button>
                </div>
              </form>
              {/* ------- signincapperauditor footer -----  */}
              <div className="signin-footer">
                <span
                  className="signincapperauditor-bottom"
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    lineHeight: "20px",
                  }}
                >
                  {t("homePageSigninPage.subLine1")}{" "}
                  <Link
                    to="/signup"
                    style={{
                      color: "#4C5480",
                      fontWeight: "600",
                      lineHeight: "24px",
                    }}
                  >
                    {t("homePageSigninPage.subLine2")}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </Model>
      </div>
      <HomePageFooter />
    </>
  );
};
export default Profile;
