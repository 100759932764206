import React, { useRef, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import NavigationBar from "../Navbar";
import HomePageHeader from "../molecule/homepage/HomePageHeader/HomePageHeader";
import BetSlip from "./BetSlip";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import CountriesSideBar from "./CountriesSideBar";
import TournamentTab from "./TournamentTab/TournamentTab";
import { useDispatch, useSelector } from "react-redux";
import { getLeagues, getSportEvents } from "../../store/betSlice";
import { totalCoef } from "../../helpers/prediction";

import ToTopBtn from "./ToTopBtn";
import ErrorPopup from "../molecule/ErrorPopup/ErrorPopup";
import { Helmet } from "react-helmet";

import "../../css/sportline.css";

const KEYS = ["live", "line"];

const SportLineNew = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const isSmallDesktop = useMediaQuery({ maxWidth: 1280 });

  const containerRef = useRef(null);

  const { predictions } = useSelector((state) => state.bet);
  const dispatch = useDispatch();

  const [isToTopBtnVisible, setIsToTopBtnVisible] = useState(false);
  const [couponVisible, setCouponVisible] = useState(false);

  const [showBetsSlip, setShowBetsSlip] = useState(
    "responsive-addPrediction-right-panel-hide"
  );
  const [isMobileDropDownOpen, setIsMobileDropDownOpen] = useState(false);
  const [
    showMobilePredictionNotification,
    setShowMobilePredictionNotification,
  ] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const activeSport = searchParams.get("sport");
  const activeSportKey = searchParams.get("key");
  const activeLeague = searchParams.get("league");
  const activeCountry = searchParams.get("country");
  const activeGame = searchParams.get("game");

  useEffect(() => {
    if (!activeSportKey || !KEYS.includes(activeSportKey)) {
      setSearchParams(
        (prev) => {
          prev.set("key", "line");
          prev.delete("sport");
          prev.delete("country");
          prev.delete("league");
          prev.delete("game");
          return prev;
        },
        { replace: true }
      );
    }
  }, []);

  useEffect(() => {
    if (activeSportKey) {
      dispatch(
        getLeagues({
          activeSport,
          activeSportKey,
        })
      );
    }
  }, [dispatch, activeSport, activeSportKey, activeGame]);

  useEffect(() => {
    if (activeSportKey) {
      dispatch(getSportEvents({ activeSport, activeLeague, activeSportKey }));
    }
  }, [dispatch, activeGame, activeSportKey, activeLeague]);

  const handleOpenPredictionBetSlip = () => {
    setShowBetsSlip("responsive-addPrediction-right-panel");
  };

  const handleHideBetSlip = () => {
    setShowBetsSlip("responsive-addPrediction-right-panel-hide");
  };

  const renderMobilePredictionNotification = () => {
    return (
      <div
        className={`notification-part prediction-notifiaction-part align-items-center ${
          couponVisible ? "visible" : ""
        }`}
        onClick={handleOpenPredictionBetSlip}
      >
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="d-flex align-items-center justify-content-between">
            <img src="/white ticket.svg" className="" alt="" />
            <div className="px-2">{t("homePage.Predictions")}</div>
            <span className="button__badge-mobile">
              {JSON.parse(localStorage.getItem("createPrediction"))?.length ||
                0}
            </span>
          </div>
          <div>
            {JSON.parse(localStorage.getItem("createPrediction"))?.length >
            0 ? (
              <div className="ps-3 d-flex">
                <img src="/plain Line.svg" alt="" />
                <span className="totalPredictionCount-div">
                  {totalCoef(predictions).toFixed(2)}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <img
          src="/double right up  white.svg"
          style={{ paddingLeft: "12px" }}
          alt=""
        ></img>
      </div>
    );
  };

  const renderSmallPredictionNotification = () => {
    return (
      <div className="notification-badge-position">
        <div
          className="notification-part"
          onClick={handleOpenPredictionBetSlip}
        >
          <img src="/ticket.svg" className="" alt="" />
          <span className="button__badge">
            {JSON.parse(localStorage.getItem("createPrediction"))?.length || 0}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>{t("sportLine.metaTitle")}</title>
        <meta name="description" content={t("sportLine.metaDescription")} />
        <meta name="og:title" content={t("sportLine.metaSocialTitle")} />
        <meta name="og:description" content={t("sportLine.metaDescription")} />

        <meta name="og:url" content="https://capperauditor.com/sportline" />
        <meta name="twitter:title" content={t("sportLine.metaSocialTitle")} />
        <meta
          name="twitter:description"
          content={t("sportLine.metaDescription")}
        />

        <link
          rel="alternate"
          href="<https://capperauditor.com/sportline>"
          hreflang="uk"
        />
        <link
          rel="alternate"
          href="<https://capperauditor.com/en/sportline>"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="<https://capperauditor.com/sportline>"
          hreflang="x-default"
        />
      </Helmet>
      <ErrorPopup />
      <div className="sport-line-container ">
        {(isMobile &&
          showBetsSlip === "responsive-addPrediction-right-panel") ||
        isMobileDropDownOpen ? null : (
          <>
            {localStorage.getItem("token") &&
            localStorage.getItem("profile") ? (
              <NavigationBar countData={1} />
            ) : (
              <HomePageHeader />
            )}
          </>
        )}
        <div className="sportline-outer-div">
          <div className="container-1240 sport-line-table-betSlip">
            <div className="row justify-content-between">
              {!isTablet ? (
                <>
                  <CountriesSideBar />
                  <TournamentTab
                    handleOpenPredictionBetSlip={handleOpenPredictionBetSlip}
                    setIsToTopBtnVisible={setIsToTopBtnVisible}
                    setCouponVisible={setCouponVisible}
                    setShowMobilePredictionNotification={
                      setShowMobilePredictionNotification
                    }
                  />
                  <div className="BetSlip-col" style={{ zIndex: 1 }}>
                    <BetSlip />
                  </div>
                </>
              ) : (
                <>
                  <TournamentTab
                    handleOpenPredictionBetSlip={handleOpenPredictionBetSlip}
                    setIsToTopBtnVisible={setIsToTopBtnVisible}
                    setCouponVisible={setCouponVisible}
                    setShowMobilePredictionNotification={
                      setShowMobilePredictionNotification
                    }
                  />
                </>
              )}
              {isMobile && !showMobilePredictionNotification ? null : (
                <>{renderMobilePredictionNotification()}</>
              )}
              <div className={showBetsSlip}>
                <BetSlip
                  mediumBetSlip={true}
                  mobileBetSlip={true}
                  hideBetSlip={handleHideBetSlip}
                />
              </div>
            </div>
          </div>
          <ToTopBtn isVisible={isToTopBtnVisible} />
        </div>
      </div>
    </>
  );
};

export default SportLineNew;
