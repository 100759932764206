import React from "react";
import ContentLoader from "react-content-loader"
const PredictionListCustomLoader = (props) => (
    <ContentLoader
        speed={2}
        // width={780}
        // height={250}
        viewBox="0 0 780 250"
        backgroundColor="#F9F9FB"
        foregroundColor="#F9F9FB"
        {...props}
    >
        <circle cx="31" cy="31" r="23" />
        <rect x="60" y="12" rx="4" ry="4" width="120" height="18" />
        <rect x="60" y="36" rx="4" ry="4" width="90" height="15" />
        <rect x="60" y="65" rx="4" ry="4" width="715" height="130" />
        <rect x="60" y="210" rx="5" ry="5" width="65" height="20" />
        <rect x="715" y="210" rx="5" ry="5" width="60" height="20" />
    </ContentLoader>

)
export default PredictionListCustomLoader;