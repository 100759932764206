import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import InputButton from "../../InputButton";
import useScroll from "../../useScroll";

import { dynamicActivate } from "../../../../i18n";
import { clearEvent, setLanguage } from "../../../../store/betSlice";

import "./homePageHeader.css";

const HomePageHeader = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [navClassList, setNavClassList] = useState([]);
  const scroll = useScroll();

  const location = useLocation();

  const [uaLink, setUaLink] = useState("");
  const [enLink, setEnLink] = useState("");

  const [languageModelShow, setLanguageModelshow] = useState(
    "language-suggestion all-languages-hide"
  );
  const [languageUAactive, setLanguaeUAActive] = useState(
    "Українська-UA-English-EN-dropDown"
  );
  const [languageENactive, setLanguaeEnActive] = useState(
    "English-EN-dropDown-active"
  );
  const [mobileLanguageModelShow, setMobileLanguageModelShow] = useState(
    "language-suggestion all-languages-hide"
  );
  const navigate = useNavigate();

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath.includes("/en/")) {
      const uaPath = currentPath.replace(/^\/en/, "");
      const enPath = currentPath;
      setEnLink(enPath);
      setUaLink(uaPath);
    } else {
      const enPath = `/en${currentPath}`;
      const uaPath = currentPath;
      setEnLink(enPath);
      setUaLink(uaPath);
    }

    if (localStorage.getItem("language")) {
      if (localStorage.getItem("language") == "ua") {
        setLanguaeUAActive("Українська-UA-dropDown-active");
        setLanguaeEnActive("Українська-UA-English-EN-dropDown");
      } else if (localStorage.getItem("language") == "en") {
        setLanguaeEnActive("English-EN-dropDown-active");
        setLanguaeUAActive("Українська-UA-English-EN-dropDown");
      } else {
        setLanguaeEnActive("English-EN-dropDown-active");
        setLanguaeUAActive("Українська-UA-English-EN-dropDown");
      }
    }
  }, []);

  const hangleModileToggle = () => {
    let showClass = document.querySelectorAll(".navbar-collapse.show");
    let showClass1 = document.querySelectorAll(
      ".parant-collapse.navbar-collapse.collapse"
    );
    if (showClass) {
      {
        const isOverflow = document.getElementsByClassName("hidden-overFlow");
        if (isOverflow.length < 1) {
          document.body.classList.add("hidden-overFlow");
        } else {
          document.body.classList.remove("hidden-overFlow");
        }
      }
    } else if (showClass1) {
      document.body.classList.remove("hidden-overFlow");
    } else {
    }
  };
  const handleLoginPage = () => {
    navigate("/signin");
  };
  const handleRegisterPage = () => {
    navigate("/signup");
  };
  const handleLanguage = () => {
    if (languageModelShow === "language-suggestion all-languages-show") {
      setLanguageModelshow("language-suggestion all-languages-hide");
    } else {
      setLanguageModelshow("language-suggestion all-languages-show");
    }
  };

  const handleMobileLanguage = () => {
    if (mobileLanguageModelShow === "language-suggestion all-languages-show") {
      setMobileLanguageModelShow("language-suggestion all-languages-hide");
    } else {
      setMobileLanguageModelShow("language-suggestion all-languages-show");
    }
  };

  const handleStoreLanguages = async (languages) => {
    localStorage.setItem("language", languages);
    dynamicActivate(languages);

    dispatch(setLanguage(languages));
    dispatch(clearEvent());

    if (localStorage.getItem("language")) {
      if (localStorage.getItem("language") == "ua") {
        setLanguaeUAActive("Українська-UA-dropDown-active");
        setLanguaeEnActive("Українська-UA-English-EN-dropDown");
      } else if (localStorage.getItem("language") == "en") {
        setLanguaeEnActive("English-EN-dropDown-active");
        setLanguaeUAActive("Українська-UA-English-EN-dropDown");
      } else {
        setLanguaeEnActive("English-EN-dropDown-active");
        setLanguaeUAActive("Українська-UA-English-EN-dropDown");
      }
    }
    setLanguageModelshow("language-suggestion all-languages-hide");
  };

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  function listenForOutsideClicks() {
    return () => {
      if (listening) return;
      if (!menuRef.current) return;
      setListening(true);
      ["mousedown"].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRef?.current?.contains(evt.target)) {
            // handleLanguageDropDown()
          } else {
            setLanguageModelshow("language-suggestion all-languages-hide");
          }
        });
      });
    };
  }

  useEffect(listenForOutsideClicks(), [languageModelShow]);

  const handleDefaultLanguage = () => {
    if (localStorage.getItem("language")) {
      return localStorage.getItem("language").toUpperCase();
    }
    // window.location.reload();
  };

  return (
    <>
      <Navbar
        className={`navbar-section notLogedIn-userNavbar ${navClassList.join(
          " "
        )}`}
      >
        <div
          className="container-1440 homepageHeader-topnav  d-flex"
          id="myTopnav"
        >
          <div className="d-flex toggle-button-responsive">
            <div className="navbar-logo d-flex align-items-center">
              <a href={localStorage.getItem("language") === "ua" ? "/" : "/en"}>
                <img src="/navbar-logo.svg" alt="Logo" />
              </a>
            </div>
            <Navbar.Toggle
              className="test"
              aria-controls="basic-navbar-nav"
              onClick={() => hangleModileToggle()}
            />
            <Navbar.Collapse
              id="basic-navbar-nav parant-collapse"
              className="parant-collapse"
            >
              <Nav className=" navbar-nav homePage-nav">
                <a
                  href={
                    localStorage.getItem("language") === "ua"
                      ? "/predictions"
                      : "/en/predictions"
                  }
                  className="navlink"
                >
                  {t("homePage.Predictions")}
                </a>
                <a
                  href={
                    localStorage.getItem("language") === "ua"
                      ? "/sportline"
                      : "/en/sportline"
                  }
                  className="navlink feed-navLink"
                >
                  {t("sportLine.sportlineTitle")}
                </a>
                <a
                  href={
                    localStorage.getItem("language") === "ua"
                      ? "/leaderboard"
                      : "/en/leaderboard"
                  }
                  className="navlink feed-navLink"
                >
                  {t("navbar.Leaderboard")}
                </a>
                <a
                  href={
                    localStorage.getItem("language") === "ua"
                      ? "/marketplace"
                      : "/en/marketplace"
                  }
                  className="navlink feed-navLink"
                >
                  {t("navbar.Marketplace")}
                </a>
              </Nav>
              {/* mobile-right-side */}
              <div className="mobile-nav-right-side ">
                <div className="mobile-languages-dropdown notLogedinUser-nav-languages">
                  <div
                    className={languageUAactive}
                    onClick={() => handleStoreLanguages("ua")}
                  >
                    <img src="/ua.svg" alt="up" />
                    <span className="Українська-UA-label">
                      Українська <span style={{ color: "#7F87B3" }}>UA</span>
                    </span>
                  </div>
                  <div
                    className={languageENactive}
                    onClick={() => handleStoreLanguages("en")}
                  >
                    <img src="/en.svg" alt="en" />
                    <span className="English-EN-label">
                      English <span style={{ color: "#7F87B3" }}>EN</span>
                    </span>
                  </div>
                </div>

                <InputButton
                  type="secondary"
                  inputLabel={t("homePage.LogIn")}
                  onClick={handleLoginPage}
                  addBtnClass={"homeNavlogin"}
                />
                <InputButton
                  type="primary"
                  inputLabel={t("homePage.account")}
                  onClick={handleRegisterPage}
                  addBtnClass={"homeNavCreateAccountBtn"}
                />
              </div>
            </Navbar.Collapse>
          </div>
          {/* desktop-right-side */}
          <div className="home-nav-right-side">
            <div className="home-Page-language-selector">
              <div
                className="nav-language-containt d-flex align-items-center"
                ref={menuRef}
                onClick={handleLanguage}
              >
                <img
                  src={
                    localStorage.getItem("language")
                      ? `/${localStorage.getItem("language")}.svg`
                      : "/en.svg"
                  }
                  className="nav-language-logo"
                  width="16px"
                  height="16px"
                  alt=""
                />
                <div className="nav-language-name">
                  {handleDefaultLanguage()}
                </div>
              </div>
              <div className={languageModelShow}>
                <a
                  href={uaLink}
                  className="all-languages d-flex align-items-center"
                  // onClick={() => handleStoreLanguages("ua")}
                >
                  <div className="slogo">
                    <img src="/ua.svg" alt="ua" />
                  </div>
                  <div className="navbar-language-name">
                    Українська <span style={{ color: "#7F87B3" }}>UA</span>
                  </div>
                </a>
                <a
                  href={enLink}
                  className="all-languages d-flex align-items-center"
                  // onClick={() => handleStoreLanguages("en")}
                >
                  <div className="slogo">
                    <img src="/en.svg" />
                  </div>
                  <div className="navbar-language-name">
                    English <span style={{ color: "#7F87B3" }}>EN</span>
                  </div>
                </a>
              </div>
            </div>

            <InputButton
              type="secondary"
              inputLabel={t("homePage.LogIn")}
              onClick={handleLoginPage}
              addBtnClass={"homeNavlogin"}
            />
            <InputButton
              type="primary"
              inputLabel={t("homePage.account")}
              onClick={handleRegisterPage}
              addBtnClass={"homeNavCreateAccountBtn"}
            />
          </div>
        </div>
      </Navbar>
    </>
  );
};
export default HomePageHeader;
