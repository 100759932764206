import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";

const ToTopBtn = ({ isVisible }) => {
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth' 
  });
    const element = document.getElementById("addPrediction-tab");
    
    element.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth' 
  });
  };

  return (
    <div
      className={isVisible ? "to-top-btn" : "to-top-btn hide"}
      onClick={scrollToTop}
    >
      <FaArrowUp />
    </div>
  );
};

export default ToTopBtn;
