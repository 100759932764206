import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/resetpassword.css";
import apiService from "../services/apiService";
import Header from "./Header";
import { eventBus } from "../services/events";
import { CONSTANT } from "../common/constant";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { AuthUtils } from "../AuthUtils";
import { useEffect } from "react";

const ResetNewPassword = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [type, setType] = useState("password");
  const [newPassword, setNewPassword] = useState("");
  const [isVAlidateSuccess, setisVAlidateSuccess] = useState(false);
  const [checkValidation, setCheackValidation] = useState({
    length: "fa fa-check",
    uppercase: "fa fa-check",
    lowercase: "fa fa-check",
    specialChar: "fa fa-check",
    number: "fa fa-check",
  });

  const validation = (e) => {
    const value = e.target.value;
    const passwordInputFeildName = e.target.name;

    if (passwordInputFeildName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{12,}/;
      const uppercasePassword = uppercaseRegExp.test(value);
      const lowercasePassword = lowercaseRegExp.test(value);
      const digitsPassword = digitsRegExp.test(value);
      const specialCharPassword = specialCharRegExp.test(value);
      const minLengthPassword = minLengthRegExp.test(value);
      if (minLengthPassword) {
        setCheackValidation((oldState) => {
          return { ...oldState, length: "fa fa-check text-green" };
        });
      } else {
        setCheackValidation((oldState) => {
          return { ...oldState, length: "fa fa-check" };
        });
      }
      if (lowercasePassword) {
        setCheackValidation((oldState) => {
          return { ...oldState, lowercase: "fa fa-check text-green" };
        });
      } else {
        setCheackValidation((oldState) => {
          return { ...oldState, lowercase: "fa fa-check" };
        });
      }
      if (uppercasePassword) {
        setCheackValidation((oldState) => {
          return { ...oldState, uppercase: "fa fa-check text-green" };
        });
      } else {
        setCheackValidation((oldState) => {
          return { ...oldState, uppercase: "fa fa-check" };
        });
      }
      if (digitsPassword) {
        setCheackValidation((oldState) => {
          return { ...oldState, number: "fa fa-check text-green" };
        });
      } else {
        setCheackValidation((oldState) => {
          return { ...oldState, number: "fa fa-check" };
        });
      }
      if (specialCharPassword) {
        setCheackValidation((oldState) => {
          return { ...oldState, specialChar: "fa fa-check text-green" };
        });
      } else {
        setCheackValidation((oldState) => {
          return { ...oldState, specialChar: "fa fa-check" };
        });
      }

      if (
        uppercasePassword &&
        lowercasePassword &&
        minLengthPassword &&
        digitsPassword &&
        specialCharPassword
      ) {
        setisVAlidateSuccess(true);
      } else {
        setisVAlidateSuccess(false);
      }
    }
  };
  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  const handleNewPassword = async (e) => {
    e.preventDefault();
    const url = new URL(window.location.href);
    let token = url.searchParams.get("token");
    if (url.searchParams.has("token")) {
      eventBus.emit(CONSTANT.SHOW_LOADER);
      const res = await apiService.changePassword(newPassword, token);
      eventBus.emit(CONSTANT.HIDE_LOADER);
      if (res.data) {
        navigate("/signin");
      }
    } else {
      alert("param not found");
    }
  };
  useEffect(() => {
    AuthUtils()
    const url = new URL(window.location.href);
    let isValid = url.searchParams.get("token");
    console.log("url.searchParams.has token::", isValid)
    if (!isValid) {
      navigate("/")
    }
  }, [])
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("resetPassWord.newPasswordTabTitle")}</title>
      </Helmet>
      <Header />
      <section className="form-section">
        <div className="">
          <div className=" d-flex justify-content-center align-items-center">
            <div className="card resetnewpassword-card">
              <div className="card-body p-0">
                <div className="resetpassword-avatar">
                  <div
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      width: "64px",
                      height: "64px",
                      backgroundColor: "#F2F3F7",
                    }}
                    alt="Avatar"
                  >
                    <img className="" src="Password.svg " />
                  </div>
                </div>
                <div className="card-title">
                  <div
                    className=" text-center mt-4 mb-4"
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      lineHeight: "24px",
                    }}
                  >
                    {t("resetPassWord.newPasswordTitle")}
                  </div>
                </div>
                <form>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputPassword"
                      className="form-label form-input-label"
                    >
                      {t("resetPassWord.Newpassword")}
                    </label>
                    <div className="input-icons resetPasswordIconfaild">
                      <input
                        className="input-field form-control form-input-field"
                        type={type}
                        name="password"
                        id="exampleInputPassword"
                        placeholder={t("resetPassWord.Enteryourpassword")}
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          color: "#7F87B3",
                        }}
                        onChange={(e) => {
                          setNewPassword(e.target.value);
                        }}
                        onKeyUp={validation}
                      />
                      <span className="toggle-icon" onClick={handleToggle}>
                        <i
                          className={
                            type == "password" ? "fa fa-eye-slash" : "fa fa-eye"
                          }
                        ></i>
                      </span>
                      <div
                        className="password-suggestion"
                        style={
                          newPassword
                            ? isVAlidateSuccess
                              ? { display: "none" }
                              : { display: "block" }
                            : { display: "none" }
                        }
                      // style={{ display: "block" }}
                      >
                        <p className="mb-2">
                          {t("tooltip.Mustcontainatleast")}
                        </p>
                        <ul className="list-unstyled mb-0">
                          <li>
                            <i className={checkValidation.length}></i>
                            {t("tooltip.characters")}
                          </li>
                          <li>
                            <i className={checkValidation.lowercase}></i>
                            {t("tooltip.lowercasecharacter")}
                          </li>
                          <li>
                            <i className={checkValidation.uppercase}></i>{" "}
                            {t("tooltip.uppercasecharacter")}
                          </li>
                          <li>
                            <i className={checkValidation.number}></i>{" "}
                            {t("tooltip.number")}
                          </li>
                          <li>
                            <i className={checkValidation.specialChar}></i>{" "}
                            {t("tooltip.specialcharacter")}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <button
                      className="form-control form-input-field-button"
                      id="exampleInputbutton"
                      onClick={handleNewPassword}
                      disabled={isVAlidateSuccess == false || newPassword == ""}
                      style={{
                        pointerEvents: newPassword == "" ? "none" : "auto",
                      }}
                    >
                      {t("resetPassWord.setNewPassBtn")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ResetNewPassword;
