import { async } from "q";
import React from "react";
import { useTranslation } from "react-i18next";
import apiService from "../../../services/apiService";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import TopPredictionListDetailsModel from "./TopPredictionListDetailsModel";

const TopPredictionList = (props) => {
  const { t, i18n } = useTranslation();
  const [modelShow, setModelShow] = useState(false);
  const [topPredictionData, setTopPredictionData] = useState([]);
  const [predictionDetail, setPredictionDetail] = useState({});
  const handleGetTopPrediction = async () => {
    const res = await apiService.getTopPrediction(props.id);
    if (res) {
      setTopPredictionData(res);
    }
  };
  const handleTopPredictionModel = (item) => {
    setPredictionDetail(item);
    setModelShow(true);
  };
  const handleTopPredictionModelHide = () => {
    setModelShow(false);
  };
  useEffect(() => {
    handleGetTopPrediction();
  }, [props.id]);
  const getOddsMultiply = (odds) => {
    let od = 1;
    for (let i of odds) {
      od = od * parseFloat(i.oc_rate.toFixed(2));
    }
    return parseFloat(od.toFixed(2));
  };
  const handleLanguageLocalization = (data) => {
    var localLocale = moment.unix(data);
    localLocale.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
    return (
      localLocale.format("MMMM D").charAt(0).toUpperCase() +
      localLocale.format("MMMM D").slice(1)
    );
  };
  return (
    <>
      <div className="Top-predictionstitle pb-3">
        {t("profile.Top3predictions")}
      </div>
      <div>
        <div className="Top-predictions-tabletitle">
          <div className="Event-table-title">{t("profile.Event")}</div>
          <div className="Event-table-Predictiontype">
            {t("profile.Predictiontype")}
          </div>
          <div className="Event-table-Odds">{t("profile.Odds")}</div>
        </div>
        <div
          className="emptyData-graph"
          style={{
            display: topPredictionData?.length <= 0 ? "block" : "none",
          }}
        >
          <div className="Notenoughdata-msg">
            {t("profile.TopPredictionEmptyData")}
          </div>
          <div className="tryAgainLine-msg">
            {t("profile.TopPredictionEmptySubTitle")}
          </div>
        </div>
        <div
          style={{
            display: topPredictionData?.length > 0 ? "block" : "none",
          }}
        >
          {topPredictionData?.map((item, indexf) => (
            <div
              className="Top-predictions-main-row"
              key={indexf}
              id={item._id}
            >
              <div className="Top-predictions-row d-flex">
                <div className="Event-table-decription">
                  <div className="Top-predictions-teams-detail">
                    <div className="d-flex Top-predictions-teams-bitton-div">
                      <div className="both-Team-logo">
                        <div className="d-flex">
                          <span className="team1-logo">
                            <img
                              src={
                                process.env.REACT_APP_teamLogo +
                                item.odds[0].opp_1_icon
                              }
                              height="28.75px"
                              width="28.75px"
                              style={{ borderRadius: "50px" }}
                            />
                          </span>
                          <span className="team2-logo">
                            <img
                              src={
                                process.env.REACT_APP_teamLogo +
                                item.odds[0].opp_2_icon
                              }
                              className="team-2-logo"
                              height="28.75px"
                              width="28.75px"
                              style={{ borderRadius: "50px" }}
                            />
                          </span>
                        </div>

                        <div className="d-flex oddsScore-button mobile-oddsScore-button">
                          <span className="dot"></span>
                          <span className="oddsScore ps-1">
                            {getOddsMultiply(item.odds)}
                          </span>
                        </div>
                      </div>

                      <div>
                        <div className="team1-team2-name pb-1">
                          {apiService.getProfileFromStorage()?.Language == "en"
                            ? item.odds[0].opp_1_name_en
                              ? item.odds[0].opp_1_name_en
                              : item.odds[0].opp_1_name
                            : item.odds[0].opp_1_name}
                          <span className="px-2">
                            {item.odds[0]?.result.score
                              ? item.odds[0]?.result.score
                              : "-:-"}
                          </span>
                          {apiService.getProfileFromStorage()?.Language == "en"
                            ? item.odds[0].opp_2_name_en
                              ? item.odds[0].opp_2_name_en
                              : item.odds[0].opp_2_name
                            : item.odds[0].opp_2_name}
                        </div>
                        <div className="both-team-league-time-sport">
                          {apiService.getProfileFromStorage()?.Language == "en"
                            ? item.odds[0].sport_name_en
                              ? item.odds[0].sport_name_en
                              : item.odds[0].sport_name
                            : item.odds[0].sport_name}{" "}
                          .{" "}
                          {apiService.getProfileFromStorage()?.Language == "en"
                            ? item.odds[0].tournament_name_en
                              ? item.odds[0].tournament_name_en
                              : item.odds[0].tournament_name
                            : item.odds[0].tournament_name}{" "}
                          |{" "}
                          {handleLanguageLocalization(item.odds[0].game_start)}
                          {", "}
                          {moment
                            .unix(item.odds[0].game_start)
                            .format("HH: mm")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Predictiontype-decription">
                  <div className="both-team-league-name align-items-center d-flex">
                    {item.odds[0].oc_group_name === "1x2"
                      ? t("profile.FullTimeResult")
                      : item.odds[0].oc_group_name}
                    <span className="both-team-name">
                      - {item.odds[0].oc_name}
                    </span>
                  </div>
                </div>
                <div className="Odds-decription">
                  <div className="oddsScore-button">
                    <span className="dot"></span>
                    <span className="oddsScore ps-1">
                      {getOddsMultiply(item.odds)}
                    </span>
                  </div>
                  <div className="pridictionCountScore-div position-relative">
                    <div className="pridictionCountScore-tooltip">
                      {t("profile.TopPrediction")}
                    </div>
                    {item.odds.length > 1 ? (
                      <div
                        className="pridictionCountScore-button d-flex"
                        onClick={() => handleTopPredictionModel(item)}
                      >
                        <span className="">+</span>
                        <span className="ps-1 d-flex">{item.odds.length - 1}  {t("profile.events")}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <TopPredictionListDetailsModel
        addModelclass={"wallet-model-container"}
        predictionDetail={predictionDetail}
        hide={handleTopPredictionModelHide}
        isModel={modelShow}
      />
    </>
  );
};
export default TopPredictionList;
