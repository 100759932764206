import React from "react"
import ContentLoader from "react-content-loader"

const MyLoader = (props) => (
    <ContentLoader
        speed={2}
        width={400}
        height={200}
        viewBox="0 0 400 160"
        backgroundColor="#F9F9FB"
        foregroundColor="#F9F9FB"
        {...props}
    >
        {/* first name */}
        <rect x="100" y="20" rx="3" ry="3" width="88" height="6" />
        {/* username */}
        <rect x="100" y="40" rx="3" ry="3" width="52" height="6" />
        <rect x="100" y="60" rx="3" ry="3" width="80" height="6" />
        {/* follower */}
        <rect x="10" y="100" rx="3" ry="3" width="52" height="6" />
        <rect x="10" y="120" rx="3" ry="3" width="40" height="6" />
        {/* following */}
        <rect x="80" y="100" rx="3" ry="3" width="52" height="6" />
        <rect x="80" y="120" rx="3" ry="3" width="40" height="6" />
        {/* prediction */}
        <rect x="150" y="100" rx="3" ry="3" width="52" height="6" />
        <rect x="150" y="120" rx="3" ry="3" width="40" height="6" />
        {/* bio */}
        <rect x="10" y="156" rx="3" ry="3" width="410" height="6" />
        {/* <rect x="0" y="72" rx="3" ry="3" width="380" height="6" /> */}
        {/* <rect x="0" y="88" rx="3" ry="3" width="178" height="6" /> */}
        <circle cx="50" cy="45" r="40" />
    </ContentLoader>
)

export default MyLoader