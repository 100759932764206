import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../../services/apiService";
import NavigationBar from "../Navbar";
import DashBorad from "./admin-sidebar/admin-dashboard/Dashboard";
import AdminSidebar from "./admin-sidebar/AdminSideBar";
import Users from "./admin-sidebar/users/Users";
import "./admin.css";
import GenaralSetting from "./admin-sidebar/genaral-setting/GenaralSetting";
import { Helmet } from "react-helmet";
const Admin = () => {
  const navigate = useNavigate();
  const [dashBoard, setDashBoard] = useState(true);
  const [settingPage, setSettingPage] = useState(false);
  const [dashBoardBackColor, setDashBoardColor] = useState(
    "dashborad-user-active"
  );
  const [dashBoardIconStroke, setDashBoardIconStroke] = useState("#131520");
  const [userIconStroke, setUserIconStroke] = useState("#7F87B3");
  const [settingIconStroke, setSettingIconStroke] = useState("#7F87B3");
  const [userBackgroundColor, setUserBackgroundColor] =
    useState("dashBoard-user-btn");
  const [settingBackgroundColor, setSettingBackgroundColor] =
    useState("dashBoard-user-btn");
  const [isAdmin, setIsAdmin] = useState(false);
  const [users, setUsers] = useState(false);
  const [pageCountRefresh, setPageCountRefersh] = useState(0);
  const handleRefreshPage = () => {
    setPageCountRefersh(pageCountRefresh + 1);
  };
  const handleDashbord = () => {
    setDashBoard(true);
    setUsers(false);
    setSettingPage(false);
    if (dashBoardBackColor) {
      setDashBoardColor("dashborad-user-active");
      setUserBackgroundColor("dashBoard-user-btn");
      setSettingBackgroundColor("dashBoard-user-btn");
      setDashBoardIconStroke("#131520");
      setSettingIconStroke("#7F87B3");
      setUserIconStroke("#7F87B3");
    }
  };
  const handleUser = () => {
    setDashBoard(false);
    setUsers(true);
    setSettingPage(false);
    if (userBackgroundColor) {
      setUserIconStroke("#131520");
      setDashBoardIconStroke("#7F87B3");
      setDashBoardColor("dashBoard-user-btn");
      setSettingIconStroke("#7F87B3");
      setSettingBackgroundColor("dashBoard-user-btn");
      setUserBackgroundColor("dashborad-user-active");
    }
  };
  const handleAdminSetting = () => {
    setSettingPage(true);
    setDashBoard(false);
    setUsers(false);
    setSettingIconStroke("#131520");
    setUserIconStroke("#7F87B3");
    setDashBoardIconStroke("#7F87B3");
    setDashBoardColor("dashBoard-user-btn");
    setUserBackgroundColor("dashBoard-user-btn");
    setSettingBackgroundColor("dashborad-user-active");
  };
  const handleAdmin = async () => {
    if (apiService.getProfileFromStorage()?.role[0] == "User") {
      navigate("/userprofile");
    } else {
      setIsAdmin(true);
    }
  };

  useEffect(() => {
    document.body.classList.add("admin-body");
    handleAdmin();
  }, []);
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {isAdmin ? (
        <div>
          <NavigationBar pageCountRefresh={pageCountRefresh} />
          <div className="adminpanel-container row">
            {/* sidebar ---- */}
            <div className="col-2 admin-panel-sidebar">
              <AdminSidebar
                dashBoardStroke={dashBoardIconStroke}
                userStroke={userIconStroke}
                settingStroke={settingIconStroke}
                SideBarButtonColor={dashBoardBackColor}
                SideBarUserButtonColor={userBackgroundColor}
                sidebarSettingButtonColor={settingBackgroundColor}
                onClickDashBoard={handleDashbord}
                onClickUser={handleUser}
                onClickSetting={handleAdminSetting}
              />
            </div>
            {/* admin panel containt */}
            <div className="col-10 admin-containt-section">
              {dashBoard ? (
                <DashBorad
                  handleUser={handleUser}
                  handleRefreshPage={handleRefreshPage}
                />
              ) : users ? (
                <Users handleRefreshPage={handleRefreshPage} />
              ) : (
                <GenaralSetting />
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Admin;
