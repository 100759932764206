import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { eventBus } from "../../services/events";
import { CONSTANT } from "../../common/constant";
import { AuthUtils } from "../../AuthUtils";
import { isValidUserName, isValidName } from "../../helpers/validator";
import apiService from "../../services/apiService";

import Header from "../Header";

const Register = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [username, setUsername] = useState(null);
  const [errorClass, setErrorClass] = useState("mb-3");
  const [error, setError] = useState("");
  const [getCountryNameIp, setCountryNameIp] = useState({});
  const [nameErrorClass, setNameErrorClass] = useState("mb-3");
  const [nameError, setNameError] = useState("");
  const [lastNameErrorClass, setLastNameErrorClass] = useState("mb-3");
  const [lastNameError, setlastNameError] = useState("");
  const [registerData, setRegisterData] = useState({
    firstName: "",
    lastName: "",
    userName: username,
  });

  useEffect(() => {
    // console.log(localStorage.getItem("profile"))
    // if (localStorage.getItem("token") && localStorage.getItem("profile")) {
    //   // navigate("/userprofile");
    // }
  }, []);

  const url = new URL(window.location.href);
  let token = url.searchParams.get("token");
  let id = url.searchParams.get("id");

  const handleAddData = async (e) => {
    const data = { ...registerData };
    // firstName
    if (e.target.name === "firstName") {
      let isName = isValidName(e.target.value, "firstName", t);
      if (!isName.isValid) {
        setNameErrorClass("mb-3 form-error");
        setNameError(isName.nameValidation);
      } else {
        setNameErrorClass("mb-3");
        setNameError("");
      }
    }
    // lastName
    if (e.target.name === "lastName") {
      let isName = isValidName(e.target.value, "lastName", t);
      if (!isName.isValid) {
        setLastNameErrorClass("mb-3 form-error");
        setlastNameError(isName.nameValidation);
      } else {
        setLastNameErrorClass("mb-3");
        setlastNameError("");
      }
    }
    // username
    if (e.target.name === "userName") {
      const payload = {
        type: "userName",
        text: e.target.value,
      };
      let isName = isValidUserName(payload.text, t);
      if (!isName.isValid) {
        setErrorClass("mb-3 form-error");
        setError(isName.nameValidation);
      } else {
        setErrorClass("mb-3");
        setError("");
      }
      eventBus.emit(CONSTANT.SHOW_LOADER);
      const res = await apiService.checkValidation(payload);
      eventBus.emit(CONSTANT.HIDE_LOADER);
      setUsername(() => payload.text);
      if (!res.data) {
        setErrorClass("mb-3 form-error");
        setError(t("register.alreadyExistMsg"));
      }
    }
    if (e.target.value.length <= 0) {
      setErrorClass("mb-3");
      setError("");
      setLastNameErrorClass("mb-3");
      setlastNameError("");
      setNameErrorClass("mb-3");
      setNameError("");
    }
    data[e.target.name] = e.target.value;
    setRegisterData(data);
  };

  const getCountryIp = async () => {
    const response = await fetch("http://ip-api.com/json/");
    const data = await response.json();
    setCountryNameIp(data);
  };

  useEffect(() => {
    getCountryIp();
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    eventBus.emit(CONSTANT.SHOW_LOADER);

    const res = await apiService.updateProfile(
      {
        firstName: registerData.firstName,
        lastName: registerData.lastName,
        userName: username.split(" ").join("")?.toLowerCase(),
        country: getCountryNameIp.country || "ukraine",
        ipAddress: getCountryNameIp.query || "127.0.0.1",
        source: "web registration page"
      },
      token
    );

    eventBus.emit(CONSTANT.HIDE_LOADER);

    // if(res.error){
    //   console.log(res)
    //   setErrorClass("mb-3 form-error");
    //   setError('Час дії вашого посилання минув. Надіслати нове посилання на пошту?');
    //   return
    // }

    if (res.data) {
      navigate("/userprofile");
    } else {
      navigate("/signin");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("register.tabTitle")}</title>
      </Helmet>
      <Header isPointerEvent={true} />
      <section className="form-section">
        <div className="">
          <div className="w-100 d-flex justify-content-center align-items-center">
            <div className="card card-register">
              <div className="card-body signup-card  p-0">
                {/* ----- Register top ----- */}
                <div className="top">
                  <div className="card-title mb-4 signup-card-title">
                    <p
                      className="mb-2"
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        textAlign: "center",
                        marginTop: "0",
                      }}
                    >
                      {t("register.title")}
                    </p>
                    <p className="signup-subtitle mb-3">
                      {t("register.subTitle")}
                    </p>
                  </div>
                </div>
                {/* ------ Register form ------  */}
                <div className="bottom">
                  <form>
                    <div className={nameErrorClass}>
                      <label
                        htmlFor="exampleInputuser"
                        className="form-label form-input-label"
                      >
                        {t("register.name")}
                      </label>
                      <input
                        type="text"
                        className="form-control form-input-field"
                        id="exampleInputuser"
                        placeholder={t("register.namePlaceHolder")}
                        name="firstName"
                        // value={registerData.firstName}
                        onChange={handleAddData}
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          color: "#7F87B3",
                        }}
                        required
                      />
                      <p
                        style={{ fontSize: "14px" }}
                        className=""
                        // className = {username ? "form-error-line-show" : "form-error-line-hide"}
                      >
                        {nameError}
                      </p>
                    </div>
                    <div className={lastNameErrorClass}>
                      <label
                        htmlFor="exampleInputuser"
                        className="form-label form-input-label"
                      >
                        {t("register.LastName")}
                      </label>
                      <input
                        type="text"
                        className="form-control form-input-field"
                        id="exampleInputuser"
                        placeholder={t("register.lastNameHolder")}
                        name="lastName"
                        // value={registerData.lastName}
                        onChange={handleAddData}
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          color: "#7F87B3",
                        }}
                        required
                      />
                      <p style={{ fontSize: "14px" }} className="">
                        {lastNameError}
                      </p>
                    </div>
                    {/* username */}
                    <div
                      className={errorClass}
                      //  className={username ? "mb-3 form-error" : "mb-3"}
                    >
                      <label
                        htmlFor="exampleInputuser"
                        className="form-label form-input-label"
                      >
                        {t("register.Username")}
                      </label>
                      <input
                        type="text"
                        className="form-control form-input-field"
                        id="userId"
                        placeholder={t("register.UsernamePLaceHolder")}
                        name="userName"
                        onChange={handleAddData}
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          color: "#7F87B3",
                        }}
                        required
                      />
                      <p
                        style={{ fontSize: "14px" }}
                        className=""
                        // className = {username ? "form-error-line-show" : "form-error-line-hide"}
                      >
                        {error}
                      </p>
                    </div>
                    <div className="">
                      <button
                        className="form-control form-input-field-button"
                        id="exampleInputbutton"
                        onClick={handleRegister}
                        disabled={
                          registerData.firstName === "" ||
                          registerData.lastName === "" ||
                          registerData.userName == null ||
                          nameError !== "" ||
                          lastNameError !== "" ||
                          error !== ""
                        }
                        style={{
                          pointerEvents:
                            registerData.firstName === "" ||
                            registerData.lastName === "" ||
                            registerData.userName == null
                              ? "none"
                              : "auto",
                        }}
                      >
                        {t("register.registerBtn")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Register;
