import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import NavigationBar from "../Navbar";
import Ad from "../General/Ad";
import ListAd from "../General/ListAd";
import RewardsLoader from "../CustomSkeletonLoaders/RewardsLoader";
import RewardItem from "./RewardItem";

import HomePageHeader from "../molecule/homepage/HomePageHeader/HomePageHeader";
import HomePageFooter from "../molecule/homepage/HomePageFooter/HomePageFooter";

import Model from "../molecule/model/Model";

import apiService from "../../services/apiService";

import "./Rewards.css";
import { calculateSum, determinePointsRange } from "../../helpers/general";

const RewardsPage = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  const [rewards, setRewards] = useState([]);
  const [rewardsCount, setRewardsCount] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [motivationText, setMotivationText] = useState("");

  useEffect(() => {
    getRewards();
  }, [localStorage.getItem("language")]);

  const getRewards = async () => {
    setIsLoad(false);
    setRewards([]);
    const rewardsRes = await apiService.getUserRewards();
    setRewards(rewardsRes);

    getUserRate(rewardsRes[0]);

    const rewardsCountRes = await apiService.getUserRewardsCount();
    setRewardsCount(rewardsCountRes);

    setIsLoad(true);
  };

  const getUserRate = (rewards) => {
    let totalCount = 0;
    const rewardsArr = Object.entries(rewards);
    for (const rewardCategory of rewardsArr) {
      const rewardCategotyItems = Object.values(rewardCategory[1].sub);
      for (const item of rewardCategotyItems) {
        const level = item.completed ? item.user_level : item.user_level - 1;

        totalCount +=
          rewardCategory[0] === "exclusive"
            ? calculateSum(level, true)
            : calculateSum(level);
      }
    }

    const userRate = determinePointsRange(totalCount);

    const motivationText = t(`rewards.motivation${userRate}`).split(/(\d+%)/);
    setMotivationText(motivationText);
  };

  const renderCategory = (category) => {
    return (
      <div className="rewards__list-item-category">
        <div className="rewards__category-title">
          {localStorage.getItem("language") === "ua"
            ? category.name
            : category.name_en}
        </div>
        <div className="rewards__category-sub-title">
          {localStorage.getItem("language") === "ua"
            ? category.description
            : category.description_en}
        </div>
      </div>
    );
  };

  return (
    <div className="rewards-page">
      <Helmet>
        <title>{t("rewards.metaTitle")}</title>
        <meta name="description" content={t("rewards.metaDescription")} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <NavigationBar countData={1} />
      {!isLoad ? (
        <RewardsLoader />
      ) : (
        <div className="rewards__page-wrapper">
          <div className="rewards__main-section">
            <div className="rewards__top-total-section">
              <div className="rewards__top-total-left">
                <div className="rewards__top-total-title">
                  <div className="rewards__top-total-title-text">
                    <h1>{t("rewards.reward")}</h1>
                    <div className="rewards__top-total-count">
                      {rewardsCount?.total || 0} / 10
                    </div>
                  </div>
                  <div className="rewards__top-total-left-description">
                    {t("rewards.titleDesc")}{" "}
                  </div>
                  <img src="/rewards/treasure.svg" alt=""></img>
                </div>
                <div className="rewards__raiting-section">
                  <img src="/rewards/rank_star.svg" alt=""></img>
                  <div>
                    {motivationText.map((part, index) => {
                      if (/\d+%/.test(part)) {
                        return (
                          <span key={index} className="highlighted">
                            {part}
                          </span>
                        );
                      } else {
                        return <span key={index}>{part}</span>;
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="rewards__top-total-right">
                <div className="rewards__top-total-right-rewards-item reward-exclusive">
                  <div className="rewards__top-total-right-rewards-item-title">
                    {t("rewards.exclusive")}
                  </div>
                  <div className="rewards__top-total-right-rewards-item-total">
                    {rewardsCount?.exclusive || 0}/1
                  </div>
                  <img className="medal_2" src="/rewards/Medal_2.svg" alt="" />
                </div>
                <div className="rewards__top-total-right-all-rewards_wrapper">
                  <div className="rewards__top-total-right-rewards-item reward-analyst">
                    <div className="rewards__top-total-right-rewards-item-title">
                      {t("rewards.analyst")}
                    </div>
                    <div className="rewards__top-total-right-rewards-item-total">
                      {rewardsCount?.analyst || 0}/4
                    </div>
                  </div>
                  <div className="rewards__top-total-right-rewards-item reward-collector">
                    <div className="rewards__top-total-right-rewards-item-title">
                      {t("rewards.collector")}
                    </div>
                    <div className="rewards__top-total-right-rewards-item-total">
                      {rewardsCount?.collector || 0}/2
                    </div>
                  </div>
                  <div className="rewards__top-total-right-rewards-item reward-influencer">
                    <div className="rewards__top-total-right-rewards-item-title">
                      {t("rewards.influencer")}
                    </div>
                    <div className="rewards__top-total-right-rewards-item-total">
                      {rewardsCount?.influencer || 0}/2
                    </div>
                  </div>
                  <div className="rewards__top-total-right-rewards-item">
                    <div className="rewards__top-total-right-rewards-item-title">
                      {t("rewards.partner")}
                    </div>
                    <div className="rewards__top-total-right-rewards-item-total">
                      {rewardsCount?.partner || 0}/1
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rewards__list-section">
              <div className="rewards__list-item">
                {rewards.length > 0 &&
                  Object.entries(rewards[0]).map((category, index) => (
                    <React.Fragment key={index}>
                      {renderCategory(category[1])}
                      <div className="rewards__category-sub-items" key={index}>
                        {Object.values(category[1].sub).map(
                          (item, itemIndex) => (
                            <React.Fragment key={itemIndex}>
                              <RewardItem
                                item={item}
                                category={category[0]}
                                getRewards={getRewards}
                              />
                            </React.Fragment>
                          )
                        )}
                      </div>
                      {index === 1 && isTablet && <ListAd />}
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>
          {!isTablet && (
            <div className="rewards__ad-section">
              <Ad />
            </div>
          )}
        </div>
      )}

      <HomePageFooter />
    </div>
  );
};

export default RewardsPage;
