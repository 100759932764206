import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { dynamicActivate } from "../../../i18n";
import InputButton from '../InputButton';
import InputField from '../InputField';
import './sharemodel.css';
import copy from "copy-to-clipboard";
import { useLocation, useNavigate } from 'react-router-dom';
import { Environment } from '../../../environment';
import { FacebookShareButton, TwitterShareButton, TelegramShareButton, } from "react-share";
const ShareModel = (props) => {
    const navigate = useNavigate()
    const location = useLocation();
    const pathName = location.pathname;
    const searchname = location.search
    const { t, i18n } = useTranslation();
    const [copyText, setCopyText] = useState('');
    const [iscopy, setIsCopy] = useState(false);


    useEffect(() => {
        setCopyText(process.env.REACT_APP_shareLinkUrl + "profile/" + props.isUserName)
    }, [copyText]);

    const copyToClipboard = () => {
        copy(copyText);
        setIsCopy(true)
    }
    return (
        <>
            <div className="shareprofile-body">
                <div className="social-media-containt">
                    <div className="shareprofile-body-title">
                        {t("profile.ShareSocialMedia")}
                    </div>
                    <div className="social-media-network d-flex">
                        <TwitterShareButton
                            url={process.env.REACT_APP_shareLinkUrl + "profile/" + props.isUserName}
                            quote={"twitter-share"}
                        >
                            <div className="twiter-media">
                                <span><img src="/twiter.svg" /></span>
                            </div>
                        </TwitterShareButton>
                        <FacebookShareButton
                            url={process.env.REACT_APP_shareLinkUrl + "profile/" + props.isUserName}
                            quote={"Facebook-share"}
                        >
                            <div className="facebook-media">
                                <span><img src="/facebook.svg" /></span>
                            </div>
                        </FacebookShareButton>
                        <TelegramShareButton
                            url={process.env.REACT_APP_shareLinkUrl + "profile/" + props.isUserName}
                            quote={"telegram-share"}
                        >
                            <div className="telegram-media">
                                <span><img src="/telegram.svg" /></span>
                            </div>
                        </TelegramShareButton>
                    </div>

                </div>
                <div className="share-link-containt">
                    <div className="link-copybtn">
                        <InputField labelClass={"shareinputfield-label"} inputLabel={t("profile.linkToShare")} defaultValue={copyText} disabled={true} inputClass={"shareinputfield"} />
                        <div className='share-input-btn'>
                            <InputButton type={"primary"} inputLabel={iscopy ? t("profile.copied") : t("profile.copyLink")} addBtnClass={"shareModelStyle"} addMainClass={"sharelink-inputbtn"} btnImg={iscopy ? undefined : "/copy.svg"} onClick={copyToClipboard} iscopy={iscopy} />
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default ShareModel;