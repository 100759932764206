import React from "react";

import './ToolTip.css'

const ToolTip = ({ text, children }) => {
  return (
    <div className="tooltip-wrapper">
      {children}
      <span className="tooltip-text">{text}</span>
    </div>
  );
};

export default ToolTip;
