import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import apiService from "../../../services/apiService";
import "./prediction-list.css";
import { useTranslation } from "react-i18next";
import { Environment } from "../../../environment";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import jQuery from "jquery";
import axios from "axios";
import InputButton from "../InputButton";
import Model from "../model/Model";
import { Modal } from "react-bootstrap";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from "react-share";
import html2canvas from "html2canvas";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";
import { eventBus } from "../../../services/events";
import { CONSTANT } from "../../../common/constant";
import "moment/min/locales";
import MorePredicrionModel from "./MorePredicrionModel";
import InputField from "../InputField";
import { formatTeamLinkIcon, getThousandsNumber, prepareFullName } from "../../../helpers/formater";
import Ad from "../../General/Ad";
import ReadMoreLessText from "../../General/ReadMoreLessText/ReadMoreLessText";

const PredictionList = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const componentRef = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { t, i18n } = useTranslation();
  const [countData, setCountData] = useState(0);
  const loginUserId = localStorage.getItem("userId");
  const [shareModelHideShow, setShareModelHideShow] = useState(false);
  const [tournamentData, setTournamentData] = useState({});
  const [eventData, setEventData] = useState({});
  const [moreEventData, setMoreEventData] = useState(false);
  const [shareImageMedia, setshareImageMedia] = useState("");
  const [allEventData, setAllEventData] = useState([]);
  const [addPredictionMsg, setAddPredictionMsg] = useState(true);
  const [errorClass, setErrorClass] = useState("mb-3");
  const [error, setError] = useState("form-error-line-hide");
  const [loginEmail, setLoginEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [morePredictionModel, setMorePredictionModel] = useState(false);
  const [sharemodelFooterTitle, setShareMOdelFooterTitle] = useState(
    "social-community-title"
  );
  const [dropDownHideShow, setDropDownHideShow] = useState(false);
  const [predictionModel, setPredictionModle] = useState(false);
  const [seelectedUserData, setSelecedUserData] = useState({});
  const [editPredictionModel, setEditPredictionModel] = useState(false);
  const [editPredictionData, setEditPredictionData] = useState({});
  const [statusData, setStatusData] = useState("");
  const [selecedBetCodeData, setSelectedBetCodeData] = useState("");
  const [deletePredictionModel, setDeletePredictionModel] = useState(false);
  const [deleteItemsData, setDeleteItemsData] = useState({});
  const BASE_API_URL = process.env.REACT_APP_baseUrl + "/auth/uploadImage";
  const [tempData, setTempData] = useState({});
  // delete prediction
  const handleDeletePrediction = (data) => {
    setDeleteItemsData(data);
    setDeletePredictionModel(true);
  };
  const handleDeletePredictionData = async (id) => {
    const res = await apiService.deletePredictionData(id);
    if (res) {
      props.increamentUserCount();
    }
    setDeletePredictionModel(false);
  };
  // edit predition
  const handleEdiePreditionList = (data) => {
    let obj = data.odds.reduce((acc, item) => {
      acc[item.BetHeadDetail.BetCode] = acc[item.BetHeadDetail.BetCode]
        ? acc[item.BetHeadDetail.BetCode]
        : {};
      acc[item.BetHeadDetail.BetCode].status =
        item?.result?.statusText == "В очікуванні"
          ? "Pending"
          : item?.result?.statusText == "Перемог"
          ? "Won"
          : item?.result?.statusText == "Поразка"
          ? "Lost"
          : item?.result?.statusText == "Повернення"
          ? "Returned"
          : item?.result?.statusText;
      acc[item.BetHeadDetail.BetCode].score = item?.result?.score;
      return acc;
    }, {});
    setTempData(obj);
    setEditPredictionData(data);
    setEditPredictionModel(true);
  };
  // console.log("tempData::::", tempData)
  const handleEditSaveChanges = async () => {
    const res = await apiService.editPrediction(
      editPredictionData._id,
      tempData
    );
    if (res) {
      props.increamentUserCount();
    }
    setEditPredictionModel(false);
  };
  const handleFilterDropDown = (data) => {
    if (dropDownHideShow) {
      setDropDownHideShow(false);
    } else {
      setDropDownHideShow(true);
    }
    if (selecedBetCodeData == "") {
      setSelectedBetCodeData(data);
    } else {
      setSelectedBetCodeData("");
    }
  };

  const handleFilterData = (data, betCode) => {
    tempData[betCode] = tempData[betCode] ? tempData[betCode] : {};
    tempData[betCode].status =
      data == "В очікуванні"
        ? "Pending"
        : data == "Перемог"
        ? "Won"
        : data == "Поразка"
        ? "Lost"
        : data == "Повернення"
        ? "Returned"
        : data;
    document.querySelector(`#a${betCode}`).textContent = data;
    setSelectedBetCodeData("");
    setDropDownHideShow(false);
  };
  const handleEditScore = (e, betcode) => {
    tempData[betcode] = tempData[betcode] ? tempData[betcode] : {};
    tempData[betcode].score = e.target.value;
    document.querySelector(`#input${betcode}`).value = e.target.value;
    const inputElement = document.querySelector(`#input${betcode}`);
    inputElement.defaultValue = e.target.value;
  };

  useEffect(() => {
    if (moreEventData) {
      setShareMOdelFooterTitle("social-community-title");
    } else {
      setShareMOdelFooterTitle("social-community-title-hide");
    }
  }, [moreEventData]);
  const handleOpenPredictionData = (data) => {
    setMorePredictionModel(true);
    setSelecedUserData(data);
  };
  const handleEmail = async (e) => {
    e.preventDefault();
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.getUserName({ email: loginEmail });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res.data) {
      navigate("/login", {
        state: {
          email: loginEmail,
          userName: userName,
          userEmail: userEmail,
          name: res.data.userName,
          profileImgUrl: res?.data.profileImgUrl,
        },
      });
    } else {
      setErrorClass("mb-3 form-error");
      setError("form-error-line-show");
    }
  };
  const isValidEmail = (email) => {
    const regexEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regexEmail.test(email);
  };
  const handleLoginEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      // username
      setUserName(e.target.value);
    } else {
      // email
      setUserEmail(e.target.value);
    }
    setLoginEmail(e.target.value);
    if (e.target.value == "") {
      setErrorClass("mb-3");
      setError("form-error-line-hide");
    }
  };
  const handlePredictionHide = () => {
    setPredictionModle(false);
  };

  const handleShareModelShow = async (data, event) => {
    // console.log("event::::", event.length > 1)
    setTournamentData(data);
    setAllEventData(event);
    if (event.length > 1) {
      setEventData(data);
      setMoreEventData(false);
    } else {
      setEventData(data);
      setMoreEventData(true);
    }
    setShareModelHideShow(true);
    setCountData(countData + 1);
  };
  // console.log("event:::", eventData)

  const handleAndMorePrediction = () => {
    setEventData(allEventData);
    setAddPredictionMsg(false);
  };

  const handlePredictionSlipDownload = async (name) => {
    download(name + ".png");
  };

  const download = (filename) => {
    html2canvas(componentRef.current).then((canvas) => {
      const a = document.createElement("a");
      a.download = filename;
      a.href = canvas.toDataURL();
      a.click();
    });
  };

  const handleShareListInMedia = async (name) => {
    // const image = await htmlToImage.toPng(componentRef.current);
    // const a = document.createElement("a");
    // a.href = image;
    // document.body.appendChild(a);
    // document.body.removeChild(a);
    // const file = DataURIToBlob(image);
    // const myFile = new File([file], "image.jpeg", {
    //   type: file.type,
    // });
    // const data = {
    //   image: myFile,
    // };
    // const response = await axios.post(BASE_API_URL, data, {
    //   headers: { "Content-Type": "multipart/form-data" },
    // });

    const content = componentRef.current;
    html2canvas(content).then((canvas) => {
      canvas.toBlob((blob) => {
        const formData = new FormData();
        formData.append("image", blob, name + ".png");

        axios
          .post(process.env.REACT_APP_Url + "/" + "upload-image", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            setshareImageMedia(response.data.imageUrl);
          })
          .catch((error) => {
            console.error("Error uploading image:", error);
          });
      }, "image/png");
    });

    // setshareImageMedia(response.data);
  };

  useEffect(() => {
    if (componentRef.current) {
      handleShareListInMedia(eventData._id);
    }
  }, [countData]);

  const handleShareModelHide = () => {
    setShareModelHideShow(false);
  };
  function DataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }
  const handleData = () => {
    setData(props.betsData);
    // console.log("props.betsData:::", JSON.stringify(props.betsData))
    if (props.betsData) {
      const newData = props.betsData.map((i) => {
        let number = 0;
        let totalOddsData = 1;
        let isRetuenOdd = false;
        i.islike = false;
        i.isDislike = false;
        i.isReturnOdds = false;
        i.odds?.map((item) => {
          // console.log("item:::", item.result.status)
          if (item.result.status === 3) {
            return (isRetuenOdd = true);
          }
          totalOddsData *= item?.oc_rate.toFixed(2);
          number = number + parseFloat(item.oc_rate);
          return number;
        });
        if (i?.odds?.length == 1) {
          i.totalOddsData = i?.odds[0]?.oc_rate;
        } else {
          i.totalOddsData = totalOddsData;
        }
        i.isReturnOdds = isRetuenOdd;
        // console.log("i:::", i)
        if (
          i.likeArray.includes(
            apiService.getProfileFromStorage()?._id.toString()
          )
        ) {
          i.islike = true;
        }
        if (
          i.dislikeArray.includes(
            apiService.getProfileFromStorage()?._id.toString()
          )
        ) {
          i.isDislike = true;
        }
        return i;
      });
      // props.increamentUserCount();
    } else {
      setData([]);
    }
  };

  console.log(props.totalBetsData + "count");
  // total multiply odds
  const getOddsMultiply = (odds) => {
    let od = 1;
    for (let i of odds) {
      od = od * parseFloat(i.oc_rate.toFixed(2));
    }
    return parseFloat(od.toFixed(2));
  };
  const calculateReturnsRate = (odds) => {
    let od = 1;
    for (let i of odds) {
      if (i.result.statusText == "Returned") {
      } else {
        od = od * parseFloat(i.oc_rate.toFixed(2));
      }
    }
    return parseFloat(od.toFixed(2));
  };
  const handleOddsReturnData = (item) => {
    // let totalOdds = getOddsMultiply(item.odds);
    let totalOdds = 1;
    item?.odds?.map((i) => {
      totalOdds *= i?.oc_rate.toFixed(2);
    });
    if (item?.BetHeadDetail.statusText == "Returned") {
      return totalOdds.toFixed(2);
    } else {
      if (item?.isReturnOdds) {
        const y = item.totalOdds == 1 ? "0.0" : calculateReturnsRate(item.odds);
        const x = totalOdds.toFixed(2) + " / " + y;
        return x;
      } else {
        return totalOdds.toFixed(2);
      }
    }
  };

  const updateOddsList = () => {
    for (let i = 0; i < data.length; i++) {
      for (let j in data[i].odds) {
        if (j == 3) break;
        jQuery(`#data-${i}-${j}`).show();
      }
    }
  };
  useEffect(() => {
    handleData();
    updateOddsList();
  }, [props.betsData, props.countData]);
  useEffect(() => {
    updateOddsList();
  }, [data]);
  useEffect(() => {
    updateOddsList();
  }, []);
  useEffect(() => {
    handleData();
  }, [props.countData]);

  function getRotationDegrees(obj) {
    var matrix =
      obj.css("-webkit-transform") ||
      obj.css("-moz-transform") ||
      obj.css("-ms-transform") ||
      obj.css("-o-transform") ||
      obj.css("transform");
    if (matrix !== "none") {
      var values = matrix.split("(")[1].split(")")[0].split(",");
      var a = values[0];
      var b = values[1];
      var angle = Math.round(Math.atan2(b, a) * (180 / Math.PI));
    } else {
      var angle = 0;
    }
    //return (angle < 0) ? angle + 360 : angle;
    return angle;
  }
  const scrollOdds = (e, mi, len) => {
    e.preventDefault();
    let obj = getRotationDegrees(jQuery(`#pdx-${mi}`));
    for (let io = 0; io < len; io++) {
      // console.log("io::", io)
      if (obj == 0 || obj != 180) {
        jQuery(`#data-${mi}-${io}`).slideDown();
        jQuery(`#pdx-${mi}`).css({ transform: "rotate(180deg)" });
      }
      if (obj === 180 && io > 2) {
        // console.log("len::::", jQuery(`#data-${mi}-${io}`).length)
        jQuery(`#data-${mi}-${io}`).slideUp();
        jQuery(`#pdx-${mi}`).css({ transform: "rotate(0deg)" });
      }
    }
  };

  const handleLikeRemoveLike = async (item) => {
    if (localStorage.getItem("token")) {
      setData((res) => {
        res = res?.map((i) => {
          if (i._id.toString() == item._id.toString()) {
            if (
              i.likeArray &&
              i.likeArray.includes(
                apiService.getProfileFromStorage()._id.toString()
              )
            ) {
              i.islike = false;
              i.likeArray = i.likeArray.filter((i) => {
                if (i != apiService.getProfileFromStorage()._id.toString()) {
                  return i;
                }
              });
            } else {
              i.islike = true;
              i.likeArray.push(
                apiService.getProfileFromStorage()._id.toString()
              );
              if (
                i.dislikeArray.includes(
                  apiService.getProfileFromStorage()._id.toString()
                )
              ) {
                i.isDislike = false;
                i.dislikeArray = i.dislikeArray.filter((i) => {
                  if (i !== apiService.getProfileFromStorage()._id.toString()) {
                    return i;
                  }
                });
              }
            }
          }
          return i;
        });
        return res;
      });
      await apiService.manageLikeDislike({
        predictionOrderId: item._id,
        type: item.islike ? 1 : 0,
      });
    } else {
      setPredictionModle(true);
    }
    // localStorage.setItem("bets", JSON.stringify(newData));
    // props.increamentUserCount();
    // setData(newData)
  };
  const handleDisLikeRemoveDisLike = async (item) => {
    if (localStorage.getItem("token")) {
      setData((res) => {
        res = res?.map((i) => {
          if (i._id.toString() == item._id.toString()) {
            if (
              i.dislikeArray &&
              i.dislikeArray.includes(
                apiService.getProfileFromStorage()._id.toString()
              )
            ) {
              i.isDislike = false;
              i.dislikeArray = i.dislikeArray.filter((i) => {
                if (i != apiService.getProfileFromStorage()._id.toString()) {
                  return i;
                }
              });
            } else {
              i.isDislike = true;
              i.dislikeArray.push(
                apiService.getProfileFromStorage()._id.toString()
              );
              if (
                i.likeArray.includes(
                  apiService.getProfileFromStorage()._id.toString()
                )
              ) {
                i.islike = false;
                i.likeArray = i.likeArray.filter((i) => {
                  if (i !== apiService.getProfileFromStorage()._id.toString()) {
                    return i;
                  }
                });
              }
            }
          }
          return i;
        });
        return res;
      });
      await apiService.manageLikeDislike({
        predictionOrderId: item._id,
        type: item.isDislike ? 3 : 2,
      });
      // setData(newData)
    } else {
      setPredictionModle(true);
    }
  };

  const handleUserProfileLink = (item) => {
    let userLink = '/';
    if (item._id.toString() === loginUserId) {
      userLink = "/userprofile";
    } else {
      userLink = `/profile/${item._id}`;
    }

    if (localStorage.getItem("language") === "ua") {
      return userLink;
    }

    return `/en${userLink}`;
  };

  const handleUserProfile = async (item, pageNumber = props.currPage || 0) => {
    const res = await apiService.getPredictionOrderByUserId(
      item._id,
      props.pageSize,
      pageNumber
    );
    if (item._id.toString() === loginUserId) {
      navigate(`/userprofile`);
    } else {
      navigate(`/profile/${item._id}`, {
        state: res,
        length: res?.length,
      });
    }
  };
  const handleSignUp = () => {
    navigate("/signup");
  };
  const handleSignIn = () => {
    navigate("/signin");
  };
  const handleLanguageLocalization = (data) => {
    var localLocale = moment.unix(data);
    localLocale.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
    return (
      localLocale.format("MMMM D").charAt(0).toUpperCase() +
      localLocale.format("MMMM D").slice(1)
    );
  };
  const handlePredictionLanguageLocalization = (data) => {
    var localLocale = moment.unix(data);
    localLocale.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
    return (
      localLocale.format("MMMM D").charAt(0).toUpperCase() +
      localLocale.format("MMMM D").slice(1)
    );
  };
  const handlePredictionListType = (i) => {
    if (i?.betType) {
      if (i.betType == "live") {
        return (
          <span className="live-type-box-div">{t("profile.allLive")}</span>
        );
      } else if (i.betType == "line") {
        return t("feed.PreGame");
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };

  const renderPredictionItem = (i, indexf) => {
    return (
      <div className="prediction-list-detail" key={indexf} id={i._id}>
        <div className="d-flex justify-content-between">
          <div className="addUser-img-date-name d-flex">
            {!i.userId.profileImgUrl ? (
              <div
                className="me-3 filter-user-avtar nav-avt rounded-circle d-flex align-items-center justify-content-center"
                alt="Avatar "
              >
                <p className="navbar-letter">{i.userId.firstName?.charAt(0)}</p>
              </div>
            ) : (
              <div className=" me-3">
                <img
                  className="addeduser-img"
                  src={i.userId.profileImgUrl}
                  alt=""
                />
              </div>
            )}
            <div>
              <a
                className="addeduser-name"
                href={handleUserProfileLink(i.userId)}
              >
                {prepareFullName(i.userId)}
              </a>
              <div className="addeduser-date-time">
                {/* {moment.unix(item.createdDate).format("MMMM D")} */}
                {handlePredictionLanguageLocalization(i.createdDate)},{" "}
                {moment.unix(i.createdDate).format("HH: mm")}
              </div>
            </div>
          </div>
          {/* admin edit delete btn */}
          {apiService.getProfileFromStorage()?.role[0] == "Admin" ? (
            <div className="d-flex predition-edit-delete-user">
              {/* edit */}
              <div className="tooltip-edit-div">
                <div className="edit-button-tooltip">{t("feed.edit")}</div>
                <div
                  className="icon-container"
                  onClick={() => handleEdiePreditionList(i)}
                >
                  <svg
                    width="24"
                    className="ps-0 edit-icon"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="permission-icon-path"
                      d="M11.492 2.78906H7.753C4.678 2.78906 2.75 4.96606 2.75 8.04806V16.3621C2.75 19.4441 4.669 21.6211 7.753 21.6211H16.577C19.662 21.6211 21.581 19.4441 21.581 16.3621V12.3341"
                      stroke="#7F87B3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      className="permission-icon-path"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.82666 10.921L16.2997 3.44799C17.2307 2.51799 18.7397 2.51799 19.6707 3.44799L20.8877 4.66499C21.8187 5.59599 21.8187 7.10599 20.8877 8.03599L13.3787 15.545C12.9717 15.952 12.4197 16.181 11.8437 16.181H8.09766L8.19166 12.401C8.20566 11.845 8.43266 11.315 8.82666 10.921Z"
                      stroke="#7F87B3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      className="permission-icon-path"
                      d="M15.1641 4.60254L19.7301 9.16854"
                      stroke="#7F87B3"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
              {/* delete */}
              <div className="d-flex ps-3 tooltip-delete-user-div">
                <div className="delete-user-button-tooltip">
                  {t("feed.DeleteUser")}
                </div>
                <div
                  className="icon-container"
                  onClick={() => handleDeletePrediction(i)}
                >
                  <img
                    src="/delete-user-img.svg"
                    className="change-permision"
                    width="24px"
                    height="24px"
                    alt=""
                  />
                  <img
                    src="/delete-user-hover-img.svg"
                    className=""
                    width="24px"
                    height="24px"
                    alt=""
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="prediction-list-content">
          {i.comments ? (
            <div className="prediction-slip-decription">
              <ReadMoreLessText text={i.comments} />
            </div>
          ) : (
            ""
          )}

          <div className="list-odds">
            {/* {i.odds[0]?.map((item, index) => ( */}
            <div
              className="addedUser-prediction-data"
              // id={`data-${indexf}-${index}`}
              // key={index}
            >
              {i.odds?.length < 2 && (
                <div className="both-team-league-time-sport d-flex pb-2">
                  <span className="d-flex align-items-center">
                    <img
                      src={"/sport-logo/" + i.odds[0]?.sport_id + ".svg"}
                      width="16px"
                      height="16px"
                      alt=""
                    />
                  </span>
                  {apiService.getProfileFromStorage()?.Language == "en"
                    ? i.odds[0]?.sport_name_en
                      ? i.odds[0]?.sport_name_en
                      : i.odds[0]?.sport_name
                    : i.odds[0]?.sport_name}{" "}
                  .{" "}
                  <span className="earning-coin-tournament_name">
                    {apiService.getProfileFromStorage()?.Language == "en"
                      ? i.odds[0]?.tournament_name_en
                        ? i.odds[0]?.tournament_name_en
                        : i.odds[0]?.tournament_name
                      : i.odds[0]?.tournament_name}{" "}
                  </span>
                  |{/* {moment.unix(item.game_start).format("MMMM D")}, */}{" "}
                  <span className="ps-2 d-flex">
                    {handleLanguageLocalization(i.odds[0]?.game_start)},
                    {moment.unix(i.odds[0]?.game_start).format("HH: mm")}
                  </span>
                </div>
              )}
              <div
                href={"/gamedetail/" + i.odds[0].game_id}
                className="prediction-list-date justify-content-between align-items-start"
              >
                <div className="d-flex align-items-center oc_teams_details">
                  <div className="d-flex align-items-center justify-content-between width-mobile-media pb-2">
                    <div className="both-Team-logo">
                      <span className="team1-logo">
                        <img
                          src={formatTeamLinkIcon(i?.odds[0]?.opp_1_icon)}
                          height="33.42px"
                          width="33.42px"
                          style={{ borderRadius: "50px" }}
                          alt=""
                        />
                      </span>
                      <span className="team2-logo">
                        <img
                          className="team-2-logo"
                          src={formatTeamLinkIcon(i?.odds[0]?.opp_2_icon)}
                          height="33.42px"
                          width="33.42px"
                          style={{ borderRadius: "50px" }}
                          alt=""
                        />
                      </span>
                    </div>
                    <div className="mobile-prediction-result  single-prediction-result">
                      {i.odds[0]?.result.score
                        ? i.odds[0]?.result.score?.split("(")[0]
                        : "-:-"}
                    </div>
                  </div>
                  <div className="both-team-contains">
                    <div className="both-team-name d-flex align-items-center">
                      <div>
                        <span>
                          {apiService.getProfileFromStorage()?.Language == "en"
                            ? i.odds[0]?.opp_1_name_en
                              ? i.odds[0]?.opp_1_name_en
                              : i.odds[0]?.opp_1_name
                            : i.odds[0]?.opp_1_name}
                        </span>
                        <span>-</span>
                        <span>
                          {apiService.getProfileFromStorage()?.Language == "en"
                            ? i.odds[0]?.opp_2_name_en
                              ? i.odds[0]?.opp_2_name_en
                              : i.odds[0]?.opp_2_name
                            : i.odds[0]?.opp_2_name}
                        </span>
                      </div>
                      {/* more event label */}
                      {i.odds.length > 1 && (
                        <div className="more-events-label-div">
                          +{i.odds.length - 1} {t("profile.events")}
                        </div>
                      )}
                    </div>
                    {i.odds.length == 1 && (
                      <div className="both-team-league-name d-flex">
                        {i.odds[0].oc_group_name === "1x2"
                          ? "Full-time result"
                          : i.odds[0].oc_group_name}
                        <span className="px-1"> - </span>
                        <span className="both-team-name">
                          {i.odds[0].oc_name}
                        </span>
                      </div>
                    )}
                    <div></div>
                  </div>
                  {i.odds.length == 1 ? (
                    ""
                  ) : (
                    <div className="mobile-btn-openpredition-btn">
                      <InputButton
                        type="secondary"
                        inputLabel={t("feed.OpenPrediction")}
                        addBtnClass={"Open-prediction-btn"}
                        onClick={() => handleOpenPredictionData(i)}
                      />
                    </div>
                  )}
                </div>
                {/* more-predition-model / oc_rate */}
                {i.odds.length == 1 ? (
                  <div className="single-prediction-result">
                    {i.odds[0]?.result.score
                      ? i.odds[0]?.result.score?.split("(")[0]
                      : "- : -"}
                  </div>
                ) : (
                  <div className="large-screen-openpredition-btn">
                    <InputButton
                      type="secondary"
                      inputLabel={t("feed.OpenPrediction")}
                      addBtnClass={"Open-prediction-btn"}
                      onClick={() => handleOpenPredictionData(i)}
                    />
                  </div>
                )}
              </div>
              <div className="row prediction-slip-odds-stakedCoin-possibleWin-type-status-div d-flex">
                {/* odds */}
                <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-odd-div">
                  <div className="odds-title">{t("chartSummary.Odds")}</div>
                  <div className="data d-flex">
                    {i.odds.length == 1 ? (
                      i.odds[0]?.oc_rate.toFixed(2)
                    ) : (
                      <div className="coef">
                        <div className="coef-data">
                          {handleOddsReturnData(i)}
                        </div>
                        {i?.BetHeadDetail.statusText == "Returned" ? (
                          ""
                        ) : i.isReturnOdds ? (
                          <div className="ps-1 alert-circle-tooltip-div">
                            <div className="alert-circle-image-tooltip">
                              {t("feed.tooltipMsg")}
                            </div>
                            <span className="alert-circle-image d-flex align-items-center">
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.709 10.795L11.568 10.874C10.92 11.236 10.691 11.422 10.696 11.74C10.701 12.154 10.37 12.494 9.956 12.5H9.946C9.536 12.5 9.201 12.171 9.196 11.76C9.179 10.492 10.216 9.912 10.835 9.566L10.976 9.486C11.545 9.173 11.829 8.739 11.829 8.193C11.829 7.29 11.094 6.556 10.191 6.556C9.272 6.556 8.552 7.275 8.552 8.193C8.552 8.607 8.216 8.943 7.802 8.943C7.388 8.943 7.052 8.607 7.052 8.193C7.052 6.463 8.46 5.056 10.191 5.056C11.921 5.056 13.329 6.463 13.329 8.193C13.329 9.296 12.753 10.22 11.709 10.795ZM9.947 14.96C9.533 14.96 9.197 14.639 9.197 14.225V14.195C9.197 13.781 9.533 13.445 9.947 13.445C10.361 13.445 10.697 13.781 10.697 14.195C10.697 14.609 10.361 14.96 9.947 14.96ZM10 0.5C4.761 0.5 0.5 4.762 0.5 10C0.5 15.238 4.761 19.5 10 19.5C15.238 19.5 19.5 15.238 19.5 10C19.5 4.762 15.238 0.5 10 0.5Z"
                                  fill="#BFC3D9"
                                />
                              </svg>
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {/* Staked coins */}
                <div className="col-sm-4  col-4 col-lg-4 col-md-4 predictionlist-Staked-coins">
                  <div className="predictionlist-coins-title">
                    {t("feed.StakedCoins")}
                  </div>
                  <div className="data">{i?.betTotal ? i?.betTotal : "-"}</div>
                </div>
                {/* possible win */}
                <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-possible-win">
                  <div className="win-title">{t("feed.PossibleWin")}</div>
                  <div className="data">
                    {!i.totalOddsData || !i?.betTotal
                      ? "-"
                      : (i.totalOddsData * i?.betTotal).toFixed(2)}
                  </div>
                </div>
                {/* type */}
                <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-type-div">
                  <div className="type-title">{t("feed.Type")}</div>
                  <div className="data">{handlePredictionListType(i)}</div>
                </div>
                {/* status */}
                <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-status-div">
                  <div className="status-title">{t("feed.Status")}</div>
                  <div
                    className="data"
                    style={{
                      backgroundColor: i.BetHeadDetail
                        ? i.BetHeadDetail?.statusText === "Won"
                          ? "#EEFBFB"
                          : i.BetHeadDetail?.statusText === "Lost"
                          ? "#F9DADA"
                          : i.BetHeadDetail?.statusText === "Returned"
                          ? "#FCF7E4"
                          : "#F2F3F7"
                        : "",
                      color: i?.BetHeadDetail?.statusText
                        ? i.BetHeadDetail?.statusText === "Won"
                          ? "#0D393A"
                          : i.BetHeadDetail?.statusText === "Lost"
                          ? "#D14343"
                          : i.BetHeadDetail?.statusText === "Returned"
                          ? "#C09E16"
                          : "#131520"
                        : "#131520",
                    }}
                  >
                    {i?.BetHeadDetail?.statusText
                      ? i.BetHeadDetail?.statusText === "Won"
                        ? t("feed.modelWon")
                        : i.BetHeadDetail?.statusText === "Lost"
                        ? t("feed.modelLost")
                        : i.BetHeadDetail?.statusText === "Returned"
                        ? t("feed.modelReturn")
                        : t("feed.modelPending")
                      : t("feed.modelPending")}
                  </div>
                </div>
              </div>
            </div>
            {/* ))} */}
          </div>
          <div className="d-flex justify-content-between comments-section">
            <div className="d-flex">
              <div className="like-count pe-3">
                <img
                  src={i.islike ? "/fill-like.svg" : "/like.svg"}
                  className="like-btn"
                  onClick={() => handleLikeRemoveLike(Object.assign({}, i))}
                  alt=""
                />
                {i.likeArray.length}
              </div>
              <div className="comment-count">
                <img
                  src={i.isDislike ? "/fill-dislike.svg" : "/comment.svg"}
                  className="disLike-btn"
                  onClick={() =>
                    handleDisLikeRemoveDisLike(Object.assign({}, i))
                  }
                  alt=""
                />
                {i.dislikeArray.length}
              </div>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleShareModelShow(i, i.odds)}
            >
              <img src="/sharebtn.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        {/* <div className='add-prediction-scroll' ref={props.listInnerRef} onScroll={props.onScroll}> */}

        <div className="add-predictions">
          {data.length > 0
            ? data?.map((i, indexf) => {
                if (indexf > 0 && indexf % 5 === 0 && isMobile)
                  return (
                    <>
                      <div
                        className="prediction-list-detail"
                        key={indexf}
                        id={i._id}
                      >
                        <div className="d-flex justify-content-between">
                          <div className="addUser-img-date-name d-flex">
                            <div className=" me-3">
                              <img
                                className="addeduser-img"
                                src="./ca.svg"
                                alt=""
                              />
                            </div>
                            <div>
                              <div className="addeduser-name">
                                Capperauditor
                              </div>
                              <div className="addeduser-date-time">
                                {t("ad")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <Ad />
                      </div>
                      {renderPredictionItem(i, indexf)}
                    </>
                  );
                return renderPredictionItem(i, indexf);
              })
            : ""}
        </div>
        {/* for not logged user */}
        {/* {localStorage.getItem("token") ? (
          " "
        ) : (
          <div
            className="d-flex justify-content-center align-items-center prediction-grop-button"
            style={{ paddingBottom: "32px" }}
          >
            <InputButton
              type={"primary"}
              addBtnClass={"Signup-to-continue"}
              inputLabel={t("signUpTxt")}
              onClick={handleSignUp}
            />
            <div className="or-word">{t("or")}</div>
            <InputButton
              type={"secondary"}
              addBtnClass={"Sign-in-continue"}
              inputLabel={t("signInTxt")}
              onClick={handleSignIn}
            />
          </div>
        )} */}
        {/* for logged user */}
        {props.totalBetsData >= 11 ? (
          <div
            className="ShowMorePredictions-button"
            style={{
              // paddingTop: "32px",
              // paddingBottom: "32px",
              display: props.showMessage !== "" ? "none" : "flex",
            }}
          >
            <InputButton
              type={"secondary"}
              addBtnClass={"ShowMorePredictions-btn"}
              inputLabel={t("feed.ShowMorePredictions")}
              onClick={props.onClick}
              addMainClass={"showMorePrediction-div"}
              showLoader={props.showLoadMoreLoader}
            />
          </div>
        ) : (
          ""
        )}

        {/* if less then 10 */}
        {props.totalBetsData <= 10 || props.showMessage !== "" ? (
          <div className="show-boTTom-message mb-5">
            {t("feed.showMessage")}
          </div>
        ) : (
          ""
        )}
        <div className="share-Prediction-model-div">
          <Modal
            show={shareModelHideShow}
            onHide={handleShareModelHide}
            className="share-Prediction-model"
          >
            <Modal.Body>
              <div className="d-flex justify-content-end share-model-header">
                <div className="model-close-btn" onClick={handleShareModelHide}>
                  <span>
                    <img
                      src="/share-model-close-btn.svg"
                      alt="close"
                      className="close-button"
                    />
                    <img
                      src="/white-close btn.svg"
                      alt="close"
                      className="white-close-button"
                    />
                  </span>
                </div>
              </div>
              {Object.keys(eventData).length > 0 && (
                <div className="shareModel-gradiant-ref">
                  <div className="" ref={componentRef}>
                    <div className="shareModel-gradiant">
                      <div className="shareModel-container">
                        <div className="shareModel-logo-team-logo-group">
                          <img
                            src="/footer-logo.svg"
                            width="202.07px"
                            height="32px"
                            alt=""
                          />
                        </div>
                        <div className="prediction-shareModel-contains">
                          {
                            // eventData.map((event, index) => (
                            <div className="single-share-prediction">
                              <div className="shareModel-tournament-name-date ">
                                <div className="shareModel-tournament-name">
                                  {eventData?.odds[0]?.sport_name} .
                                  {eventData?.odds[0]?.tournament_name}
                                </div>
                                <span className="line-color-share-prediction px-3">
                                  |
                                </span>
                                <div className="date-line-color-share">
                                  {handlePredictionLanguageLocalization(
                                    eventData?.odds[0]?.game_start
                                  )}
                                  ,{" "}
                                  {moment
                                    .unix(eventData?.odds[0]?.game_start)
                                    .format("HH: mm")}
                                </div>
                              </div>
                              <div className="pb-3 single-prediction-team-list">
                                <div className="d-flex align-items-center single-prediction-team-list-name-logo">
                                  <div className="both-Team-logo">
                                    <span className="share-model-team1-logo">
                                      <img
                                        src={
                                          process.env.REACT_APP_teamLogo +
                                          eventData?.odds[0]?.opp_1_icon
                                        }
                                        height="37.5px"
                                        width="37.5px"
                                        style={{ borderRadius: "50px" }}
                                        alt=""
                                      />
                                    </span>
                                    <span className="share-model-team2-logo">
                                      <img
                                        className="team-2-logo"
                                        src={
                                          process.env.REACT_APP_teamLogo +
                                          eventData?.odds[0]?.opp_2_icon
                                        }
                                        height="37.5px"
                                        width="37.5px"
                                        style={{ borderRadius: "50px" }}
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div>
                                    <div className="share-model-both-team-name d-flex">
                                      <div className="share-model-team1-name">
                                        {eventData?.odds[0]?.opp_1_name}{" "}
                                        <span className="px-2">-</span>
                                        {eventData?.odds[0]?.opp_2_name}
                                      </div>
                                    </div>
                                    {/* {moreEventData ? */}
                                    <div className="share-model-oc-group-name">
                                      {eventData?.odds[0]?.oc_group_name ===
                                      "1x2"
                                        ? "Full-time result"
                                        : eventData?.odds[0]?.oc_group_name}
                                      <span className="ps-1 pe-1">-</span>
                                      {eventData?.odds[0]?.oc_name}
                                    </div>
                                    {/* : ""
                                    } */}
                                  </div>
                                </div>
                                {eventData?.odds.length > 1 ? (
                                  <div className="more-prediction-eventlist-count">
                                    +{allEventData.length - 1}{" "}
                                    {t("profile.events")}
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center share-model-result-score-status-div">
                                    <span className="share-model-team1-name result-score px-1">
                                      {eventData?.odds[0]?.result.score
                                        ? eventData?.odds[0]?.result.score?.split(
                                            "("
                                          )[0]
                                        : "-:-"}
                                    </span>
                                  </div>
                                )}
                              </div>
                              {/* --- */}
                              {/* stack coin */}
                              {/* {console.log(
                                "eventDataeventDataeventDataeventData",
                                eventData
                              )} */}
                              <div className="share-model-wrapper-div">
                                <div className="share-model-property">
                                  <div className="row">
                                    <div className="col-4 col-lg-4 col-md-4 col-sm-4  share-model-content mb-2">
                                      <div className="odds-title pb-1">
                                        {t("chartSummary.Odds")}
                                      </div>
                                      <div className="data d-flex">
                                        {eventData[0]?.length == 1 ? (
                                          eventData?.odds[0]?.oc_rate.toFixed(2)
                                        ) : (
                                          <div className="d-flex align-items-center">
                                            {handleOddsReturnData(eventData)}
                                            {eventData?.BetHeadDetail
                                              .statusText == "Returned" ? (
                                              ""
                                            ) : eventData?.isReturnOdds ? (
                                              <div className="ps-1 alert-circle-tooltip-div">
                                                <div className="alert-circle-image-tooltip">
                                                  {t("feed.tooltipMsg")}
                                                </div>
                                                <span className="alert-circle-image d-flex align-items-center">
                                                  <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <path
                                                      fillRule="evenodd"
                                                      clipRule="evenodd"
                                                      d="M11.709 10.795L11.568 10.874C10.92 11.236 10.691 11.422 10.696 11.74C10.701 12.154 10.37 12.494 9.956 12.5H9.946C9.536 12.5 9.201 12.171 9.196 11.76C9.179 10.492 10.216 9.912 10.835 9.566L10.976 9.486C11.545 9.173 11.829 8.739 11.829 8.193C11.829 7.29 11.094 6.556 10.191 6.556C9.272 6.556 8.552 7.275 8.552 8.193C8.552 8.607 8.216 8.943 7.802 8.943C7.388 8.943 7.052 8.607 7.052 8.193C7.052 6.463 8.46 5.056 10.191 5.056C11.921 5.056 13.329 6.463 13.329 8.193C13.329 9.296 12.753 10.22 11.709 10.795ZM9.947 14.96C9.533 14.96 9.197 14.639 9.197 14.225V14.195C9.197 13.781 9.533 13.445 9.947 13.445C10.361 13.445 10.697 13.781 10.697 14.195C10.697 14.609 10.361 14.96 9.947 14.96ZM10 0.5C4.761 0.5 0.5 4.762 0.5 10C0.5 15.238 4.761 19.5 10 19.5C15.238 19.5 19.5 15.238 19.5 10C19.5 4.762 15.238 0.5 10 0.5Z"
                                                      fill="#BFC3D9"
                                                    />
                                                  </svg>
                                                </span>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <div className="col-4 col-lg-4 col-md-4 col-sm-4 share-model-content mb-2">
                                      <div className="predictionlist-coins-title pb-1">
                                        {t("feed.StakedCoins")}
                                      </div>
                                      <div className="data">
                                        {eventData?.betTotal
                                          ? eventData?.betTotal
                                          : "-"}
                                      </div>
                                    </div>
                                    <div className="col-4 col-lg-4 col-md-4 col-sm-4 share-model-content mb-2">
                                      <div className="win-title pb-1">
                                        {t("feed.PossibleWin")}
                                      </div>
                                      <div className="data">
                                        {!eventData?.totalOddsData ||
                                        !eventData?.betTotal
                                          ? "-"
                                          : (
                                              eventData?.totalOddsData *
                                              eventData?.betTotal
                                            ).toFixed(2)}
                                      </div>
                                    </div>
                                    <hr className="share-model-divider-line" />
                                    <div className="col-4 col-lg-4 col-md-4 col-sm-4 share-model-content">
                                      <div className="type-title pb-1">
                                        {t("feed.Type")}
                                      </div>
                                      <div className="data">
                                        {handlePredictionListType(eventData)}
                                      </div>
                                    </div>
                                    <div className="col-4 col-lg-4 col-md-4 col-sm-4 share-model-content">
                                      <div className="status-title pb-1">
                                        {t("feed.Status")}
                                      </div>
                                      <div
                                        className="data status-text"
                                        style={{
                                          backgroundColor:
                                            eventData?.BetHeadDetail
                                              ? eventData?.BetHeadDetail
                                                  ?.statusText === "Won"
                                                ? "#EEFBFB"
                                                : eventData?.BetHeadDetail
                                                    ?.statusText === "Lost"
                                                ? "#F9DADA"
                                                : eventData?.BetHeadDetail
                                                    ?.statusText === "Returned"
                                                ? "#FCF7E4"
                                                : "#F2F3F7"
                                              : "",
                                          color: eventData?.BetHeadDetail
                                            ?.statusText
                                            ? eventData?.BetHeadDetail
                                                ?.statusText === "Won"
                                              ? "#0D393A"
                                              : eventData?.BetHeadDetail
                                                  ?.statusText === "Lost"
                                              ? "#D14343"
                                              : eventData?.BetHeadDetail
                                                  ?.statusText === "Returned"
                                              ? "#C09E16"
                                              : "#131520"
                                            : "#131520",
                                        }}
                                      >
                                        {eventData?.BetHeadDetail?.statusText
                                          ? eventData?.BetHeadDetail
                                              ?.statusText === "Won"
                                            ? t("feed.modelWon")
                                            : eventData?.BetHeadDetail
                                                ?.statusText === "Lost"
                                            ? t("feed.modelLost")
                                            : eventData?.BetHeadDetail
                                                ?.statusText === "Returned"
                                            ? t("feed.modelReturn")
                                            : t("feed.modelPending")
                                          : t("feed.modelPending")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            // ))
                          }

                          {/* sccener ---- */}
                          <div className="scnner-panel">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-end">
                                <div
                                  className="QRCode-div"
                                  style={{ height: "auto" }}
                                >
                                  <QRCode
                                    className="shareModel-QrCode"
                                    size={256}
                                    style={{
                                      height: "auto",
                                      maxWidth: "100%",
                                      width: "100%",
                                      padding: "4px",
                                    }}
                                    value={
                                      process.env.REACT_APP_shareLinkUrl +
                                      `profile/${tournamentData?.userId?.userName}`
                                    }
                                    // viewBox={`0 0 256 256`}
                                  />
                                </div>
                                <div className="joinCapperauditor-message">
                                  {t("feed.joinCapperauditore")}
                                </div>
                              </div>
                              <div className="shareModel-date-time">
                                <div className="pb-2">
                                  {t("feed.PredictionDate")}
                                </div>
                                <div>
                                  {moment
                                    .unix(tournamentData.createdDate)
                                    .format("DD.MM.YYYY")}
                                  {", "}
                                  {moment
                                    .unix(tournamentData.createdDate)
                                    .format("HH:mm")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <div className="">
                <div className={sharemodelFooterTitle}>
                  {t("feed.socialCommunity")}
                </div>
                <div className="share-model-social-media-network">
                  <TwitterShareButton
                    url={shareImageMedia}
                    quote={"twiter-media"}
                    // beforeOnClick={handleShareListInMedia}
                    disabled={shareImageMedia == "" || !shareImageMedia}
                  >
                    <div className="twiter-media">
                      <span>
                        <img
                          src="/twiter.svg"
                          onClick={() => {
                            console.log("click done.");
                          }}
                          alt=""
                        />
                      </span>
                    </div>
                  </TwitterShareButton>
                  <FacebookShareButton
                    url={shareImageMedia}
                    quote={"facebook-media"}
                    disabled={shareImageMedia == "" || !shareImageMedia}
                  >
                    <div className="facebook-media">
                      <span>
                        <img src="/facebook.svg" alt="" />
                      </span>
                    </div>
                  </FacebookShareButton>
                  <TelegramShareButton
                    url={shareImageMedia}
                    quote={"telegram-media"}
                    disabled={shareImageMedia == "" || !shareImageMedia}
                  >
                    <div className="telegram-media">
                      <span>
                        <img src="/telegram.svg" alt="" />
                      </span>
                    </div>
                  </TelegramShareButton>

                  {/* download */}
                  <div>
                    <div
                      className="download-media"
                      onClick={() =>
                        handlePredictionSlipDownload(eventData._id)
                      }
                    >
                      <span>
                        <img src="/download.svg" alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Footer>
          </Modal>

          {/* login model  */}
          <Model
            isModel={predictionModel}
            modelClassName={"home-page-signupPage"}
            hideModel={handlePredictionHide}
            modelTitle={t("homePageSigninPage.SignIn")}
          >
            <div className="userLoginModel">
              {/* --- signinCapperauditor top --- */}
              <div className="top">
                <div className="btn-containt">
                  <button
                    type="button"
                    className="btn  btn-google-facebook btn-google"
                    onClick={() => {
                      window.location.href =
                        process.env.REACT_APP_googleOAuthUrl;
                    }}
                  >
                    <span className="signup-icon">
                      <i className="fab fa-google" />
                    </span>
                    {t("homePageSigninPage.isGoogleBtn")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-google-facebook btn-facebook"
                    onClick={() => {
                      window.location.href =
                        process.env.REACT_APP_facebookOAuthUrl;
                    }}
                  >
                    <span className="signup-icon">
                      <i
                        className="fab fa-facebook-f"
                        style={{ color: "#4267B2" }}
                      ></i>
                    </span>
                    {t("homePageSigninPage.isFabookBtn")}
                  </button>
                </div>
                <div className="text-divider">{t("homePageSigninPage.or")}</div>
              </div>
              {/* ------ signincapperauditor form ------  */}
              <div className="bottom">
                <form>
                  <div className={errorClass}>
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label form-input-label"
                    >
                      {t("homePageSigninPage.emailorusername")}
                    </label>
                    <input
                      type="email"
                      className="form-control form-input-field"
                      id="InputEmail"
                      onChange={handleLoginEmail}
                      placeholder={t(
                        "homePageSigninPage.EnteremailPlaceHolder"
                      )}
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        color: "#7F87B3",
                      }}
                    />
                    <p style={{ fontSize: "14px" }} className={error}>
                      {t("SignIn.Youremailorusernameisincorrect")}
                    </p>
                  </div>
                  <div className="mb-4">
                    <button
                      className="form-control form-input-field-button"
                      id="Inputbutton"
                      onClick={handleEmail}
                      disabled={loginEmail == ""}
                      style={{
                        pointerEvents: loginEmail == "" ? "none" : "auto",
                      }}
                    >
                      {t("homePageSigninPage.Continue")}
                    </button>
                  </div>
                </form>
                {/* ------- signincapperauditor footer -----  */}
                <div className="signin-footer">
                  <span
                    className="signincapperauditor-bottom"
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      lineHeight: "20px",
                    }}
                  >
                    {t("homePageSigninPage.subLine1")}{" "}
                    <Link
                      to="/signup"
                      style={{
                        color: "#4C5480",
                        fontWeight: "600",
                        lineHeight: "24px",
                      }}
                    >
                      {t("homePageSigninPage.subLine2")}
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </Model>
        </div>
      </div>
      {/* </div> */}

      {/* multipul-prediction-model */}
      <div>
        <MorePredicrionModel
          handleOddsReturnData={handleOddsReturnData}
          isModel={morePredictionModel}
          data={seelectedUserData}
          handlePredictionListType={handlePredictionListType}
          hide={() => setMorePredictionModel(false)}
          handleUserProfile={handleUserProfile}
        />
      </div>

      {/* edit-model */}
      <Model
        isModel={editPredictionModel}
        modelClassName={"edit-delete-prediction-model"}
        hideModel={() => setEditPredictionModel(false)}
        modelTitle={t("feed.EditPredictionModelTitle")}
      >
        <div className="editPredictionData-scroll">
          {editPredictionData.odds?.map((item, index) => (
            <div key={index} className="editPredictionData-div">
              <div className="d-flex align-items-center oc_teams_details">
                <div className="both-Team-logo pe-0">
                  <span className="team1-logo">
                    <img
                      src={process.env.REACT_APP_teamLogo + item?.opp_1_icon}
                      height="33.42px"
                      width="33.42px"
                      style={{ borderRadius: "50px" }}
                      alt=""
                    />
                  </span>
                  <span className="team2-logo">
                    <img
                      className="team-2-logo"
                      src={process.env.REACT_APP_teamLogo + item?.opp_2_icon}
                      height="33.42px"
                      width="33.42px"
                      style={{ borderRadius: "50px" }}
                      alt=""
                    />
                  </span>
                </div>
                <div className="both-team-contains">
                  <div className="both-team-name d-flex align-items-center">
                    <div>
                      <span>
                        {apiService.getProfileFromStorage()?.Language == "en"
                          ? item?.opp_1_name_en
                            ? item?.opp_1_name_en
                            : item?.opp_1_name
                          : item?.opp_1_name}
                      </span>
                      <span>-</span>
                      <span>
                        {apiService.getProfileFromStorage()?.Language == "en"
                          ? item?.opp_2_name_en
                            ? item?.opp_2_name_en
                            : item?.opp_2_name
                          : item?.opp_2_name}
                      </span>
                    </div>
                  </div>
                  <div className="both-team-league-name d-flex">
                    {item.oc_group_name === "1x2"
                      ? "Full-time result"
                      : item.oc_group_name}
                    <span className="px-1"> - </span>
                    <span className="both-team-name">{item.oc_name}</span>
                  </div>
                  <div></div>
                </div>
              </div>
              <div
                className="editPrediction-status-score"
                id={`input${item.BetHeadDetail.BetCode}`}
              >
                {/* status drop-down */}
                <div className="PredictionStatus pt-3 w-100">
                  <div className="title pb-1">{t("feed.PredictionStatus")}</div>
                  <div className="sortby-container w-100">
                    <div className="sortby-dropdown">
                      <div
                        className="d-flex selected-fliter ignore-dropdown"
                        id={`a${item.BetHeadDetail.BetCode}`}
                        onClick={() =>
                          handleFilterDropDown(item.BetHeadDetail.BetCode)
                        }
                      >
                        {item?.result?.statusText}
                        <div className="filter-name d-flex align-items-center"></div>
                      </div>
                      {/* down up arrow */}
                      <div className="drop-down-up-down">
                        {dropDownHideShow &&
                        selecedBetCodeData == item.BetHeadDetail.BetCode ? (
                          <img src="/up.svg" alt="" />
                        ) : (
                          <img src="/down.svg" alt="" />
                        )}
                      </div>
                      {/* ----- */}
                      <ul className="fliter-type ignore-dropdown">
                        <div
                          className="list-drop-down"
                          style={{
                            display:
                              selecedBetCodeData !== ""
                                ? selecedBetCodeData ==
                                  item.BetHeadDetail.BetCode
                                  ? "block"
                                  : "none"
                                : "none",
                          }}
                        >
                          <li
                            className="d-flex filter-menu"
                            onClick={() =>
                              handleFilterData(
                                t("feed.Pending"),
                                item.BetHeadDetail.BetCode
                              )
                            }
                          >
                            <div className="filter-name">
                              {t("feed.Pending")}
                            </div>
                          </li>
                          <li
                            className="d-flex filter-menu"
                            onClick={() =>
                              handleFilterData(
                                t("feed.Won"),
                                item.BetHeadDetail.BetCode
                              )
                            }
                          >
                            <div className="filter-name ">{t("feed.Won")}</div>
                          </li>
                          <li
                            className="d-flex filter-menu"
                            onClick={() =>
                              handleFilterData(
                                t("feed.statusLost"),
                                item.BetHeadDetail.BetCode
                              )
                            }
                          >
                            <div className="filter-name ">
                              {t("feed.statusLost")}
                            </div>
                          </li>
                          <li
                            className="d-flex filter-menu"
                            onClick={() =>
                              handleFilterData(
                                t("feed.dropDownReturned"),
                                item.BetHeadDetail.BetCode
                              )
                            }
                          >
                            <div className="filter-name ">
                              {t("feed.dropDownReturned")}
                            </div>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* score */}
                <div className="score-input-feild pt-2">
                  <div className="inputfield-label">{t("feed.Score")}</div>
                  <div className="input-toggle">
                    <input
                      type="text"
                      className="form-control inputfield-tab"
                      id={`input${item.BetHeadDetail.BetCode}`}
                      defaultValue={item?.result.score}
                      onChange={(e) =>
                        handleEditScore(e, item.BetHeadDetail.BetCode)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="delete-sportModel-Buttons">
          <InputButton
            type={"secondary"}
            inputLabel={t("profile.saveCancel")}
            addBtnClass={"deleteDiscardBtn"}
            onClick={() => setEditPredictionModel(false)}
          />
          <InputButton
            type={"primary"}
            inputLabel={t("setting.saveChanges")}
            addBtnClass={"deleteConfirmBtn"}
            onClick={handleEditSaveChanges}
          />
        </div>
      </Model>
      {/* delete model */}

      <Model
        isModel={deletePredictionModel}
        modelClassName={"edit-delete-prediction-model"}
        hideModel={() => setDeletePredictionModel(false)}
        modelTitle={t("feed.DeletePredictionTitle")}
      >
        <div>
          <div className="delete-prediction-message">
            {t("feed.DeletePredictionMessage")}
          </div>
          {Object.keys(deleteItemsData).length > 0 ? (
            <div>
              <div className="d-flex align-items-center oc_teams_details pt-3">
                <div className="d-flex align-items-center justify-content-between width-mobile-media pb-2">
                  <div className="both-Team-logo">
                    <span className="team1-logo">
                      <img
                        src={
                          process.env.REACT_APP_teamLogo +
                          deleteItemsData?.odds[0]?.opp_1_icon
                        }
                        height="33.42px"
                        width="33.42px"
                        style={{ borderRadius: "50px" }}
                        alt=""
                      />
                    </span>
                    <span className="team2-logo">
                      <img
                        className="team-2-logo"
                        src={
                          process.env.REACT_APP_teamLogo +
                          deleteItemsData?.odds[0]?.opp_2_icon
                        }
                        height="33.42px"
                        width="33.42px"
                        style={{ borderRadius: "50px" }}
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="mobile-prediction-result  single-prediction-result">
                    {deleteItemsData?.odds[0]?.result.score
                      ? deleteItemsData?.odds[0]?.result.score?.split("(")[0]
                      : "-:-"}
                  </div>
                </div>
                <div className="both-team-contains">
                  <div className="both-team-name d-flex align-items-center">
                    <div>
                      <span>
                        {apiService.getProfileFromStorage()?.Language == "en"
                          ? deleteItemsData?.odds[0]?.opp_1_name_en
                            ? deleteItemsData?.odds[0]?.opp_1_name_en
                            : deleteItemsData?.odds[0]?.opp_1_name
                          : deleteItemsData?.odds[0]?.opp_1_name}
                      </span>
                      <span>-</span>
                      <span>
                        {apiService.getProfileFromStorage()?.Language == "en"
                          ? deleteItemsData?.odds[0]?.opp_2_name_en
                            ? deleteItemsData?.odds[0]?.opp_2_name_en
                            : deleteItemsData?.odds[0]?.opp_2_name
                          : deleteItemsData?.odds[0]?.opp_2_name}
                      </span>
                    </div>
                    {deleteItemsData?.odds.length > 1 && (
                      <div className="more-events-label-div">
                        +{deleteItemsData?.odds.length - 1}{" "}
                        {t("profile.events")}
                      </div>
                    )}
                  </div>
                  {deleteItemsData?.odds.length == 1 && (
                    <div className="both-team-league-name d-flex">
                      {deleteItemsData?.odds[0]?.oc_group_name === "1x2"
                        ? "Full-time result"
                        : deleteItemsData?.odds[0]?.oc_group_name}
                      <span className="px-1"> - </span>
                      <span className="both-team-name">
                        {deleteItemsData?.odds[0]?.oc_name}
                      </span>
                    </div>
                  )}
                  <div></div>
                </div>
              </div>
              <div className="delete-sportModel-Buttons">
                <InputButton
                  type={"secondary"}
                  inputLabel={t("error.avatarDeleteDiscard")}
                  addBtnClass={"deleteDiscardBtn"}
                  onClick={() => setDeletePredictionModel(false)}
                />
                <InputButton
                  type={"primary"}
                  inputLabel={t("error.avatarDeleteConfirm")}
                  addBtnClass={"deleteConfirmBtn"}
                  onClick={() =>
                    handleDeletePredictionData(deleteItemsData._id)
                  }
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Model>

      <div className="bottom-text-section predictions__bottom-text-section">
        <h1>{t("predictions.bottomTextTitle")}</h1>
        <p>
          {t("predictions.bottomTextDescription", {
            count: getThousandsNumber(
              props.totalBetsData,
              localStorage.getItem("language")
            ),
          })}
        </p>
      </div>
    </>
  );
};
export default PredictionList;
