import React from "react";

import "./SkeletonGridBody.css";

const SkeletonGridBody = ({ count, isMobile }) => {
  return (
    <>
      {Array(count)
        .fill(0)
        .map((item, index) => {
          return (
            <tr key={index}>
              <td className="user-place-cell">
                <div className="cell-wrapper-center">
                  <div className="skeleton-grid-placeholder circle"></div>
                </div>
              </td>
              <td className="width">
                <div className="user__info">
                  <div className="skeleton-grid-placeholder circle-md"></div>
                  <div className="skeleton-grid-placeholder lg"></div>
                </div>
              </td>
              {!isMobile && (
                <>
                  <td className="width-md">
                    <div className="skeleton-grid-placeholder"></div>
                  </td>
                  <td className="width-md">
                    <div className="skeleton-grid-placeholder"></div>
                  </td>
                  <td className="width-md">
                    <div className="skeleton-grid-placeholder"></div>
                  </td>
                  <td className="width-md">
                    <div className="skeleton-grid-placeholder"></div>
                  </td>
                </>
              )}
              <td className="width-md_plus">
                <div className="skeleton-grid-placeholder md"></div>
              </td>
            </tr>
          );
        })}
    </>
  );
};

export default SkeletonGridBody;
