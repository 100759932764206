import React from 'react'
import { useNavigate } from 'react-router'
import HomePageHeader from '../homepage/HomePageHeader/HomePageHeader';
import './errorpage.css'

const ErrorPage = () => {
    const navigate = useNavigate();
    const handleTakeHomePage = () => {
        navigate('/')
    }
    return (
        <>
            <HomePageHeader />
            <div className='error-page-container'>
                <div className='error-image-wrapper'>
                    <img className='error-image' src="/Error-image.svg" alt="Error" />
                </div>
                <div className='error-content'>
                    Page not found
                </div>
                <div className='error-desc'>
                    Sorry, the page you are looking for doesn’t exist or has been moved.
                </div>

                <div className='take-me-home-btn'>
                    <button className='take-me-home-btn-desc' onClick={handleTakeHomePage}>
                        Take me home
                    </button>
                </div>
            </div>

        </>
    )
}

export default ErrorPage