import React from "react";

import "./HorizontalChart.css";

const HorizontalChart = () => {
  return (
    <div className="horizontal-chart-wrapper">
      <div className="chart-title">Діапазон коефіцієнтів</div>
      <div className="scale">
        <div className="scale-item min-scale-item">
          <div className="scale-number">1</div>
          <div className="scale-delimeter"></div>
          <div className="scale-title">Мін</div>
        </div>
        <div className="scale-item middle-scale-item">
          <div className="scale-number">3</div>
          <div className="scale-delimeter"></div>
          <div className="scale-title">Середній</div>
        </div>
        <div className="scale-item max-scale-item">
          <div className="scale-number">6</div>
          <div className="scale-delimeter"></div>
          <div className="scale-title">Макс</div>
        </div>
        <div className="scale-progress"></div>
      </div>
      <div className="chart-area-wrapper">
        <div className="chart-area-inner">
          <div className="chart-area-y">
            <div className="chart-area-y__item">3.00+</div>
            <div className="chart-area-y__item">2.51 - 3.00</div>
            <div className="chart-area-y__item">2.01 - 2.50</div>
            <div className="chart-area-y__item">1.51 - 2.00</div>
            <div className="chart-area-y__item">1.01 - 1.50</div>
          </div>
          <div className="chart-area">
            <div className="chart-area__item"></div>
            <div className="chart-area__item"></div>
            <div className="chart-area__item"></div>
            <div className="chart-area__item"></div>
            <div className="chart-area__item"></div>

            <div className="chart-area-lines">
              <div className="chart-area-line"></div>
              <div className="chart-area-line"></div>
              <div className="chart-area-line"></div>
              <div className="chart-area-line"></div>
            </div>
          </div>
        </div>
        <div className="chart-area-x">
          <div className="chart-area-x__item">0%</div>
          <div className="chart-area-x__item">25%</div>
          <div className="chart-area-x__item">50%</div>
          <div className="chart-area-x__item">100%</div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalChart;
