import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

const SportlineSidebarLoader = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="sidebar__loader-wrapper">
      <div className="popular-leagues__title">
        {t("profile.popularLeagues").toUpperCase()}
      </div>
      {Array(8)
        .fill(0)
        .map((item, index) => {
          return (
            <div className="sidebar__loader-popular-item-wrapper" key={index}>
              <div className="sidebar__loader-item-right">
                <div className="sportline-sidebar-skeleton-placeholder country"></div>
                <div className="sportline-sidebar-skeleton-placeholder"></div>
              </div>
              <div className="sportline-sidebar-skeleton-placeholder counter"></div>
            </div>
          );
        })}
      <div className="sidebar__title">{t("allSports")}</div>

      <div className='all-sports-loader-wrapper'>
        {Array(12)
          .fill(0)
          .map((item, index) => {
            return (
              <div className="sidebar__loader-item-wrapper" key={index}>
                <div className="sidebar__loader-item-right">
                  <div className="sportline-sidebar-skeleton-placeholder country"></div>
                  <div className="sportline-sidebar-skeleton-placeholder"></div>
                </div>
                <div className="sportline-sidebar-skeleton-placeholder counter"></div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SportlineSidebarLoader;
