import React from "react";
import { Navigate } from "react-router-dom";
import apiService from "../services/apiService";

export default function Protected(props) {
  const { Component } = props;

  const profData = apiService.getUserProfileCheckAndSet();
  let isLoggedIn = localStorage.getItem("token") && profData;

  return (
    isLoggedIn ? <Component /> : <Navigate to="/signin" />

  );
}
