export const PredictionType = {
  en: {
    1: {
      "Full time result": "1x2",
      "Double Chance": "Double Chance",
      Handicap: "Handicap",
      Total: "Total",
      "Total 1": "Total 1",
      "Total 2": "Total 2",
      "HT-FT": "HT-FT",
      "Score Draw": "Score Draw",
      "Both Teams To Score": "Both Teams To Score",
      "Even/Odd": "Even/Odd",
      "Scores In Each Half": "Scores In Each Half",
      "Team 2, Result + Total": "Team 2, Result + Total",
      "Team 2 Win To Nil": "Team 2 Win To Nil",
      "Team 1, Result + Total": "Team 1, Result + Total",
      "Team 1 Win To Nil": "Team 1 Win To Nil",
      "Draw + Total": "Draw + Total",
      "Next Goal": "Next Goal",
      "European Handicap": "European Handicap",
      "Last Goal": "Last Goal",
      "Goal In Both Halves": "Goal In Both Halves",
      "First Goal Time": "First Goal Time",
      "Second Goal Time": "Second Goal Time",
      "Team 1 To Score A Goal In Both Halves":
        "Team 1 To Score A Goal In Both Halves",
      "Team 2 To Score A Goal In Both Halves":
        "Team 2 To Score A Goal In Both Halves",
      "Team 1 To Score Their Goal In Which Half":
        "Team 1 To Score Their Goal In Which Half",
      "Team 2 To Score Their Goal In Which Half":
        "Team 2 To Score Their Goal In Which Half",
      "To Come From Behind And Win - Team 1":
        "To Come From Behind And Win - Team 1",
      "To Come From Behind And Win - Team 2":
        "To Come From Behind And Win - Team 2",
      "Team 1 To Win By Exactly One Goal Or To Draw":
        "Team 1 To Win By Exactly One Goal Or To Draw",
      "Team 2 To Win By Exactly One Goal Or To Draw":
        "Team 2 To Win By Exactly One Goal Or To Draw",
      "Team 1 To Win Either Half": "Team 1 To Win Either Half",
      "Team 2 To Win Either Half": "Team 2 To Win Either Half",
      "Team 1 To Win Both Halves": "Team 1 To Win Both Halves",
      "Team 2 To Win Both Halves": "Team 2 To Win Both Halves",
      "Total Goals By Halves": "Total Goals By Halves",
      "Draw In At Least One Half": "Draw In At Least One Half",
      "Penalty Awarded": "Penalty Awarded",
      "Sending Off": "Sending Off",
      "Own Goal": "Own Goal",
      "Race To": "Race To",
      "Goal In Half": "Goal In Half",
      "Period Of First Goal": "Period Of First Goal",
      "Half/Half": "Half/Half",
      "Correct Score": "Correct Score",
      "3Way Total": "3Way Total",
      "Individual 3Way Total 1": "Individual 3Way Total 1",
      "Individual Total 1 Even/Odd": "Individual Total 1 Even/Odd",
      "Individual 3Way Total 2": "Individual 3Way Total 2",
      "Individual Total 2 Even/Odd": "Individual Total 2 Even/Odd",
      "Goal Up To Minute": "Goal Up To Minute",
      "Team 1 To Score Goal N From Minute To Minute":
        "Team 1 To Score Goal N From Minute To Minute",
      "Team 2 To Score Goal N From Minute To Minute":
        "Team 2 To Score Goal N From Minute To Minute",
      "How Goal Will Be Scored": "How Goal Will Be Scored",
      "Total Goal Minutes": "Total Goal Minutes",
      "Last Goal Time": "Last Goal Time",
      "Goal After Minute - Yes/No": "Goal After Minute - Yes/No",
      "Penalty Awarded And Sending Off": "Penalty Awarded And Sending Off",
      "Substitute Player To Score": "Substitute Player To Score",
      "A Player Scores Two Goals (Brace)": "A Player Scores Two Goals (Brace)",
      "A Player Scores A Hat-Trick": "A Player Scores A Hat-Trick",
      "Team 1 Time In The Lead (min)": "Team 1 Time In The Lead (min)",
      "Team 2 Time In The Lead (min)": "Team 2 Time In The Lead (min)",
      "Draw Duration (min)": "Draw Duration (min)",
      "To Score A Short-Handed Goal": "To Score A Short-Handed Goal",
      "Team 1 Player To Get Red Card": "Team 1 Player To Get Red Card",
      "Team 2 Player To Get Red Card": "Team 2 Player To Get Red Card",
      "Team 1 To Score Goals In A Row": "Team 1 To Score Goals In A Row",
      "Team 2 To Score Goals In A Row": "Team 2 To Score Goals In A Row",
      "Draw And Total Corners Under/Over": "Draw And Total Corners Under/Over",
      "Team To Win And Total Corners Under/Over":
        "Team To Win And Total Corners Under/Over",
      "Total Each Team Will Score Under/Over":
        "Total Each Team Will Score Under/Over",
      "Result In Minute": "Result In Minute",
      "Win Or Draw In Minute": "Win Or Draw In Minute",
      "Total In Minute": "Total In Minute",
      "Injury Time Goal": "Injury Time Goal",
      "HT-FT + Total": "HT-FT + Total",
      "Goal In Time Interval": "Goal In Time Interval",
      "Team 1 To Score N Goals": "Team 1 To Score N Goals",
      "Team 2 To Score N Goals": "Team 2 To Score N Goals",
      "Win To Nil": "Win To Nil",
      "Any Team To Come From Behind And Win":
        "Any Team To Come From Behind And Win",
      "At Least One Team Will Not Score + Total":
        "At Least One Team Will Not Score + Total",
      "Win By": "Win By",
      "Number In The Score": "Number In The Score",
      "Result And Both Teams To Score": "Result And Both Teams To Score",
      "Draw In Both Halves": "Draw In Both Halves",
      "Teams, Goal Time": "Teams, Goal Time",
      "Result / Teams To Score": "Result / Teams To Score",
      "Team 1 To Score Their First Goal In Time Interval":
        "Team 1 To Score Their First Goal In Time Interval",
      "Team 2 To Score Their First Goal In Time Interval":
        "Team 2 To Score Their First Goal In Time Interval",
      "Team 1 To Score Their Last Goal In Time Interval":
        "Team 1 To Score Their Last Goal In Time Interval",
      "Team 2 To Score Their Last Goal In Time Interval":
        "Team 2 To Score Their Last Goal In Time Interval",
      "Team 1 First Goal In Interval": "Team 1 First Goal In Interval",
      "Team 2 First Goal In Interval": "Team 2 First Goal In Interval",
      "Team And Goal Time": "Team And Goal Time",
      "To Keep Clean Sheet": "To Keep Clean Sheet",
      "First Goal To Be An Own Goal": "First Goal To Be An Own Goal",
      "Team 1 Scores In Halves": "Team 1 Scores In Halves",
      "Team 2 Scores In Halves": "Team 2 Scores In Halves",
      "Team Goals": "Team Goals",
      "Both Teams To Score Yes/No + Total":
        "Both Teams To Score Yes/No + Total",
      "Both Teams To Score In Both Halves":
        "Both Teams To Score In Both Halves",
      "Team 1, First Goal In Interval": "Team 1, First Goal In Interval",
      "Team 2, First Goal In Interval": "Team 2, First Goal In Interval",
      "Goal In Time Interval - Yes/No": "Goal In Time Interval - Yes/No",
      "Goal Interval - Yes": "Goal Interval - Yes",
      "Goal Interval - No": "Goal Interval - No",
      "Total And Both To Score": "Total And Both To Score",
      "Goal Scored By Kicking": "Goal Scored By Kicking",
      "Headed Goal": "Headed Goal",
      "Goal Directly From Free Kick": "Goal Directly From Free Kick",
      "Goal After Corner": "Goal After Corner",
      "Asian Total": "Asian Total",
      "Asian Handicap": "Asian Handicap",
      "W1 + Total 1": "W1 + Total 1",
      "W2 + Total 2": "W2 + Total 2",
      "Outcome + Number Of Goals": "Outcome + Number Of Goals",
      "Outcome Of The First Half Or The Match":
        "Outcome Of The First Half Or The Match",
      "Match Result + First Half Total": "Match Result + First Half Total",
      "Total By Halves": "Total By Halves",
      "Team 1 To Score Penalty": "Team 1 To Score Penalty",
      "Team 2 To Score Penalty": "Team 2 To Score Penalty",
      Fouls: "Fouls",
      "Both Halves To Be Won By Different Teams":
        "Both Halves To Be Won By Different Teams",
      "Exact Number": "Exact Number",
      "Individual Total 1 Exact Number Of Goals":
        "Individual Total 1 Exact Number Of Goals",
      "Individual Total 2 Exact Number Of Goals":
        "Individual Total 2 Exact Number Of Goals",
      "Any Team To Win By": "Any Team To Win By",
      "Goals In A Row By A Single Team": "Goals In A Row By A Single Team",
      "Team 1 To Lead In The Match And Not To Lose The Match":
        "Team 1 To Lead In The Match And Not To Lose The Match",
      "Team 2 To Lead In The Match And Not To Lose The Match":
        "Team 2 To Lead In The Match And Not To Lose The Match",
      "Goalkeeper To Be Sent Off": "Goalkeeper To Be Sent Off",
      "Team To Be Losing During The Match + Result":
        "Team To Be Losing During The Match + Result",
      "Who Will Score Goal": "Who Will Score Goal",
      "Multi Goal": "Multi Goal",
      "Asian Team Total1": "Asian Team Total1",
      "Asian Team Total2": "Asian Team Total2",
      "correct score": "correct score",
    },
    3: {
      Handicap: "Handicap",
      Total: "Total",
      "Total 1": "Total 1",
      "Total 2": "Total 2",
      "Even/Odd": "Even/Odd",
      "Team 2, Result + Total": "Team 2, Result + Total",
      "Team 1, Result + Total": "Team 1, Result + Total",
      "Team Wins": "Team Wins",
      "European Handicap": "European Handicap",
      "Individual Total 1 Even/Odd": "Individual Total 1 Even/Odd",
      "Individual Total 2 Even/Odd": "Individual Total 2 Even/Odd",
      "Highest Scoring Period": "Highest Scoring Period",
      "Highest Scoring Half": "Highest Scoring Half",
      "Which Team Will Be The First To Score Points?":
        "Which Team Will Be The First To Score Points?",
      "Team To Win All Quarters": "Team To Win All Quarters",
      "Team To Win Both Halves": "Team To Win Both Halves",
      "Team To Win 1st Half And The Match":
        "Team To Win 1st Half And The Match",
      "Team To Win 1st Quarter And The Match":
        "Team To Win 1st Quarter And The Match",
      "Total Each Team Will Score Under/Over":
        "Total Each Team Will Score Under/Over",
      "First Points To Be Scored By": "First Points To Be Scored By",
      "Last Points To Be Scored By": "Last Points To Be Scored By",
      Timeout: "Timeout",
      "Team's Score in Quarter": "Team's Score in Quarter",
      "Which Team Will Score Points Last?":
        "Which Team Will Score Points Last?",
      "Number In The Score": "Number In The Score",
      "Exact Points Difference.": "Exact Points Difference.",
      "Total Of Drawn Quarters": "Total Of Drawn Quarters",
      "First Half / Full-time": "First Half / Full-time",
      "Win By Quarters": "Win By Quarters",
      "Any Team To Win By": "Any Team To Win By",
      "1X2 In Regular Time": "1X2 In Regular Time",
      "Regular Time Double Chance": "Regular Time Double Chance",
      "W1 + Total 1": "W1 + Total 1",
      "W2 + Total 2": "W2 + Total 2",
      "Each Quarter Over": "Each Quarter Over",
      "Each Quarter Under": "Each Quarter Under",
      "Each Half Over": "Each Half Over",
      "Each Half Under": "Each Half Under",
      "Handicap 1 + Total": "Handicap 1 + Total",
      "Handicap 2 + Total": "Handicap 2 + Total",
      "How Many Quarters Will Team 1 Win": "How Many Quarters Will Team 1 Win",
      "How Many Quarters Will Team 2 Win": "How Many Quarters Will Team 2 Win",
      "Race To Points (2Way)": "Race To Points (2Way)",
      "Draw In One Of The Halves": "Draw In One Of The Halves",
      "Leader After The First Quarter To Win The Match":
        "Leader After The First Quarter To Win The Match",
      "Team 1, Total Quarters With The Same Number Of Points":
        "Team 1, Total Quarters With The Same Number Of Points",
      "Team 2, Total Quarters With The Same Number Of Points":
        "Team 2, Total Quarters With The Same Number Of Points",
      "Total Quarters With The Same Number Of Points":
        "Total Quarters With The Same Number Of Points",
      "Team Performance In Half": "Team Performance In Half",
      "Leader After First Half To Win The Match":
        "Leader After First Half To Win The Match",
      "Win By Halves": "Win By Halves",
      "How Many Quarters In A Row Will Team 1 Win":
        "How Many Quarters In A Row Will Team 1 Win",
      "How Many Quarters In A Row Will Team 2 Win":
        "How Many Quarters In A Row Will Team 2 Win",
      "How Many Quarters In A Row Will Either Team Win":
        "How Many Quarters In A Row Will Either Team Win",
      "Smallest Winning Margin At Any Break Between Quarters":
        "Smallest Winning Margin At Any Break Between Quarters",
      "Largest Winning Margin At Any Break Between Quarters":
        "Largest Winning Margin At Any Break Between Quarters",
    },
    4: {
      "Full time result": "1x2",
      Handicap: "Handicap",
    },
    6: {
      "Full time result": "1x2",
      Handicap: "Handicap",
      Total: "Total",
      "Total 1": "Total 1",
      "Total 2": "Total 2",
      "Even/Odd": "Even/Odd",
      "Team 2, Result + Total": "Team 2, Result + Total",
      "Team 1, Result + Total": "Team 1, Result + Total",
      "Correct Score": "Correct Score",
      "Sets Handicap": "Sets Handicap",
      "Exact Total Sets": "Exact Total Sets",
      "Score After Sets": "Score After Sets",
      "Total Sets": "Total Sets",
      "Match Point Total": "Match Point Total",
      "Score After X Sets": "Score After X Sets",
      "Set / Match": "Set / Match",
      "Sets Scoring": "Sets Scoring",
      "Sets With The Same Total": "Sets With The Same Total",
      "How Many Sets In A Row Will A Team Win":
        "How Many Sets In A Row Will A Team Win",
    },
    7: {
      "Full time result": "1x2",
      "Double Chance": "Double Chance",
      Handicap: "Handicap",
      "HT-FT": "HT-FT",
      "Even/Odd": "Even/Odd",
      "Scores In Each Half": "Scores In Each Half",
      "Team Wins": "Team Wins",
      "European Handicap": "European Handicap",
      "Team 1 To Win Either Half": "Team 1 To Win Either Half",
      "Team 2 To Win Either Half": "Team 2 To Win Either Half",
      "Team 1 To Win Both Halves": "Team 1 To Win Both Halves",
      "Team 2 To Win Both Halves": "Team 2 To Win Both Halves",
      "Individual Total 1 Even/Odd": "Individual Total 1 Even/Odd",
      "Individual Total 2 Even/Odd": "Individual Total 2 Even/Odd",
      "Which Team Will Be The First To Score Points?":
        "Which Team Will Be The First To Score Points?",
      "Result In Minute": "Result In Minute",
      "Win To Nil": "Win To Nil",
      "Race To": "Race To",
      "Which Team Will Score Points Last?":
        "Which Team Will Score Points Last?",
      "Next Try": "Next Try",
      "Exact Points Difference...": "Exact Points Difference...",
      "Team Will Not Be Losing": "Team Will Not Be Losing",
      "To Win By (3Way).": "To Win By (3Way).",
      "Win By.": "Win By.",
      "Win By...": "Win By...",
      "Win By....": "Win By....",
      "HT-FT To Win By": "HT-FT To Win By",
      "Will 1st Try Be Converted": "Will 1st Try Be Converted",
      "Half With Most Tries": "Half With Most Tries",
      "Total Tries Even/Odd": "Total Tries Even/Odd",
      "Team To Score Last Try": "Team To Score Last Try",
      "Team To Score First And Win": "Team To Score First And Win",
      "HT-FT (5Way)": "HT-FT (5Way)",
      "Either Team To Win Both Halves": "Either Team To Win Both Halves",
      "Shirt Of First Try Scorer": "Shirt Of First Try Scorer",
      "Shirt Of Last Try Scorer": "Shirt Of Last Try Scorer",
      "1st Try / Result": "1st Try / Result",
      "Score First / 1st Half Result / Match Outcome":
        "Score First / 1st Half Result / Match Outcome",
      "1st Try / 1st Half Result": "1st Try / 1st Half Result",
      "Team Performance In Half": "Team Performance In Half",
      "First Points And Winner": "First Points And Winner",
    },
    8: {
      "Full time result": "1x2",
      "Double Chance": "Double Chance",
      Handicap: "Handicap",
      Total: "Total",
      "Total 1": "Total 1",
      "Total 2": "Total 2",
      "Even/Odd": "Even/Odd",
      "Scores In Each Half": "Scores In Each Half",
      "European Handicap": "European Handicap",
      "Individual 3Way Total 1": "Individual 3Way Total 1",
      "Individual Total 1 Even/Odd": "Individual Total 1 Even/Odd",
      "Individual 3Way Total 2": "Individual 3Way Total 2",
      "Individual Total 2 Even/Odd": "Individual Total 2 Even/Odd",
      "Which Team Will Be The First To Score Points?":
        "Which Team Will Be The First To Score Points?",
      "Team 1 To Score N Goals": "Team 1 To Score N Goals",
      "Team 2 To Score N Goals": "Team 2 To Score N Goals",
      "Exact Points Difference.": "Exact Points Difference.",
      "Asian Total": "Asian Total",
      "Asian Handicap": "Asian Handicap",
      SuperTotal: "SuperTotal",
      SuperHandicap: "SuperHandicap",
      // "Sweden, Top Goalscorer In The Tournament": "Sweden, Top Goalscorer In The Tournament"
    },
    9: {
      "Full time result": "1x2",
    },
    12: {
      "Full time result": "1x2",
    },
    13: {
      "Full time result": "1x2",
      Handicap: "Handicap",
      Total: "Total",
      "Total 1": "Total 1",
      "Total 2": "Total 2",
      "Even/Odd": "Even/Odd",
      "Team 2, Result + Total": "Team 2, Result + Total",
      "Team 2 Win To Nil": "Team 2 Win To Nil",
      "Team 1, Result + Total": "Team 1, Result + Total",
      "European Handicap": "European Handicap",
      "3Way Total": "3Way Total",
      "Individual 3Way Total 1": "Individual 3Way Total 1",
      "Individual Total 1 Even/Odd": "Individual Total 1 Even/Odd",
      "Will There Be Overtime? - Yes/No": "Will There Be Overtime? - Yes/No",
      "Individual 3Way Total 2": "Individual 3Way Total 2",
      "Individual Total 2 Even/Odd": "Individual Total 2 Even/Odd",
      "Highest Scoring Period": "Highest Scoring Period",
      "Highest Scoring Half": "Highest Scoring Half",
      "Which Team Will Be The First To Score Points?":
        "Which Team Will Be The First To Score Points?",
      "Team To Win Both Halves": "Team To Win Both Halves",
      "Race To": "Race To",
      Timeout: "Timeout",
      "Team's Score in Quarter": "Team's Score in Quarter",
      "Which Team Will Score Points Last?":
        "Which Team Will Score Points Last?",
      "Lowest Scoring Quarter": "Lowest Scoring Quarter",
      "First Half / Full-time": "First Half / Full-time",
      Safety: "Safety",
      "Next Field Goal": "Next Field Goal",
      "Total Touchdowns (3Way)": "Total Touchdowns (3Way)",
      "Total Touchdowns (2Way)": "Total Touchdowns (2Way)",
      "Total Scored Field Goals (3Way)": "Total Scored Field Goals (3Way)",
      "Total Scored Field Goals (2Way)": "Total Scored Field Goals (2Way)",
      "To Win By (3Way).": "To Win By (3Way).",
      "First To Happen": "First To Happen",
      "Longest Touchdown In The Match": "Longest Touchdown In The Match",
      "Longest Field Goal In The Match": "Longest Field Goal In The Match",
      "3 Straight Scores By Either Team": "3 Straight Scores By Either Team",
      "Result Of Drive": "Result Of Drive",
      "Team 1 To Score In Each Quarter": "Team 1 To Score In Each Quarter",
      "Team 2 To Score In Each Quarter": "Team 2 To Score In Each Quarter",
      "Shortest Touchdown Scored (Yards)": "Shortest Touchdown Scored (Yards)",
      "Longest Touchdown Scored": "Longest Touchdown Scored",
      "Longest Field Goal Scored": "Longest Field Goal Scored",
      "Team 1, Total Touchdowns": "Team 1, Total Touchdowns",
      "Team 2, Total Touchdowns": "Team 2, Total Touchdowns",
      "Team 1, Total Scored Field Goals": "Team 1, Total Scored Field Goals",
      "Team 2, Total Scored Field Goals": "Team 2, Total Scored Field Goals",
      "Team To Take First Instant Replay": "Team To Take First Instant Replay",
      "Win By....": "Win By....",
      "Handicap 1 + Total": "Handicap 1 + Total",
      "Handicap 2 + Total": "Handicap 2 + Total",
      "Both Teams To Score A Number Of Points":
        "Both Teams To Score A Number Of Points",
      "Team To Score First And Win": "Team To Score First And Win",
      "Which Team Will Score First And Win":
        "Which Team Will Score First And Win",
      "Which Team Will Score First And Fail To Win":
        "Which Team Will Score First And Fail To Win",
      "Leader After Each Quarter": "Leader After Each Quarter",
      "Type Of Score (3Way)": "Type Of Score (3Way)",
      "Type Of Score (6Way)": "Type Of Score (6Way)",
      "Total Touchdowns": "Total Touchdowns",
      "Total Touchdowns By Each Team In Each Half":
        "Total Touchdowns By Each Team In Each Half",
      "Last Scoring Play": "Last Scoring Play",
      "Team 1, First Scoring Play": "Team 1, First Scoring Play",
      "Team 2, First Scoring Play": "Team 2, First Scoring Play",
      "Total Field Goals Scored": "Total Field Goals Scored",
      "Total Field Goals Scored By Team": "Total Field Goals Scored By Team",
      "Total Touchdowns Scored By Team": "Total Touchdowns Scored By Team",
      "Time Of First Touchdown": "Time Of First Touchdown",
      "Time Of First Field Goal": "Time Of First Field Goal",
      "Team To Score In Both Halves": "Team To Score In Both Halves",
      "Team To Commit First Accepted Penalty":
        "Team To Commit First Accepted Penalty",
      "Time Of First Score": "Time Of First Score",
      "Total Touchdowns Scored By Team (3Way)":
        "Total Touchdowns Scored By Team (3Way)",
      "Total Field Goals Scored By Team (3Way)":
        "Total Field Goals Scored By Team (3Way)",
      "Successful 2-Point Conversion": "Successful 2-Point Conversion",
    },
    14: {
      "Who Will Win? - Yes/No": "Who Will Win? - Yes/No",
    },
    16: {
      "Full time result": "1x2",
      "Double Chance": "Double Chance",
      Handicap: "Handicap",
      Total: "Total",
      "Total 1": "Total 1",
      "Total 2": "Total 2",
      "Both Teams To Score": "Both Teams To Score",
      "Even/Odd": "Even/Odd",
      "Scored Goal": "Scored Goal",
      "Team 2, Result + Total": "Team 2, Result + Total",
      "Team 2 Win To Nil": "Team 2 Win To Nil",
      "Team 1, Result + Total": "Team 1, Result + Total",
      "Team 1 Win To Nil": "Team 1 Win To Nil",
      "Draw + Total": "Draw + Total",
      "Next Goal": "Next Goal",
      "Team Wins": "Team Wins",
      "Last Goal": "Last Goal",
      "First Goal Time": "First Goal Time",
      "To Come From Behind And Win - Team 1":
        "To Come From Behind And Win - Team 1",
      "To Come From Behind And Win - Team 2":
        "To Come From Behind And Win - Team 2",
      "Race To": "Race To",
      "Correct Score": "Correct Score",
      "Individual Total 1 Even/Odd": "Individual Total 1 Even/Odd",
      "Will There Be Overtime? - Yes/No": "Will There Be Overtime? - Yes/No",
      "Individual Total 2 Even/Odd": "Individual Total 2 Even/Odd",
      "Scoring Periods": "Scoring Periods",
      "Last Goal Time": "Last Goal Time",
      "Team To Score In Each Period - Yes/No":
        "Team To Score In Each Period - Yes/No",
      "A Player Scores Two Goals (Brace)": "A Player Scores Two Goals (Brace)",
      "A Player Scores A Hat-Trick": "A Player Scores A Hat-Trick",
      "Highest Scoring Period": "Highest Scoring Period",
      "Team 1 Time In The Lead (min)": "Team 1 Time In The Lead (min)",
      "Team 2 Time In The Lead (min)": "Team 2 Time In The Lead (min)",
      "Draw Duration (min)": "Draw Duration (min)",
      "Team 1, First Goal + Match Result": "Team 1, First Goal + Match Result",
      "Team 2, First Goal + Match Result": "Team 2, First Goal + Match Result",
      "To Score A Short-Handed Goal": "To Score A Short-Handed Goal",
      "To Score An Empty Net Goal": "To Score An Empty Net Goal",
      "Total Goal Minutes": "Total Goal Minutes",
      "Team 1 To Score Goals In A Row": "Team 1 To Score Goals In A Row",
      "Team 2 To Score Goals In A Row": "Team 2 To Score Goals In A Row",
      "Total Each Team Will Score Under/Over":
        "Total Each Team Will Score Under/Over",
      "Result In Minute": "Result In Minute",
      "Win Or Draw In Minute": "Win Or Draw In Minute",
      "Total In Minute": "Total In Minute",
      "Goal In Each Period": "Goal In Each Period",
      "Results, Periods": "Results, Periods",
      "Team Not To Lose Any Periods": "Team Not To Lose Any Periods",
      "All Periods - Total Under": "All Periods - Total Under",
      "All Periods - Total Over": "All Periods - Total Over",
      "Highest Scoring Period Total": "Highest Scoring Period Total",
      "Lowest Scoring Period Total": "Lowest Scoring Period Total",
      "Both Teams To Score In Minute": "Both Teams To Score In Minute",
      "Double Chance + Total": "Double Chance + Total",
      "Win To Nil": "Win To Nil",
      "Any Team To Come From Behind And Win":
        "Any Team To Come From Behind And Win",
      "Both Teams To Score In All Periods":
        "Both Teams To Score In All Periods",
      "Win By": "Win By",
      "Number In The Score": "Number In The Score",
      "Result And Both Teams To Score": "Result And Both Teams To Score",
      "Who Will Win Most Periods": "Who Will Win Most Periods",
      "When The Winner Will Be Determined":
        "When The Winner Will Be Determined",
      "Teams, Goal Time": "Teams, Goal Time",
      "Result / Teams To Score": "Result / Teams To Score",
      "Method Of Victory": "Method Of Victory",
      "Goal In Minute": "Goal In Minute",
      "Team Goals": "Team Goals",
      "Goal In Time Interval - Yes/No": "Goal In Time Interval - Yes/No",
      "Team To Score Their Goal In Period":
        "Team To Score Their Goal In Period",
      "Team To Win In All Periods": "Team To Win In All Periods",
      "Penalty Shot In Regular Time": "Penalty Shot In Regular Time",
      "Will A Penalty Shot Be Scored In Regular Time":
        "Will A Penalty Shot Be Scored In Regular Time",
      "How The First Goal Will Be Scored": "How The First Goal Will Be Scored",
      "First To Happen": "First To Happen",
      "Position Of Goalscorer": "Position Of Goalscorer",
      "Period / Match": "Period / Match",
      "Asian Total": "Asian Total",
      "Asian Handicap": "Asian Handicap",
      "W1 + Total 1": "W1 + Total 1",
      "W2 + Total 2": "W2 + Total 2",
      "Defender To Score First Goal": "Defender To Score First Goal",
      "Goalkeeper To Score": "Goalkeeper To Score",
      "Exact Number Of Goals": "Exact Number Of Goals",
      "Individual Total 1 Exact Number Of Goals":
        "Individual Total 1 Exact Number Of Goals",
      "Individual Total 2 Exact Number Of Goals":
        "Individual Total 2 Exact Number Of Goals",
      "Number Of Minutes Without A Goal": "Number Of Minutes Without A Goal",
      "Team 1, Max. Number Of Whole Minutes In A Row Without A Goal In Match":
        "Team 1, Max. Number Of Whole Minutes In A Row Without A Goal In Match",
      "Team 2, Max. Number Of Whole Minutes In A Row Without A Goal In Match":
        "Team 2, Max. Number Of Whole Minutes In A Row Without A Goal In Match",
      "Team To Be Losing During The Match + Result":
        "Team To Be Losing During The Match + Result",
      "Team 1, Win Or Draw In Each Period":
        "Team 1, Win Or Draw In Each Period",
      "Team 2, Win Or Draw In Each Period":
        "Team 2, Win Or Draw In Each Period",
      "Regular Time Result + Both Teams To Score":
        "Regular Time Result + Both Teams To Score",
      "Team 1, Each Period Total Under": "Team 1, Each Period Total Under",
      "Team 1, Each Period Total Over": "Team 1, Each Period Total Over",
      "Team 2, Each Period Total Under": "Team 2, Each Period Total Under",
      "Team 2, Each Period Total Over": "Team 2, Each Period Total Over",
      "Correct Score (17Way)": "Correct Score (17Way)",
      "Any Team To Win By": "Any Team To Win By",
      "Total Goals In Each Period": "Total Goals In Each Period",
      "Team 1 Total In Minute": "Team 1 Total In Minute",
      "Team 2 Total In Minute": "Team 2 Total In Minute",
      "Total Goals Scored By One Of The Players":
        "Total Goals Scored By One Of The Players",
      "Team 1 To Score Specified Goal + Outcome":
        "Team 1 To Score Specified Goal + Outcome",
      "Team 2 To Score Specified Goal + Outcome":
        "Team 2 To Score Specified Goal + Outcome",
      "Team 1 To Score Specified Goal + Double Chance":
        "Team 1 To Score Specified Goal + Double Chance",
      "Team 2 To Score Specified Goal + Double Chance":
        "Team 2 To Score Specified Goal + Double Chance",
      "Score After Two Goals": "Score After Two Goals",
      "Score After Three Goals": "Score After Three Goals",
      "Score After Four Goals": "Score After Four Goals",
      "Team 1 To Be Losing By A Specified Number Of Goals During The Match And Not To Lose The Match":
        "Team 1 To Be Losing By A Specified Number Of Goals During The Match And Not To Lose The Match",
      "Team 2 To Be Losing By A Specified Number Of Goals During The Match And Not To Lose The Match":
        "Team 2 To Be Losing By A Specified Number Of Goals During The Match And Not To Lose The Match",
      "Team 1 To Be Losing By Specified Number Of Goals During The Match And To Win The Match":
        "Team 1 To Be Losing By Specified Number Of Goals During The Match And To Win The Match",
      "Team 2 To Be Losing By Specified Number Of Goals During The Match And To Win The Match":
        "Team 2 To Be Losing By Specified Number Of Goals During The Match And To Win The Match",
      "1X + Team 1 Total": "1X + Team 1 Total",
      "Double Chance + Team 2 Total": "Double Chance + Team 2 Total",
      "Team 1, Min. Number Of Whole Minutes In A Row Without A Goal In Match":
        "Team 1, Min. Number Of Whole Minutes In A Row Without A Goal In Match",
      "Team 1, Highest Scoring Period": "Team 1, Highest Scoring Period",
      "Team 2, Highest Scoring Period": "Team 2, Highest Scoring Period",
      "Team 1, All Goals To Be Scored On One Side Of The Field":
        "Team 1, All Goals To Be Scored On One Side Of The Field",
      "Team 2, All Goals To Be Scored On One Side Of The Field":
        "Team 2, All Goals To Be Scored On One Side Of The Field",
      "Score After X Scored Goals": "Score After X Scored Goals",
    },
    40: {},
    189: {
      "Full time result": "1x2",
    },
  },
  ua: {
    1: {
      "Повний результат": "1x2",
      "Подвійний шанс": "Double Chance",
      Гандикап: "Handicap",
      Всього: "Total",
      "Всього 1": "Total 1",
      "Всього 2": "Total 2",
      "HT-FT": "HT-FT",
      Нічия: "Score Draw",
      "Обидві команди заб'ють": "Both Teams To Score",
      "Навіть дивно": "Even/Odd",
      "Очки в кожному таймі": "Scores In Each Half",
      "Команда 2, Результат + Тотал": "Team 2, Result + Total",
      "Команда 2 виграє до нуля": "Team 2 Win To Nil",
      "Команда 1, Результат + Тотал": "Team 1, Result + Total",
      "Команда 1 виграє до нуля": "Team 1 Win To Nil",
      "Нічия + Тотал": "Draw + Total",
      "Наступний гол": "Next Goal",
      "Європейський гандикап": "European Handicap",
      "Останній гол": "Last Goal",
      "Гол в обох таймах": "Goal In Both Halves",
      "Час першого голу": "First Goal Time",
      "Час другого голу": "Second Goal Time",
      "Команда 1 заб’є гол в обох таймах":
        "Team 1 To Score A Goal In Both Halves",
      "Команда 2 заб’є гол в обох таймах":
        "Team 2 To Score A Goal In Both Halves",
      "У якому таймі команда 1 заб’є свій гол":
        "Team 1 To Score Their Goal In Which Half",
      "Команда 2 заб’є свій гол, у якому таймі":
        "Team 2 To Score Their Goal In Which Half",
      "Зайти ззаду та перемогти - команда 1":
        "To Come From Behind And Win - Team 1",
      "Зайти ззаду та перемогти - команда 2":
        "To Come From Behind And Win - Team 2",
      "Команда 1 виграє з різницею рівно в один гол або зіграє внічию":
        "Team 1 To Win By Exactly One Goal Or To Draw",
      "Команда 2 виграє з різницею рівно в один гол або зіграє внічию":
        "Team 2 To Win By Exactly One Goal Or To Draw",
      "Команда 1 виграє будь-який тайм": "Team 1 To Win Either Half",
      "Команда 2 виграє будь-який тайм": "Team 2 To Win Either Half",
      "Команда 1 виграє обидва тайми": "Team 1 To Win Both Halves",
      "Команда 2 виграє обидва тайми": "Team 2 To Win Both Halves",
      "Тотал голів за таймами": "Total Goals By Halves",
      "Намалюйте принаймні одну половину": "Draw In At Least One Half",
      "Присуджено пенальті": "Penalty Awarded",
      Відправлення: "Sending Off",
      "Власна мета": "Own Goal",
      "Гонка до": "Race To",
      "Гол у таймі": "Goal In Half",
      "Період першого голу": "Period Of First Goal",
      "Половина/Половина": "Half/Half",
      "Правильний рахунок": "Correct Score",
      "3Way Total": "3Way Total",
      "Індивідуальний 3-сторонній залік 1": "Individual 3Way Total 1",
      "Індивідуальний тотал 1 парний/непарний": "Individual Total 1 Even/Odd",
      "Індивідуальний залік 3 сторони 2": "Individual 3Way Total 2",
      "Індивідуальний тотал 2 Парні/Непарні": "Individual Total 2 Even/Odd",
      "Ціль до хвилини": "Goal Up To Minute",
      "Команда 1 забиває гол N з хвилини на хвилину":
        "Team 1 To Score Goal N From Minute To Minute",
      "Команда 2 забиває гол N з хвилини на хвилину":
        "Team 2 To Score Goal N From Minute To Minute",
      "Як буде забитий гол": "How Goal Will Be Scored",
      "Загальна кількість голів": "Total Goal Minutes",
      "Час останнього голу": "Last Goal Time",
      "Гол після хвилини - Так/Ні": "Goal After Minute - Yes/No",
      "Призначений пенальті та вилучення": "Penalty Awarded And Sending Off",
      "Запасний гравець, який забиває": "Substitute Player To Score",
      "Гравець забиває два голи (дужка)": "A Player Scores Two Goals (Brace)",
      "Гравець робить хет-трик": "A Player Scores A Hat-Trick",
      "Час лідерства команди 1 (хв.)": "Team 1 Time In The Lead (min)",
      "Час лідерства команди 2 (хв.)": "Team 2 Time In The Lead (min)",
      "Тривалість розіграшу (хв.)": "Draw Duration (min)",
      "Забити гол із короткої руки": "To Score A Short-Handed Goal",
      "Гравець команди 1 отримає червону картку":
        "Team 1 Player To Get Red Card",
      "Гравець команди 2 отримає червону картку":
        "Team 2 Player To Get Red Card",
      "Команда 1 забиває голи поспіль": "Team 1 To Score Goals In A Row",
      "Команда 2 забиває голи поспіль": "Team 2 To Score Goals In A Row",
      "Нічия та загальна кількість кутових менше/більше":
        "Draw And Total Corners Under/Over",
      "Команда для перемоги та загальна кількість кутових Менше/Більше":
        "Team To Win And Total Corners Under/Over",
      "Усього кожна команда заб’є менше/більше":
        "Total Each Team Will Score Under/Over",
      "Результат за хвилину": "Result In Minute",
      "Перемагайте або нічия за хвилину": "Win Or Draw In Minute",
      "Усього за хвилину": "Total In Minute",
      "Гол за травмований час": "Injury Time Goal",
      "HT-FT + Разом": "HT-FT + Total",
      "Мета в інтервалі часу": "Goal In Time Interval",
      "Команда 1 заб'є N голів": "Team 1 To Score N Goals",
      "Команда 2 заб'є N голів": "Team 2 To Score N Goals",
      "Виграти до нуля": "Win To Nil",
      "Будь-яка команда, яка піде позаду та виграє":
        "Any Team To Come From Behind And Win",
      "Принаймні одна команда не заб’є + Тотал":
        "At Least One Team Will Not Score + Total",
      "Win By": "Win By",
      "Число в рахунку": "Number In The Score",
      "Результат і заб'ють обидві команди": "Result And Both Teams To Score",
      "Нічия в обох таймах": "Draw In Both Halves",
      "Команди, час голу": "Teams, Goal Time",
      "Результат / Команди заб'ють": "Result / Teams To Score",
      "Команда 1 заб’є свій перший гол у часовому інтервалі":
        "Team 1 To Score Their First Goal In Time Interval",
      "Команда 2 заб’є свій перший гол у проміжку часу":
        "Team 2 To Score Their First Goal In Time Interval",
      "Команда 1 заб’є свій останній гол у проміжку часу":
        "Team 1 To Score Their Last Goal In Time Interval",
      "Команда 2 заб’є свій останній гол у часовий інтервал":
        "Team 2 To Score Their Last Goal In Time Interval",
      "Перший гол команди 1 у перерві": "Team 1 First Goal In Interval",
      "Перший гол команди 2 у перерві": "Team 2 First Goal In Interval",
      "Команда та час голу": "Team And Goal Time",
      "Щоб зберегти чистий аркуш": "To Keep Clean Sheet",
      "Перший гол повинен бути автоголом": "First Goal To Be An Own Goal",
      "Команда 1 забиває у таймах": "Team 1 Scores In Halves",
      "Команда 2 забиває у таймах": "Team 2 Scores In Halves",
      "Командні цілі": "Team Goals",
      "Обидві команди забивають Так/Ні + Тотал":
        "Both Teams To Score Yes/No + Total",
      "Обидві команди заб'ють в обох таймах":
        "Both Teams To Score In Both Halves",
      "Команда 1, перший гол у перерві": "Team 1, First Goal In Interval",
      "Команда 1, перший гол у перерві": "Team 2, First Goal In Interval",
      "Ціль у часовому інтервалі - Так/Ні": "Goal In Time Interval - Yes/No",
      "Цільовий інтервал - Так": "Goal Interval - Yes",
      "Цільовий інтервал - Ні": "Goal Interval - No",
      "Тотал і обидва забити": "Total And Both To Score",
      "Гол забитий ударом ногою": "Goal Scored By Kicking",
      "Головою гол": "Headed Goal",
      "Гол зі штрафного": "Goal Directly From Free Kick",
      "Гол після кутового": "Goal After Corner",
      "Азіатський загальний": "Asian Total",
      "Азійський гандикап": "Asian Handicap",
      "W1 + Разом 1": "W1 + Total 1",
      "W2 + Разом 2": "W2 + Total 2",
      "Результат + кількість голів": "Outcome + Number Of Goals",
      "Результат першого тайму або матчу":
        "Outcome Of The First Half Or The Match",
      "Результат матчу + Тотал першого тайму":
        "Match Result + First Half Total",
      "Всього навпіл": "Total By Halves",
      "Команда 1 забиває пенальті": "Team 1 To Score Penalty",
      "Команда 2 заб'є пенальті": "Team 2 To Score Penalty",
      Фоли: "Fouls",
      "Обидва тайми виграють різні команди":
        "Both Halves To Be Won By Different Teams",
      "Точне число": "Exact Number",
      "Індивідуальний тотал 1 Точна кількість голів":
        "Individual Total 1 Exact Number Of Goals",
      "Індивідуальний тотал 2 Точна кількість голів":
        "Individual Total 2 Exact Number Of Goals",
      "Будь-яка команда для перемоги": "Any Team To Win By",
      "Голи поспіль однієї команди": "Goals In A Row By A Single Team",
      "Команда 1 веде в матчі і не програє матч":
        "Team 1 To Lead In The Match And Not To Lose The Match",
      "Команда 2 веде в матчі і не програє матч":
        "Team 2 To Lead In The Match And Not To Lose The Match",
      "Воротар буде вилучений": "Goalkeeper To Be Sent Off",
      "Команда, яка програє під час матчу + результат":
        "Team To Be Losing During The Match + Result",
      "Хто заб'є гол": "Who Will Score Goal",
      Мультигол: "Multi Goal",
      "Тотал азіатської команди1": "Asian Team Total1",
      "Тотал азіатської команди2": "Asian Team Total2",
      "Точний рахунок": "correct score",
    },
    3: {
      Гандикап: "Handicap",
      Всього: "Total",
      "Всього 1": "Total 1",
      "Всього 2": "Total 2",
      "Навіть дивно": "Even/Odd",
      "Команда 2, Результат + Тотал": "Team 2, Result + Total",
      "Команда 1, Результат + Тотал": "Team 1, Result + Total",
      "Команда виграє": "Team Wins",
      "Європейський гандикап": "European Handicap",
      "Індивідуальний тотал 1 парний/непарний": "Individual Total 1 Even/Odd",
      "Індивідуальний тотал 2 Парні/Непарні": "Individual Total 2 Even/Odd",
      "Найрезультативніший період": "Highest Scoring Period",
      "Найрезультативніший тайм": "Highest Scoring Half",
      "Яка команда першою набере очки?":
        "Which Team Will Be The First To Score Points?",
      "Команда на перемогу в усіх чвертях": "Team To Win All Quarters",
      "Команда виграє обидва тайми": "Team To Win Both Halves",
      "Команда виграє 1-й тайм і матч": "Team To Win 1st Half And The Match",
      "Команда виграє 1-у чверть і матч":
        "Team To Win 1st Quarter And The Match",
      "Усього кожна команда заб’є менше/більше":
        "Total Each Team Will Score Under/Over",
      "Перші очки, які потрібно набрати": "First Points To Be Scored By",
      "Останні очки, які потрібно набрати": "Last Points To Be Scored By",
      "Час вийшов": "Timeout",
      "Рахунок команди в чверті": "Team's Score in Quarter",
      "Яка команда набере очки останньою?":
        "Which Team Will Score Points Last?",
      "Число в рахунку": "Number In The Score",
      "Точна різниця балів..": "Exact Points Difference.",
      "Загальна кількість витягнутих чвертей": "Total Of Drawn Quarters",
      "Перший тайм / Повний робочий день": "First Half / Full-time",
      "Перемога за чвертями": "Win By Quarters",
      " Будь-яка команда для перемоги": "Any Team To Win By",
      "1X2 в основний час": "1X2 In Regular Time",
      "Подвійний шанс в основний час": "Regular Time Double Chance",
      "W1 + Разом 1": "W1 + Total 1",
      "W1 + Разом 2": "W2 + Total 2",
      "Кожен квартал закінчено": "Each Quarter Over",
      "Кожен квартал під": "Each Quarter Under",
      "Кожна половина": "Each Half Over",
      "Кожна половина під": "Each Half Under",
      "Фора 1 + Тотал": "Handicap 1 + Total",
      "Фора 2 + Тотал": "Handicap 2 + Total",
      "Скільки чвертей виграє команда 1": "How Many Quarters Will Team 1 Win",
      "Скільки чвертей виграє команда 2": "How Many Quarters Will Team 2 Win",
      "Гонка за очками (2 сторони)": "Race To Points (2Way)",
      "Намалюйте одну з половинок": "Draw In One Of The Halves",
      "Лідер після першої чверті для перемоги в матчі":
        "Leader After The First Quarter To Win The Match",
      "Команда 1, загальна кількість чвертей з однаковою кількістю очок":
        "Team 1, Total Quarters With The Same Number Of Points",
      "Команда 2, загальна кількість чвертей з однаковою кількістю очок":
        "Team 2, Total Quarters With The Same Number Of Points",
      "Total Quarters With The Same Number Of Points":
        "Всього чвертей з однаковою кількістю балів",
      "Team Performance In Half": "Командна продуктивність у половині",
      "Лідер після першого тайму, щоб виграти матч":
        "Leader After First Half To Win The Match",
      "Перемога за таймами": "Win By Halves",
      "Скільки чвертей поспіль виграє команда 1":
        "How Many Quarters In A Row Will Team 1 Win",
      "Скільки чвертей поспіль виграє команда 2":
        "How Many Quarters In A Row Will Team 2 Win",
      "Скільки чвертей поспіль виграє одна з команд":
        "How Many Quarters In A Row Will Either Team Win",
      "Найменша маржа виграшу в будь-якій перерві між чвертями":
        "Smallest Winning Margin At Any Break Between Quarters",
      "Найбільша маржа виграшу в будь-якій перерві між чвертями":
        "Largest Winning Margin At Any Break Between Quarters",
    },
    4: {
      "Повний результат": "1x2",
      Гандикап: "Handicap",
    },
    6: {
      "Повний результат": "1x2",
      Гандикап: "Handicap",
      Total: "Total",
      "Всього 1": "Total 1",
      "Всього 2": "Total 2",
      "Парний/Непарний": "Even/Odd",
      "Команда 2, результат + загальна сума": "Team 2, Result + Total",
      "Команда 1, результат + загальна сума": "Team 1, Result + Total",
      "Правильний рахунок": "Correct Score",
      "Фора сетів": "Sets Handicap",
      "Точна загальна кількість наборів": "Exact Total Sets",
      "Оцінка після наборів": "Score After Sets",
      "Загальна кількість наборів": "Total Sets",
      "Загальна кількість матчів": "Match Point Total",
      "Оцінка після X наборів": "Score After X Sets",
      "Набір / Матч": "Set / Match",
      "Сети підрахунок": "Sets Scoring",
      "Набори з однаковою сумою": "Sets With The Same Total",
      "Скільки сетів поспіль виграє команда":
        "How Many Sets In A Row Will A Team Win",
    },
    7: {
      "Повний результат": "1x2",
      "Подвійний шанс": "Double Chance",
      Гандикап: "Handicap",
      "HT-FT": "HT-FT",
      "Парний/Непарний": "Even/Odd",
      "Голи в кожному таймі": "Scores In Each Half",
      "Команда виграє": "Team Wins",
      "Європейський гандикап": "European Handicap",
      "Команда 1 виграє будь-який тайм": "Team 1 To Win Either Half",
      "Команда 2 виграє будь-який тайм": "Team 2 To Win Either Half",
      "Команда 1 виграє обидва тайми": "Team 1 To Win Both Halves",
      "Команда 2 виграє обидва тайми": "Team 2 To Win Both Halves",
      "Індивідуальний підсумок 1 парний/непарний":
        "Individual Total 1 Even/Odd",
      "Індивідуальний тотал 2 парних/непарних": "Individual Total 2 Even/Odd",
      "Яка команда першою набере очки?":
        "Which Team Will Be The First To Score Points?",
      "Результат за хвилину": "Result In Minute",
      "Виграти до нуля": "Win To Nil",
      "Гонка до": "Race To",
      "Яка команда набере очки останньою?":
        "Which Team Will Score Points Last?",
      "Наступна спроба": "Next Try",
      "Точна різниця очок...": "Exact Points Difference...",
      "Команда не програє": "Team Will Not Be Losing",
      "Щоб виграти (3Way).": "To Win By (3Way).",
      "Виграти завдяки.": "Win By.",
      "Виграти завдяки...": "Win By...",
      "Виграй завдяки....": "Win By....",
      "HT-FT, щоб перемогти": "HT-FT To Win By",
      "Перша спроба буде перетворена": "Will 1st Try Be Converted",
      "Половина з найбільшою кількістю спроб": "Half With Most Tries",
      "Усього спроб парно/непарно": "Total Tries Even/Odd",
      "Команда заб'є остання спроба": "Team To Score Last Try",
      "Команда заб'є першою і виграє": "Team To Score First And Win",
      "HT-FT (5 шлях)": "HT-FT (5Way)",
      "Будь-яка команда виграє обидва тайми": "Either Team To Win Both Halves",
      "Футболка бомбардира першої спроби": "Shirt Of First Try Scorer",
      "Футболка бомбардира останньої спроби": "Shirt Of Last Try Scorer",
      "1-ша спроба / результат": "1st Try / Result",
      "Перший рахунок / Результат 1-го тайму / Результат матчу":
        "Score First / 1st Half Result / Match Outcome",
      "1-ша спроба / 1-й тайм результат": "1st Try / 1st Half Result",
      "Командна продуктивність у половині": "Team Performance In Half",
      "Перші очки та переможець": "First Points And Winner",
    },
    8: {
      "Повний результат": "1x2",
      "Подвійний шанс": "Double Chance",
      Гандикап: "Handicap",
      Total: "Total",
      "Всього 1": "Total 1",
      "Всього 2": "Total 2",
      "Парний/Непарний": "Even/Odd",
      "Голи в кожному таймі": "Scores In Each Half",
      "Європейський гандикап": "European Handicap",
      "Індивідуальний 3-сторонній залік 1": "Individual 3Way Total 1",
      "Індивідуальний тотал 1 парний/непарний": "Individual Total 1 Even/Odd",
      "Індивідуальний залік 3 сторони 2": "Individual 3Way Total 2",
      "Індивідуальний тотал 2 Парні/Непарні": "Individual Total 2 Even/Odd",
      "Яка команда першою набере очки?":
        "Which Team Will Be The First To Score Points?",
      "Команда 1 заб'є N голів": "Team 1 To Score N Goals",
      "Команда 2 заб'є N голів": "Team 2 To Score N Goals",
      "Точна різниця балів.": "Exact Points Difference.",
      "Азіатський загальний": "Asian Total",
      "Азійський гандикап": "Asian Handicap",
      SuperTotal: "SuperTotal",
      Супергандикап: "SuperHandicap",
      // "Sweden, Top Goalscorer In The Tournament": "Sweden, Top Goalscorer In The Tournament"
    },
    9: {
      "Повний результат": "1x2",
    },
    12: {
      "Повний результат": "1x2",
    },
    13: {
      "Повний результат": "1x2",
      Гандикап: "Handicap",
      Всього: "Total",
      "Всього 1": "Total 1",
      "Всього 2": "Total 2",
      "Навіть дивно": "Even/Odd",
      "Команда 2, Результат + Тотал": "Team 2, Result + Total",
      "Команда 2 виграє до нуля": "Team 2 Win To Nil",
      "Команда 1, Результат + Тотал": "Team 1, Result + Total",
      "Європейський гандикап": "European Handicap",
      "3Way Total": "3Way Total",
      "Індивідуальний 3-сторонній залік 1": "Individual 3Way Total 1",
      "Індивідуальний тотал 1 парний/непарний": "Individual Total 1 Even/Odd",
      "Чи буде понаднормова робота? - Так ні":
        "Will There Be Overtime? - Yes/No",
      "Індивідуальний залік 3 сторони 2": "Individual 3Way Total 2",
      "Індивідуальний тотал 2 Парні/Непарні": "Individual Total 2 Even/Odd",
      "Найрезультативніший період": "Highest Scoring Period",
      "Найрезультативніший тайм": "Highest Scoring Half",
      "Яка команда першою набере очки?":
        "Which Team Will Be The First To Score Points?",
      "Команда виграє обидва тайми": "Team To Win Both Halves",
      "Гонка до": "Race To",
      "Час вийшов": "Timeout",
      "Рахунок команди в чверті": "Team's Score in Quarter",
      "Яка команда набере очки останньою?":
        "Which Team Will Score Points Last?",
      "Найменш результативна чверть": "Lowest Scoring Quarter",
      "Перший тайм / Повний робочий день": "First Half / Full-time",
      Безпека: "Safety",
      "Наступний філдгол": "Next Field Goal",
      "Загальна кількість тачдаунів (3 сторони)": "Total Touchdowns (3Way)",
      "Загальна кількість тачдаунів (2 сторони)": "Total Touchdowns (2Way)",
      "Загальна кількість забитих м’ячів з гри (3 сторони)":
        "Total Scored Field Goals (3Way)",
      "Загальна кількість забитих м'ячів з гри (двосторонні)":
        "Total Scored Field Goals (2Way)",
      "Щоб перемогти (3 сторони).": "To Win By (3Way).",
      "First To Happen": "First To Happen",
      "Найдовший тачдаун у матчі": "Longest Touchdown In The Match",
      "Найдовший філд-гол у матчі": "Longest Field Goal In The Match",
      "3 голи підряд будь-якою командою": "3 Straight Scores By Either Team",
      "Результат Drive": "Result Of Drive",
      "Команда 1 забиває в кожній чверті": "Team 1 To Score In Each Quarter",
      "Команда 2 забиває в кожній чверті": "Team 2 To Score In Each Quarter",
      "Найкоротший забитий тачдаун (ярди)": "Shortest Touchdown Scored (Yards)",
      "Найдовший тачдаун": "Longest Touchdown Scored",
      "Найдовший забитий гол з гри": "Longest Field Goal Scored",
      "Команда 1, загальна кількість тачдаунів": "Team 1, Total Touchdowns",
      "Команда 2, загальна кількість тачдаунів": "Team 2, Total Touchdowns",
      "Команда 1, загальна кількість забитих м’ячів з гри":
        "Team 1, Total Scored Field Goals",
      "Команда 2, загальна кількість забитих м’ячів з гри":
        "Team 2, Total Scored Field Goals",
      "Команда, яка зробить перший миттєвий повтор":
        "Team To Take First Instant Replay",
      "Виграти завдяки....": "Win By....",
      "Фора 1 + Тотал": "Handicap 1 + Total",
      "Фора 2 + Тотал": "Handicap 2 + Total",
      "Обидві команди повинні набрати кількість очок":
        "Both Teams To Score A Number Of Points",
      "Команда заб'є першою і виграє": "Team To Score First And Win",
      "Яка команда заб'є першою та переможе":
        "Which Team Will Score First And Win",
      "Яка команда заб'є першою і не виграє":
        "Which Team Will Score First And Fail To Win",
      "Лідер після кожної чверті": "Leader After Each Quarter",
      "Тип результату (3 сторони)": "Type Of Score (3Way)",
      "Тип результату (6 способів)": "Type Of Score (6Way)",
      "Загальна кількість тачдаунів": "Total Touchdowns",
      "Загальна кількість тачдаунів кожною командою в кожному таймі":
        "Total Touchdowns By Each Team In Each Half",
      "Остання результативна гра": "Last Scoring Play",
      "Команда 1, перша гольова гра": "Team 1, First Scoring Play",
      "Команда 2, перша гольова гра": "Team 2, First Scoring Play",
      "Загальна кількість забитих м’ячів з гри": "Total Field Goals Scored",
      "Загальна кількість м’ячів, забитих командою":
        "Total Field Goals Scored By Team",
      "Загальна кількість тачдаунів, забитих командою":
        "Total Touchdowns Scored By Team",
      "Час першого приземлення": "Time Of First Touchdown",
      "Час першого голу з гри": "Time Of First Field Goal",
      "Команда заб'є в обох таймах": "Team To Score In Both Halves",
      "Команда виконує першим прийнятий пенальті":
        "Team To Commit First Accepted Penalty",
      "Час першого результату": "Time Of First Score",
      "Загальна кількість тачдаунів командою (3 сторони)":
        "Total Touchdowns Scored By Team (3Way)",
      "Загальна кількість м’ячів, забитих командою (3 сторони)":
        "Total Field Goals Scored By Team (3Way)",
      "Успішне 2-бальне перетворення": "Successful 2-Point Conversion",
    },
    14: {
      "Хто виграє? - Так ні": "Who Will Win? - Yes/No",
    },
    16: {
      "Full time result": "1x2",
      "Double Chance": "Double Chance",
      Handicap: "Handicap",
      Total: "Total",
      "Total 1": "Total 1",
      "Total 2": "Total 2",
      "Both Teams To Score": "Both Teams To Score",
      "Even/Odd": "Even/Odd",
      "Scored Goal": "Scored Goal",
      "Team 2, Result + Total": "Team 2, Result + Total",
      "Team 2 Win To Nil": "Team 2 Win To Nil",
      "Team 1, Result + Total": "Team 1, Result + Total",
      "Team 1 Win To Nil": "Team 1 Win To Nil",
      "Draw + Total": "Draw + Total",
      "Next Goal": "Next Goal",
      "Team Wins": "Team Wins",
      "Last Goal": "Last Goal",
      "First Goal Time": "First Goal Time",
      "To Come From Behind And Win - Team 1":
        "To Come From Behind And Win - Team 1",
      "To Come From Behind And Win - Team 2":
        "To Come From Behind And Win - Team 2",
      "Race To": "Race To",
      "Correct Score": "Correct Score",
      "Individual Total 1 Even/Odd": "Individual Total 1 Even/Odd",
      "Will There Be Overtime? - Yes/No": "Will There Be Overtime? - Yes/No",
      "Individual Total 2 Even/Odd": "Individual Total 2 Even/Odd",
      "Scoring Periods": "Scoring Periods",
      "Last Goal Time": "Last Goal Time",
      "Team To Score In Each Period - Yes/No":
        "Team To Score In Each Period - Yes/No",
      "A Player Scores Two Goals (Brace)": "A Player Scores Two Goals (Brace)",
      "A Player Scores A Hat-Trick": "A Player Scores A Hat-Trick",
      "Highest Scoring Period": "Highest Scoring Period",
      "Team 1 Time In The Lead (min)": "Team 1 Time In The Lead (min)",
      "Team 2 Time In The Lead (min)": "Team 2 Time In The Lead (min)",
      "Draw Duration (min)": "Draw Duration (min)",
      "Team 1, First Goal + Match Result": "Team 1, First Goal + Match Result",
      "Team 2, First Goal + Match Result": "Team 2, First Goal + Match Result",
      "To Score A Short-Handed Goal": "To Score A Short-Handed Goal",
      "To Score An Empty Net Goal": "To Score An Empty Net Goal",
      "Total Goal Minutes": "Total Goal Minutes",
      "Team 1 To Score Goals In A Row": "Team 1 To Score Goals In A Row",
      "Team 2 To Score Goals In A Row": "Team 2 To Score Goals In A Row",
      "Total Each Team Will Score Under/Over":
        "Total Each Team Will Score Under/Over",
      "Result In Minute": "Result In Minute",
      "Win Or Draw In Minute": "Win Or Draw In Minute",
      "Total In Minute": "Total In Minute",
      "Goal In Each Period": "Goal In Each Period",
      "Results, Periods": "Results, Periods",
      "Team Not To Lose Any Periods": "Team Not To Lose Any Periods",
      "All Periods - Total Under": "All Periods - Total Under",
      "All Periods - Total Over": "All Periods - Total Over",
      "Highest Scoring Period Total": "Highest Scoring Period Total",
      "Lowest Scoring Period Total": "Lowest Scoring Period Total",
      "Both Teams To Score In Minute": "Both Teams To Score In Minute",
      "Double Chance + Total": "Double Chance + Total",
      "Win To Nil": "Win To Nil",
      "Any Team To Come From Behind And Win":
        "Any Team To Come From Behind And Win",
      "Both Teams To Score In All Periods":
        "Both Teams To Score In All Periods",
      "Win By": "Win By",
      "Number In The Score": "Number In The Score",
      "Result And Both Teams To Score": "Result And Both Teams To Score",
      "Who Will Win Most Periods": "Who Will Win Most Periods",
      "When The Winner Will Be Determined":
        "When The Winner Will Be Determined",
      "Teams, Goal Time": "Teams, Goal Time",
      "Result / Teams To Score": "Result / Teams To Score",
      "Method Of Victory": "Method Of Victory",
      "Goal In Minute": "Goal In Minute",
      "Team Goals": "Team Goals",
      "Goal In Time Interval - Yes/No": "Goal In Time Interval - Yes/No",
      "Team To Score Their Goal In Period":
        "Team To Score Their Goal In Period",
      "Team To Win In All Periods": "Team To Win In All Periods",
      "Penalty Shot In Regular Time": "Penalty Shot In Regular Time",
      "Will A Penalty Shot Be Scored In Regular Time":
        "Will A Penalty Shot Be Scored In Regular Time",
      "How The First Goal Will Be Scored": "How The First Goal Will Be Scored",
      "First To Happen": "First To Happen",
      "Position Of Goalscorer": "Position Of Goalscorer",
      "Period / Match": "Period / Match",
      "Asian Total": "Asian Total",
      "Asian Handicap": "Asian Handicap",
      "W1 + Total 1": "W1 + Total 1",
      "W2 + Total 2": "W2 + Total 2",
      "Defender To Score First Goal": "Defender To Score First Goal",
      "Goalkeeper To Score": "Goalkeeper To Score",
      "Exact Number Of Goals": "Exact Number Of Goals",
      "Individual Total 1 Exact Number Of Goals":
        "Individual Total 1 Exact Number Of Goals",
      "Individual Total 2 Exact Number Of Goals":
        "Individual Total 2 Exact Number Of Goals",
      "Number Of Minutes Without A Goal": "Number Of Minutes Without A Goal",
      "Team 1, Max. Number Of Whole Minutes In A Row Without A Goal In Match":
        "Team 1, Max. Number Of Whole Minutes In A Row Without A Goal In Match",
      "Team 2, Max. Number Of Whole Minutes In A Row Without A Goal In Match":
        "Team 2, Max. Number Of Whole Minutes In A Row Without A Goal In Match",
      "Team To Be Losing During The Match + Result":
        "Team To Be Losing During The Match + Result",
      "Team 1, Win Or Draw In Each Period":
        "Team 1, Win Or Draw In Each Period",
      "Team 2, Win Or Draw In Each Period":
        "Team 2, Win Or Draw In Each Period",
      "Regular Time Result + Both Teams To Score":
        "Regular Time Result + Both Teams To Score",
      "Team 1, Each Period Total Under": "Team 1, Each Period Total Under",
      "Team 1, Each Period Total Over": "Team 1, Each Period Total Over",
      "Team 2, Each Period Total Under": "Team 2, Each Period Total Under",
      "Team 2, Each Period Total Over": "Team 2, Each Period Total Over",
      "Correct Score (17Way)": "Correct Score (17Way)",
      "Any Team To Win By": "Any Team To Win By",
      "Total Goals In Each Period": "Total Goals In Each Period",
      "Team 1 Total In Minute": "Team 1 Total In Minute",
      "Team 2 Total In Minute": "Team 2 Total In Minute",
      "Total Goals Scored By One Of The Players":
        "Total Goals Scored By One Of The Players",
      "Team 1 To Score Specified Goal + Outcome":
        "Team 1 To Score Specified Goal + Outcome",
      "Team 2 To Score Specified Goal + Outcome":
        "Team 2 To Score Specified Goal + Outcome",
      "Team 1 To Score Specified Goal + Double Chance":
        "Team 1 To Score Specified Goal + Double Chance",
      "Team 2 To Score Specified Goal + Double Chance":
        "Team 2 To Score Specified Goal + Double Chance",
      "Score After Two Goals": "Score After Two Goals",
      "Score After Three Goals": "Score After Three Goals",
      "Score After Four Goals": "Score After Four Goals",
      "Team 1 To Be Losing By A Specified Number Of Goals During The Match And Not To Lose The Match":
        "Team 1 To Be Losing By A Specified Number Of Goals During The Match And Not To Lose The Match",
      "Team 2 To Be Losing By A Specified Number Of Goals During The Match And Not To Lose The Match":
        "Team 2 To Be Losing By A Specified Number Of Goals During The Match And Not To Lose The Match",
      "Team 1 To Be Losing By Specified Number Of Goals During The Match And To Win The Match":
        "Team 1 To Be Losing By Specified Number Of Goals During The Match And To Win The Match",
      "Team 2 To Be Losing By Specified Number Of Goals During The Match And To Win The Match":
        "Team 2 To Be Losing By Specified Number Of Goals During The Match And To Win The Match",
      "1X + Team 1 Total": "1X + Team 1 Total",
      "Double Chance + Team 2 Total": "Double Chance + Team 2 Total",
      "Team 1, Min. Number Of Whole Minutes In A Row Without A Goal In Match":
        "Team 1, Min. Number Of Whole Minutes In A Row Without A Goal In Match",
      "Team 1, Highest Scoring Period": "Team 1, Highest Scoring Period",
      "Team 2, Highest Scoring Period": "Team 2, Highest Scoring Period",
      "Team 1, All Goals To Be Scored On One Side Of The Field":
        "Team 1, All Goals To Be Scored On One Side Of The Field",
      "Team 2, All Goals To Be Scored On One Side Of The Field":
        "Team 2, All Goals To Be Scored On One Side Of The Field",
      "Score After X Scored Goals": "Score After X Scored Goals",
    },
    40: {},
    189: {
      "Повний результат": "1x2",
    },
  },
};
