import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { setPredictions } from "../../../store/betSlice";
import { handleAddOdds, handleActiveOdds } from "../../../helpers/prediction";

const OddItem = React.memo(({ odd, event, seq }) => {
  const { predictions } = useSelector((state) => state.bet);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams({});

  const activeSportKey = searchParams.get("key");

  const handleArrowImage = (funct, change) => {
    if (funct && change < 0) {
      return "/decreased arrow.svg";
    } else if (funct && change > 0) {
      return "/increased arrow.svg";
    } else if (change > 0) {
      return "/win arrow up.svg";
    } else if (change < 0) {
      return "/lose arrow down.svg";
    } else {
      return "";
    }
  };

  const renderOddItem = () => {
    return (
      <div
        onClick={(e) =>
          handleAddOdds(
            e,
            event,
            odd,
            predictions,
            dispatch,
            setPredictions,
            activeSportKey
          )
        }
        style={{ pointerEvents: !odd.oc_rate && "none" }}
        key={seq}
        className={
          handleActiveOdds(odd.oc_pointer, predictions)
            ? "champions-leaue-score champions-leaue-score-active"
            : `champions-leaue-score ${
                odd.change > 0 
                  ? "increased-odds-div"
                  : odd.change < 0
                  ? "decreased-odds-div"
                  : ""
              } `
        }
      >
        <span className="d-flex">
          <img
            className="pe-1"
            src={handleArrowImage(
              handleActiveOdds(odd.oc_pointer, predictions),
              odd.change
            )}
            alt=""
          />
          {parseFloat(odd.oc_rate).toFixed(2)}
        </span>
      </div>
    );
  };

  return (
    <>
      {isMobile ? (
        <div className="leaue-score champions-leaue-score-data-X">
          {renderOddItem()}
        </div>
      ) : (
        <td className="leaue-score champions-leaue-score-data-X">
          {renderOddItem()}
        </td>
      )}
    </>
  );
});

export default OddItem;
