import React from "react";

import "./style.css";

const PredictionsListLoader = () => {
  return (
    <>
      {Array(5)
        .fill(0)
        .map((item, index) => {
          return (
            <div className="predictions-list-container" key={index}>
              <div className="prediction-item-wrapper">
                <div className="prediction-item-title">
                  <div className="skeleton-grid-placeholder user-img"></div>
                  <div className="username-wrapper">
                    <div className="skeleton-grid-placeholder username"></div>
                    <div className="skeleton-grid-placeholder date"></div>
                  </div>
                </div>

                <div className="prediction-details-wrapper">
                  <div className="prediction-details-wrapper_top">
                    <div className="prediction-details-wrapper_top_left">
                      <div className="skeleton-grid-placeholder user-img"></div>
                      <div className="skeleton-grid-placeholder user-img over"></div>

                      <div className="skeleton-grid-placeholder title-text"></div>
                      <div className="skeleton-grid-placeholder title-text"></div>
                    </div>
                    <div className="skeleton-grid-placeholder top-share"></div>
                  </div>

                  <div className="prediction-details-wrapper_bottom">
                    <div className="coef-item-wrapper">
                      <div className="skeleton-grid-placeholder coef-title"></div>
                      <div className="skeleton-grid-placeholder coef"></div>
                    </div>

                    <div className="coef-item-wrapper">
                      <div className="skeleton-grid-placeholder coef-title"></div>
                      <div className="skeleton-grid-placeholder coef"></div>
                    </div>

                    <div className="coef-item-wrapper">
                      <div className="skeleton-grid-placeholder coef-title"></div>
                      <div className="skeleton-grid-placeholder coef"></div>
                    </div>

                    <div className="coef-item-wrapper">
                      <div className="skeleton-grid-placeholder coef-title"></div>
                      <div className="skeleton-grid-placeholder coef"></div>
                    </div>

                    <div className="coef-item-wrapper last">
                      <div className="skeleton-grid-placeholder coef-title"></div>
                      <div className="skeleton-grid-placeholder coef"></div>
                    </div>
                  </div>
                </div>

                <div className="prediction-item-bottom">
                  <div className="prediction-item-bottom-left">
                    <div className="skeleton-grid-placeholder like"></div>
                    <div className="skeleton-grid-placeholder like"></div>
                  </div>
                  <div className="skeleton-grid-placeholder share"></div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default PredictionsListLoader;
