import React, { useEffect, useState } from 'react'
import Model from './Model'
import InputButton from '../InputButton'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import apiService from '../../../services/apiService';

const ExitConfirmationModal = (props) => {
    const navigate = useNavigate();
    const [signUpReward, setSignupReward] = useState({})
    const { t, i18n } = useTranslation();
    const handleNavigateSignup = () => {
        navigate("signup")
        props.hideModel()
    }
    const getSignUpReward = async () => {
        const res = await apiService.getAuthSignUpReward()
        if (res) {
            setSignupReward(res)
        }
    }
    useEffect(() => {
        getSignUpReward()
    }, [])
    return (
        <>
            {/* <div>
                <Model
                    isModel={props.isModel}
                    modelClassName={"collect-coin-model-div exitConfirmtionModel-div"}
                    hideModel={props.hideModel}
                >
                    <div>
                        <div className="d-flex justify-content-center pb-4">
                            <img
                                src={`/wallet-coin/model-coin-icon/4.png`}
                                width={200}
                                height={200}
                            />
                        </div>
                        <div className="text-center coin-model-collected-message">
                            {t("navbar.confirmModel.firstTitle")}<img src='/Happy heart.svg' />
                        </div>
                        <div className="coin-model-subtitle text-center pb-4">
                            {t("navbar.confirmModel.subTitle1")} <span className='' style={{ color: "#131520", fontWeight: "700" }}> {signUpReward?.SignUpReward + " " + t("navbar.confirmModel.subTitle2")}</span> {t("navbar.confirmModel.subTitle3")}
                        </div>
                        <div className="d-flex justify-content-center">
                            <InputButton
                                type="primary"
                                addBtnClass={"thankYou-btn"}
                                onClick={handleNavigateSignup}
                                inputLabel={t("navbar.confirmModel.confirmModelBtn")}
                            />
                        </div>
                    </div>
                </Model>
            </div> */}
        </>
    )
}

export default ExitConfirmationModal
