import React, { useEffect, useState, useRef } from "react";
import NavigationBar from "../../Navbar";
import "./navbarNotification.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import InputButton from "../InputButton";
import moment from "moment/moment";
import apiService from "../../../services/apiService";
import NotificationModal from "./NotificationModel";
import HomePageFooter from "../homepage/HomePageFooter/HomePageFooter";
import { Helmet } from "react-helmet";

const Notification = () => {
  const { t, i18n } = useTranslation();
  const pageSize = 10;
  const [currentState, setCurrentState] = useState(0);
  const [pageState, setPageState] = useState(0);
  const [notificationData, setNotificationData] = useState([]);
  const [modelShow, setModelShow] = useState(false);
  const [notificationId, setNotificationId] = useState("");
  const [activeTabKey, setActiveTabKey] = useState("");
  const [NotificationCount, setNotificationCount] = useState(0);
  const [countData, setCountData] = useState(0);
  const increamentUserCount = () => {
    setCountData(countData + 1);
  };
  const handleNotification = async () => {
    const res = await apiService.getAllNotification({
      size: pageSize,
      page: currentState,
      activeTabKey:
        activeTabKey == "Predictions"
          ? "PREDICTION"
          : activeTabKey == "Usersactivity"
          ? "USER_ACTIVITY"
          : "",
    });
    setNotificationData(res?.resData);
    setNotificationCount(res?.countNotification);
    setPageState(Math.ceil(res?.countNotification / pageSize));
  };

  useEffect(() => {
    handleNotification();
  }, [countData, activeTabKey, currentState]);
  useEffect(() => {}, [notificationData]);

  const handlePagination = (e, currentPage) => {
    e.preventDefault();
    setCurrentState(currentPage);
  };

  const handleNotificationModelShow = (id) => {
    setNotificationId(id);
    let newNotifyData = notificationData.map((i) => {
      if (i._id.toString() == id) {
        i.isRead = true;
      }
      return i;
    });

    setTimeout(() => {
      increamentUserCount();
    }, 1000);
    setNotificationData(newNotifyData);
    setModelShow(true);
  };
  const handleNoticationModelHide = () => {
    setModelShow(false);
  };
  useEffect(() => {
    document.body.classList.add("notification-tab-content");
    let el = document.getElementsByClassName(
      "notification-tab nav nav-tabs nav-justified"
    )[0];
    let parent = el.parentNode;
    let wrapper = document.createElement("div");
    wrapper.setAttribute("class", "setting-tab-list");
    parent.replaceChild(wrapper, el);
    wrapper.appendChild(el);
    el = document.getElementsByClassName("tab-content")[0];
    parent = el.parentNode;
    wrapper = document.createElement("div");
    wrapper.setAttribute("class", "notification-tab-content");
    parent.replaceChild(wrapper, el);
    wrapper.appendChild(el);
  }, []);
  useEffect(() => {
    document.title = t("navbar.notification.Notifications");
  }, []);
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <NavigationBar xcount={countData} />
      <div className="setting" id="notification-section">
        <div className="notification-section">
          <div className="notification-container">
            <div className="container-1240">
              <div className="notificationpage-title">
                {t("navbar.notification.NotificationsTitle")}
              </div>
            </div>
            <div className="tab-part">
              <Tabs
                defaultActiveKey="All"
                id="justify-tab-example"
                className="notification-tab"
                justify
                onSelect={(e) => {
                  setActiveTabKey(e);
                }}
              >
                {/*  All tab  */}
                <Tab eventKey="All" title={t("navbar.notification.All")}>
                  {/* no data yet */}
                  {notificationData?.length <= 0 ? (
                    <div className="Empty-notification-dashboard">
                      <div className="Empty-notification-div">
                        <img src="/Searchsquare.svg" />
                        <div className="title">
                          {t("navbar.notification.Nodatayet")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="accordion-notification-container">
                      {notificationData?.map((item, index) => (
                        <div
                          className="w-100 Accordion-section"
                          style={{
                            backgroundColor: item.isRead
                              ? "#ffffff"
                              : "#F2F3F7",
                          }}
                          key={index}
                          onClick={() => handleNotificationModelShow(item._id)}
                        >
                          <div className="Accordion-main-containt">
                            <div className="d-flex">
                              <img src="/email notification.svg" />
                              <div>
                                <div className="notification-subject">
                                  {localStorage.getItem("language") == "en"
                                    ? item.title.en
                                    : item.title.ua}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="notification-date-time">
                                {moment
                                  .unix(item.timestamp)
                                  .format("DD/MM/YYYY")}{" "}
                                {moment.unix(item.timestamp).format("HH:mm")}
                              </div>
                            </div>
                          </div>
                          <div className="Accordion-body-hide-description">
                            {localStorage.getItem("language") == "en"
                              ? item.body.en
                              : item.body.ua}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* pagination */}
                  {NotificationCount <= 10 ? (
                    ""
                  ) : (
                    <div className="pagination-section">
                      <InputButton
                        type="icon-button"
                        addBtnClass={"Previous-btn"}
                        addSpan={
                          <span>
                            <img src="/left arrow.svg" />
                          </span>
                        }
                        onClick={(e) => handlePagination(e, currentState - 1)}
                        disabled={currentState <= 0}
                        inputLabel={t("navbar.notification.Previous")}
                      />
                      <div className="d-flex flex-wrap">
                        {[...Array(pageState)]?.map((page, i) => (
                          <div
                            className="d-flex"
                            key={i}
                            style={{
                              backgroundColor:
                                i === currentState ? "#F2F3F7" : "",
                              borderRadius: "8px",
                            }}
                          >
                            <div
                              className="pageCount"
                              onClick={(e) => handlePagination(e, i)}
                            >
                              {i + 1}
                            </div>
                          </div>
                        ))}
                      </div>
                      <InputButton
                        type="icon-button"
                        addBtnClass={"Next-btn"}
                        addEditBtn={
                          <img
                            className="edit-icon"
                            src="/notificationrigt arrow.svg"
                          ></img>
                        }
                        disabled={currentState >= pageState - 1}
                        onClick={(e) => handlePagination(e, currentState + 1)}
                        inputLabel={t("navbar.notification.Next")}
                      />
                    </div>
                  )}
                </Tab>

                {/* Predictions tab */}
                <Tab
                  eventKey="Predictions"
                  title={t("navbar.notification.Predictions")}
                >
                  {/* no data yet */}
                  {notificationData.length <= 0 ? (
                    <div className="Empty-notification-dashboard">
                      <div className="Empty-notification-div">
                        <img src="/Searchsquare.svg" />
                        <div className="title">
                          {t("navbar.notification.Nodatayet")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="accordion-notification-container">
                      {notificationData?.map((item, index) => (
                        <div
                          className="w-100 Accordion-section"
                          style={{
                            backgroundColor: item.isRead
                              ? "#ffffff"
                              : "#F2F3F7",
                          }}
                          key={index}
                          onClick={() => handleNotificationModelShow(item._id)}
                        >
                          <div className="Accordion-main-containt">
                            <div className="d-flex">
                              <img src="/email notification.svg" />
                              <div>
                                <div className="notification-subject">
                                  {localStorage.getItem("language") == "en"
                                    ? item.title.en
                                    : item.title.ua}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="notification-date-time">
                                {moment
                                  .unix(item.timestamp)
                                  .format("DD/MM/YYYY")}{" "}
                                {moment.unix(item.timestamp).format("HH:mm")}
                              </div>
                            </div>
                          </div>
                          <div className="Accordion-body-hide-description">
                            {localStorage.getItem("language") == "en"
                              ? item.body.en
                              : item.body.ua}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {/* pagination */}
                  {NotificationCount <= 10 ? (
                    ""
                  ) : (
                    <div className="pagination-section">
                      <InputButton
                        type="icon-button"
                        addBtnClass={"Previous-btn"}
                        addSpan={
                          <span>
                            <img src="/left arrow.svg" />
                          </span>
                        }
                        onClick={(e) => handlePagination(e, currentState - 1)}
                        disabled={currentState <= 0}
                        inputLabel={t("navbar.notification.Previous")}
                      />
                      <div className="d-flex flex-wrap">
                        {[...Array(pageState)].map((page, i) => (
                          <div
                            className="d-flex"
                            key={i}
                            style={{
                              backgroundColor:
                                i === currentState ? "#F2F3F7" : "",
                              borderRadius: "8px",
                            }}
                          >
                            <div
                              className="pageCount"
                              onClick={(e) => handlePagination(e, i)}
                            >
                              {i + 1}
                            </div>
                          </div>
                        ))}
                      </div>
                      <InputButton
                        type="icon-button"
                        addBtnClass={"Next-btn"}
                        addEditBtn={
                          <img
                            className="edit-icon"
                            src="/notificationrigt arrow.svg"
                          ></img>
                        }
                        disabled={currentState >= pageState - 1}
                        onClick={(e) => handlePagination(e, currentState + 1)}
                        inputLabel={t("navbar.notification.Next")}
                      />
                    </div>
                  )}
                </Tab>
                {/* Users activity tab */}
                <Tab
                  eventKey="Usersactivity"
                  className="Usersactivity-tab"
                  title={t("navbar.notification.Usersactivity")}
                >
                  {/* no data yet */}
                  {notificationData.length <= 0 ? (
                    <div className="Empty-notification-dashboard">
                      <div className="Empty-notification-div">
                        <img src="/Searchsquare.svg" />
                        <div className="title">
                          {t("navbar.notification.Nodatayet")}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="accordion-notification-container">
                      {notificationData?.map((item, index) => (
                        <div
                          className="w-100 Accordion-section"
                          style={{
                            backgroundColor: item.isRead
                              ? "#ffffff"
                              : "#F2F3F7",
                          }}
                          key={index}
                          onClick={() => handleNotificationModelShow(item._id)}
                        >
                          <div className="Accordion-main-containt">
                            <div className="d-flex">
                              <img src="/email notification.svg" />
                              <div>
                                <div className="notification-subject">
                                  {localStorage.getItem("language") == "en"
                                    ? item.title.en
                                    : item.title.ua}
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <div className="notification-date-time">
                                {moment
                                  .unix(item.timestamp)
                                  .format("DD/MM/YYYY")}{" "}
                                {moment.unix(item.timestamp).format("HH:mm")}
                              </div>
                            </div>
                          </div>
                          <div className="Accordion-body-hide-description">
                            {localStorage.getItem("language") == "en"
                              ? item.body.en
                              : item.body.ua}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* pagination */}
                  {NotificationCount <= 10 ? (
                    ""
                  ) : (
                    <div className="pagination-section">
                      <InputButton
                        type="icon-button"
                        addBtnClass={"Previous-btn"}
                        addSpan={
                          <span>
                            <img src="/left arrow.svg" />
                          </span>
                        }
                        onClick={(e) => handlePagination(e, currentState - 1)}
                        disabled={currentState <= 0}
                        inputLabel={t("navbar.notification.Previous")}
                      />
                      <div className="d-flex flex-wrap">
                        {[...Array(pageState)].map((page, i) => (
                          <div
                            className="d-flex"
                            key={i}
                            style={{
                              backgroundColor:
                                i === currentState ? "#F2F3F7" : "",
                              borderRadius: "8px",
                            }}
                          >
                            <div
                              className="pageCount"
                              onClick={(e) => handlePagination(e, i)}
                            >
                              {i + 1}
                            </div>
                          </div>
                        ))}
                      </div>
                      <InputButton
                        type="icon-button"
                        addBtnClass={"Next-btn"}
                        addEditBtn={
                          <img
                            className="edit-icon"
                            src="/notificationrigt arrow.svg"
                          ></img>
                        }
                        disabled={currentState >= pageState - 1}
                        onClick={(e) => handlePagination(e, currentState + 1)}
                        inputLabel={t("navbar.notification.Next")}
                      />
                    </div>
                  )}
                </Tab>
              </Tabs>
              {/* notification-Model */}
              <NotificationModal
                id={notificationId}
                isModel={modelShow}
                show={handleNotificationModelShow}
                hide={handleNoticationModelHide}
              />
            </div>
          </div>
        </div>
      </div>
      <HomePageFooter />
    </>
  );
};

export default Notification;
