import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import '../searchBar/searchBar.css'

const SearchBar = (props) => {
    const [addMainClass, setAddMainClass] = useState("filtered-items-containter")
    const [scrollContainer, setScrollContiner] = useState("filtered-items")
    useEffect(() => {
        if (props.addClass) {
            setAddMainClass(props.addClass)
        } else {
            setAddMainClass("filtered-items-containter")
        }
        if (props.addMainContainer) {
            setScrollContiner("filtered-items" + " " + props.addMainContainer)
        } else {
            setScrollContiner("filtered-items")
        }
        if (props.isSearchBar) {
            setScrollContiner("")
        } else {
            setAddMainClass("filtered-items-containter")
        }
    })
    return (
        <>
            {
                props.showSuggetions ?
                    <div className='showSuggetions-filter'>
                        {props.isSearchBar ? " " : <div className="searchbar dropDownSearchbar">
                            <span>{localStorage.getItem("token") ? <img src="/Search.svg" /> : <img src='/searchbarLocked.svg' />}</span>
                            <input
                                type="text"
                                className="form-control searchbar-feild dropDownSearchbar-feild"
                                id=""
                                placeholder={props.inputPlaceHolder}
                                onChange={props.onChange}
                                name=""
                            />
                        </div>}
                        <div>
                            <div className={scrollContainer}>
                                {props.children ? props.children : ""}
                            </div>
                            {props.displayMsg ? <div className="filter-sportDataEmpty-msg">{props.errorMsg}</div> : ""}
                        </div>

                    </div>
                    : <div className={props.addClass ? props.addClass + " searchbar" : " searchbar"}>
                        <span>{props.disabled ? <img src='/searchbarLocked.svg' /> : <img src="/Search.svg" />}</span>
                        {props.disabled ? <div className='searchbarLocked-tooltip'>
                            {props.lockSearchBarMsg}
                        </div> : ""}
                        <input
                            type="text"
                            className="form-control searchbar-feild"
                            id=""
                            placeholder={props.inputPlaceHolder}
                            onChange={props.onChange}
                            name=""
                            disabled={props.disabled}
                        />
                    </div>
            }
            {/* <div className={props.addClass ? props.addClass + " searchbar" : " searchbar"}>
                <span><img src="/Search.svg" /></span>
                <input
                    type="text"
                    className="form-control searchbar-feild"
                    id=""
                    placeholder={props.inputPlaceHolder}
                    onChange={props.onChange}
                    name=""
                />
            </div> */}
        </>
    )
}
export default SearchBar;