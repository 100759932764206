import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { dynamicActivate } from "../i18n";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import LoadingComponent from "./molecule/loader/LoadingComponent";
import InputButton from "./molecule/InputButton";
import NavbarNotification from "./molecule/navbarNotification/NavbarNotification";

import socketService from "../services/socketService";
import apiService from "../services/apiService";
import { formatPrice } from "../helpers/formater";
import { clearEvent, setLanguage } from "../store/betSlice";

import "../css/navbar.css";

const NavigationBar = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const location = useLocation();

  const [uaLink, setUaLink] = useState("");
  const [enLink, setEnLink] = useState("");

  const [userProfile, setUSerProfile] = useState({});
  const [countData, setCountData] = useState(0);
  const [navClassList, setNavClassList] = useState([]);
  const [currencyModelShow, setCurrencyModelshow] = useState(
    "currency-suggestion currency-part-hide"
  );
  const [languageUAactive, setLanguaeUAActive] = useState(
    "Українська-UA-dropDown-active"
  );
  const [languageENactive, setLanguaeEnActive] = useState(
    "Українська-UA-English-EN-dropDown"
  );
  const [navbarModelShow, setNavbarModelShow] = useState(
    "navbar-suggestion navbar-model-hide"
  );
  const [languageModelShow, setLanguageModelshow] = useState(
    "language-suggestion all-languages-hide"
  );
  const [showNotification, setShowNotification] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const increamentUserCount = () => {
    setCountData(countData + 1);
  };

  function listenForOutsideClicks() {
    return () => {
      if (listening) return;
      if (!menuRef.current) return;
      setListening(true);
      ["mousedown"].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRef?.current?.contains(evt.target)) {
            // handleLanguageDropDown()
          } else {
            setLanguageModelshow("language-suggestion all-languages-hide");
          }
        });
      });
    };
  }
  const menuRefCurrency = useRef(null);
  const [listeningCurrency, setListeningCurrency] = useState(false);
  function listenForOutsideClicksCurrency() {
    return () => {
      if (listeningCurrency) return;
      if (!menuRefCurrency.current) return;
      setListeningCurrency(true);
      ["mousedown"].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRefCurrency?.current?.contains(evt.target)) {
            // handleLanguageDropDown()
          } else {
            setCurrencyModelshow("currency-suggestion currency-part-hide");
          }
        });
      });
    };
  }
  const menuRefnavProfile = useRef(null);
  const [listeningnavProfile, setListeningNavProfile] = useState(false);
  function listenForOutsideClicksnavProfile() {
    return () => {
      if (listeningnavProfile) return;
      if (!menuRefnavProfile.current) return;
      setListeningNavProfile(true);
      ["mousedown"].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRefnavProfile?.current?.contains(evt.target)) {
            // handleLanguageDropDown()
          } else {
            setNavbarModelShow("navbar-suggestion navbar-model-hide");
          }
        });
      });
    };
  }
  useEffect(listenForOutsideClicks(), [languageModelShow]);
  useEffect(listenForOutsideClicksCurrency(), [currencyModelShow]);
  useEffect(listenForOutsideClicksnavProfile(), [navbarModelShow]);

  useEffect(() => {
    // dynamicActivate(localStorage.getItem("language"));
    if (apiService.getProfileFromStorage()?.Language == "ua") {
      setLanguaeUAActive("Українська-UA-dropDown-active");
      setLanguaeEnActive("Українська-UA-English-EN-dropDown");
    } else if (apiService.getProfileFromStorage()?.Language == "en") {
      setLanguaeEnActive("English-EN-dropDown-active");
      setLanguaeUAActive("Українська-UA-English-EN-dropDown");
    } else {
      setLanguaeEnActive("English-EN-dropDown-active");
      setLanguaeUAActive("Українська-UA-English-EN-dropDown");
    }
  }, [localStorage.getItem("profile")]);

  const handleLogout = () => {
    // localStorage.clear();
    localStorage.removeItem("createPrediction");
    localStorage.removeItem("currency");
    localStorage.removeItem("bets");
    localStorage.removeItem("userId");
    localStorage.removeItem("profile");
    localStorage.removeItem("prediction-list");
    localStorage.removeItem("token");
    localStorage.removeItem("lastOddsMap");
    localStorage.removeItem("coinData");
    navigate("/signin");
    window.location.reload();
  };

  const handleNavbarProfile = () => {
    if (navbarModelShow === "navbar-suggestion navbar-model-show") {
      setNavbarModelShow("navbar-suggestion navbar-model-hide");
    } else {
      setNavbarModelShow("navbar-suggestion navbar-model-show");
    }
    setLanguageModelshow("language-suggestion all-languages-hide");
    setCurrencyModelshow("currency-suggestion currency-part-hide");
  };

  const handleStoreLanguages = async (languages) => {
    localStorage.setItem("language", languages);
    dynamicActivate(languages);
    dispatch(setLanguage(languages));
    dispatch(clearEvent());

    const res = await apiService.updateUserProfile({ Language: languages });

    if (res) {
      if (apiService.getProfileFromStorage()?.Language == "ua") {
        setLanguaeUAActive("Українська-UA-dropDown-active");
        setLanguaeEnActive("Українська-UA-English-EN-dropDown");
      } else if (apiService.getProfileFromStorage()?.Language == "en") {
        setLanguaeEnActive("English-EN-dropDown-active");
        setLanguaeUAActive("Українська-UA-English-EN-dropDown");
      } else {
        setLanguaeEnActive("English-EN-dropDown-active");
        setLanguaeUAActive("Українська-UA-English-EN-dropDown");
      }
    }

    // window.location.reload();
  };

  useEffect(() => {
    const currentPath = location.pathname;

    if (currentPath.includes("/en/")) {
      const uaPath = currentPath.replace(/^\/en/, "");
      const enPath = currentPath;
      setEnLink(enPath);
      setUaLink(uaPath);

      localStorage.setItem("language", "en");
      dynamicActivate("en");

      dispatch(setLanguage("en"));
      dispatch(clearEvent());
    } else {
      const enPath = `/en${currentPath}`;
      const uaPath = currentPath;
      setEnLink(enPath);
      setUaLink(uaPath);

      localStorage.setItem("language", "ua");
      dynamicActivate("ua");

      dispatch(setLanguage("ua"));
      dispatch(clearEvent());
    }
  }, []);

  const handleStoreCurrency = async (currency) => {
    localStorage.setItem("currency", currency);
    const res = await apiService.updateUserProfile({ Currency: currency });
    if (res) {
      window.location.reload();
    }
  };

  const handleCurrency = () => {
    setNavbarModelShow("navbar-suggestion navbar-model-hide");
    setLanguageModelshow("language-suggestion all-languages-hide");
    if (currencyModelShow === "currency-suggestion currency-part-show") {
      setCurrencyModelshow("currency-suggestion currency-part-hide");
    } else {
      setCurrencyModelshow("currency-suggestion currency-part-show");
    }
  };
  const getUserData = async () => {
    const res = await apiService.updateUserProfile({
      Language: localStorage.getItem("language"),
    });
    // const res = await apiService.getUserProfile();
    const profile = await apiService.getUserProfileCheckAndSet();
    if (!profile || profile == "null") {
      window.history.go(-1);
      return;
    }
    setUSerProfile(profile);
    // props.increamentUserCountData()
  };

  useEffect(() => {
    // document.title = "Capperauditor";
    getUserData();
  }, [props.countData]);

  const handleLanguage = () => {
    setNavbarModelShow("navbar-suggestion navbar-model-hide");
    setCurrencyModelshow("currency-suggestion currency-part-hide");
    if (languageModelShow === "language-suggestion all-languages-show") {
      setLanguageModelshow("language-suggestion all-languages-hide");
    } else {
      setLanguageModelshow("language-suggestion all-languages-show");
    }
  };
  const hangleModileToggle = () => {
    let showClass = document.querySelectorAll(".navbar-collapse.show");
    let showClass1 = document.querySelectorAll(
      ".parant-collapse.navbar-collapse.collapse"
    );
    if (showClass) {
      {
        const isOverflow = document.getElementsByClassName("hidden-overFlow");
        if (isOverflow.length < 1) {
          document.body.classList.add("hidden-overFlow");
        } else {
          document.body.classList.remove("hidden-overFlow");
        }
      }
    } else if (showClass1) {
      document.body.classList.remove("hidden-overFlow");
    }
  };
  const notifyMe = async (data) => {
    setShowNotification((oldState) => [data, ...oldState]);
    setNotificationCount((oldState) => oldState + 1);
  };
  const getoldNotification = async () => {
    const res = await apiService.getNotification();
    if (res) {
      setShowNotification(res.resData);
      setNotificationCount(res.countNotification);
    }
  };
  useEffect(() => {
    // console.log("Count updated...")
  }, [notificationCount]);
  useEffect(() => {
    getoldNotification();
    socketService.subscribe(
      `notification/${localStorage.getItem("userId")}`,
      notifyMe
    );
  }, [countData, props.xcount]);
  const [userCoins, setUserCoins] = useState("");

  const getUserCoin = async () => {
    const res = await apiService.getUserCoin();
    res.map((i) => {
      if (i.coinType == "TOTAL") {
        setUserCoins(formatPrice(i.totalAmount, true));
        localStorage.setItem("coinData", i.totalAmount);
      }
    });
  };
  useEffect(() => {
    getUserCoin();
  }, [
    props.pageCountRefresh,
    props.pageRefresh,
    props.countData,
    props.totalCoin,
    props.oddsActive,
  ]);
  const handleWallet = () => {
    if (localStorage.getItem("language") === "en") {
      navigate("/en/wallet");
    } else {
      navigate("/wallet");
    }
  };
  return (
    <>
      {/* --------- navbar --------- */}
      <Navbar
        className={`navbar-section logedInUser-navbar ${navClassList.join(
          " "
        )}`}
      >
        <div
          className="container-1240 d-flex topnav topnav-container align-items-center"
          id="myTopnav"
        >
          <div className="navbar-logo d-flex align-items-center">
            <Link
              to={
                localStorage.getItem("language") === "ua" ? "/feed" : "/en/feed"
              }
            >
              <img src="/navbar-logo.svg" alt="Logo" />
            </Link>
            {/* mobile-coin */}
            <div className="mobile-coin-section" onClick={handleWallet}>
              <img
                src="/CAP coin.png"
                className="pe-1 coin-navbar-image"
                alt=""
                // style={{ maxWidth: "34px", width: "100%" }}
              />
              <div className="coin-total-count">
                {formatPrice(localStorage.getItem("coinData"), true)}
              </div>
            </div>
          </div>
          <Navbar.Toggle
            className="test"
            aria-controls="basic-navbar-nav"
            onClick={() => hangleModileToggle()}
          />
          <Navbar.Collapse
            id="basic-navbar-nav parant-collapse"
            className="parant-collapse logedin-parant-collapse"
          >
            <Nav className="me-auto navbar-nav">
              <a
                href={
                  localStorage.getItem("language") === "ua"
                    ? "/userprofile"
                    : "/en/userprofile"
                }
                className="navlink userProfile-navLink"
              >
                {t("navbar.myProfile")}
              </a>
              <a
                href={
                  localStorage.getItem("language") === "ua"
                    ? "/feed"
                    : "/en/feed"
                }
                className="navlink feed-navLink"
              >
                {t("navbar.feed")}
              </a>
              <a
                href={
                  localStorage.getItem("language") === "ua"
                    ? "/sportline"
                    : "/en/sportline"
                }
                className="navlink feed-navLink"
              >
                {t("sportLine.sportlineTitle")}
              </a>
              <a
                href={
                  localStorage.getItem("language") === "ua"
                    ? "/leaderboard"
                    : "/en/leaderboard"
                }
                className="navlink feed-navLink"
              >
                {t("navbar.Leaderboard")}
              </a>
              <a
                href={
                  localStorage.getItem("language") === "ua"
                    ? "/marketplace"
                    : "/en/marketplace"
                }
                className="navlink feed-navLink"
              >
                {t("navbar.Marketplace")}
              </a>
              {apiService.isAdmin() ? (
                <a
                  href={
                    localStorage.getItem("language") === "ua"
                      ? "/admin"
                      : "/en/admin"
                  }
                  className="navlink admin-navlink"
                >
                  {t("navbar.AdminPanel")}
                </a>
              ) : (
                ""
              )}
            </Nav>
            <div className="right-side">
              {/* lerge screen coin */}
              <div
                className="learge-screen-coin-section"
                onClick={handleWallet}
              >
                <img
                  src="/CAP coin.png"
                  className="coin-navbar-image"
                  alt=""
                  // style={{ maxWidth: "34px", width: "100%" }}
                />
                <div className="coin-total-count ps-2">
                  {formatPrice(localStorage.getItem("coinData"), true)}
                </div>
              </div>
              <div className="country-language d-flex justify-content-between">
                <div className="coin-side-border"></div>
                <div
                  className="language-toggle d-flex"
                  onClick={handleLanguage}
                >
                  <div
                    className="country-part d-flex align-items-center"
                    ref={menuRef}
                    onClick={handleLanguage}
                  >
                    <div className="country-symbol">
                      <img
                        src={
                          localStorage.getItem("language")
                            ? `/${localStorage.getItem("language")}.svg`
                            : apiService.getProfileFromStorage()?.Language ==
                              "en"
                            ? "/en.svg"
                            : "/ua.svg"
                        }
                      />
                    </div>
                    <div className="mobile-country-name">
                      {localStorage.getItem("language")
                        ? localStorage.getItem("language") === "en"
                          ? "English"
                          : "Українська"
                        : apiService.getProfileFromStorage()?.Language == "en"
                        ? "English"
                        : "Українська"}{" "}
                      <span style={{ color: "#7F87B3" }}>
                        {localStorage.getItem("language")
                          ? localStorage.getItem("language").toUpperCase()
                          : apiService.getProfileFromStorage()?.Language == "en"
                          ? "EN"
                          : "UA"}
                      </span>
                    </div>
                    <div className="country-name">
                      {localStorage.getItem("language")
                        ? localStorage.getItem("language").toUpperCase()
                        : apiService
                            .getProfileFromStorage()
                            ?.Language?.toUpperCase()}
                    </div>
                  </div>
                  {languageModelShow ===
                  "language-suggestion all-languages-show" ? (
                    <img src="/up.svg" className="language-toggle-icon"></img>
                  ) : (
                    <img src="/down.svg" className="language-toggle-icon"></img>
                  )}
                </div>
                <div className={languageModelShow}>
                  <a
                    href={uaLink}
                    className="all-languages d-flex align-items-center"
                  >
                    <div className="slogo">
                      <img src="/ua.svg" />
                    </div>
                    <div className="navbar-language-name">
                      Українська <span style={{ color: "#7F87B3" }}>UA</span>
                    </div>
                  </a>
                  <a
                    href={enLink}
                    className="all-languages d-flex align-items-center"
                  >
                    <div className="slogo">
                      <img src="/en.svg" />
                    </div>
                    <div className="navbar-language-name">
                      English <span style={{ color: "#7F87B3" }}>EN</span>
                    </div>
                  </a>
                </div>
                {/* notification */}
                <Link
                  to={
                    localStorage.getItem("language") === "ua"
                      ? "/wallet"
                      : "/en/wallet"
                  }
                  className="setting-profile-part"
                >
                  <div className="navbar-profile-detail">
                    {t("wallet.WalletTitle")}
                  </div>
                </Link>
                <Link
                  to={
                    localStorage.getItem("language") === "ua"
                      ? "/achievements"
                      : "/en/achievements"
                  }
                  className="setting-profile-part"
                >
                  <div className="navbar-profile-detail">
                    {t("rewards.reward")}
                  </div>
                </Link>
                <div className="navbar-notification-part">
                  <div className="notification-img">
                    <div className="notificationIcon-icon">
                      <svg
                        className="notification-svg"
                        width="17"
                        height="20"
                        viewBox="0 0 17 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          className="notificationIcon-icon-path"
                          d="M6.46318 17.2279C6.96309 17.1222 10.0093 17.1222 10.5092 17.2279C10.9366 17.3266 11.3987 17.5573 11.3987 18.0608C11.3738 18.5402 11.0926 18.9653 10.704 19.2352C10.2001 19.628 9.60875 19.8767 8.99057 19.9664C8.64868 20.0107 8.31276 20.0117 7.98279 19.9664C7.36362 19.8767 6.77227 19.628 6.26938 19.2342C5.87978 18.9653 5.59852 18.5402 5.57367 18.0608C5.57367 17.5573 6.03582 17.3266 6.46318 17.2279ZM8.54522 0C10.6254 0 12.7502 0.987019 14.0125 2.62466C14.8314 3.67916 15.2071 4.73265 15.2071 6.3703V6.79633C15.2071 8.05226 15.539 8.79253 16.2695 9.64559C16.8231 10.2741 17 11.0808 17 11.956C17 12.8302 16.7128 13.6601 16.1373 14.3339C15.384 15.1417 14.3215 15.6573 13.2372 15.747C11.6659 15.8809 10.0937 15.9937 8.5005 15.9937C6.90634 15.9937 5.33505 15.9263 3.76375 15.747C2.67846 15.6573 1.61602 15.1417 0.863666 14.3339C0.28822 13.6601 0 12.8302 0 11.956C0 11.0808 0.177901 10.2741 0.730488 9.64559C1.48384 8.79253 1.79392 8.05226 1.79392 6.79633V6.3703C1.79392 4.68834 2.21333 3.58852 3.077 2.51186C4.36106 0.941697 6.41935 0 8.45577 0H8.54522Z"
                          fill="#7F87B3"
                        />
                      </svg>
                    </div>
                    <a
                      href={
                        localStorage.getItem("language") === "ua"
                          ? "/notification"
                          : "/en/notification"
                      }
                      className="notification-mobile-title pe-2"
                    >
                      {t("navbar.notification.Notifications")}
                    </a>
                    {notificationCount > 0 ? (
                      <span className="notification-button__badge ">
                        {notificationCount}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="desktop-notification-suggestion">
                    <NavbarNotification
                      countData={countData}
                      increamentUserCount={increamentUserCount}
                      showNotification={showNotification}
                    />
                  </div>
                </div>
                <div
                  className="currancy-toggle d-flex"
                  onClick={handleCurrency}
                >
                  {/* <div
                  className="language-part d-flex align-items-center" ref={menuRefCurrency}
                  onClick={handleCurrency}
                > */}
                  {/* <div className="country-symbol">
                      <img
                        src={
                          localStorage.getItem("currency")
                            ? `/${localStorage.getItem("currency")}.svg`
                            : "/usd.svg"
                        }
                      />
                    </div> */}
                  {/* 
                  <div className="language-name">
                    {localStorage.getItem("currency")
                      ? localStorage.getItem("currency").toUpperCase()
                      : "USD"}
                  </div> */}
                </div>
                {/* {currencyModelShow === "currency-suggestion currency-part-show" ?
                  <img src="/up.svg" className="currancy-toggle-icon"></img> : <img src="/down.svg" className="currancy-toggle-icon"></img>
                } */}
              </div>
              <div className={currencyModelShow}>
                <div
                  className="currency-part d-flex"
                  onClick={() => handleStoreCurrency("usd")}
                >
                  <div className="slogo">
                    <img src="/usd.svg" />
                  </div>
                  <div className="navbar-currency-name">USD</div>
                </div>
                <div
                  className="currency-part d-flex"
                  onClick={() => handleStoreCurrency("euro")}
                >
                  <div className=" slogo">
                    <img src="/euro.svg" />
                  </div>
                  <div className="navbar-currency-name">EURO</div>
                </div>
                <div
                  className="currency-part d-flex"
                  onClick={() => handleStoreCurrency("uah")}
                >
                  <div className="slogo">
                    <img src="/ua.svg" />
                  </div>
                  <div className="navbar-currency-name">UAH</div>
                </div>
              </div>
              {/* </div> */}
              <div className="desktop-navigation-profile navigation-avatar d-flex mt-0 close-button">
                <div
                  className="avatar-name-containt"
                  ref={menuRefnavProfile}
                  style={{
                    display: !localStorage.getItem("token") ? "none" : "block",
                  }}
                  onClick={handleNavbarProfile}
                >
                  <div
                    className="navbar-avtar nav-avt rounded-circle d-flex align-items-center justify-content-center"
                    alt="Avatar "
                  >
                    {userProfile?.profileImgUrl !== "" ? (
                      <img
                        src={userProfile?.profileImgUrl}
                        value={userProfile?.profileImgUrl}
                        className="navbar-uploaded-img"
                        width="44px"
                        height="44px"
                        style={{ display: "block" }}
                      />
                    ) : (
                      ""
                    )}
                    {userProfile?.profileImgUrl == "" ? (
                      <p className="navbar-letter">
                        {userProfile.firstName
                          ? userProfile.firstName.charAt(0)
                          : "o"}
                      </p>
                    ) : (
                      ""
                    )}
                    <div
                      className={navbarModelShow}
                      style={{
                        display:
                          navbarModelShow ===
                          "navbar-suggestion navbar-model-show"
                            ? "block"
                            : "none",
                      }}
                    >
                      <div className="navbar-avatar-top">
                        <Link
                          to={
                            localStorage.getItem("language") === "ua"
                              ? "/userprofile"
                              : "/en/userprofile"
                          }
                          className="profile-part d-flex"
                        >
                          <div className="">
                            <img src="/userProfileImage.svg" />
                          </div>
                          <div className="navbar-profile-detail">
                            {t("navbar.profile")}
                          </div>
                        </Link>
                        <Link
                          to={
                            localStorage.getItem("language") === "ua"
                              ? "/wallet"
                              : "/en/wallet"
                          }
                          className="profile-part d-flex"
                        >
                          <div className="">
                            <img src="/userWallet.svg" />
                          </div>
                          <div className="navbar-profile-detail">
                            {t("wallet.WalletTitle")}
                          </div>
                        </Link>
                        <Link
                          to={
                            localStorage.getItem("language") === "ua"
                              ? "/achievements"
                              : "/en/achievements"
                          }
                          className="profile-part d-flex"
                        >
                          <div className="">
                            <img src="/Achievements.svg" />
                          </div>
                          <div className="navbar-profile-detail">
                            {t("rewards.reward")}
                          </div>
                        </Link>
                      </div>
                      <hr />
                      <Link
                        to={
                          localStorage.getItem("language") === "ua"
                            ? "/setting"
                            : "/en/setting"
                        }
                        className="profile-part d-flex mx-3"
                      >
                        <div className="">
                          <img src="/userSettings.svg" />
                        </div>
                        <div className="navbar-profile-detail">
                          {t("navbar.settings")}
                        </div>
                      </Link>
                      <div className="logout-part">
                        <div
                          className="d-flex logout-full-part"
                          onClick={handleLogout}
                        >
                          <div className="">
                            <img src="/userLogout.svg" />
                          </div>
                          <div className="navbar-profile-detail">
                            {t("navbar.logout")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="toggle-avatar-name">
                    {apiService.getProfileFromStorage()
                      ? apiService.getProfileFromStorage().firstName +
                        " " +
                        apiService.getProfileFromStorage().lastName
                      : ""}
                  </div>
                </div>
              </div>
              <Link
                to={
                  localStorage.getItem("language") === "ua"
                    ? "/setting"
                    : "/en/setting"
                }
                className="setting-profile-part"
              >
                <div className="navbar-profile-detail">
                  {t("navbar.settings")}
                </div>
              </Link>

              <div className="mobile-languages-dropdown">
                <div
                  className={languageUAactive}
                  onClick={() => handleStoreLanguages("ua")}
                >
                  <img src="/ua.svg" />
                  <span className="Українська-UA-label">
                    Українська <span style={{ color: "#7F87B3" }}>UA</span>
                  </span>
                </div>
                <div
                  className={languageENactive}
                  onClick={() => handleStoreLanguages("en")}
                >
                  <img src="/en.svg" />
                  <span className="English-EN-label">
                    English <span style={{ color: "#7F87B3" }}>EN</span>
                  </span>
                </div>
              </div>
              <div className="mobile-logout-button">
                <InputButton
                  type="icon-button"
                  addBtnClass={"mobile-logout-icon-button"}
                  addSpan={
                    <span>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.016 7.38948V6.45648C15.016 4.42148 13.366 2.77148 11.331 2.77148H6.45597C4.42197 2.77148 2.77197 4.42148 2.77197 6.45648V17.5865C2.77197 19.6215 4.42197 21.2715 6.45597 21.2715H11.341C13.37 21.2715 15.016 19.6265 15.016 17.5975V16.6545"
                          stroke="#FFFFFF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21.8096 12.0214H9.76855"
                          stroke="#FFFFFF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.8813 9.10632L21.8093 12.0213L18.8813 14.9373"
                          stroke="#FFFFFF"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  }
                  onClick={handleLogout}
                  inputLabel={t("navbar.logout")}
                />
              </div>
            </div>
          </Navbar.Collapse>
        </div>
        <LoadingComponent />
      </Navbar>
    </>
  );
};
export default NavigationBar;
