import React from "react";
import { Modal } from "react-bootstrap";
import { prepareFullName } from "../../helpers/formater";
import { useTranslation } from "react-i18next";

import "./modal.css";

const FollowingModal = ({
  loginUserId,
  items,
  isFollower,
  handleHide,
  showModal,
  handleUnFollow,
  handleFollow,
  handleUserProfile,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Modal show={showModal} onHide={handleHide} className="follower-model">
        <Modal.Header className="following-modal">
          <Modal.Title className="model-title">
            {isFollower ? t("profile.followers") : t("profile.following")}
          </Modal.Title>
          <div className="model-close-btn" onClick={handleHide}>
            <span>
              {" "}
              <img
                src="/close-squere-btn.svg"
                alt="close"
                className="close-button"
              />
            </span>
          </div>
        </Modal.Header>
        <div className="content-modal">
          {items.length === 0 && (
            <div className="no-follow-container">
              <img className="no-follow-img" src="/followers.svg" alt=""></img>
              <div className="no-follow-text-wraper">
                <h1 className="no-follow-title">
                  {isFollower
                    ? t("profile.noFollowersTitle")
                    : t("profile.noFollowingTitle")}
                </h1>
                <span className="no-follow-text">
                  {isFollower
                    ? t("profile.noFollowersText")
                    : t("profile.noFollowingText")}
                </span>
              </div>
            </div>
          )}
          {items?.map((item, index) => (
            <div className="follower-model-body" key={index}>
              <div className="user-frame d-flex">
                <div className="follower-user-circle" alt="">
                  {item?.profileImgUrl ? (
                    <img
                      src={item?.profileImgUrl}
                      className="follower-user-circle"
                      alt="document"
                    />
                  ) : (
                    <div className="navbar-avtar nav-avt rounded-circle d-flex align-items-center justify-content-center">
                      <p className="navbar-letter">
                        {item?.firstName?.charAt(0)}
                      </p>
                    </div>
                  )}
                </div>
                <div className="user-detail ">
                  <div
                    className="user-name username-hover"
                    onClick={() => handleUserProfile(item)}
                  >
                    {prepareFullName(item)}
                  </div>
                  <div className="userId">@{item.userName}</div>
                </div>
              </div>
              {item._id == loginUserId ? (
                <div className="d-flex align-items-center">
                  <button
                    className="follow-button"
                    style={{ display: "none" }}
                  ></button>
                </div>
              ) : (
                ""
              )}

              {item?.isFollowed == false ? (
                <div className="d-flex align-items-center">
                  {item._id == loginUserId ? (
                    <button
                      className="follow-button"
                      style={{ display: "none" }}
                    >
                      {t("profile.follow")}
                    </button>
                  ) : (
                    <button
                      className="follow-button"
                      onClick={() => handleFollow(item._id)}
                    >
                      {t("profile.follow")}
                    </button>
                  )}
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  {item._id == loginUserId ? (
                    <button
                      className="following-button"
                      lang={localStorage.getItem("language")}
                      style={{ display: "none" }}
                    >
                      <span> {t("profile.following")}</span>
                    </button>
                  ) : (
                    <button
                      className="following-button"
                      lang={localStorage.getItem("language")}
                      onClick={() => handleUnFollow(item._id)}
                    >
                      <span> {t("profile.following")}</span>
                    </button>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default FollowingModal;
