import React from "react";
import ContentLoader from "react-content-loader";

const AddPredictionModel = (props) => (
  <ContentLoader
    speed={2}
    viewBox="0 0 950 1000"
    backgroundColor="#F9F9FB"
    foregroundColor="#F9F9FB"
    {...props}
  >
    <rect x="30" y="15" rx="4" ry="4" width="30" height="15" />
    <rect x="70" y="15" rx="4" ry="4" width="250" height="15" />
    <rect x="400" y="15" rx="4" ry="4" width="30" height="15" />
    <rect x="500" y="15" rx="4" ry="4" width="30" height="15" />
    <rect x="600" y="15" rx="4" ry="4" width="30" height="15" />
    <rect x="700" y="15" rx="4" ry="4" width="30" height="15" />
    <rect x="800" y="15" rx="4" ry="4" width="30" height="15" />
    <rect x="900" y="15" rx="4" ry="4" width="30" height="15" />

    {/* 1 */}
    <rect x="30" y="85" rx="4" ry="4" width="40" height="8" />
    <circle cx="90" cy="75" r="13" />
    <circle cx="90" cy="105" r="13" />
    <rect x="115" y="75" rx="4" ry="4" width="160" height="6" />
    <rect x="115" y="100" rx="4" ry="4" width="160" height="6" />

    {/* 2 */}
    <rect x="393" y="80" rx="4" ry="4" width="45" height="25" />
    <rect x="493" y="80" rx="4" ry="4" width="45" height="25" />
    <rect x="593" y="80" rx="4" ry="4" width="45" height="25" />
    <rect x="693" y="80" rx="4" ry="4" width="45" height="25" />
    <rect x="793" y="80" rx="4" ry="4" width="45" height="25" />
    <rect x="893" y="80" rx="4" ry="4" width="45" height="25" />
    {/* ------- */}

    {/* 1 */}
    <rect x="30" y="185" rx="4" ry="4" width="40" height="8" />
    <circle cx="90" cy="175" r="13" />
    <circle cx="90" cy="205" r="13" />
    <rect x="115" y="175" rx="4" ry="4" width="160" height="6" />
    <rect x="115" y="200" rx="4" ry="4" width="160" height="6" />
    <rect x="393" y="175" rx="4" ry="4" width="45" height="25" />
    <rect x="493" y="175" rx="4" ry="4" width="45" height="25" />
    <rect x="593" y="175" rx="4" ry="4" width="45" height="25" />
    <rect x="693" y="175" rx="4" ry="4" width="45" height="25" />
    <rect x="793" y="175" rx="4" ry="4" width="45" height="25" />
    <rect x="893" y="175" rx="4" ry="4" width="45" height="25" />

    {/* ---------- */}
    <rect x="30" y="285" rx="4" ry="4" width="40" height="8" />
    <circle cx="90" cy="275" r="13" />
    <circle cx="90" cy="305" r="13" />
    <rect x="115" y="275" rx="4" ry="4" width="160" height="6" />
    <rect x="115" y="300" rx="4" ry="4" width="160" height="6" />
    <rect x="393" y="280" rx="4" ry="4" width="45" height="25" />
    <rect x="493" y="280" rx="4" ry="4" width="45" height="25" />
    <rect x="593" y="280" rx="4" ry="4" width="45" height="25" />
    <rect x="693" y="280" rx="4" ry="4" width="45" height="25" />
    <rect x="793" y="280" rx="4" ry="4" width="45" height="25" />
    <rect x="893" y="280" rx="4" ry="4" width="45" height="25" />

    {/* ---------- */}
    <rect x="30" y="385" rx="4" ry="4" width="40" height="8" />
    <circle cx="90" cy="375" r="13" />
    <circle cx="90" cy="405" r="13" />
    <rect x="115" y="375" rx="4" ry="4" width="160" height="6" />
    <rect x="115" y="400" rx="4" ry="4" width="160" height="6" />
    <rect x="393" y="380" rx="4" ry="4" width="45" height="25" />
    <rect x="493" y="380" rx="4" ry="4" width="45" height="25" />
    <rect x="593" y="380" rx="4" ry="4" width="45" height="25" />
    <rect x="693" y="380" rx="4" ry="4" width="45" height="25" />
    <rect x="793" y="380" rx="4" ry="4" width="45" height="25" />
    <rect x="893" y="380" rx="4" ry="4" width="45" height="25" />

    {/* -------------- */}
    <rect x="30" y="485" rx="4" ry="4" width="40" height="8" />
    <circle cx="90" cy="475" r="13" />
    <circle cx="90" cy="505" r="13" />
    <rect x="115" y="475" rx="4" ry="4" width="160" height="6" />
    <rect x="115" y="500" rx="4" ry="4" width="160" height="6" />
    <rect x="393" y="480" rx="4" ry="4" width="45" height="25" />
    <rect x="493" y="480" rx="4" ry="4" width="45" height="25" />
    <rect x="593" y="480" rx="4" ry="4" width="45" height="25" />
    <rect x="693" y="480" rx="4" ry="4" width="45" height="25" />
    <rect x="793" y="480" rx="4" ry="4" width="45" height="25" />
    <rect x="893" y="480" rx="4" ry="4" width="45" height="25" />
  </ContentLoader>
);
export default AddPredictionModel;
