import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import './rangeslider.css'

const RangeSlider = ({ min, max, onChange, up }) => {
    const [minVal, setMinVal] = useState(min);
    const [maxVal, setMaxVal] = useState(max);
    const minValRef = useRef(min);
    const maxValRef = useRef(max);
    const range = useRef(null);
    // Convert to percentage
    const getPercent = useCallback(
        (value) => Math.round(((value - min) / (max - min)) * 100),
        [min, max]
    );

    // Set width of the range to decrease from the left side
    useEffect(() => {
        const minPercent = getPercent(minVal);
        const maxPercent = getPercent(maxValRef.current);

        if (range.current) {
            range.current.style.left = `${minPercent}%`;
            if (minVal == 1.05 && maxVal == 20.05) {
                range.current.style.width = `100%`;
            } else {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }

        }
    }, [minVal, getPercent]);

    useEffect(() => {
        setMinVal(1.05)
        setMaxVal(20.05)
    }, [up])

    // Set width of the range to decrease from the right side
    useEffect(() => {
        const minPercent = getPercent(minValRef.current);
        const maxPercent = getPercent(maxVal);

        if (range.current) {
            if (minVal == 1.05 && maxVal == 20.05) {
                range.current.style.width = `100%`;
            } else {
                range.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [maxVal, getPercent]);

    // Get min and max values when their state changes
    useEffect(() => {
        if (minVal == 1.05 && maxVal == 20.05) {
            return
        } else {
            onChange({ min: minVal, max: maxVal });
        }
    }, [minVal, maxVal]);

    return (
        <div>
            <div className="form-group d-flex justify-content-between range-counter-div mb-3">
                <input type="text" className="form-control range-min-value" value={minVal} onChange={(e) => {
                    const value = parseFloat(parseFloat(e.target.value).toFixed(2))
                    if (!value) return;
                    setMinVal(value)
                }}
                    onBlur={(e) => {
                        const value = parseFloat(parseFloat(e.target.value).toFixed(2))
                        if (!value || value >= maxVal || value <= minVal) {
                            setMinVal(min)
                        }
                    }} />
                <input type="text" className="form-control range-max-value" value={maxVal ? maxVal == "20.05" ? "20.5+" : maxVal : "20.5+"} onChange={(e) => {
                    const value = parseFloat(parseFloat(e.target.value).toFixed(2))
                    if (!value) return;
                    setMaxVal(value)
                }}
                    onBlur={(e) => {
                        const value = parseFloat(parseFloat(e.target.value).toFixed(2))
                        if (!value || value >= maxVal || value <= minVal) {
                            setMaxVal(max)
                        }
                    }}
                />
            </div>

            <input
                type="range"
                min={min}
                max={max}
                value={minVal < min || minVal > max ? min : minVal}
                onChange={(event) => {
                    const value = Math.min(Number(event.target.value), maxVal);
                    setMinVal(value);
                    minValRef.current = value;
                }}
                className="thumb thumb--left"
                style={{ zIndex: minVal > max - 100 && "5" }}
                step="0.5"
            />
            <input
                type="range"
                min={min}
                max={max}
                value={maxVal > max || maxVal < min ? max : maxVal}
                onChange={(event) => {
                    const value = Math.max(Number(event.target.value), minVal);
                    setMaxVal(value);
                    maxValRef.current = value;
                }}
                className="thumb thumb--right"
                step="0.5"
            />
            <div>
                <div className="slider">
                    <div className="slider__track" />
                    <div ref={range} className="slider__range" />
                </div>
            </div>

        </div >
    );
};

RangeSlider.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};


export default RangeSlider;
