const handleTogglePasswordShow = (type, setType) => {
  if (type === "password") {
    setType("text");
  } else {
    setType("password");
  }
};

export const calculateSum = (num, double) => {
  let sum = 0;
  if (num) {
    for (let i = 1; i <= num; i++) {
      sum += double ? i * 2 : i;
    }
  }
  return sum;
};

export const determinePointsRange = (points) => {
  const ranges = {
    5: [113, 300],
    4: [103, 112],
    3: [82, 102],
    2: [60, 81],
    1: [0, 59],
  };

  for (const range in ranges) {
    const [min, max] = ranges[range];
    if (points >= min && points <= max) {
      return range;
    }
  }

  return "1";
};
