import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { eventBus } from "../../services/events";
import { CONSTANT } from "../../common/constant";
import { AuthUtils } from "../../AuthUtils";
import { isValidUserName, passValidation } from "../../helpers/validator";

import apiService from "../../services/apiService";

import Header from "../Header";

const SettingUpAccount = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [type, setType] = useState("password");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorClass, setErrorClass] = useState("mb-3");
  const [error, setError] = useState("");
  const [getCountryNameIp, setCountryNameIp] = useState({});
  const [isValidateSuccess, setIsValidateSuccess] = useState(false);
  const [checkValidation, setCheckValidation] = useState({
    length: "fa fa-check",
    uppercase: "fa fa-check",
    lowercase: "fa fa-check",
    specialChar: "fa fa-check",
    number: "fa fa-check",
  });

  const url = new URL(window.location.href);
  let token = url.searchParams.get("token");
  let id = url.searchParams.get("id");

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };

  const handleAddData = async (e) => {
    if (e.target.name === "userName") {
      const payload = {
        type: "userName",
        text: e.target.value,
      };
      setUserName(() => payload.text);
      let isName = isValidUserName(payload.text, t);
      if (!isName.isValid) {
        setErrorClass("mb-3 form-error");
        setError(isName.nameValidation);
      } else {
        setErrorClass("mb-3");
        setError("");
      }
      eventBus.emit(CONSTANT.SHOW_LOADER);
      const res = await apiService.checkValidation(payload);
      eventBus.emit(CONSTANT.HIDE_LOADER);
      if (!res.data) {
        setErrorClass("mb-3 form-error");
        setError(t("register.alreadyExistMsg"));
      }
    }
    if (e.target.value.length <= 0) {
      setErrorClass("mb-3");
      setError("");
    }
    if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };

  const getCountryIp = async () => {
    const response = await fetch("https://geolocation-db.com/json/");
    const data = await response.json();
    setCountryNameIp(data);
  };

  const verifyEmail = async () => {
    if (url.searchParams.has("token")) {
      eventBus.emit(CONSTANT.SHOW_LOADER);
      const res = await apiService.varifyEmailId(token);
      eventBus.emit(CONSTANT.HIDE_LOADER);
      if (res.data) {
        localStorage.setItem("token", token);
        localStorage.setItem("userId", id);
      } else {
        navigate("/signin");
      }
    }
  };

  // useEffect(() => {
  //   const url = new URL(window.location.href);
  //   let isValid = url.searchParams.get("token");
  //   if (!isValid) {
  //     navigate("/");
  //   }
  //   AuthUtils();
  //   getCountryIp();
  // }, []);

  const handleRegister = async (e) => {
    e.preventDefault();

    const response = await apiService.updateUserProfile({
      Language: localStorage.getItem("language"),
    });

    eventBus.emit(CONSTANT.SHOW_LOADER);
    
    const res = await apiService.updateProfileOAuth({
      userName: userName.split(" ").join("")?.toLowerCase(),
      password: password,
      country: getCountryNameIp.country_name || "ukraine",
      ipAddress: getCountryNameIp.IPv4 || "127.0.0.1",
      source: 'oAuth register page'
    });

    localStorage.setItem("token", token);
    localStorage.setItem("userId", id);

    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res.data) {
      navigate("/userprofile");
      // localStorage.removeItem("token")
      // localStorage.removeItem("userId")
      // navigate("/signin");
    } else {
      navigate("/signin");
    }
  };
  
  return (
    <>
      <Header isPointerEvent={true} />
      <section className="form-section">
        <div className="">
          <div className=" d-flex justify-content-center align-items-center">
            <div className="card card-settingaccount">
              <div className="card-body p-0 settingaccount-card-body">
                {/* ----- Register top ----- */}
                <div className="top">
                  <div
                    className="card-title  signup-card-title mt-0"
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      textAlign: "center",
                      marginTop: "4px",
                    }}
                  >
                    {t("register.title")}
                  </div>
                  <p
                    className="mb-4"
                    style={{
                      textAlign: "center",
                      fontSize: "16px",
                      fontWeight: "300",
                      color: "#7F87B3",
                      lineHeight: "24px",
                    }}
                  >
                    {t("settingUpAccount.subTitle")}
                  </p>
                </div>
                {/* ------ Register form ------  */}
                <div className="bottom">
                  <form className="setting-acc-form">
                    <div className={errorClass}>
                      <label
                        htmlFor="exampleInputuser"
                        className="form-label form-input-label"
                      >
                        {t("register.Username")}
                      </label>
                      <input
                        type="text"
                        className="form-control form-input-field"
                        id="exampleInputuser"
                        onChange={handleAddData}
                        name="userName"
                        placeholder={t("register.UsernamePLaceHolder")}
                        style={{
                          fontSize: "14px",
                          fontWeight: "300",
                          color: "#7F87B3",
                        }}
                        required
                      />
                      <p
                        style={{ fontSize: "14px" }}
                        className=""
                        // className = {username ? "form-error-line-show" : "form-error-line-hide"}
                      >
                        {error}
                      </p>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword"
                        className="form-label form-input-label"
                      >
                        {t("signUp.Password")}
                      </label>
                      <div className="input-icons">
                        <input
                          className="input-field form-control form-input-field"
                          type={type}
                          id="exampleInputPassword"
                          name="password"
                          onChange={handleAddData}
                          onKeyUp={(e) => passValidation(e, setCheckValidation, setIsValidateSuccess)}
                          placeholder={t("signUp.Enteryourpassword")}
                          style={{
                            fontSize: "14px",
                            fontWeight: "300",
                            color: "#7F87B3",
                          }}
                        />
                        <span className="toggle-icon" onClick={handleToggle}>
                          <i
                            className={
                              type == "password"
                                ? "fa fa-eye-slash"
                                : "fa fa-eye"
                            }
                          ></i>
                        </span>
                        <div
                          className="password-suggestion setting-up-page"
                          style={
                            password
                              ? isValidateSuccess
                                ? { display: "none" }
                                : { display: "block" }
                              : { display: "none" }
                          }
                          // style={{ display: "block" }}
                        >
                          <p className="mb-2">
                            {t("tooltip.Mustcontainatleast")}
                          </p>
                          <ul className="list-unstyled mb-0">
                            <li>
                              <i className={checkValidation.length}></i>
                              {t("tooltip.characters")}
                            </li>
                            <li>
                              <i className={checkValidation.lowercase}></i>
                              {t("tooltip.lowercasecharacter")}
                            </li>
                            <li>
                              <i className={checkValidation.uppercase}></i>
                              {t("tooltip.uppercasecharacter")}
                            </li>
                            <li>
                              <i className={checkValidation.number}></i>
                              {t("tooltip.number")}
                            </li>
                            <li>
                              <i className={checkValidation.specialChar}></i>
                              {t("tooltip.specialcharacter")}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3">
                      <button
                        className="form-control form-input-field-button"
                        id="exampleInputbutton"
                        disabled={
                          userName === "" ||
                          password === "" ||
                          isValidateSuccess === false ||
                          error !== ""
                        }
                        onClick={handleRegister}
                        style={{
                          pointerEvents:
                            userName === "" || password === "" ? "none" : "auto",
                        }}
                      >
                        {t("homePageSigninPage.Continue")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SettingUpAccount;
