import React from 'react';

const Notification = (props) => {
    return (
        <>
            <section>
                <div className="">
                    <div className={props.hideShow}>
                        <div className="d-flex align-items-center ok-img"><img src="ok.svg" width="18.5px" height="18.5px" className=""></img></div>
                        <div className="popup-content">{props.message}</div>
                        <div className="d-flex align-items-center close-img"><img src="Close-Square.svg" width="18.5px" height="18.5px" className=""></img></div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Notification;

