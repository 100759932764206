import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Model from "../../molecule/model/Model";
import InputButton from "../../molecule/InputButton";
import InputField from "../../molecule/InputField";
import SearchBar from "../../molecule/searchBar/SearchBar";
import apiService from "../../../services/apiService";
import { useEffect } from "react";
import { Environment } from "../../../environment";
import AdminUsertableCustomLoader from "../../Custom loader/AdminUsertableCustomLoader";
const UserTable = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [deleteUserModel, setDeleteUserModel] = useState(false);
  const [changePermissionUser, setChangePermissionUser] = useState({});
  const [ChangeUserPermissionModel, setChangeUserPermissionModel] =
    useState(false);
  const [showDropdown, setShowDropDown] = useState(
    "list-drop-down list-drop-down-hide "
  );
  const [saveChangesButton, setSaveChangtesButton] = useState(false);
  const [userData, setUserData] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [useCount, setUserCount] = useState(0);
  const [deleteUserNAme, setDeleteUserName] = useState("");
  const [deleteUserId, setDeleteUserId] = useState("");
  const [pageRefresh, setPageRefersh] = useState(0);
  const [countData, setCountData] = useState(0);
  const loginUserId = localStorage.getItem("userId");
  const [userPost, setUserPost] = useState("");
  const [balanceData, setBalanceData] = useState("");
  const [userPostLogo, setUserPostLogo] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const handleChangePermissionDropDown = () => {
    if (showDropdown === "list-drop-down list-drop-down-show") {
      setShowDropDown("list-drop-down list-drop-down-hide");
    } else {
      setShowDropDown("list-drop-down list-drop-down-show");
    }
  };
  const handleUserPermission = (name, logo) => {
    setUserPostLogo(logo);
    setUserPost(name);
    setSaveChangtesButton(true);
    setShowDropDown("list-drop-down list-drop-down-hide");
  };
  const handleChangePermission = (item, role) => {
    if (role == "User") {
      setUserPost(t("admin.SingleUser"));
      setUserPostLogo("single user.svg");
    } else {
      setUserPost(t("admin.Admin"));
      setUserPostLogo("admin-hover.svg");
    }
    setChangeUserPermissionModel(true);
    setChangePermissionUser(item);
  };
  // console.log("saveChangesButton:::", saveChangesButton);

  const handleHideChangePermission = () => {
    setChangeUserPermissionModel(false);
  };
  // edit permission
  const changeUserPermission = async () => {
    setChangeUserPermissionModel(false);
    const res = await apiService.changeUserPermission(
      userPost == "User" || userPost == "Користувач" ? "User" : "Admin",
      changePermissionUser._id,
      balanceData
    );
    setCountData(countData + 1);
    if (res) {
      if (!saveChangesButton) {
      } else {
        if (changePermissionUser._id == localStorage.getItem("userId")) {
          localStorage.removeItem("createPrediction");
          localStorage.removeItem("currency");
          localStorage.removeItem("bets");
          localStorage.removeItem("userId");
          localStorage.removeItem("profile");
          localStorage.removeItem("prediction-list");
          localStorage.removeItem("token");
          navigate("/signin");
          window.location.reload();
        }
      }
      if (balanceData !== "0") {
        props.handleRefreshPage();
      }
    }
  };
  const handleUserProfile = async (item) => {
    if (item._id?.toString() == loginUserId) {
      navigate(`/userprofile`);
    } else {
      navigate(`/profile/${item._id}`);
    }
  };

  // delete user
  const handleShowDeleteUser = (item) => {
    setDeleteUserModel(true);
    setDeleteUserId(item._id);
    setDeleteUserName(item.firstName + " " + item.lastName);
  };
  const handleDeleteUserData = async () => {
    const res = await apiService.deleteUserByAdmin(deleteUserId);
    setCountData(countData + 1);
    setDeleteUserModel(false);
  };
  const handleHideDeleteUser = () => {
    setDeleteUserModel(false);
  };
  // get all user
  const getAllUser = async () => {
    const res = await apiService.getAllUser({});
    setUserCount(res?.length);
    if (props.isSearchBar) {
      setUserData(res?.slice(0, 10));
      if (res?.length <= 10) {
        setShowMessage(true);
      }
    } else {
      setUserData(res?.slice(0, 5));
      if (res?.length <= 5) {
        setShowMessage(true);
      }
    }
    if (res) {
      setShowLoader(false);
    }
  };
  // lead more user
  const handleLoadMoreUsers = async () => {
    const res = await apiService.getAllUser({});
    setUserData(res);
    setShowMessage(true);
  };
  // user search
  const userSearch = async (e) => {
    const res = await apiService.getAllUser(e.target.value);
    setUserData(res);
  };
  useEffect(() => {
    getAllUser();
  }, [countData]);
  return (
    <>
      {/* userTable */}
      <div className="getAllUserTable">
        <div
          className="user-searchbar"
          style={{ display: props.isSearchBar ? "block" : "none" }}
        >
          <SearchBar
            onChange={userSearch}
            inputPlaceHolder={t("admin.adminSearchPlaceHolder")}
            addClass={"userTable-container"}
            // addMainContainer={"filter-tournament-container"}
          />
          <div className="allUserCount">
            <span className="pe-1">{useCount || 0}</span>
            {t("admin.usersfound")}
          </div>
        </div>
        {showLoader ? (
          <AdminUsertableCustomLoader />
        ) : (
          <table className="user-table">
            <thead className="heading">
              <tr>
                <th>{t("admin.userTable.user")}</th>
                <th>{t("admin.userTable.Country")}</th>
                <th>{t("admin.userTable.Email")}</th>
                <th>{t("admin.userTable.Status")}</th>
                <th>{t("admin.userTable.SRate")}</th>
                <th>{t("admin.userTable.Pred")}</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {userData?.map((item, index) => (
                <tr key={index}>
                  <td className="user-profile-containt-panel">
                    {!item.profileImgUrl ? (
                      <div
                        className="me-3 usertable-avtar nav-avt d-flex align-items-center justify-content-center"
                        alt="Avatar "
                      >
                        <p className="navbar-letter">
                          {item.lastName?.charAt(0)}
                        </p>
                      </div>
                    ) : (
                      <div className=" me-3">
                        <img
                          className="usertable-img"
                          src={item.profileImgUrl}
                        />
                      </div>
                    )}
                    <div>
                      <div
                        className="user-first-last-name"
                        onClick={() => handleUserProfile(item)}
                      >
                        {item.firstName === undefined &&
                        item.lastName === undefined
                          ? "-"
                          : item.firstName + " " + item.lastName}
                      </div>
                      <div className="user-name">
                        {item.userName === undefined
                          ? "-"
                          : "@" + item.userName}
                      </div>
                    </div>
                  </td>
                  <td className="Country-panel">
                    <div className="Country-name">{item.country}</div>
                    <div className="ip-count">
                      {" "}
                      {item.ipAddress ? (
                        "IP: " + item.ipAddress
                      ) : (
                        <span className="">-</span>
                      )}
                    </div>
                  </td>
                  <td className="email-panel">
                    <div className="email-id">{item.email}</div>
                    {item.isGoogle ? (
                      <div
                        className="signed-with-google-facebook"
                        onClick={() => {
                          window.location.href =
                            process.env.REACT_APP_googleOAuthUrl;
                        }}
                      >
                        Signed with Google
                      </div>
                    ) : item.isFacebook ? (
                      <div
                        className="signed-with-google-facebook"
                        onClick={() => {
                          window.location.href =
                            process.env.REACT_APP_facebookOAuthUrl;
                        }}
                      >
                        Signed with Facebook
                      </div>
                    ) : (
                      <div className="signed-with-google-facebook">
                        Signed with Email
                      </div>
                    )}
                  </td>
                  <td className="status-button">
                    {item.status == "Pending" ? (
                      <div className="PendingBtn">{t("admin.PendingBtn")}</div>
                    ) : (
                      <div className="ActivatedBtn">
                        {t("admin.ActivatedBtn")}
                      </div>
                    )}
                  </td>
                  <td className="s-rate-button">
                    <div className="blue-btn">{item.srate}%</div>
                    {/* <div className="yellow-btn">70%</div> */}
                    {/* <div className="red-btn">100%</div> */}
                  </td>
                  <td>
                    <div className="Pred-rate">{item.prediction}</div>
                  </td>
                  <td className="d-flex tooltip-icon justify-content-between delete-changepermission-icon">
                    {/* chage permission */}
                    <div className="d-flex align-items-center tooltip-change-permision-div">
                      <div className="">
                        <div
                          className={
                            localStorage.getItem("language") == "en"
                              ? "change-permision-button-tooltip"
                              : "change-permision-button-tooltip-ua"
                          }
                        >
                          {t("admin.UserSettings")}
                        </div>
                        <div
                          className="icon-container"
                          onClick={() =>
                            handleChangePermission(item, item?.role[0])
                          }
                        >
                          <svg
                            width="24"
                            className="ps-0 edit-icon"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              className="permission-icon-path"
                              d="M11.492 2.78906H7.753C4.678 2.78906 2.75 4.96606 2.75 8.04806V16.3621C2.75 19.4441 4.669 21.6211 7.753 21.6211H16.577C19.662 21.6211 21.581 19.4441 21.581 16.3621V12.3341"
                              stroke="#7F87B3"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              className="permission-icon-path"
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M8.82666 10.921L16.2997 3.44799C17.2307 2.51799 18.7397 2.51799 19.6707 3.44799L20.8877 4.66499C21.8187 5.59599 21.8187 7.10599 20.8877 8.03599L13.3787 15.545C12.9717 15.952 12.4197 16.181 11.8437 16.181H8.09766L8.19166 12.401C8.20566 11.845 8.43266 11.315 8.82666 10.921Z"
                              stroke="#7F87B3"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              className="permission-icon-path"
                              d="M15.1641 4.60254L19.7301 9.16854"
                              stroke="#7F87B3"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    {/* delete-tooltip */}
                    <div className="d-flex align-items-center tooltip-delete-user-div">
                      <div className="delete-user-button-tooltip">
                        {t("admin.DeleteUser")}
                      </div>
                      <div
                        className="icon-container"
                        onClick={() => handleShowDeleteUser(item)}
                      >
                        <img
                          src="delete-user-img.svg"
                          className="change-permision"
                          width="24px"
                          height="24px"
                        />
                        <img
                          src="delete-user-hover-img.svg"
                          className=""
                          width="24px"
                          height="24px"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* bottom button */}
        <div
          className=" justify-content-center"
          style={{ display: showMessage ? "none" : "flex" }}
        >
          {props.allUserBtn ? (
            useCount > 5 ? (
              <InputButton
                type={"secondary"}
                inputLabel={t("admin.SeeAllUsers")}
                addBtnClass={"SeeAllUsersBtn"}
                onClick={props.handleUserTab}
              />
            ) : (
              ""
            )
          ) : useCount > 10 ? (
            <InputButton
              type={"secondary"}
              inputLabel={t("admin.Loadmoreusers")}
              addBtnClass={"SeeAllUsersBtn"}
              onClick={handleLoadMoreUsers}
            />
          ) : (
            " "
          )}
        </div>
        <div
          className="show-boTTom-message justify-content-center"
          style={{ display: showMessage ? "flex" : "none" }}
        >
          {t("feed.showMessage")}
        </div>
        {/* delete user model */}
        <Model
          isModel={deleteUserModel}
          modelClassName={"delete-user-model"}
          hideModel={handleHideDeleteUser}
          modelTitle={t("profile.DeleteSport")}
        >
          <div className="delete-sport-Msg">
            {t("admin.sureityMsg")} {deleteUserNAme}?
          </div>
          <div className="delete-sportModel-Buttons">
            <InputButton
              type={"secondary"}
              inputLabel={t("error.avatarDeleteDiscard")}
              addBtnClass={"deleteDiscardBtn"}
              onClick={handleHideDeleteUser}
            />
            <InputButton
              type={"primary"}
              inputLabel={t("error.avatarDeleteConfirm")}
              addBtnClass={"deleteConfirmBtn"}
              onClick={handleDeleteUserData}
            />
          </div>
        </Model>
        {/* change user permission */}
        <Model
          isModel={ChangeUserPermissionModel}
          modelClassName={"change-permission-model"}
          hideModel={handleHideChangePermission}
          modelTitle={t("admin.UserSettings")}
        >
          <div className="d-flex align-items-center change-permission-user mb-3">
            {!changePermissionUser.profileImgUrl ? (
              <div
                className="me-3 usertable-avtar nav-avt d-flex align-items-center justify-content-center"
                alt="Avatar "
              >
                <p className="navbar-letter">
                  {changePermissionUser.lastName?.charAt(0)}
                </p>
              </div>
            ) : (
              <div className=" me-3">
                <img
                  className="usertable-img"
                  src={changePermissionUser.profileImgUrl}
                />
              </div>
            )}
            <div>
              <div className="user-first-last-name">
                {changePermissionUser.firstName +
                  " " +
                  changePermissionUser.lastName}
              </div>
              <div className="user-name">@{changePermissionUser.userName}</div>
            </div>
          </div>
          <div className="">
            <div className="tabs-dropdown mb-3">
              <div
                className="d-flex selected-language ignore-dropdown"
                onClick={handleChangePermissionDropDown}
              >
                <div className="d-flex align-items-center">
                  <img
                    src={userPostLogo}
                    className="drop-down-logo ignore-dropdown"
                    height="20px"
                    width="20px"
                  ></img>
                  <div className="language-name ignore-dropdown">
                    {userPost}
                  </div>
                </div>
                {showDropdown === "list-drop-down list-drop-down-show" ? (
                  <img src="up.svg" className="ignore-dropdown"></img>
                ) : (
                  <img src="down.svg" className="ignore-dropdown"></img>
                )}
              </div>
              <ul
                className="list-unstyled ignore-dropdown"
                id="language-dropdown"
              >
                <div className={showDropdown}>
                  <li
                    data-value="value 1"
                    className="d-flex language-menu"
                    onClick={() =>
                      handleUserPermission(
                        `${t(`admin.Admin`)}`,
                        "admin-hover.svg"
                      )
                    }
                  >
                    <img src="admin-hover.svg" className="drop-down-logo" />
                    <div className="language-name">{t("admin.Admin")}</div>
                  </li>
                  <li
                    data-value="value 1"
                    className="d-flex language-menu"
                    onClick={() =>
                      handleUserPermission(
                        `${t(`admin.SingleUser`)}`,
                        "single user.svg"
                      )
                    }
                  >
                    <img src="single user.svg" className="drop-down-logo"></img>
                    <div className="language-name">{t("admin.SingleUser")}</div>
                  </li>
                </div>
              </ul>
            </div>
            <div>
              <InputField
                inputLabel={t("wallet.Balance")}
                onChange={(e) => setBalanceData(e.target.value)}
                inputType="text"
                defaultValue={"0"}
              />
            </div>
          </div>
          <div className="delete-sportModel-Buttons">
            <InputButton
              type={"secondary"}
              inputLabel={t("profile.saveCancel")}
              addBtnClass={"deleteDiscardBtn"}
              onClick={handleHideChangePermission}
            />
            <InputButton
              type={"primary"}
              inputLabel={t("setting.saveChanges")}
              addBtnClass={"deleteConfirmBtn"}
              onClick={changeUserPermission}
              disabled={!saveChangesButton && balanceData == ""}
            />
          </div>
        </Model>
      </div>
    </>
  );
};
export default UserTable;
