import React from "react";
import { NotificationContainer, NotificationManager } from "react-notifications";
import "../CokkiePopup/cookiePopup.css"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import InputButton from "../InputButton";
import { useState } from "react";
import Cookies from 'js-cookie';
import { useEffect } from "react";
const CokkiePopup = () => {
    const { t, i18n } = useTranslation();
    const [isSetCookie, setIsSetCookie] = useState(false)
    const handleGotItBtn = () => {
        setIsSetCookie(false)
        Cookies.set('capperauditorCookie', true, { expires: 7 });
    }
    const handleCookie = () => {
        if (!Cookies.get('capperauditorCookie') || Cookies.get('capperauditorCookie') == false) {
            setIsSetCookie(true)
            NotificationManager.info(<>
                <div className="">
                    <div className="w-100 d-flex justify-content-center">
                        <img src="/home-img/updatedHome/cookie.svg" className="cookie-image" />
                    </div>
                    <div className="cookie-section pb-4">{t("mainHomePage.cookieTitle")}</div>
                    <div className="d-flex align-items-center justify-content-between">
                        <Link to="/cookie" className="cookie-nav-title">{t("mainHomePage.CookiePolicyTitle")}</Link>
                        <InputButton type="primary" addBtnClass="cookie-btn" inputLabel={t("mainHomePage.gotItBtn")} onClick={handleGotItBtn} />
                    </div>
                </div>
            </>, "", 0)
        } else {
            setIsSetCookie(false)
        }
    }
    useEffect(() => {
        setTimeout(() => {
            handleCookie()
        }, 1500)
    }, [isSetCookie])
    return (
        <div>
            <div className="">
                <div className="prediction-list-error-popup cookie-popup">
                    {isSetCookie ? <NotificationContainer /> : ""}
                </div>
            </div>
        </div>
    )
}
export default CokkiePopup;