import React from "react";

import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { FaRegCircleQuestion } from "react-icons/fa6";
import { useMediaQuery } from "react-responsive";

import LeaderboardGridBody from "./LeaderboardGridBody";
import SkeletonGridBody from "./SkeletonGridBody/SkeletonGridBody";

import "./LeaderboardGrid.css";

const LeaderboardGrid = ({ users, topFromCurrentUser }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="leaderboard__table">
      {!localStorage.getItem('token') && 
      <div className="signup-banner">
        <img src="/Leaderboard-img/lock.svg" alt="lock"></img>
        <div className="signup-banner-title">{t("Leaderboard.signupTitle")}</div>
        <div className="signup-banner-text">{t("Leaderboard.signupText")}</div>
        <a className="sigin-link" href="/signin">{t("Leaderboard.signupBtn")}</a>
      </div>
      }
      <table>
        <thead className="table-header">
          <tr className="table-header">
            <th className="user-place-header width-sm">#</th>
            <th className="width-lg">
              {t("Leaderboard.Grid.tableHeaders.predictor")}
            </th>
            {!isMobile && (
              <>
                <th className="width-md">
                  {t("Leaderboard.Grid.tableHeaders.predictions")}
                </th>
                <th className="width-md">
                  <span
                    className="hint"
                    data-tooltip-id="table-header-tooltip"
                    data-tooltip-content={t(
                      "Leaderboard.Grid.tableHeaderHints.avg"
                    )}
                    data-tooltip-place="top"
                  >
                    {t("Leaderboard.Grid.tableHeaders.avg")}
                    <div className="hint-icon">
                      <FaRegCircleQuestion />
                    </div>
                  </span>
                </th>
                <th className="width-md">
                  <span
                    className="hint"
                    data-tooltip-id="table-header-tooltip"
                    data-tooltip-content={t(
                      "Leaderboard.Grid.tableHeaderHints.spc"
                    )}
                    data-tooltip-place="top"
                  >
                    {t("Leaderboard.Grid.tableHeaders.spc")}
                    <div className="hint-icon">
                      <FaRegCircleQuestion />
                    </div>
                  </span>
                </th>
                <th>
                  <span
                    className="hint"
                    data-tooltip-id="table-header-tooltip"
                    data-tooltip-content={t(
                      "Leaderboard.Grid.tableHeaderHints.sport"
                    )}
                    data-tooltip-place="top"
                  >
                    {t("Leaderboard.Grid.tableHeaders.sport")}
                    <div className="hint-icon">
                      <FaRegCircleQuestion />
                    </div>
                  </span>
                </th>
              </>
            )}
            <th className="coins-header width-md_plus">
              <img className="coin-icon" src="/CAP coin.png" alt="coin"></img>
              {t("Leaderboard.Grid.tableHeaders.coins")}
            </th>
          </tr>
        </thead>
        <tbody>
          {users.length > 0 ? (
            <>
              <LeaderboardGridBody users={users} isMobile={isMobile} />
              {topFromCurrentUser.length > 0 ? (
                <>
                  <tr className="delimeter-dots">
                    <td colSpan={!isMobile ? "7" : "3"}>
                      <div className="leaderboard-dot"></div>
                      <div className="leaderboard-dot"></div>
                      <div className="leaderboard-dot"></div>
                    </td>
                  </tr>
                  <LeaderboardGridBody users={topFromCurrentUser} isMobile={isMobile} />
                </>
              ) : null}
            </>
          ) : (
            <SkeletonGridBody count={5} isMobile={isMobile} />
          )}
        </tbody>
      </table>
      <Tooltip
        id="table-header-tooltip"
        style={{
          backgroundColor: "#131520",
          color: "#FFF",
          borderRadius: "8px",
          fontWeight: "500",
          fontSize: "12px",
          maxWidth: "320px",
          textAlign: "center",
          zIndex: "26",
        }}
      />
    </div>
  );
};

export default LeaderboardGrid;
