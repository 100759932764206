import React from "react";
import { useTranslation } from "react-i18next";
import UserTable from "../UserTable";
import "./users.css";
const Users = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="user-panel">
        <div className="users-top-div">
          <div className="User-panel-title pb-1">
            {t("admin.Users")}
            <span className="ps-2">🙂</span>
          </div>
          <div className="Let-check-status">{t("admin.checkUserToday")}</div>
        </div>
        <div>
          <UserTable
            isSearchBar={true}
            handleRefreshPage={props.handleRefreshPage}
          />
        </div>
      </div>
    </>
  );
};
export default Users;
