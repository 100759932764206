import React from "react";
import ContentLoader from "react-content-loader"

const AdminCardCustomLoader = (props) => (
    <ContentLoader
        speed={2}
        width={235}
        height={110}
        viewBox="0 0 235 110"
        backgroundColor="#F9F9FB"
        foregroundColor="#F9F9FB"
        {...props}
    >
        <rect x="0" y="12" rx="4" ry="4" width="200" height="9" />
        <rect x="0" y="63" rx="4" ry="4" width="50" height="9" />
        <rect x="0" y="95" rx="4" ry="4" width="150" height="9" />
    </ContentLoader>
)
export default AdminCardCustomLoader;