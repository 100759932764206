import React, { useEffect } from "react";
import HomePageHeader from "./HomePageHeader/HomePageHeader";
import "./homepage.css";
import InputButton from "../InputButton";
import { useTranslation } from "react-i18next";
import Review from "./review/Review";
import { useNavigate } from "react-router";
import { Environment } from "../../../environment";
import Accordion from "./Accordion/Accordion";
import HomePageFooter from "./HomePageFooter/HomePageFooter";
import apiService from "../../../services/apiService";
import { useState } from "react";
import NavigationBar from "../../Navbar";
import HomePageLoader from "../../Custom loader/HomePageLoader";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { Modal, Tab, Tabs } from "react-bootstrap";
import Model from "../model/Model";
import "@dotlottie/player-component";
import CokkiePopup from "../CokkiePopup/CokkiePopup";
import { paginationEnData, paginationUaData } from "./ReviewUser";
import { formatPrice } from "../../../helpers/formater";
// import { NotificationManager } from "react-notifications";
const HomePage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [homePageTab, setHomePageTab] = useState("earned-coins");
  const [counterData, setCounterData] = useState({});
  const [youTubeModelShow, setYouTubeModelShow] = useState(false);
  const [reviewrUsers, setReviewrUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isTabletScreen, setIsTabletScreen] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [IsScreenSized, setIsScreenResized] = useState(0);
  const [pageSize, setPageSize] = useState(
    isMobileScreen ? 1 : isTabletScreen ? 2 : 3
  );
  const [endIndex, setEndIndex] = useState(null);
  const [paginationData, setPaginationData] = useState([]);
  const [animationGifStyle, setAnimationGifStyle] = useState({
    width: "710px",
    height: "525px",
    "border-radius": "24px",
  });
  const [signUpReward, setSignupReward] = useState("");
  const getSignUpReward = async () => {
    const res = await apiService.getAuthSignUpReward();
    if (res) {
      setSignupReward(res?.SignUpReward);
    }
  };
  useEffect(() => {
    getSignUpReward();
  }, []);
  useEffect(() => {}, [pageSize, reviewrUsers]);
  useEffect(() => {
    if (localStorage.getItem("language") == "ua") {
      setPaginationData(paginationUaData);
    } else {
      setPaginationData(paginationEnData);
    }
  }, []);

  const handlePaginaationData = () => {
    const startIndex = (currentPage - 1) * pageSize;
    // console.log("startIndex", startIndex)
    const endIndex = startIndex + pageSize;
    // console.log("endIndex:::", endIndex)
    setEndIndex(endIndex);
    const pagination = paginationData.slice(startIndex, endIndex);
    // console.log("pagination::::", pagination)
    setReviewrUsers(pagination);
  };
  useEffect(() => {
    // handlePagination data
    handlePaginaationData();
  }, [IsScreenSized, pageSize, currentPage]);
  const getHomePageData = async () => {
    const res = await apiService.getHomePageData();
    if (res) {
      res.coinsearned = 250000;
      res.coefficientForcast = 5.0;
      setCounterData(res);
    }
  };
  useEffect(() => {
    handleResize();
  }, []);
  const handleResize = () => {
    const screenWidth = window.innerWidth;
    const isMobileSize = screenWidth - 12 <= 768;
    const isTebletSize = screenWidth - 12 >= 769 && screenWidth - 12 <= 1024;
    const isSmallLeptopSize =
      screenWidth - 12 >= 1025 && screenWidth - 12 <= 1324;
    const isLargeLepTopSize =
      screenWidth - 12 >= 1324 && screenWidth - 12 <= 1424;
    if (isMobileSize) {
      setPageSize(1);
      setIsMobileScreen(true);
      setAnimationGifStyle({
        width: "100%",
        padding: "0 15px",
        height: "auto",
        "border-radius": "24px",
      });
    } else if (isTebletSize) {
      setPageSize(2);
      setIsTabletScreen(true);
      setAnimationGifStyle({
        width: "100%",
        padding: "0 15px",
        height: "auto",
        "border-radius": "24px",
      });
    } else if (isSmallLeptopSize) {
      setAnimationGifStyle({
        width: "525px",
        padding: "0 15px",
        height: "auto",
        "border-radius": "24px",
      });
    } else if (isLargeLepTopSize) {
      setAnimationGifStyle({
        width: "650px",
        padding: "0 15px",
        height: "auto",
        "border-radius": "24px",
      });
    } else {
      setPageSize(3);
      setAnimationGifStyle({
        width: "710px",
        height: "525px",
        "border-radius": "24px",
      });
    }
    setIsScreenResized(IsScreenSized + 1);
  };
  useEffect(() => {
    // Function to handle screen resize
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    getHomePageData();
  }, []);
  useEffect(() => {
    document.body.classList.add("home-containt");
  }, []);
  const isLoggedIn = () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    if (profile && profile != "null" && profile.userName != "") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    // counter
    document.querySelectorAll(".counter-data").forEach(function (element) {
      var countTo = element.getAttribute("data-count");
      var countNum = parseInt(element.textContent, 10);
      var duration = 1500;
      var startTime = null;
      function animateCounter(timestamp) {
        if (!startTime) startTime = timestamp;
        var progress = timestamp - startTime;

        if (progress < duration) {
          var currentCount = Math.floor(
            countNum + (countTo - countNum) * (progress / duration)
          );
          element.textContent = currentCount;
          requestAnimationFrame(animateCounter);
        } else {
          if (element.getAttribute("id") === "coefficient-forecasts") {
            element.textContent = countTo;
          } else {
            element.textContent = formatPrice(countTo);
          }
        }
      }
      requestAnimationFrame(animateCounter);
    });
  }, [counterData]);

  return (
    <>
      <Helmet>
        <title>{t("mainHomePage.metaTitle")}</title>
        <meta name="description" content={t("mainHomePage.metaDescription")} />
        <meta name="og:title" content={t("mainHomePage.metaSocialTitle")} />
        <meta
          name="og:description"
          content={t("mainHomePage.metaSocialDesc")}
        />
        <meta name="og:url" content="https://capperauditor.com" />
        <meta
          name="twitter:title"
          content={t("mainHomePage.metaSocialTitle")}
        />
        <meta
          name="twitter:description"
          content={t("mainHomePage.metaSocialDesc")}
        />
        <link
          rel="alternate"
          href="<https://capperauditor.com>"
          hreflang="uk"
        />
        <link
          rel="alternate"
          href="<https://capperauditor.com/en>"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="<https://capperauditor.com>"
          hreflang="x-default"
        />
      </Helmet>
      {isLoggedIn() ? <NavigationBar /> : <HomePageHeader />}
      {/* top */}

      <CokkiePopup />
      <div className="homePage-container">
        <div className="home-page-container-bottom-1240 homepage-First-container">
          {/* main-section */}
          <div className="home-page-main-top-section">
            <div className="row">
              <div className="col-lg-6  home-page-grid col-md-12 col-12">
                <div className="home-page-main-decription-section">
                  <div className="">
                    <div className="home-page-main-title pb-3">
                      <div className=" w-100">
                        <div
                          style={{
                            display:
                              localStorage.getItem("language") == "en"
                                ? "block"
                                : "none",
                          }}
                        >
                          Post sports predictions for{" "}
                          <span
                            className="pe-1 span-border-bottom"
                            style={{ color: "#EF8352" }}
                          >
                            FREE
                          </span>{" "}
                          and win real prizes
                        </div>
                        <div
                          style={{
                            display:
                              localStorage.getItem("language") == "ua"
                                ? "block"
                                : "none",
                          }}
                        >
                          {" "}
                          <span
                            className="pe-1 span-border-bottom"
                            style={{ color: "#EF8352" }}
                          >
                            Безкоштовно
                          </span>{" "}
                          публікуйте прогнози на спорт та вигравайте реальні
                          призи{" "}
                        </div>
                      </div>
                      {/* second title */}
                      {/* <div>{t("mainHomePage.mainTitle")}</div> */}
                    </div>
                    {/* subtitle */}
                    <div className="home-page-main-subtitle pb-4">
                      {t("mainHomePage.mainSubTitle")}
                    </div>
                    {/* intruction div */}
                    <div className="intruction-forCoin-div d-flex align-items-center">
                      <img src="/CAP coin.png" width={27.3} className="pe-2" />
                      {signUpReward || 1000}
                      {t("mainHomePage.defaultCoinTitle")}
                    </div>
                    <div className="main-section-primary-secondary-btn d-flex align-items-center">
                      <div className="mobile-freeRegistrationEmail">
                        <InputButton
                          type={"primary"}
                          addBtnClass={"Signup-to-continue"}
                          inputLabel={t("mainHomePage.freeRegistrationEmail")}
                          onClick={() => navigate("/signup")}
                        />
                      </div>
                      <span className="or-name" style={{ color: "#7F87B3" }}>
                        {t("mainHomePage.or")}
                      </span>
                      <div className="d-flex google-face-book-btn">
                        <div className="ms-3 d-flex align-items-center">
                          <img
                            src="/google-logo.svg"
                            onClick={() => {
                              window.location.href =
                                process.env.REACT_APP_googleOAuthUrl;
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </div>

                        <div className="ms-3 d-flex align-items-center">
                          <img
                            src="/facebook-logo.svg"
                            onClick={() => {
                              window.location.href =
                                process.env.REACT_APP_facebookOAuthUrl;
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                      {/* <InputButton type={"icon-button"} addBtnClass={"Sign-in-continue"} addSpan={
                        <span>
                          <img src="/play.svg" />
                        </span>
                      } inputLabel={t("mainHomePage.watchVideoBtn")} onClick={() => setYouTubeModelShow(true)} /> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 home-page-grid  col-md-12 col-12 p-0">
                {/* <img src="/hero-img-wrapper.svg" className="wallapaer-image" /> */}
                <dotlottie-player
                  className="dotlottie-player-div"
                  src="https://lottie.host/60b88d6b-0354-46ab-82f0-e4533c013af7/PanOzNTVAs.json"
                  style={animationGifStyle}
                  background="transparent"
                  speed="1"
                  direction="1"
                  mode="normal"
                  loop
                  autoplay
                ></dotlottie-player>
              </div>
            </div>
          </div>
          <div className="home-page-main-container">
            {/* count-down section */}
            <div className="count-down-section row d-flex justify-content-between">
              <div className="text-center counter-section-div">
                <div
                  className="counter-data pb-2"
                  id="Forecasters-joined"
                  data-count={counterData?.getAllUserCount || 0}
                >
                  0
                </div>
                <div className="counter-title">
                  {t("mainHomePage.counter1")}
                </div>
              </div>
              <div className="text-center counter-section-div">
                <div
                  className="counter-data pb-2"
                  id="forecast-made"
                  data-count={counterData?.getAllPredictionCount || 0}
                >
                  0
                </div>
                <div className="counter-title">
                  {t("mainHomePage.counter2")}
                </div>
              </div>
              <div className="text-center counter-section-div">
                <div className=" d-flex justify-content-center">
                  <div
                    id="Coins-earned"
                    className="counter-data pb-2"
                    data-count={counterData?.sumEarnedAmount || 0}
                  >
                    0
                  </div>
                  {/* <div className="k-count">K+</div> */}
                </div>
                <div className="counter-title">
                  {t("mainHomePage.counter3")}
                </div>
              </div>
              <div className="text-center counter-section-div">
                <div className=" d-flex justify-content-center">
                  <div
                    className="counter-data pb-2"
                    id="Coins-collected"
                    data-count={counterData?.sumCollectAmount || 0}
                  >
                    0k+
                  </div>
                  {/* <div className="k-count">K+</div> */}
                </div>
                <div className="counter-title">
                  {t("mainHomePage.counter4")}
                </div>
              </div>
              <div className="text-center counter-section-div">
                <div
                  className="counter-data pb-2"
                  id="coefficient-forecasts"
                  data-count={counterData?.overallAverage?.toFixed(2) || 0}
                >
                  0
                </div>
                <div className="counter-title">
                  {t("mainHomePage.counter5")}
                </div>
              </div>
            </div>
            {/* sportsKnowledgeIntroTitle */}
            <div className="sportsKnowledgeIntroTitle-subtitle">
              <div className="title text-center">
                {t("mainHomePage.sportsKnowledgeIntroTitle")}
              </div>
              <div className="sub-title mt-3">
                {t("mainHomePage.sportsKnowledgeIntroSubTitle")}
              </div>
            </div>
            {/* step 1 */}
            <div className="sportline-Predictions-section step-1-section">
              <div className="row sportline-Predictions-section-row align-items-center">
                <div className="col p-0">
                  <Link to="/sportline">
                    <div className="silder-image-background">
                      <div className="grid-card-arr is-role-tabs"></div>
                      <img
                        src={
                          localStorage.getItem("language") == "en"
                            ? "/home-img/updatedHome/eng/Predictions_ENG.png"
                            : "/home-img/updatedHome/ua/Predictions_UA.png"
                        }
                        className="web-step-3-image"
                      />
                      <div className="mobile-step-3-image">
                        <img
                          src={
                            localStorage.getItem("language") == "en"
                              ? "/home-img/updatedHome/eng/Predictions_ENG.png"
                              : "/home-img/updatedHome/ua/Predictions_UA.png"
                          }
                          className="position-relative pb-2"
                        />
                        <img
                          src={
                            localStorage.getItem("language") == "en"
                              ? "/home-img/updatedHome/eng/Predictions_ENG.png"
                              : "/home-img/updatedHome/ua/Predictions_UA.png"
                          }
                          className="position-relative"
                        />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col step-1-col p-0">
                  <div className="step-container-section">
                    <div className="mb-4">
                      <div className="step-btn">
                        {t("mainHomePage.Step")} #1
                      </div>
                    </div>
                    <div className="step-decription-title mb-4">
                      {t("mainHomePage.step1Title")}
                    </div>
                    <div className="step-decription-sub-title mb-4">
                      {t("mainHomePage.step1SubTitle")}
                    </div>
                    <InputButton
                      type={"primary"}
                      addBtnClass={"Signup-to-continue"}
                      inputLabel={t("mainHomePage.step1Btn")}
                      onClick={() => navigate("/sportline")}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* step 2 */}
            <div className="sportline-Predictions-section step-two-section other-div-paddings">
              <div className="row sportline-Predictions-section-row  align-items-center">
                <div className="col p-0 step-2-col">
                  <div className="step-container-section">
                    <div className="mb-4">
                      <div className="step-btn">
                        {t("mainHomePage.Step")} #2
                      </div>
                    </div>
                    <div className="step-decription-title mb-4">
                      {t("mainHomePage.step2Title")}
                    </div>
                    <div className={`earning-history-tab home-page-step2-tab`}>
                      <Tabs
                        defaultActiveKey="earned-coins"
                        id="uncontrolled-tab-example"
                        className="earning-history-tab-margin"
                        onSelect={(e) => setHomePageTab(e)}
                      >
                        <Tab
                          eventKey="earned-coins"
                          title={t("mainHomePage.step2EarnTab")}
                        ></Tab>
                        <Tab
                          eventKey="collected-coins"
                          title={t("mainHomePage.step2CollectTab")}
                        ></Tab>
                      </Tabs>
                    </div>
                    <div className="step-decription-sub-title mb-4">
                      {/* earn-coin */}
                      <div
                        style={{
                          display:
                            homePageTab == "earned-coins" ? "block" : "none",
                        }}
                      >
                        {/* first subtitle */}
                        <div className="pb-4">
                          <div
                            className=""
                            style={{
                              display:
                                localStorage.getItem("language") == "en"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            After registration, each user will receive{" "}
                            <span style={{ fontWeight: 600 }}>
                              {signUpReward || 1000} CAP
                            </span>{" "}
                            coins (the virtual currency of our site) to their
                            account. This amount will allow you to start
                            publishing your predictions. The more accurate the
                            predictions, the higher the earnings
                          </div>
                          <div
                            className=""
                            style={{
                              display:
                                localStorage.getItem("language") == "ua"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Після реєстрації кожен користувач отримає{" "}
                            <span style={{ fontWeight: 600 }}>
                              {" "}
                              {signUpReward || 1000} CAP{" "}
                            </span>{" "}
                            монет ( вірутальна валюта нашого сайту ) на свій
                            рахунок. Ця сума дозволить вам розпочати публікацію
                            своїх прогнозів. Чим точніші прогнози, тим більший
                            заробіток
                          </div>
                        </div>
                        {/* second title */}
                        <div>
                          <div
                            className=""
                            style={{
                              display:
                                localStorage.getItem("language") == "en"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            What happens if you spend all your coins on
                            predictions but earn nothing? Don't worry, we have
                            an option to get coins for free every day. To learn
                            more, go to the{" "}
                            <span style={{ fontWeight: 600 }}>
                              "Collect coins"
                            </span>{" "}
                            tab
                          </div>
                          <div
                            className=""
                            style={{
                              display:
                                localStorage.getItem("language") == "ua"
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Що буде, якщо ви розтратите всі монети на прогнози,
                            але нічого не заробите? Не хвилюйтесь, у нас є
                            можливість отримувати монети безкоштовно щодня. Щоб
                            дізнатися більше, перейдіть на вкладку{" "}
                            <span style={{ fontWeight: 600 }}>
                              "Збирайте монети"{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* collect tab */}
                      <div
                        style={{
                          display:
                            homePageTab == "earned-coins" ? "none" : "block",
                        }}
                      >
                        <div
                          className=""
                          style={{
                            display:
                              localStorage.getItem("language") == "en"
                                ? "block"
                                : "none",
                          }}
                        >
                          Visit the site every day and collect coins. The longer
                          you do this, the higher the daily reward you will
                          receive. For example, on the first day you can collect
                          15 CAP coins, and on the 7th day this amount will
                          increase to 50. Sounds exciting, doesn't it? So don't
                          waste your time and join now to collect your first
                          coins!
                        </div>
                        <div
                          className=""
                          style={{
                            display:
                              localStorage.getItem("language") == "ua"
                                ? "block"
                                : "none",
                          }}
                        >
                          Щодня заходьте на сайт та збирайте монети. Чим довше
                          ви будете це робити, тим більшу щоденну винагороду ви
                          отримаєте. Наприклад, у перший день ви можете зібрати
                          15 CAP монет, а на 7-й день ця сума зросте до 50.
                          Звучить захоплююче, чи не так? Тож не гайте часу і
                          приєднуйтесь прямо зараз, щоб зібрати свої перші
                          монети!
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col p-0">
                  <Link>
                    <div className="silder-image-background slider-step-2">
                      {/* <div className="grid-card-arr is-role-tabs"></div> */}
                      <img
                        style={{
                          display:
                            homePageTab == "earned-coins" ? "none" : "block",
                        }}
                        src={
                          localStorage.getItem("language") == "en"
                            ? "/home-img/updatedHome/eng/collect_coins_ENg.png"
                            : "/home-img/updatedHome/ua/collect_coins_UA.png"
                        }
                        className={
                          homePageTab + "-img web-step-3-image slider-step-2"
                        }
                      />
                      <img
                        style={{
                          display:
                            homePageTab == "earned-coins" ? "block" : "none",
                        }}
                        src={
                          localStorage.getItem("language") == "en"
                            ? "/home-img/updatedHome/eng/Gains_ENG.png"
                            : "/home-img/updatedHome/ua/Gains_UA.png"
                        }
                        className={homePageTab + "-img web-step-3-image"}
                      />

                      <div className="mobile-step-3-image">
                        <div>
                          <img
                            style={{
                              display:
                                homePageTab == "earned-coins"
                                  ? "none"
                                  : "block",
                            }}
                            src={
                              localStorage.getItem("language") == "en"
                                ? "/home-img/updatedHome/eng/collect_coins_ENg.png"
                                : "/home-img/updatedHome/ua/collect_coins_UA.png"
                            }
                            className={
                              homePageTab + "-img position-relative pb-2"
                            }
                          />
                          <img
                            style={{
                              display:
                                homePageTab == "earned-coins"
                                  ? "block"
                                  : "none",
                            }}
                            src={
                              localStorage.getItem("language") == "en"
                                ? "/home-img/updatedHome/eng/Gains_ENG.png"
                                : "/home-img/updatedHome/ua/Gains_UA.png"
                            }
                            className={
                              homePageTab + "-img position-relative pb-2"
                            }
                          />
                          {/* <img style={{ display: homePageTab == "earned-coins" ? "block" : "none" }} src="/home-img/updatedHome/ua/step 2-mobile earn ua.png" className={homePageTab + "-img position-relative"} />
                          <img style={{ display: homePageTab == "earned-coins" ? "none" : "block" }} src="/home-img/updatedHome/ua/step 2-mobile collect ua.png" className={homePageTab + "-img position-relative"} /> */}
                        </div>
                        <div>
                          <img
                            style={{
                              display:
                                homePageTab == "earned-coins"
                                  ? "none"
                                  : "block",
                            }}
                            src={
                              localStorage.getItem("language") == "en"
                                ? "/home-img/updatedHome/eng/collect_coins_ENg.png"
                                : "/home-img/updatedHome/ua/collect_coins_UA.png"
                            }
                            className={
                              homePageTab + "-img position-relative pb-2"
                            }
                          />
                          <img
                            style={{
                              display:
                                homePageTab == "earned-coins"
                                  ? "block"
                                  : "none",
                            }}
                            src={
                              localStorage.getItem("language") == "en"
                                ? "/home-img/updatedHome/eng/Gains_ENG.png"
                                : "/home-img/updatedHome/ua/Gains_UA.png"
                            }
                            className={
                              homePageTab + "-img position-relative pb-2"
                            }
                          />
                          {/* <img style={{ display: homePageTab == "earned-coins" ? "block" : "none" }} src="/home-img/updatedHome/ua/step 2-mobile earn ua.png" className={homePageTab + "-img position-relative"} />
                          <img style={{ display: homePageTab == "earned-coins" ? "none" : "block" }} src="/home-img/updatedHome/ua/step 2-mobile collect ua.png" className={homePageTab + "-img position-relative"} /> */}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            {/* step 3 */}
            <div className="sportline-Predictions-section other-div-paddings">
              <div className="row sportline-Predictions-section-row  align-items-center">
                <div className="col p-0">
                  <Link to="/marketplace">
                    <div className="silder-image-background">
                      <div className="grid-card-arr is-role-tabs"></div>
                      <img
                        src={
                          localStorage.getItem("language") == "en"
                            ? "/home-img/updatedHome/eng/Prizes_ENG.png"
                            : "/home-img/updatedHome/ua/Prizes_UA.png"
                        }
                        className="web-step-3-image step-3-image"
                      />
                      <div className="mobile-step-3-image">
                        <div>
                          <img
                            src="/home-img/updatedHome/ua/step3-mobile-ua.png"
                            className="step-3-image position-relative"
                          />
                        </div>
                        <div>
                          <img
                            src="/home-img/updatedHome/ua/step3-mobile-ua.png"
                            className="step-3-image position-relative"
                          />
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col p-0 step-1-col">
                  <div className="step-container-section">
                    <div className="mb-4">
                      <div className="step-btn">
                        {t("mainHomePage.Step")} #3
                      </div>
                    </div>
                    <div className="step-decription-title mb-4">
                      {t("mainHomePage.step3Title")}
                    </div>
                    <div className="step-decription-sub-title mb-4">
                      {t("mainHomePage.step3SubTitle")}
                    </div>
                    <InputButton
                      type={"primary"}
                      addBtnClass={"Signup-to-continue"}
                      inputLabel={t("mainHomePage.step3Btn")}
                      onClick={() => navigate("/marketplace")}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* more step */}
            <div className="other-div-paddings more-step-section">
              <div className="more-step-title text-center">
                {t("mainHomePage.AndMoreTitle")}
              </div>
              <div className="row more-step-first-row justify-content-between">
                <div className="col more-sport-data-col-8 ">
                  <div className="title pb-3">
                    {t("mainHomePage.addMoreItems1Title")}
                    <br />
                    {t("mainHomePage.addMoreItems1Title2")}
                  </div>
                  <div className="sub-title">
                    {t("mainHomePage.addMoreItems1subTitle")}
                  </div>
                  <div className="d-flex">
                    <img
                      src={
                        localStorage.getItem("language") == "en"
                          ? "/home-img/updatedHome/eng/Sports-line-image-eng.png"
                          : "/home-img/updatedHome/ua/Sports-line-image-ua.png"
                      }
                      height={212}
                      className="Sports-line-data-image"
                    />
                    <img
                      src={
                        localStorage.getItem("language") == "en"
                          ? "/home-img/updatedHome/eng/Sports-line-image-eng.png"
                          : "/home-img/updatedHome/ua/Sports-line-image-ua.png"
                      }
                      height={212}
                      className="Sports-line-data-image mx-3"
                    />
                    <img
                      src={
                        localStorage.getItem("language") == "en"
                          ? "/home-img/updatedHome/eng/Sports-line-image-eng.png"
                          : "/home-img/updatedHome/ua/Sports-line-image-ua.png"
                      }
                      height={212}
                      className="Sports-line-data-image mx-3"
                    />
                  </div>
                </div>
                <div className="col more-sport-data-col-4 d-flex">
                  <div className="title">
                    {t("mainHomePage.addMoreItems2Title")}
                  </div>
                  <div className="d-flex">
                    <img
                      src={
                        localStorage.getItem("language") == "en"
                          ? "/home-img/updatedHome/eng/Forecasts real time eng.png"
                          : "/home-img/updatedHome/ua/Forecasts real time ua.png"
                      }
                      height={244}
                      className="Sports-line-data-image"
                    />
                    <img
                      src={
                        localStorage.getItem("language") == "en"
                          ? "/home-img/updatedHome/eng/Forecasts real time eng.png"
                          : "/home-img/updatedHome/ua/Forecasts real time ua.png"
                      }
                      height={244}
                      className="Sports-line-data-image"
                      style={{ margin: "0px 12px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="row more-step-second-row justify-content-between">
                <div className="col more-sport-data-col-4">
                  <div className="title">
                    {t("mainHomePage.addMoreItems3Title")}
                  </div>
                  <div className="d-flex">
                    <img
                      src="/home-img/updatedHome/ua/large-community/large-community-1.png"
                      className="Sports-line-data-image pb-2"
                    />
                    <img
                      src="/home-img/updatedHome/ua/large-community/large-community-1.png"
                      className="Sports-line-data-image pb-2"
                    />
                    <img
                      src="/home-img/updatedHome/ua/large-community/large-community-1.png"
                      className="Sports-line-data-image pb-2"
                    />
                  </div>
                  <div className="d-flex">
                    <img
                      src="/home-img/updatedHome/ua/large-community/large-community-2.png"
                      className="Sports-line-data-image large-community-2-image pb-2"
                    />
                    <img
                      src="/home-img/updatedHome/ua/large-community/large-community-2.png"
                      className="Sports-line-data-image large-community-2-image pb-2"
                    />
                    <img
                      src="/home-img/updatedHome/ua/large-community/large-community-2.png"
                      className="Sports-line-data-image large-community-2-image pb-2"
                    />
                  </div>
                  <div className="d-flex">
                    <img
                      src="/home-img/updatedHome/ua/large-community/large-community-3.png"
                      className="Sports-line-data-image  pb-2"
                    />
                    <img
                      src="/home-img/updatedHome/ua/large-community/large-community-3.png"
                      className="Sports-line-data-image  pb-2"
                    />
                    <img
                      src="/home-img/updatedHome/ua/large-community/large-community-3.png"
                      className="Sports-line-data-image  pb-2"
                    />
                  </div>
                </div>
                <div className="col more-sport-data-col-8">
                  <div className="title pb-3">
                    {t("mainHomePage.addMoreItems4Title")}
                  </div>
                  <div className="d-flex pt-3">
                    <img
                      src={
                        localStorage.getItem("language") == "en"
                          ? "/home-img/updatedHome/eng/friend expect image eng.png"
                          : "/home-img/updatedHome/ua/friend expect image ua.png"
                      }
                      height={244}
                      className="Sports-line-data-image"
                    />
                    <img
                      src={
                        localStorage.getItem("language") == "en"
                          ? "/home-img/updatedHome/eng/friend expect image eng.png"
                          : "/home-img/updatedHome/ua/friend expect image ua.png"
                      }
                      height={244}
                      className="Sports-line-data-image"
                      style={{ margin: "0px 6px" }}
                    />
                  </div>
                </div>
              </div>
              <div className="row more-step-three-row justify-content-between">
                <div className="col more-sport-data-col-8">
                  <div>
                    <div className="title pb-3">
                      {t("mainHomePage.addMoreItems5Title")}
                    </div>
                    <div className="sub-title">
                      {t("mainHomePage.addMoreItems5SubTitle")}
                    </div>
                  </div>
                  <div className=" d-flex">
                    <img
                      src={
                        localStorage.getItem("language") == "en"
                          ? "/home-img/updatedHome/eng/Detailed-image eng.png"
                          : "/home-img/updatedHome/ua/Detailed-image ua.png"
                      }
                      height={212}
                      className=" Sports-line-data-image"
                    />
                    <img
                      src={
                        localStorage.getItem("language") == "en"
                          ? "/home-img/updatedHome/eng/Detailed-image eng.png"
                          : "/home-img/updatedHome/ua/Detailed-image ua.png"
                      }
                      height={212}
                      className=" Sports-line-data-image"
                      style={{ margin: "0px 12px" }}
                    />
                  </div>
                </div>
                <div className="col more-sport-data-col-4 d-flex">
                  <div className="title">
                    {t("mainHomePage.addMoreItems6Title")}
                  </div>
                  <div className="d-flex pt-2 justify-content-center w-100">
                    <div className="position-relative">
                      <img
                        src={
                          localStorage.getItem("language") == "en"
                            ? "/home-img/updatedHome/eng/Share prediction _ ENG.png"
                            : "/home-img/updatedHome/ua/Share prediction _ UA.png"
                        }
                        className="prediction-image w-100"
                      />
                      <img
                        src="/home-img/updatedHome/teligram.png"
                        className="home-page-teligram-image"
                      />
                      <img
                        src="/home-img/updatedHome/twitter.png"
                        className="home-page-twitter-image"
                      />
                      <img
                        src="/home-img/updatedHome/facebook.png"
                        className="home-page-facebook-image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
      {/* popular prediction */}
      <div className="popular-container d-flex justify-content-center">
        <div className="popular-container-header">
          <div className="popular-title">
            {t("mainHomePage.popularPredictionTitle")}
          </div>
          <div className="popular-subtitle">
            {t("mainHomePage.popularPredictionSubTitle")}
          </div>
        </div>
        <div className="popular-container-body ">
          <div className="prediction-images d-flex">
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 1 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 1 ua.png"
              }
              className=" Sports-line-data-image "
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 1 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 1 ua.png"
              }
              className=" Sports-line-data-image mx-4"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 1 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 1 ua.png"
              }
              className=" Sports-line-data-image mx-4"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 1 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 1 ua.png"
              }
              className=" Sports-line-data-image mx-4"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 1 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 1 ua.png"
              }
              className=" Sports-line-data-image mx-4"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 1 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 1 ua.png"
              }
              className=" Sports-line-data-image mx-4"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 1 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 1 ua.png"
              }
              className=" Sports-line-data-image mx-4"
              height={210}
            />
          </div>
          <div className="prediction-images">
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 2 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 2 ua.png"
              }
              className=" Sports-line-data-image large-community-2-image"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 2 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 2 ua.png"
              }
              className=" Sports-line-data-image large-community-2-image mx-4"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 2 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 2 ua.png"
              }
              className=" Sports-line-data-image large-community-2-image mx-4"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 2 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 2 ua.png"
              }
              className=" Sports-line-data-image large-community-2-image mx-4"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 2 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 2 ua.png"
              }
              className=" Sports-line-data-image large-community-2-image mx-4"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 2 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 2 ua.png"
              }
              className=" Sports-line-data-image large-community-2-image mx-4"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 2 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 2 ua.png"
              }
              className=" Sports-line-data-image large-community-2-image mx-4"
              height={210}
            />
            <img
              src={
                localStorage.getItem("language") == "en"
                  ? "/home-img/updatedHome/eng/Predicitions 2 eng.png"
                  : "/home-img/updatedHome/ua/Predicitions 2 ua.png"
              }
              className=" Sports-line-data-image large-community-2-image mx-4"
              height={210}
            />
          </div>
          <div className="prediction-images-overlay">
            <div className="prediction-overlay-gradient"></div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center prediction-btn-data w-100">
        <InputButton
          type={"primary"}
          addBtnClass={"Signup-to-continue me-0"}
          inputLabel={t("mainHomePage.ShowMorePredictions")}
          onClick={() => navigate("/prediction")}
        />
      </div>
      {/* review section */}
      <div className="other-div-paddings home-page-container-bottom-1240 ">
        {/* title */}
        <div className="d-flex justify-content-between review-section-title px-4">
          <div>
            <div className="pb-4 review-average">
              <img
                src="/home-img/updatedHome/yellow-star.svg"
                className="pe-3"
              />
              <img
                src="/home-img/updatedHome/yellow-star.svg"
                className="pe-3"
              />
              <img
                src="/home-img/updatedHome/yellow-star.svg"
                className="pe-3"
              />
              <img
                src="/home-img/updatedHome/yellow-star.svg"
                className="pe-3"
              />
              <img
                src="/home-img/updatedHome/yellow-star.svg"
                className="pe-3"
              />
            </div>
            <div className="review-sectionTitle">
              {t("mainHomePage.reviewTitle1")}
              <br />
              {t("mainHomePage.reviewTitle2")}
            </div>
          </div>
          {/* Trustpilot score - logo */}
          {/* <div className="TrustpilotScoreLogo">
            <img src="/home-img/updatedHome/TrustpilotScoreLogo.svg" style={{ paddingBottom: "32px" }} />
            <div className="TrustpilotScoreLogo-title pb-3">{t("mainHomePage.TrustpilotScore")}</div>
            <div className="TrustpilotScoreLogo-subtitle pb-3">{t("mainHomePage.TrustpilotScoreSubTitle")}</div>
          </div> */}
        </div>
        {/* review section */}
        <div>
          <div className="row">
            {reviewrUsers?.map((item, index) => (
              <div className="col-4 review-data-card-col px-3" key={index}>
                <div className="review-data-card h-100">
                  <div>
                    <div className="title-section flex-wrap">
                      <div className="title">{item.title}</div>
                      <div className="step-btn">{item.specialTitle}</div>
                    </div>
                    <div className="review-description">{item.decription}</div>
                  </div>
                  <div className="d-flex">
                    <img src={item.userImage} width={52} height={52} />
                    <div className="ps-3">
                      <div className="reviewer-name">{item.userName}</div>
                      <div className="rate-qte d-flex">
                        <div className="pe-2">
                          <img
                            src="/home-img/updatedHome/yellow-star.svg"
                            className="pe-0"
                            width="18px"
                            height="18px"
                          />
                        </div>
                        <div className="pe-2">
                          <img
                            src="/home-img/updatedHome/yellow-star.svg"
                            className="pe-0"
                            width="18px"
                            height="18px"
                          />
                        </div>
                        <div className="pe-2">
                          <img
                            src="/home-img/updatedHome/yellow-star.svg"
                            className="pe-0"
                            width="18px"
                            height="18px"
                          />
                        </div>
                        <div className="pe-2">
                          <img
                            src="/home-img/updatedHome/yellow-star.svg"
                            className="pe-0"
                            width="18px"
                            height="18px"
                          />
                        </div>
                        <div className="pe-2">
                          <img
                            src="/home-img/updatedHome/yellow-star.svg"
                            className="pe-0"
                            width="18px"
                            height="18px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="review-pagination">
            <button
              className="review-page-left"
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <img src="/left arrow.svg" />
            </button>
            <div className="review-page">
              {currentPage} of {Math.ceil(paginationData.length / pageSize)}
            </div>
            <button
              className="review-page-right"
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={endIndex >= paginationData.length}
            >
              <img src="/notificationrigt arrow.svg" />
            </button>
          </div>
        </div>
      </div>
      {/* Accordion */}
      <div className="Accordion-container">
        <div className="Accordion-title">
          {t("mainHomePage.FrequentlyQuestions")}
        </div>
        <div>
          <Accordion
            accordionHeader={t("mainHomePage.accodianTitle1")}
            accordionDecription={
              <div>
                <div>{t("mainHomePage.accodianSubTitle1")}</div>
                <div className="pb-3">
                  {t("mainHomePage.accodian1SubTitle1")}
                </div>
                <div className="pb-3">
                  {t("mainHomePage.accodian1SubTitle2")}
                </div>
                <div>{t("mainHomePage.accodian1SubTitle3")}</div>
              </div>
            }
            EventKey={1}
          />
        </div>
        <div>
          <Accordion
            accordionHeader={t("mainHomePage.accodianTitle2")}
            accordionDecription={t("mainHomePage.accodianSubTitle2")}
            EventKey={1}
          />
        </div>
        <div>
          <Accordion
            accordionHeader={t("mainHomePage.accodianTitle3")}
            accordionDecription={t("mainHomePage.accodianSubTitle3")}
            EventKey={1}
          />
        </div>
        <div>
          <Accordion
            accordionHeader={t("mainHomePage.accodianTitle4")}
            accordionDecription={t("mainHomePage.accodianSubTitle4")}
            EventKey={1}
          />
        </div>
        <div>
          <Accordion
            accordionHeader={t("mainHomePage.accodianTitle5")}
            accordionDecription={t("mainHomePage.accodianSubTitle5")}
            EventKey={1}
          />
        </div>
        <div>
          <Accordion
            accordionHeader={t("mainHomePage.accodianTitle6")}
            accordionDecription={
              <span className="d-flex">
                {t("mainHomePage.accodian6SubTitle1") + signUpReward || 1000}
                {t("mainHomePage.accodian6SubTitle2")}
              </span>
            }
            EventKey={1}
          />
        </div>
        <div>
          <Accordion
            accordionHeader={t("mainHomePage.accodianTitle7")}
            accordionDecription={t("mainHomePage.accodianSubTitle7")}
            addSpan={
              <Link
                to="/marketplace"
                style={{ color: "#208B8E", textDecoration: "underline" }}
              >
                {t("mainHomePage.accodianSubTitle8")}
              </Link>
            }
            EventKey={1}
          />
        </div>
        {/* <div>
          <Accordion
            accordionHeader={t("mainHomePage.accodianTitle5")}
            accordionDecription={t("mainHomePage.accodianSubTitle1") + " " + t("mainHomePage.accodianSubTitle5")}
            EventKey={1}
            addSpan={<span style={{ color: "#208B8E", textDecoration: "underline" }}>{t("mainHomePage.accodianSubTitle5")}</span>}
          />
        </div> */}
      </div>
      {/* Get a bonus section */}
      <div className="other-div-paddings get-Bonus-main-container home-page-main-container">
        <div className="row Get-bonus-section">
          <div className="col-7 Get-bonus-description-part">
            <div className="Get-bonus-description-section">
              <h1 className="title pb-3">
                {t("mainHomePage.getBonusTitle1")}
              </h1>
              <div className="sub-title">
                {t("mainHomePage.getBonusSubTitle")}
              </div>
              <div className="">
                <InputButton
                  type={"primary"}
                  addBtnClass={"get-Bonus-Btn Signup-to-continue"}
                  inputLabel={t("mainHomePage.getBonusBtn")}
                  onClick={() => navigate("/signup")}
                />
              </div>
            </div>
          </div>
          <div className="col-5 Get-bonus-description-image">
            <img
              src="/home-img/updatedHome/Get-bonus.png"
              className="get-bonus-image"
            />
          </div>
        </div>
      </div>

      <Model
        modelClassName="youtube-player"
        isModel={youTubeModelShow}
        hideModel={() => setYouTubeModelShow(false)}
      >
        <iframe src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>
      </Model>
      {/* footer */}
      <div div className="">
        <HomePageFooter />
      </div>
    </>
  );
};
export default HomePage;
