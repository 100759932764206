import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import LeftNavLoader from "../Custom loader/LeftNavLoader";
import { Accordion } from "react-bootstrap";
import { STATUS_LOADING } from "../../consts/StatusConst";
import { clearData, clearEvent, clearSportEvents } from "../../store/betSlice";
import SportlineSidebarLoader from "../CustomSkeletonLoaders/SportlineSidebarLoader";

const CountriesSideBar = () => {
  const { t, i18n } = useTranslation();
  const isTablet = useMediaQuery({ maxWidth: 1024 });
  const scrollContainerRef = useRef(null);
  const dropDownRef = useRef(null);

  const [totalCountOfEvents, setTotalCountOfEvents] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({});
  const [selectedLeague, setSelectedLeague] = useState({});

  const { sportEventsStatus, leaguesLoadingStatus, leagues } = useSelector(
    (state) => state.bet
  );
  const dispatch = useDispatch();

  const activeSport = searchParams.get("sport");
  const activeSportKey = searchParams.get("key");
  const activeCountry = searchParams.get("country");
  const activeLeague = searchParams.get("league");

  useEffect(() => {
    if (leagues) {
      getActiveLeague();
      const totalCount = leagues?.menu?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.counter;
      }, 0);

      setTotalCountOfEvents(totalCount);
    }
  }, [dispatch, leagues, activeLeague, activeCountry]);

  const getActiveLeague = () => {
    if (leagues.menu && activeSport && activeCountry && activeLeague) {
      const sport = leagues?.menu.find((item) => item.id == activeSport);
      const country = sport?.sub.find((item) => item.id == activeCountry);
      const league = country?.sub.find((item) => item.id == activeLeague);
      if (league) {
        setSelectedLeague(league);
      }
    }
  };

  const handleAllSports = () => {
    if (!activeLeague) return;

    setSearchParams(
      (prev) => {
        prev.delete("sport");
        prev.delete("country");
        prev.delete("league");
        prev.delete("game");
        return prev;
      },
      { replace: true }
    );

    setSelectedLeague({});
    dispatch(clearData());
    setIsOpen(false);
  };

  const handleType = (type) => {
    setSearchParams(
      (prev) => {
        prev.set("key", type);
        prev.delete("sport");
        prev.delete("country");
        prev.delete("league");
        prev.delete("game");
        return prev;
      },
      { replace: true }
    );

    setSelectedLeague({});
    dispatch(clearData());
  };

  const handleLeague = (id, sportId, countryId) => {
    setSearchParams(
      (prev) => {
        prev.set("league", id);
        prev.set("sport", sportId);
        prev.set("country", countryId);
        prev.delete("game");
        return prev;
      },
      { replace: true }
    );

    dispatch(clearSportEvents());
    setIsOpen(false);
  };

  const renderPopularLeagues = () => {
    if (leagues?.popularLeagues?.length > 0) {
      return (
        <div className="popular-leagues__section">
          <div className="popular-leagues__title">
            {t("profile.popularLeagues").toUpperCase()}
          </div>
          <div className="popular-leagues_list">
            {leagues.popularLeagues.map((league) => (
              <div
                className={
                  activeLeague == league.league_id
                    ? "popular-leagues_list-item active"
                    : "popular-leagues_list-item"
                }
                key={"popular-league-" + league.league_id}
                onClick={() =>
                  handleLeague(
                    league.league_id,
                    league.sport_id,
                    league.country_id
                  )
                }
              >
                <div className="popular-leagues_list-item-title">
                  <img
                    src={`/country-logo/${league.country_id}.png`}
                    alt="country-icon"
                  />
                  <div className="popular-leagues_list-item-title-txt">
                    {localStorage.getItem("language") === "en"
                      ? league.name_en
                      : league.name}
                  </div>
                </div>
                <span className="leagues__item-counter">{league.counter}</span>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  const renderDesktopSideBar = () => {
    return (
      <div className="live-sportline-scroll" ref={scrollContainerRef}>
        <div className="sidebar-wrapper">
          {renderToggleMenu()}
          {leaguesLoadingStatus === STATUS_LOADING ? (
            <SportlineSidebarLoader />
          ) : (
            <>
              {renderPopularLeagues()}
              <div className="sidebar__title">{t("allSports")}</div>
              {renderAccordion()}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderToggleMenu = () => {
    return (
      <div className="sidebar__toggle-menu">
        <div
          className={
            activeSportKey === "line"
              ? "sidebar__toggle-menu-item active"
              : "sidebar__toggle-menu-item"
          }
          onClick={() => handleType("line")}
          style={{
            pointerEvents: activeSportKey === "line" ? "none" : "",
          }}
        >
          {t("line")}
        </div>
        <div
          className={
            activeSportKey === "live"
              ? "sidebar__toggle-menu-item item-live active"
              : "sidebar__toggle-menu-item item-live"
          }
          onClick={() => handleType("live")}
          style={{
            pointerEvents: activeSportKey === "live" ? "none" : "",
          }}
        >
          <span className="dot-live"></span> Live
        </div>
      </div>
    );
  };

  const renderAccordion = () => {
    return (
      <Accordion className="sidebar-sports-accordion">
        {leagues?.menu?.map((sport) => (
          <Accordion.Item
            eventKey={"sport-" + sport.id}
            key={sport.id}
            className="sport-accordion-item"
          >
            <Accordion.Header className="sidebar-sports-accordion-header">
              <div className="sidebar__sport-item-title">
                <img
                  src={"/black-sport-logo/" + sport.id + ".svg"}
                  alt="sport-icon"
                />
                {localStorage.getItem("language") === "en"
                  ? sport.name_en
                  : sport.name}
                <span className="item-counter">({sport.counter})</span>
              </div>
            </Accordion.Header>
            <Accordion.Body className="sidebar-sports-accordion-body">
              {renderCountries(sport)}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    );
  };

  const renderCountries = (item) => {
    return (
      <Accordion className="sidebar-countries-accordion">
        {item?.sub.map(
          (country) =>
            country.id !== 1 && (
              <Accordion.Item
                eventKey={"country-" + country.id}
                key={country.id}
                className="countries-accordion-item"
              >
                <Accordion.Header className="sidebar-countries-accordion-header">
                  <div className="sidebar__country-item-wrapper">
                    <div className="sidebar__country-item-title">
                      <img
                        src={`/country-logo/${country.id}.png`}
                        alt="country-icon"
                      />
                      {localStorage.getItem("language") === "en"
                        ? country.name_en
                        : country.name}
                    </div>
                    <span className="leagues__item-counter">
                      {country.counter}
                    </span>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="sidebar-sports-accordion-body">
                  {renderLeagues(country)}
                </Accordion.Body>
              </Accordion.Item>
            )
        )}
      </Accordion>
    );
  };

  const renderLeagues = (item) => {
    return (
      <>
        {item?.sub.map((league) => (
          <div
            className={
              activeLeague == league.id
                ? "sport-item__leagues-item active"
                : "sport-item__leagues-item"
            }
            key={league.id}
            onClick={() => handleLeague(league.id, item.sport_id, item.id)}
          >
            <div className="sport-item__leagues-item-title">
              {localStorage.getItem("language") === "en"
                ? league.name_en
                : league.name}
            </div>
            <span className="leagues__item-counter">{league.counter}</span>
          </div>
        ))}
      </>
    );
  };

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderMobile = () => {
    return (
      <div className="mobile-top-panel-wrapper">
        {renderToggleMenu()}
        <div
          ref={dropDownRef}
          className="dropdown-input leagues"
          style={{
            opacity:
              sportEventsStatus === STATUS_LOADING &&
              leaguesLoadingStatus !== STATUS_LOADING
                ? "60%"
                : "",
            pointerEvents: sportEventsStatus === STATUS_LOADING ? "none" : "",
          }}
        >
          <div
            className={
              isOpen
                ? "leagues-select open"
                : selectedLeague.id
                ? "leagues-select selected"
                : "leagues-select"
            }
            onClick={() => setIsOpen(!isOpen)}
          >
            {selectedLeague.id ? (
              <>
                <div className="dd-text-wrapper">
                  <img
                    src={`/country-logo/${selectedLeague.country_id}.png`}
                    className="pe-2"
                    alt=""
                  ></img>
                  <span className="dd-text">
                    {localStorage.getItem("language") === "ua"
                      ? selectedLeague.name
                      : selectedLeague.name_en}
                  </span>
                </div>
                <img src="/dd-arrow.svg" className="dd-arrow" alt=""></img>
              </>
            ) : (
              <>
                <span>{t("allSports")}</span>
                <img src="/dd-arrow.svg" className="dd-arrow" alt=""></img>
              </>
            )}
          </div>
          <div
            className="leagues-list-items"
            style={{
              display: isOpen ? "block" : "none",
            }}
          >
            {renderPopularLeagues()}
            <div
              className="all-sports__dropdown-title"
              onClick={() => handleAllSports()}
            >
              <img src="./list.svg" alt=""></img>
              {t("allSports")}
              <span className="item-counter">({totalCountOfEvents})</span>
            </div>
            {renderAccordion()}
          </div>
        </div>
      </div>
    );
  };

  const renderDesktop = () => {
    return (
      <div className="col-lg-3 sportname-tournamentname-col">
        <div
          className="sportname-tour-back"
          style={{
            opacity:
              sportEventsStatus === STATUS_LOADING &&
              leaguesLoadingStatus !== STATUS_LOADING
                ? "60%"
                : "",
            pointerEvents: sportEventsStatus === STATUS_LOADING ? "none" : "",
          }}
        >
          {renderDesktopSideBar()}
        </div>
      </div>
    );
  };

  return <>{!isTablet ? renderDesktop() : renderMobile()}</>;
};

export default CountriesSideBar;
