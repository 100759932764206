import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthUtils } from "../AuthUtils";
import Header from "./Header";

const CheckMail = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (location.state?.email) {
      setEmail(location.state?.email);
    } else {
      navigate("/signin");
    }
    // AuthUtils()
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("checkMail.tabTitle")}</title>
      </Helmet>
      <Header />
      <section className="form-section">
        <div className="">
          <div className=" d-flex justify-content-center align-items-center">
            <div className="card checkmail-card">
              <div className="card-body p-0">
                {/* ------ avatar ----- */}
                <div className="checkmail-avatar">
                  <div
                    className="rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      width: "64px",
                      height: "64px",
                      backgroundColor: "#F2F3F7",
                    }}
                    alt="Avatar"
                  >
                    <img src="mail.svg" />
                  </div>
                </div>
                <div className="card-title mb-0">
                  <div
                    className=" text-center mt-4"
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      lineHeight: "24px",
                    }}
                  >
                    {t("checkMail.subTitle")}
                  </div>
                  <p
                    className="text-center mt-2 mb-4"
                    style={{
                      fontSize: "16px",
                      color: "#7F87B3",
                      lineHeight: "24px",
                      fontWeight: "300",
                    }}
                  >
                    {t("checkMail.firstMsg")}

                    <span
                      className="me-1"
                      style={{
                        cursor: "pointer",
                        fontWeight: 700,
                        paddingLeft: "4px",
                      }}
                    >
                      {email} .
                    </span>
                    {t("checkMail.secondMsg")}
                  </p>
                  <div>
                    <p
                      className="text-center mt-2 mb-0"
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        lineHeight: "20px",
                        color: "#7F87B3",
                      }}
                    >
                      {t("checkMail.Wrongemail")}

                      <Link
                        to="/signup"
                        style={{
                          textDecoration: "none",
                          fontWeight: "600",
                          lineHeight: "24px",
                          color: "#4C5480",
                          paddingLeft: "4px",
                          fontWeight: 700,
                        }}
                      >
                        {t("checkMail.ReEnterAddress")}
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CheckMail;
