import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import apiService from "../../services/apiService";
import RewardItem from "./RewardItem";
import { Link } from "react-router-dom";
import WalletRewardsLoader from "../CustomSkeletonLoaders/WalletRewardsLoader";

const WalletRewardsSection = () => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [rewards, setRewards] = useState([]);
  const [rewardsCount, setRewardsCount] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  useEffect(() => {
    getRewards();
  }, []);

  const getRewards = async () => {
    setIsLoad(false);
    setRewards([]);
    const rewardsRes = await apiService.getUserRewards();
    let rewardsArr = [];

    const rewardsResArr = Object.entries(rewardsRes[0]);
    for (const category of rewardsResArr) {
      const modifiedArray = Object.values(category[1].sub).map((item) => {
        return { ...item, category: category[0] };
      });
      rewardsArr.push(...modifiedArray);
    }

    rewardsArr.sort((a, b) => {
      if (a.user_level < b.user_level) return 1;
      if (a.user_level > b.user_level) return -1;
      return b.user_progress - a.user_progress;
    });

    setRewards(rewardsArr.slice(0, 4));

    const rewardsCountRes = await apiService.getUserRewardsCount();
    setRewardsCount(rewardsCountRes);

    setIsLoad(true);
  };

  return (
    <div className="wallet-rewards-section">
      <div className="wallet-rewards-title-wrapper">
        <div className="wallet-rewards-title">
          <h2>{t("rewards.reward")}</h2>
          <h4>{t("rewards.titleDesc")}</h4>
        </div>
        {!isMobile && (
          <Link
            className="wallet-rewards__show-all-btn"
            to={
              localStorage.getItem("language") === "ua"
                ? "/achievements"
                : "/en/achievements"
            }
          >
            {t("rewards.showAll")}
          </Link>
        )}
      </div>
      {!isLoad ? (
        <WalletRewardsLoader />
      ) : (
        <>
          {rewards.length > 0 && (
            <div className="wallet-rewards-items">
              {rewards.map((item, itemIndex) => (
                <div className="wallet-rewards-item_wrapper" key={itemIndex}>
                  <RewardItem item={item} getRewards={getRewards} />
                </div>
              ))}
            </div>
          )}
          <div className="wallet-rewards__show-all-wrapper">
            {isMobile && (
              <Link
                to={
                  localStorage.getItem("language") === "ua"
                    ? "/achievements"
                    : "/en/achievements"
                }
                className="wallet-rewards__show-all-btn"
              >
                {t("rewards.showAll")}
              </Link>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default WalletRewardsSection;
