import React from "react";

import "./ProgressBar.css";

const ProgressBar = ({ progress, end }) => {
  const percentage = (progress / end) * 100;
  return (
    <div className="progress-bar-wrapper">
      <div className="progress-bar-title">
        <div className="progress-bar-start">{progress}</div>
        <div className="progress-bar-end">{end}</div>
      </div>
      <div className="progress-bar-container">
        <div className="progress-bar" style={{ width: `${percentage}%` }}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
