import React from "react";
import ContentLoader from "react-content-loader"
const AdminUsertableCustomLoader = (props) => (
    <ContentLoader
        speed={2}
        width={1000}
        height={500}
        viewBox="0 0 1000 500"
        backgroundColor="#F9F9FB"
        foregroundColor="#F9F9FB"
        {...props}
    >
        {/* table -title */}
        <rect x="5" y="5" rx="4" ry="4" width="60" height="25" />
        <rect x="250" y="5" rx="4" ry="4" width="70" height="25" />
        <rect x="390" y="5" rx="4" ry="4" width="60" height="25" />
        <rect x="610" y="5" rx="4" ry="4" width="60" height="25" />
        <rect x="720" y="5" rx="4" ry="4" width="60" height="25" />
        <rect x="800" y="5" rx="4" ry="4" width="40" height="25" />
        {/* description */}
        <rect x="60" y="78" rx="3" ry="3" width="165" height="6" />
        <rect x="60" y="95" rx="3" ry="3" width="165" height="6" />
        <circle cx="25" cy="90" r="20" />
        {/* 2 */}
        <rect x="248" y="78" rx="3" ry="3" width="90" height="6" />
        <rect x="248" y="95" rx="3" ry="3" width="90" height="6" />
        {/* 3 */}
        <rect x="385" y="78" rx="3" ry="3" width="195" height="6" />
        <rect x="385" y="95" rx="3" ry="3" width="195" height="6" />
        {/* 4 */}
        <rect x="608" y="75" rx="5" ry="5" width="90" height="30" />
        {/* 5 */}
        <rect x="728" y="75" rx="10" ry="10" width="40" height="30" />
        {/* 6 */}
        <rect x="800" y="75" rx="10" ry="10" width="40" height="30" />
        {/* 7 */}
        <rect x="860" y="75" rx="10" ry="10" width="30" height="30" />
        <rect x="900" y="75" rx="10" ry="10" width="30" height="30" />

        {/* ------------- */}

        {/* description */}
        <rect x="60" y="163" rx="3" ry="3" width="165" height="6" />
        <rect x="60" y="182" rx="3" ry="3" width="165" height="6" />
        <circle cx="25" cy="175" r="20" />
        {/* 2 */}
        <rect x="248" y="163" rx="3" ry="3" width="90" height="6" />
        <rect x="248" y="182" rx="3" ry="3" width="90" height="6" />
        {/* 3 */}
        <rect x="385" y="163" rx="3" ry="3" width="195" height="6" />
        <rect x="385" y="182" rx="3" ry="3" width="195" height="6" />
        {/* 4 */}
        <rect x="608" y="160" rx="5" ry="5" width="90" height="30" />
        {/* 5 */}
        <rect x="728" y="160" rx="10" ry="10" width="40" height="30" />
        {/* 6 */}
        <rect x="800" y="160" rx="10" ry="10" width="40" height="30" />
        {/* 7 */}
        <rect x="860" y="160" rx="10" ry="10" width="30" height="30" />
        <rect x="900" y="160" rx="10" ry="10" width="30" height="30" />

        {/* description */}
        <rect x="60" y="248" rx="3" ry="3" width="165" height="6" />
        <rect x="60" y="269" rx="3" ry="3" width="165" height="6" />
        <circle cx="25" cy="260" r="20" />
        {/* 2 */}
        <rect x="248" y="248" rx="3" ry="3" width="90" height="6" />
        <rect x="248" y="269" rx="3" ry="3" width="90" height="6" />
        {/* 3 */}
        <rect x="385" y="248" rx="3" ry="3" width="195" height="6" />
        <rect x="385" y="269" rx="3" ry="3" width="195" height="6" />
        {/* 4 */}
        <rect x="608" y="245" rx="5" ry="5" width="90" height="30" />
        {/* 5 */}
        <rect x="728" y="245" rx="10" ry="10" width="40" height="30" />
        {/* 6 */}
        <rect x="800" y="245" rx="10" ry="10" width="40" height="30" />
        {/* 7 */}
        <rect x="860" y="245" rx="10" ry="10" width="30" height="30" />
        <rect x="900" y="245" rx="10" ry="10" width="30" height="30" />

        {/* description */}
        <rect x="60" y="333" rx="3" ry="3" width="165" height="6" />
        <rect x="60" y="356" rx="3" ry="3" width="165" height="6" />
        <circle cx="25" cy="345" r="20" />
        {/* 2 */}
        <rect x="248" y="333" rx="3" ry="3" width="90" height="6" />
        <rect x="248" y="356" rx="3" ry="3" width="90" height="6" />
        {/* 3 */}
        <rect x="385" y="333" rx="3" ry="3" width="195" height="6" />
        <rect x="385" y="356" rx="3" ry="3" width="195" height="6" />
        {/* 4 */}
        <rect x="608" y="330" rx="5" ry="5" width="90" height="30" />
        {/* 5 */}
        <rect x="728" y="330" rx="10" ry="10" width="40" height="30" />
        {/* 6 */}
        <rect x="800" y="330" rx="10" ry="10" width="40" height="30" />
        {/* 7 */}
        <rect x="860" y="330" rx="10" ry="10" width="30" height="30" />
        <rect x="900" y="330" rx="10" ry="10" width="30" height="30" />

        {/* description */}
        <rect x="60" y="418" rx="3" ry="3" width="165" height="6" />
        <rect x="60" y="440" rx="3" ry="3" width="165" height="6" />
        <circle cx="25" cy="430" r="20" />
        {/* 2 */}
        <rect x="248" y="418" rx="3" ry="3" width="90" height="6" />
        <rect x="248" y="440" rx="3" ry="3" width="90" height="6" />
        {/* 3 */}
        <rect x="385" y="418" rx="3" ry="3" width="195" height="6" />
        <rect x="385" y="440" rx="3" ry="3" width="195" height="6" />
        {/* 4 */}
        <rect x="608" y="415" rx="5" ry="5" width="90" height="30" />
        {/* 5 */}
        <rect x="728" y="415" rx="10" ry="10" width="40" height="30" />
        {/* 6 */}
        <rect x="800" y="415" rx="10" ry="10" width="40" height="30" />
        {/* 7 */}
        <rect x="860" y="415" rx="10" ry="10" width="30" height="30" />
        <rect x="900" y="415" rx="10" ry="10" width="30" height="30" />
    </ContentLoader>
)
export default AdminUsertableCustomLoader