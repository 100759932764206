import React from "react";
import "./adminsidebar.css";
import { useTranslation } from "react-i18next";
import InputButton from "../../molecule/InputButton";

const AdminSidebar = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="sidebar-container">
        <div className="sidebar-dashboard-container">
          <InputButton
            type="icon-button"
            addinnerClass="Recomended-following-btn-style"
            inputLabel={t("admin.Dashboard")}
            addBtnClass={props.SideBarButtonColor}
            addSpan={
              <span>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 6.5C3 3.87479 3.02811 3 6.5 3C9.97189 3 10 3.87479 10 6.5C10 9.12521 10.0111 10 6.5 10C2.98893 10 3 9.12521 3 6.5Z"
                    stroke={props.dashBoardStroke}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 6.5C14 3.87479 14.0281 3 17.5 3C20.9719 3 21 3.87479 21 6.5C21 9.12521 21.0111 10 17.5 10C13.9889 10 14 9.12521 14 6.5Z"
                    stroke={props.dashBoardStroke}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3 17.5C3 14.8748 3.02811 14 6.5 14C9.97189 14 10 14.8748 10 17.5C10 20.1252 10.0111 21 6.5 21C2.98893 21 3 20.1252 3 17.5Z"
                    stroke={props.dashBoardStroke}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14 17.5C14 14.8748 14.0281 14 17.5 14C20.9719 14 21 14.8748 21 17.5C21 20.1252 21.0111 21 17.5 21C13.9889 21 14 20.1252 14 17.5Z"
                    stroke={props.dashBoardStroke}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            }
            onClick={props.onClickDashBoard}
          />
        </div>
        <div className="sidebar-users-container">
          <InputButton
            type="icon-button"
            addinnerClass="Recomended-following-btn-style"
            inputLabel={t("admin.Users")}
            addBtnClass={props.SideBarUserButtonColor}
            addSpan={
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.8877 10.8967C19.2827 10.7007 20.3567 9.50473 20.3597 8.05573C20.3597 6.62773 19.3187 5.44373 17.9537 5.21973" stroke={props.userStroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M19.7285 14.2505C21.0795 14.4525 22.0225 14.9255 22.0225 15.9005C22.0225 16.5715 21.5785 17.0075 20.8605 17.2815" stroke={props.userStroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11.8867 14.6641C8.67273 14.6641 5.92773 15.1511 5.92773 17.0961C5.92773 19.0401 8.65573 19.5411 11.8867 19.5411C15.1007 19.5411 17.8447 19.0591 17.8447 17.1131C17.8447 15.1671 15.1177 14.6641 11.8867 14.6641Z" stroke={props.userStroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M11.8867 11.888C13.9957 11.888 15.7057 10.179 15.7057 8.069C15.7057 5.96 13.9957 4.25 11.8867 4.25C9.77768 4.25 8.06765 5.96 8.06765 8.069C8.05968 10.171 9.75668 11.881 11.8587 11.888H11.8867Z" stroke={props.userStroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M5.88484 10.8967C4.48884 10.7007 3.41584 9.50473 3.41284 8.05573C3.41284 6.62773 4.45384 5.44373 5.81884 5.21973" stroke={props.userStroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M4.044 14.2505C2.693 14.4525 1.75 14.9255 1.75 15.9005C1.75 16.5715 2.194 17.0075 2.912 17.2815" stroke={props.userStroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
            }
            onClick={props.onClickUser}
          />
        </div>
        <div className="sidebar-setting-container">
          <InputButton
            type="icon-button"
            addinnerClass="Recomended-following-btn-style"
            inputLabel={t("navbar.settings")}
            addBtnClass={props.sidebarSettingButtonColor}
            addSpan={
              <span>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M20.8064 7.62361L20.184 6.54352C19.6574 5.6296 18.4905 5.31432 17.5753 5.83872C17.1397 6.09534 16.6198 6.16815 16.1305 6.04109C15.6411 5.91402 15.2224 5.59752 14.9666 5.16137C14.8021 4.88415 14.7137 4.56839 14.7103 4.24604C14.7251 3.72922 14.5302 3.2284 14.1698 2.85767C13.8094 2.48694 13.3143 2.27786 12.7973 2.27808H11.5433C11.0367 2.27808 10.5511 2.47991 10.1938 2.83895C9.83644 3.19798 9.63693 3.68459 9.63937 4.19112C9.62435 5.23693 8.77224 6.07681 7.72632 6.0767C7.40397 6.07336 7.08821 5.98494 6.81099 5.82041C5.89582 5.29601 4.72887 5.61129 4.20229 6.52522L3.5341 7.62361C3.00817 8.53639 3.31916 9.70261 4.22975 10.2323C4.82166 10.574 5.18629 11.2056 5.18629 11.8891C5.18629 12.5725 4.82166 13.2041 4.22975 13.5458C3.32031 14.0719 3.00898 15.2353 3.5341 16.1454L4.16568 17.2346C4.4124 17.6798 4.82636 18.0083 5.31595 18.1474C5.80554 18.2866 6.3304 18.2249 6.77438 17.976C7.21084 17.7213 7.73094 17.6516 8.2191 17.7822C8.70725 17.9128 9.12299 18.233 9.37392 18.6717C9.53845 18.9489 9.62686 19.2646 9.63021 19.587C9.63021 20.6435 10.4867 21.5 11.5433 21.5H12.7973C13.8502 21.5 14.7053 20.6491 14.7103 19.5962C14.7079 19.088 14.9086 18.6 15.2679 18.2407C15.6272 17.8814 16.1152 17.6807 16.6233 17.6831C16.9449 17.6917 17.2594 17.7798 17.5387 17.9394C18.4515 18.4653 19.6177 18.1544 20.1474 17.2438L20.8064 16.1454C21.0615 15.7075 21.1315 15.186 21.001 14.6964C20.8704 14.2067 20.55 13.7894 20.1108 13.5367C19.6715 13.284 19.3511 12.8666 19.2206 12.3769C19.09 11.8873 19.16 11.3658 19.4151 10.928C19.581 10.6383 19.8211 10.3982 20.1108 10.2323C21.0159 9.70289 21.3262 8.54349 20.8064 7.63277V7.62361Z" stroke={props.settingStroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M12.1747 14.5252C13.6306 14.5252 14.8109 13.345 14.8109 11.8891C14.8109 10.4332 13.6306 9.25293 12.1747 9.25293C10.7188 9.25293 9.53857 10.4332 9.53857 11.8891C9.53857 13.345 10.7188 14.5252 12.1747 14.5252Z" stroke={props.settingStroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
            }
            onClick={props.onClickSetting}
          />
        </div>
      </div>
    </>
  );
};
export default AdminSidebar;
