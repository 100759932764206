import React from "react";
import { useTranslation } from "react-i18next";

import Ad from "./Ad";

const ListAd = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="list-add__wrapper">
      <div className="d-flex justify-content-between">
        <div className="addUser-img-date-name d-flex">
          <div className="me-3">
            <img className="addeduser-img" src="/ca.svg" alt="" />
          </div>
          <div>
            <div className="addeduser-name">Capperauditor</div>
            <div className="addeduser-date-time">{t("ad")}</div>
          </div>
        </div>
      </div>
      <Ad />
    </div>
  );
};

export default ListAd;
