import React, { useEffect, useState, useRef } from "react";
import NavigationBar from "../Navbar";
import "./setting.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useTranslation } from "react-i18next";
import { dynamicActivate } from "../../i18n";
import Notification from "../molecule/Notification";
import apiService from "../../services/apiService";
import InputField from "../molecule/InputField";
import { Button } from "bootstrap";
import InputButton from "../molecule/InputButton";
import { loadLanguages } from "i18next";
import { eventBus } from "../../services/events";
import { CONSTANT } from "../../common/constant";
import { useLocation, useNavigate } from "react-router-dom";
import HomePageFooter from "../molecule/homepage/HomePageFooter/HomePageFooter";
import { isValidFullName, isValidName } from "../../helpers/validator";
import { Helmet } from "react-helmet";

function useOutsideAlerter(ref, handleLanguageDropDown) {}

const Setting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const tab = location.state ? location.state.tab ? location.state.tab : 'profile' : 'profile'
  const [key, setKey] = useState("general");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isNameError, setIsNameError] = useState(false);
  const [nameError, setNameError] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailError, setEmailError] = useState("");

  let fullname = "";
  if (localStorage.getItem("profile")) {
    fullname =
      apiService.getProfileFromStorage().firstName +
      " " +
      apiService.getProfileFromStorage().lastName;
  } else {
    navigate("/signin");
  }
  const [name, setName] = useState(fullname);
  const [userName, setUserName] = useState("");
  let useremail = "";
  if (localStorage.getItem("profile")) {
    useremail = apiService.getProfileFromStorage().email;
  } else {
    navigate("/signin");
  }
  const [email, setEmail] = useState(useremail);
  const wrapperRef = useRef(null);
  const [showVerifyEmail, setShowVerifyEmail] = useState(
    "verify-email-popup verify-email-popup-hide"
  );
  const { t, i18n } = useTranslation();
  let lang = "EN";
  let langLogo = "/en.svg";
  let langU = "en";
  if (localStorage.getItem("language") == "ua") {
    lang = "Українська";
    langLogo = "/ua.svg";
    langU = "ua";
  } else if (localStorage.getItem("language") == "en") {
    lang = "English";
    langLogo = "/en.svg";
    langU = "en";
  } else if (localStorage.getItem("profile")) {
    lang =
      apiService.getProfileFromStorage().Language == "en"
        ? "English"
        : "Українська";
    langLogo =
      apiService.getProfileFromStorage().Language == "en"
        ? "/en.svg"
        : "/ua.svg";
    langU = apiService.getProfileFromStorage().Language;
  } else {
    lang = "English";
    langLogo = "/en.svg";
    langU = "en";
  }
  let currencyL = "USD";
  let currencyU = "usd";
  let currencyLLogo = "usd.svg";
  if (localStorage.getItem("currency") == "uah") {
    currencyL = "UAH";
    currencyU = "uah";
    currencyLLogo = "/ua.svg";
  } else if (localStorage.getItem("currency") == "euro") {
    currencyL = "EURO";
    currencyU = "euro";
    currencyLLogo = "/euro.svg";
  } else if (localStorage.getItem("currency") == "usd") {
    currencyL = "USD";
    currencyU = "usd";
    currencyLLogo = "/usd.svg";
  } else if (apiService.getProfileFromStorage()?.Currency) {
    currencyL =
      apiService.getProfileFromStorage().Currency == "usd"
        ? "USD"
        : "uah"
        ? "UAH"
        : "EURO";
    currencyLLogo =
      apiService.getProfileFromStorage().Currency == "usd"
        ? "/usd.svg"
        : "uah"
        ? "/ua.svg"
        : "/euro.svg";
    currencyU = apiService.getProfileFromStorage().Currency;
  }
  const [passEmailErr, setPassEmailErr] = useState(false);
  const [inputDisable, setInputDisable] = useState(false);
  const [language, setlanguage] = useState(lang);
  const [languageName, setlanguageName] = useState(langU);
  const [languageLogo, setlanguageLogo] = useState(langLogo);
  const [oddsFormatData, setOddsFormateData] = useState("EU Odds (1.50)");
  const [currency, setCurrency] = useState(currencyL);
  const [currencyName, setCurrencyName] = useState(currencyU);
  const [currencyLogo, setCurrencyLogo] = useState(currencyLLogo);
  const [showDropdown, setShowDropDown] = useState(
    "list-drop-down list-drop-down-hide "
  );
  const [showOddFormateDropdown, setOddFormateShowDropDown] = useState(
    "list-drop-down list-drop-down-hide "
  );
  const [showCurrencyDropdown, setShowCurrencyDropDown] = useState(
    "list-drop-down list-drop-down-hide "
  );
  const [successPopupShow, setSuccessPopup] = useState(
    "success-popup popup-hide"
  );
  const [newEmail, setNewEmail] = useState("");
  const [isCheacked, setIsCheacked] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("profile")) {
      if (apiService.getProfileFromStorage().changeEmail !== "") {
        setShowVerifyEmail("verify-email-popup verify-email-popup-show");
        setNewEmail(apiService.getProfileFromStorage().changeEmail);
        setInputDisable(true);
        setEmail(apiService.getProfileFromStorage().email);
      } else {
        setShowVerifyEmail("verify-email-popup verify-email-popup-hide");
        setNewEmail("");
        setEmail("");
        setInputDisable(false);
      }
    } else {
      navigate("/userprofile");
    }
    setIsCheacked(false);
  }, [isCheacked]);
  const handleLanguageDropDown = () => {
    if (showDropdown === "list-drop-down list-drop-down-show") {
      setShowDropDown("list-drop-down list-drop-down-hide");
    } else {
      setShowDropDown("list-drop-down list-drop-down-show");
    }
    setShowCurrencyDropDown("list-drop-down list-drop-down-hide");
    setOddFormateShowDropDown("list-drop-down list-drop-down-hide");
  };
  const handleCurrencyDropDown = () => {
    // setShowCurrencyDropDown("list-drop-down list-drop-down-show ")
    if (showCurrencyDropdown === "list-drop-down list-drop-down-show") {
      setShowCurrencyDropDown("list-drop-down list-drop-down-hide");
    } else {
      setShowCurrencyDropDown("list-drop-down list-drop-down-show");
    }
    setShowDropDown("list-drop-down list-drop-down-hide");
    setOddFormateShowDropDown("list-drop-down list-drop-down-hide");
  };

  const handleOddFormateDropDown = () => {
    // setOddFormateShowDropDown("list-drop-down list-drop-down-show ")
    if (showOddFormateDropdown === "list-drop-down list-drop-down-show") {
      setOddFormateShowDropDown("list-drop-down list-drop-down-hide");
    } else {
      setOddFormateShowDropDown("list-drop-down list-drop-down-show");
    }
    setShowDropDown("list-drop-down list-drop-down-hide");
    setShowCurrencyDropDown("list-drop-down list-drop-down-hide");
  };
  const handleLanguage = (language, languageShortName, logo) => {
    setlanguageName(languageShortName);
    setlanguage(language);
    setlanguageLogo(logo);
    setShowDropDown("list-drop-down list-drop-down-hide");
  };
  const handleCurrency = (currency, currencyShortName, logo) => {
    setCurrencyName(currencyShortName);
    setCurrency(currency);
    setCurrencyLogo(logo);
    setShowCurrencyDropDown("list-drop-down list-drop-down-hide");
  };
  const handleOddFormate = (data) => {
    setOddsFormateData(data);
    setOddFormateShowDropDown("list-drop-down list-drop-down-hide");
  };
  const handleUpdateData = async () => {
    // setSuccessPopup("success-popup popup-show")
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.updateUserProfile({
      Language: languageName,
      Currency: currencyName,
      Odds_format: oddsFormatData,
    });
    eventBus.emit(CONSTANT.HIDE_LOADER);

    if (res) {
      setSuccessPopup("success-popup popup-show");
    }
    setTimeout(() => {
      window.location.reload();
      navigate("/setting", {
        state: {
          tab: "general",
        },
      });
      // window.location.reload();
    }, 1000);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  useOutsideAlerter(wrapperRef);

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  function listenForOutsideClicks() {
    return () => {
      if (listening) return;
      if (!menuRef.current) return;
      setListening(true);
      ["mousedown"].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRef.current.contains(evt.target)) {
          } else {
            setShowDropDown("list-drop-down list-drop-down-hide");
          }
        });
      });
    };
  }
  const menuRefCurrency = useRef(null);
  const [listeningCurrency, setListeningCurrency] = useState(false);
  function listenForOutsideClicksCurrency() {
    return () => {
      if (listeningCurrency) return;
      if (!menuRefCurrency.current) return;
      setListeningCurrency(true);
      ["mousedown"].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRefCurrency.current.contains(evt.target)) {
            // handleLanguageDropDown()
          } else {
            setShowCurrencyDropDown("list-drop-down list-drop-down-hide");
          }
        });
      });
    };
  }

  const menuRefOdd = useRef(null);
  const [listeningOdd, setListeningOdd] = useState(false);
  function listenForOutsideClicksOdd() {
    return () => {
      if (listeningOdd) return;
      if (!menuRefOdd.current) return;
      setListeningOdd(true);
      ["mousedown"].forEach((type) => {
        document.addEventListener(`click`, (evt) => {
          if (menuRefOdd.current.contains(evt.target)) {
          } else {
            setOddFormateShowDropDown("list-drop-down list-drop-down-hide");
          }
        });
      });
    };
  }

  useEffect(listenForOutsideClicks(), [showDropdown]);
  useEffect(listenForOutsideClicksCurrency(), [showCurrencyDropdown]);
  useEffect(listenForOutsideClicksOdd(), [showOddFormateDropdown]);

  useEffect(() => {
    document.body.classList.add("setting-tab-content");
    let el = document.getElementsByClassName(
      "setting-tab nav nav-tabs nav-justified"
    )[0];
    let parent = el.parentNode;
    let wrapper = document.createElement("div");
    wrapper.setAttribute("class", "setting-tab-list");
    parent.replaceChild(wrapper, el);
    wrapper.appendChild(el);

    el = document.getElementsByClassName("tab-content")[0];
    parent = el.parentNode;
    wrapper = document.createElement("div");
    wrapper.setAttribute("class", "setting-tab-content");
    parent.replaceChild(wrapper, el);
    wrapper.appendChild(el);
  }, []);

  const resendVerificationEmail = async () => {
    const res = await apiService.changeEmail(
      apiService.getProfileFromStorage().changeEmail
    );
    await apiService.getUserProfile();
    if (res.data) {
      setIsCheacked(true);
    }
  };
  const handleProfile = async () => {
    const userFullName =
      apiService.getProfileFromStorage().firstName +
      " " +
      apiService.getProfileFromStorage().lastName;
    if (name !== userFullName) {
      const firstName = name.split(" ")[0];
      const lastName = name.split(" ")[1];
      eventBus.emit(CONSTANT.SHOW_LOADER);
      const res = await apiService.updateUserProfile({ firstName, lastName });
      eventBus.emit(CONSTANT.HIDE_LOADER);
      if (res.data) {
        setIsCheacked(true);
      }
      if (!res.data) {
        setSuccessPopup("success-popup popup-show");
      }
      setTimeout(() => {
        setSuccessPopup("success-popup popup-hide");
      }, 1050);
    }
    if (apiService.getProfileFromStorage().email != email && email !== "") {
      eventBus.emit(CONSTANT.SHOW_LOADER);
      const res = await apiService.changeEmail(email);
      eventBus.emit(CONSTANT.HIDE_LOADER);
      await apiService.getUserProfile();
      if (res.data) {
        setIsCheacked(true);
        setPassEmailErr(false);
      } else {
        setPassEmailErr(true);
      }
    }
  };
  const handleCancleChangeEmail = async () => {
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.updateUserProfile({ changeEmail: "" });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res) {
      setIsCheacked(true);
    }
  };

  const [passErr, setPassErr] = useState(false);
  const [passErrConfirm, setPassErrConfirm] = useState(false);
  const [isVAlidateSuccess, setisVAlidateSuccess] = useState(false);
  const [checkValidation, setCheackValidation] = useState({
    length: "fa fa-check",
    uppercase: "fa fa-check",
    lowercase: "fa fa-check",
    specialChar: "fa fa-check",
    number: "fa fa-check",
  });
  const handleSecurity = async () => {
    if (newPassword !== confirmPassword) {
      setPassErrConfirm(true);
      return;
    }
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.userChangePassword({
      oldPassword: currentPassword,
      newPassword: confirmPassword,
    });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (!res.error) {
      setSuccessPopup("success-popup popup-show");
      setPassErr(false);
      setPassErrConfirm(false);
    } else {
      setPassErr(true);
    }
    setTimeout(() => {
      setSuccessPopup("success-popup popup-hide");
    }, 1050);
  };
  const validationPass = (e) => {
    const value = e.target.value;
    const uppercaseRegExp = /(?=.*?[A-Z])/;
    const lowercaseRegExp = /(?=.*?[a-z])/;
    const digitsRegExp = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp = /.{12,}/;
    const uppercasePassword = uppercaseRegExp.test(value);
    const lowercasePassword = lowercaseRegExp.test(value);
    const digitsPassword = digitsRegExp.test(value);
    const specialCharPassword = specialCharRegExp.test(value);
    const minLengthPassword = minLengthRegExp.test(value);
    if (minLengthPassword) {
      setCheackValidation((oldState) => {
        return { ...oldState, length: "fa fa-check text-green" };
      });
    } else {
      setCheackValidation((oldState) => {
        return { ...oldState, length: "fa fa-check" };
      });
    }
    if (lowercasePassword) {
      setCheackValidation((oldState) => {
        return { ...oldState, lowercase: "fa fa-check text-green" };
      });
    } else {
      setCheackValidation((oldState) => {
        return { ...oldState, lowercase: "fa fa-check" };
      });
    }
    if (uppercasePassword) {
      setCheackValidation((oldState) => {
        return { ...oldState, uppercase: "fa fa-check text-green" };
      });
    } else {
      setCheackValidation((oldState) => {
        return { ...oldState, uppercase: "fa fa-check" };
      });
    }
    if (digitsPassword) {
      setCheackValidation((oldState) => {
        return { ...oldState, number: "fa fa-check text-green" };
      });
    } else {
      setCheackValidation((oldState) => {
        return { ...oldState, number: "fa fa-check" };
      });
    }
    if (specialCharPassword) {
      setCheackValidation((oldState) => {
        return { ...oldState, specialChar: "fa fa-check text-green" };
      });
    } else {
      setCheackValidation((oldState) => {
        return { ...oldState, specialChar: "fa fa-check" };
      });
    }

    if (
      uppercasePassword &&
      lowercasePassword &&
      minLengthPassword &&
      digitsPassword &&
      specialCharPassword
    ) {
      setisVAlidateSuccess(true);
    } else {
      setisVAlidateSuccess(false);
    }
  };

  const handleNameChange = (name) => {
    let isName = isValidFullName(name, t);

    if (!isName.isValid) {
      setIsNameError(true);
      setNameError(isName.nameValidation);
    } else {
      setName(name);
      setIsNameError(false);
      setNameError("");
    }
  };

  useEffect(() => {
    document.title = t("setting.metaTitle");
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <NavigationBar />
      <div className="setting" id="setting-section">
        <div className="setting-section">
          <div className=" setting-container">
            <div className="container-1240">
              <div className="settingpage-title">{t("setting.settings")}</div>
            </div>

            <div className="tab-part">
              <Tabs
                defaultActiveKey="general"
                id="justify-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="setting-tab"
                justify
              >
                {/*  General tab  */}
                <Tab eventKey="general" title={t("setting.general")}>
                  {/* language-dropdown */}
                  <div className="general-tab-container">
                    <div className="tabs-label">{t("setting.language")}</div>
                    <div className="tabs-dropdown">
                      <div
                        className="d-flex selected-language ignore-dropdown"
                        ref={menuRef}
                        onClick={handleLanguageDropDown}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={languageLogo}
                            className="drop-down-logo ignore-dropdown"
                            height="20px"
                            width="20px"
                          ></img>
                          <div className="language-name ignore-dropdown">
                            {language}
                          </div>
                        </div>
                        {showDropdown ===
                        "list-drop-down list-drop-down-show" ? (
                          <img src="/up.svg" className="ignore-dropdown"></img>
                        ) : (
                          <img
                            src="/down.svg"
                            className="ignore-dropdown"
                          ></img>
                        )}
                      </div>
                      <ul
                        className="list-unstyled ignore-dropdown"
                        id="language-dropdown"
                      >
                        <div className={showDropdown}>
                          <li
                            data-value="value 1"
                            className="d-flex language-menu"
                            onClick={() =>
                              handleLanguage("Українська", "ua", "/ua.svg")
                            }
                          >
                            <img src="/ua.svg" className="drop-down-logo"></img>
                            <div className="language-name">
                              Українська{" "}
                              <span style={{ color: "#7F87B3" }}>UA</span>
                            </div>
                          </li>
                          <li
                            data-value="value 1"
                            className="d-flex language-menu"
                            onClick={() =>
                              handleLanguage("English", "en", "/en.svg")
                            }
                          >
                            <img src="/en.svg" className="drop-down-logo"></img>
                            <div className="language-name">
                              English{" "}
                              <span style={{ color: "#7F87B3" }}>EN</span>
                            </div>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className="">
                    <button
                      className="general-button"
                      onClick={handleUpdateData}
                    >
                      {t("setting.saveChanges")}
                    </button>
                    <Notification
                      message={t("setting.changesSaved")}
                      hideShow={successPopupShow}
                    />
                  </div>
                </Tab>

                {/* Profile tab */}

                <Tab eventKey="profile" title={t("setting.profile")}>
                  <div className="profile-tab-container">
                    <InputField
                      inputLabel={t("setting.name")}
                      inputType="text"
                      defaultValue={
                        apiService.getProfileFromStorage().firstName +
                        " " +
                        apiService.getProfileFromStorage().lastName
                      }
                      isError={isNameError}
                      errorMsg={nameError}
                      onChange={(e) => handleNameChange(e.target.value)}
                    />
                    <InputField
                      inputLabel={t("setting.username")}
                      inputType="text"
                      defaultValue={apiService.getProfileFromStorage().userName}
                      disabled={true}
                      isError={false}
                      errorMsg={"some error"}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                    <div>
                      <InputField
                        id="email-profile"
                        inputLabel={t("setting.email")}
                        inputType="email"
                        defaultValue={apiService.getProfileFromStorage().email}
                        disabled={inputDisable}
                        isError={passEmailErr}
                        errorMsg={t("setting.emailError")}
                        onChange={handleEmail}
                        verifyEmailContent={t("setting.verifyEmailContaint", {
                          email: "abc@gmail.com",
                        })}
                      />

                      <div className={showVerifyEmail}>
                        <div className="verify-email-popup-frame p-3 d-flex">
                          <div className="warning-icon">
                            <img src="/info.svg"></img>
                          </div>
                          <div className="verify-email-frame">
                            <div className="verify-email-frame-title">
                              {t("setting.verifyEmailTitle")}
                            </div>
                            <div className="verify-email-frame-containt">
                              {/* We’ve sent an email to <span className="verify-email">vozniuk.team@gmail.com.</span> Your change will not be complete until you verify your new email address. */}
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: t("setting.verifyEmailContaint", {
                                    email: newEmail,
                                  }),
                                }}
                              />
                            </div>
                            <div className="d-flex">
                              <div style={{ paddingRight: "4px" }}>
                                {" "}
                                <InputButton
                                  type="secondary"
                                  addBtnClass={"cancle-change-btn-width"}
                                  inputLabel={t("setting.cancelChange")}
                                  onClick={handleCancleChangeEmail}
                                />
                              </div>
                              <div style={{ paddingLeft: "4px" }}>
                                {" "}
                                <InputButton
                                  type="secondary"
                                  addBtnClass={"resendEmail-btn-width"}
                                  inputLabel={t("setting.resendEmail")}
                                  onClick={resendVerificationEmail}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <InputButton
                      type="primary"
                      inputLabel={t("setting.saveChanges")}
                      addBtnClass="profile-save-changes"
                      disabled={
                        name ===
                          apiService.getProfileFromStorage().firstName +
                            " " +
                            apiService.getProfileFromStorage().lastName &&
                        email === ""
                          ? true
                          : false
                      }
                      onClick={handleProfile}
                    />
                    <Notification
                      message={t("setting.nameUpdatedMessage")}
                      hideShow={successPopupShow + " " + "updateName-pop-up"}
                    />
                  </div>
                </Tab>
                {/* security tab */}
                <Tab eventKey="security" title={t("setting.security")}>
                  <div className="security-tab-container">
                    <InputField
                      inputLabel={t("setting.currentPassword") + "*"}
                      inputType="password"
                      inputPlaceHolder="Enter your current password"
                      isError={passErr}
                      errorMsg={t("setting.currentPasswordWrong")}
                      onChange={(e) => {
                        setPassErr(false);
                        setCurrentPassword(e.target.value);
                      }}
                    />
                    <InputField
                      inputLabel={t("setting.newPassword") + "*"}
                      inputType="password"
                      passShowBtn={true}
                      inputPlaceHolder="Enter a new password"
                      isError={passErrConfirm}
                      errorMsg={t("setting.passwordMismatch")}
                      onChange={(e) => {
                        setPassErrConfirm(false);
                        setNewPassword(e.target.value);
                        validationPass(e);
                      }}
                    />
                    <div
                      className="setting-password-acc"
                      style={
                        newPassword !== ""
                          ? isVAlidateSuccess
                            ? { display: "none" }
                            : { display: "block" }
                          : { display: "none" }
                      }
                    >
                      <p className="mb-2">Must contain at least:</p>
                      <ul className="list-unstyled mb-0">
                        <li>
                          <i className={checkValidation.length}></i>12
                          characters
                        </li>
                        <li>
                          <i className={checkValidation.lowercase}></i>1 lower
                          case character
                        </li>
                        <li>
                          <i className={checkValidation.uppercase}></i>1 upper
                          case character
                        </li>
                        <li>
                          <i className={checkValidation.number}></i>1 number
                        </li>
                        <li>
                          <i className={checkValidation.specialChar}></i>1
                          special character
                        </li>
                      </ul>
                    </div>

                    <InputField
                      inputLabel={t("setting.confirmPassword") + "*"}
                      inputType="password"
                      inputPlaceHolder="Enter your new password again"
                      isError={false}
                      errorMsg={"some error"}
                      onChange={(e) => {
                        setPassErrConfirm(false);
                        setConfirmPassword(e.target.value);
                      }}
                    />

                    <InputButton
                      type="primary"
                      addBtnClass="setting-saveChanges"
                      inputLabel={t("setting.saveChanges")}
                      disabled={
                        currentPassword === "" ||
                        newPassword === "" ||
                        confirmPassword === "" ||
                        passErrConfirm
                          ? true
                          : false
                      }
                      onClick={handleSecurity}
                    />
                    <Notification
                      message={t("setting.successedMessage")}
                      hideShow={
                        successPopupShow + " " + "updatePassword-pop-up"
                      }
                    />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <HomePageFooter />
    </>
  );
};

// document.getElementsByClassName("setting-tab-content")[0].addEventListener("click", (e) => {
//     if (document.getElementsByClassName("list-drop-down-show").length > 0) {
//         if (e.path[0].classList['value'].includes("ignore-dropdown")) {
//             document.getElementsByClassName("list-drop-down-show")[0].classList.toggle("list-drop-down-hide")
//         }
//     }
// });

export default Setting;
