import React from "react";
import { useMediaQuery } from "react-responsive";

import "./style.css";

const WalletHistoryLoader = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const renderMobile = () => {
    return (
      <>
        {Array(4)
          .fill(0)
          .map((item, index) => (
            <div className="mobile-reward-history-item" key={index}>
              <div className="mobile-reward-history-title">
                <div className="skeleton-placeholder-light mobile-wallet-history-img"></div>
                <div className="mobile-reward-history-text-wrapper">
                  <div className="skeleton-placeholder-light mobile-wallet-history-title"></div>
                  <div className="skeleton-placeholder-light mobile-wallet-history-subtitle"></div>
                </div>
              </div>

              <div className="mobile-reward-history-values">
                <div className="mobile-reward-history-value">
                  <div className="skeleton-placeholder-light mobile-wallet-history-value-title1"></div>
                  <div className="skeleton-placeholder-light mobile-wallet-history-value-data1"></div>

                </div>
                <div className="mobile-reward-history-value date-value">
                <div className="skeleton-placeholder-light mobile-wallet-history-value-title2"></div>
                  <div className="skeleton-placeholder-light mobile-wallet-history-value-data2"></div>
                </div>
              </div>
            </div>
          ))}
      </>
    );
  };

  if(isMobile) return renderMobile();
  
  return (
    <div className="wallet-history-loader-wrapper">
      <div className="wallet-history-loader-title-row">
        <div className="wallet-history-loader-title1">
          <div className="skeleton-placeholder-light wallet-history-title1"></div>
        </div>
        <div className="wallet-history-loader-title2">
          <div className="skeleton-placeholder-light wallet-history-title2"></div>
        </div>
        <div className="wallet-history-loader-title3">
          <div className="skeleton-placeholder-light wallet-history-title3"></div>
        </div>
        <div className="wallet-history-loader-title4">
          <div className="skeleton-placeholder-light wallet-history-title4"></div>
        </div>
      </div>

      {Array(4)
        .fill(0)
        .map((item, index) => (
          <div className="wallet-history-loader-data-row" key={index}>
            <div className="wallet-history-loader-data1">
              <div className="skeleton-placeholder-light wallet-history-img"></div>
              <div className="skeleton-placeholder-light wallet-history-data1"></div>
            </div>
            <div className="wallet-history-loader-data2">
              <div className="skeleton-placeholder-light wallet-history-data2"></div>
            </div>
            <div className="wallet-history-loader-data3">
              <div className="skeleton-placeholder-light wallet-history-data3"></div>
            </div>
            <div className="wallet-history-loader-data4">
              <div className="skeleton-placeholder-light wallet-history-data4"></div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default WalletHistoryLoader;
