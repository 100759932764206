import React from 'react'
import ContentLoader from "react-content-loader"

const MoreOddsCustomLoader = (props) =>
(<ContentLoader
    speed={2}
    // width={950}
    // height={1050}
    viewBox="0 0 950 1050"
    backgroundColor="#F9F9FB"
    foregroundColor="#F9F9FB"
    {...props}
>
    <rect x="35" y="15" rx="4" ry="4" width="35" height="20" />
    <rect x="85" y="15" rx="4" ry="4" width="250" height="20" />
    <rect x="415" y="55" rx="4" ry="4" width="150" height="6" />
    <rect x="245" y="100" rx="4" ry="4" width="150" height="6" />
    <circle cx="430" cy="105" r="25" />
    <rect x="480" y="100" rx="4" ry="4" width="15" height="6" />
    <circle cx="550" cy="105" r="25" />
    <rect x="585" y="100" rx="4" ry="4" width="150" height="6" />
    {/* 1 */}
    <rect x="35" y="220" rx="4" ry="4" width="150" height="16" />
    <rect x="35" y="255" rx="4" ry="4" width="270" height="40" />
    <rect x="350" y="255" rx="4" ry="4" width="270" height="40" />
    <rect x="665" y="255" rx="4" ry="4" width="270" height="40" />
    {/* 2 */}
    <rect x="35" y="320" rx="4" ry="4" width="150" height="16" />
    <rect x="35" y="355" rx="4" ry="4" width="270" height="40" />
    <rect x="350" y="355" rx="4" ry="4" width="270" height="40" />
    <rect x="665" y="355" rx="4" ry="4" width="270" height="40" />
    {/* 3 */}
    <rect x="35" y="420" rx="4" ry="4" width="150" height="16" />
    <rect x="35" y="455" rx="4" ry="4" width="435" height="40" />
    <rect x="500" y="455" rx="4" ry="4" width="435" height="40" />
    <rect x="35" y="505" rx="4" ry="4" width="435" height="40" />
    <rect x="500" y="505" rx="4" ry="4" width="435" height="40" />
    <rect x="35" y="555" rx="4" ry="4" width="435" height="40" />
    <rect x="500" y="555" rx="4" ry="4" width="435" height="40" />
    <rect x="35" y="605" rx="4" ry="4" width="435" height="40" />
    <rect x="500" y="605" rx="4" ry="4" width="435" height="40" />
    <rect x="35" y="655" rx="4" ry="4" width="435" height="40" />
    <rect x="500" y="655" rx="4" ry="4" width="435" height="40" />
    <rect x="35" y="705" rx="4" ry="4" width="435" height="40" />
    <rect x="500" y="705" rx="4" ry="4" width="435" height="40" />
    <rect x="35" y="755" rx="4" ry="4" width="435" height="40" />
    <rect x="500" y="755" rx="4" ry="4" width="435" height="40" />

    {/* 4 */}
    <rect x="35" y="820" rx="4" ry="4" width="150" height="16" />
    <rect x="35" y="855" rx="4" ry="4" width="270" height="40" />
    <rect x="350" y="855" rx="4" ry="4" width="270" height="40" />
    <rect x="665" y="855" rx="4" ry="4" width="270" height="40" />
    <rect x="35" y="905" rx="4" ry="4" width="270" height="40" />
    <rect x="350" y="905" rx="4" ry="4" width="270" height="40" />
    <rect x="665" y="905" rx="4" ry="4" width="270" height="40" />
    <rect x="35" y="955" rx="4" ry="4" width="270" height="40" />
    <rect x="350" y="955" rx="4" ry="4" width="270" height="40" />
    <rect x="665" y="955" rx="4" ry="4" width="270" height="40" />























</ContentLoader>
)
export default MoreOddsCustomLoader