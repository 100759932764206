import React, { useState, useEffect } from "react";
import InputButton from "../InputButton";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import "./feedsidebar.css";
import FeedPrediction from "../feedPrediction/FeedPrediction";
import apiService from "../../../services/apiService";
import { Environment } from "../../../environment";
import AddPrediction from "../Prediction/AddPrediction";
import Model from "../model/Model";
import { eventBus } from "../../../services/events";
import { CONSTANT } from "../../../common/constant";
import { Link, useNavigate } from "react-router-dom";
import FeedSideBarCustomLoader from "../../Custom loader/FeedSideBarCustomLoader";
import Ad from "../../General/Ad";
import Ad2 from "../../General/Ad2";

const FeedSideBar = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loginEmail, setLoginEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [errorClass, setErrorClass] = useState("mb-3");
  const [error, setError] = useState("form-error-line-hide");
  const [isActive, setIsActive] = useState(false);
  const [makePrediction, setMakePrediction] = useState(false);
  const [popularEvents, setPopularEvents] = useState([]);
  const [popularLeague, setPopularLeague] = useState([]);
  const [addSomePredictionsModel, setSomeAddPredictionModel] = useState(
    "add-prediction-model-hide"
  );
  const [predictionModel, setPredictionModle] = useState(false);
  const [addPredictionsModel, setAddPredictionModle] = useState(false);
  const [predictionTableHide, setpredictionTableHide] = useState(false);
  const [propularData, setPropularData] = useState("");
  const [CustomLoader, setCustomLoader] = useState(true);
  const handleAddPredictionBtn = () => {
    navigate("/sportline");
    if (localStorage.getItem("token")) {
      // if (isActive) {
      //   setIsActive(false);
      // } else {
      //   setIsActive(true);
      // }
    }
    // else {
    //   setPredictionModle(true);
    // }
  };
  const handleEmail = async (e) => {
    e.preventDefault();
    eventBus.emit(CONSTANT.SHOW_LOADER);
    const res = await apiService.getUserName({ email: loginEmail });
    eventBus.emit(CONSTANT.HIDE_LOADER);
    if (res.data) {
      navigate("/login", {
        state: {
          email: loginEmail,
          userName: userName,
          userEmail: userEmail,
          name: res.data.userName,
          profileImgUrl: res?.data.profileImgUrl,
        },
      });
    } else {
      setErrorClass("mb-3 form-error");
      setError("form-error-line-show");
    }
  };
  const isValidEmail = (email) => {
    const regexEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return regexEmail.test(email);
  };

  const handleLoginEmail = (e) => {
    if (!isValidEmail(e.target.value)) {
      // username
      setUserName(e.target.value);
    } else {
      // email
      setUserEmail(e.target.value);
    }
    setLoginEmail(e.target.value);
    if (e.target.value == "") {
      setErrorClass("mb-3");
      setError("form-error-line-hide");
    }
  };
  const handlePredictionHide = () => {
    setPredictionModle(false);
  };
  const getPopularEvents = async () => {
    if (localStorage.getItem("token")) {
      const res = await apiService.getPopularEvents();
      setPopularEvents(res);
      if (res) {
        setCustomLoader(false);
      }
    } else {
      const res = await apiService.getPopularPublicEvents();
      setPopularEvents(res);
      if (res) {
        setCustomLoader(false);
      }
    }
  };
  const getPopularLeague = async () => {
    if (localStorage.getItem("token")) {
      const res = await apiService.getPopularLeague();
      setPopularLeague(res);
      if (res) {
        setCustomLoader(false);
      }
    } else {
      const res = await apiService.getPopularPublicLeague();
      setPopularLeague(res);
      if (res) {
        setCustomLoader(false);
      }
    }
  };
  useEffect(() => {
    getPopularEvents();
    getPopularLeague();
  }, []);

  const handleMakePrediction = (data) => {
    navigate("/sportline", {
      state: data,
    });
    // if (localStorage.getItem("token")) {
    //   setPropularData(data);
    //   setAddPredictionModle(true);
    //   setSomeAddPredictionModel("add-prediction-model-show");
    //   setpredictionTableHide(true);
    // } else {
    //   // navigate('/signin')
    //   setPredictionModle(true);
    // }
  };
  const handleAddPredictionHide = () => {
    setAddPredictionModle(false);
  };
  const handlePopularLeagues = (item) => {
    if (localStorage.getItem("token") && localStorage.getItem("profile")) {
      props.filterHandle(item);
    } else {
      setPredictionModle(true);
    }
  };
  return (
    <>
      <div className="feedsidebar-section">
        <div>
          <div className="feedsidebar-buttons">
            <div
              className="Recomended-following-button"
              style={{
                display: localStorage.getItem("token") ? "block" : "none",
              }}
            >
              <div className="row Recomended-following-div">
                <div className="col-6 col-md-12 Recomended-part-btn">
                  <InputButton
                    type="icon-button"
                    inputLabel={t("feed.Recomended")}
                    addinnerClass="Recomended-following-btn-style"
                    addBtnClass={props.recommandAddClass}
                    addSpan={
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.1043 4.17701L14.9317 7.82776C15.1108 8.18616 15.4565 8.43467 15.8573 8.49218L19.9453 9.08062C20.9554 9.22644 21.3573 10.4505 20.6263 11.1519L17.6702 13.9924C17.3797 14.2718 17.2474 14.6733 17.3162 15.0676L18.0138 19.0778C18.1856 20.0698 17.1298 20.8267 16.227 20.3574L12.5732 18.4627C12.215 18.2768 11.786 18.2768 11.4268 18.4627L7.773 20.3574C6.87023 20.8267 5.81439 20.0698 5.98724 19.0778L6.68385 15.0676C6.75257 14.6733 6.62033 14.2718 6.32982 13.9924L3.37368 11.1519C2.64272 10.4505 3.04464 9.22644 4.05466 9.08062L8.14265 8.49218C8.54354 8.43467 8.89028 8.18616 9.06937 7.82776L10.8957 4.17701C11.3477 3.27433 12.6523 3.27433 13.1043 4.17701Z"
                            stroke={props.recommandBtnBorder}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    }
                    onClick={props.onClickRecomended}
                  />
                </div>
                <div className="col-6 col-md-12 following-part">
                  <InputButton
                    type="icon-button"
                    inputLabel={t("profile.followingBtn")}
                    addinnerClass="Recomended-following-btn-style"
                    addBtnClass={props.followingAddClass}
                    addSpan={
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.8877 10.8967C19.2827 10.7007 20.3567 9.50473 20.3597 8.05573C20.3597 6.62773 19.3187 5.44373 17.9537 5.21973"
                            stroke={props.followingBtnBorder}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M19.7285 14.2505C21.0795 14.4525 22.0225 14.9255 22.0225 15.9005C22.0225 16.5715 21.5785 17.0075 20.8605 17.2815"
                            stroke={props.followingBtnBorder}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.8867 14.6641C8.67273 14.6641 5.92773 15.1511 5.92773 17.0961C5.92773 19.0401 8.65573 19.5411 11.8867 19.5411C15.1007 19.5411 17.8447 19.0591 17.8447 17.1131C17.8447 15.1671 15.1177 14.6641 11.8867 14.6641Z"
                            stroke={props.followingBtnBorder}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.8867 11.888C13.9957 11.888 15.7057 10.179 15.7057 8.069C15.7057 5.96 13.9957 4.25 11.8867 4.25C9.77768 4.25 8.06765 5.96 8.06765 8.069C8.05968 10.171 9.75668 11.881 11.8587 11.888H11.8867Z"
                            stroke={props.followingBtnBorder}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.88484 10.8967C4.48884 10.7007 3.41584 9.50473 3.41284 8.05573C3.41284 6.62773 4.45384 5.44373 5.81884 5.21973"
                            stroke={props.followingBtnBorder}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.044 14.2505C2.693 14.4525 1.75 14.9255 1.75 15.9005C1.75 16.5715 2.194 17.0075 2.912 17.2815"
                            stroke={props.followingBtnBorder}
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    }
                    onClick={props.onClickFollowingBtn}
                  />
                </div>
              </div>
            </div>
            <div className="">
              <InputButton
                type="primary"
                inputLabel={t("profile.addPredictions")}
                addBtnClass={"prediction-list-btn"}
                onClick={handleAddPredictionBtn}
              />
            </div>
          </div>
          {/* <div className="propular-event">
            <div className="propular-event-title">
              {t("feed.PopularEvents")}
            </div>
            {CustomLoader ? (
              <FeedSideBarCustomLoader />
            ) : (
              <div className="">
                {popularEvents?.map((item, index) =>
                  Object.keys(item).length > 0 ? (
                    <div className="propular-events-contains" key={index}>
                      <div className="d-flex justify-content-between">
                        <div className="">
                          <div>
                            <img
                              src={
                                process.env.REACT_APP_teamLogo + item.opp_1_icon + '.png'
                              }
                              width="24px"
                              height="24px"
                              alt=""
                            />
                          </div>
                          <div className="">
                            <img
                              className="team-2-event-logo"
                              src={
                                process.env.REACT_APP_teamLogo + item.opp_2_icon + '.png'
                              }
                              width="24px"
                              height="24px"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="teams-event-name">
                          <div className="team-1-event-name">
                            {item.opp_1_name}
                          </div>
                          <div className="team-2-event-name">
                            {item.opp_2_name}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex align-items-center tooltip-prediction-div">
                        <div className="prediction-button-tooltip">
                          {t("feed.Makeprediction")}
                        </div>
                        <div className="icon-container">
                          <img
                            src="sidebar-ticket.svg"
                            className="Ticket-Star-icon"
                            onClick={() => handleMakePrediction(item)}
                            width="24px"
                            height="24px"
                          />
                          <img
                            src="ticket-hover.svg"
                            className="Ticket-Star-icon"
                            onClick={() => handleMakePrediction(item)}
                            width="24px"
                            height="24px"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    " "
                  )
                )}
              </div>
            )}
          </div>
          <div className="Popular-leagues">
            <div className="Popular-leagues-title">
              {t("profile.popularLeagues")}
            </div>
            {CustomLoader ? (
              <FeedSideBarCustomLoader />
            ) : (
              <div className="Popular-leagues-contains">
                {popularLeague?.map((item, index) =>
                  Object.keys(item).length > 0 ? (
                    <div
                      className="d-flex leagues-section"
                      key={index}
                      onClick={() => handlePopularLeagues(item)}
                    >
                      <div className="leagues-logo">
                        <img src={"sport-logo/" + item.sport_id + ".svg"} />
                      </div>
                      <div className="Popular-leagues-name">
                        {item.tournament_name}
                      </div>
                    </div>
                  ) : (
                    " "
                  )
                )}
              </div>
            )}
          </div> */}
          <div>
            <div>
              <FeedPrediction
                makePrediction={makePrediction}
                show={isActive}
                increamentUserCount={props.increamentUserCount}
                countData={props.countData}
              />
            </div>
          </div>
        </div>
        <div className={addSomePredictionsModel}>
          {propularData ? (
            <div className="prediction-list-model-body">
              {addPredictionsModel ? (
                <AddPrediction
                  hideModel={handleAddPredictionHide}
                  predictionTableHide={predictionTableHide}
                  isModel={addPredictionsModel}
                  propularData={propularData}
                  countData={props.countData}
                  increamentUserCount={props.increamentUserCount}
                  // AllTornamentData={handleAllLeagueDataPOpup}
                />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        {/* login model  */}
        <Model
          isModel={predictionModel}
          modelClassName={"home-page-signupPage"}
          hideModel={handlePredictionHide}
          modelTitle={t("homePageSigninPage.SignIn")}
        >
          <div className="userLoginModel">
            {/* --- signinCapperauditor top --- */}
            <div className="top">
              <div className="btn-containt">
                <button
                  type="button"
                  className="btn  btn-google-facebook btn-google"
                  onClick={() => {
                    window.location.href = process.env.REACT_APP_googleOAuthUrl;
                  }}
                >
                  <span className="signup-icon">
                    <i className="fab fa-google" />
                  </span>
                  {t("homePageSigninPage.isGoogleBtn")}
                </button>
                <button
                  type="button"
                  className="btn btn-google-facebook btn-facebook"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_facebookOAuthUrl;
                  }}
                >
                  <span className="signup-icon">
                    <i
                      className="fab fa-facebook-f"
                      style={{ color: "#4267B2" }}
                    ></i>
                  </span>
                  {t("homePageSigninPage.isFabookBtn")}
                </button>
              </div>
              <div className="text-divider">{t("homePageSigninPage.or")}</div>
            </div>
            {/* ------ signincapperauditor form ------  */}
            <div className="bottom">
              <form>
                <div className={errorClass}>
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label form-input-label"
                  >
                    {t("homePageSigninPage.emailorusername")}
                  </label>
                  <input
                    type="email"
                    className="form-control form-input-field"
                    id="InputEmail"
                    onChange={handleLoginEmail}
                    placeholder={t("homePageSigninPage.EnteremailPlaceHolder")}
                    style={{
                      fontSize: "14px",
                      fontWeight: "300",
                      color: "#7F87B3",
                    }}
                  />
                  <p style={{ fontSize: "14px" }} className={error}>
                    {t("SignIn.Youremailorusernameisincorrect")}
                  </p>
                </div>
                <div className="mb-4">
                  <button
                    className="form-control form-input-field-button"
                    id="Inputbutton"
                    onClick={handleEmail}
                    disabled={loginEmail == ""}
                    style={{
                      pointerEvents: loginEmail == "" ? "none" : "auto",
                    }}
                  >
                    {t("homePageSigninPage.Continue")}
                  </button>
                </div>
              </form>
              {/* ------- signincapperauditor footer -----  */}
              <div className="signin-footer">
                <span
                  className="signincapperauditor-bottom"
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    lineHeight: "20px",
                  }}
                >
                  {t("homePageSigninPage.subLine1")}{" "}
                  <Link
                    to="/signup"
                    style={{
                      color: "#4C5480",
                      fontWeight: "600",
                      lineHeight: "24px",
                    }}
                  >
                    {t("homePageSigninPage.subLine2")}
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </Model>
      </div>
    </>
  );
};
export default FeedSideBar;
