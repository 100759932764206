import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../../services/apiService";
import Header from "../Header";

const OAuthRedirect = () => {
  const navigate = useNavigate();

  const handleOAuth = async () => {
    const url = new URL(window.location.href);
    let token = url.searchParams.get("token");
    let redirect = url.searchParams.get("redirect");
    let id = url.searchParams.get("id");
    
    if (token && id) {
      localStorage.setItem("token", token);
      localStorage.setItem("userId", id);

      const profileData = await apiService.getUserProfile();

      if (profileData.isVerifyEmail) {
        localStorage.setItem("profile", JSON.stringify(profileData));
        navigate(`/userprofile?token=${token}&&id=${id}`, {
          state: { token: token },
        });
      } else{
        navigate(`/settingaccount?token=${token}&&id=${id}`, {
          state: { token: "Bearer " + token },
        });
      }
    }
  };

  useEffect(() => {
    handleOAuth();
  });

  return (
    <>
      <Header />
      <div>OAuthRedirect</div>
    </>
  );
};
export default OAuthRedirect;
