import React, { useRef, useState, useEffect } from "react";

import "./DropDownInput.css";

function DropDownInput({ listItems, selectedItem, setSelectedItem }) {
  const componentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={componentRef} className="dropdown-input">
      <div
        className={isOpen ? "select open" : "select"}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="selected">{localStorage.getItem("language") === "ua" ? selectedItem.text : selectedItem.text_en}</span>
        <div className={isOpen ? "caret-rotate" : "caret"}>
          <img src="/down.svg" alt="caret"></img>
        </div>
      </div>
      <ul className={isOpen ? "list-items open" : "list-items"}>
        {listItems.map((item, index) => (
          <li
            key={index}
            onClick={(e) => {
              setSelectedItem(item);
              setIsOpen(!isOpen);
            }}
          >
            {localStorage.getItem("language") === "ua" ? item.text : item.text_en}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default DropDownInput;
