import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/login.css";
import Header from "./Header";

const LoginwithChangeUsername = () => {
  const [type, setType] = useState("password");

  const handleToggle = () => {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  };
  return (
    <>
      <Header />
      <section className="form-section">
        <div className="d-flex justify-content-center align-items-center">
          <div className="card login-card">
            <div className="card-body p-0">
              <div className="login-avatar">
                <div
                  className="rounded-circle"
                  style={{
                    width: "64px",
                    height: "64px",
                    backgroundColor: "#F2F3F7",
                  }}
                  alt="Avatar"
                >
                  <p>C</p>
                </div>
              </div>
              <div className="card-title ">
                <div className="welcome-carl text-center mt-4">
                  Welcome Carl!
                </div>
                <p className="text-center mt-2 mb-4 login-email">
                  capperauditor@gmail.com
                  <Link to="#" className="login-change-email ms-2">
                    Change username
                  </Link>
                </p>
              </div>
              <form>
                <div className="mb-3">
                  <div className="login-input-label">
                    <label
                      htmlFor="exampleInputPassword"
                      className="form-label form-input-label"
                    >
                      Your Password
                    </label>
                    <Link
                      to="/resetpassword"
                      htmlFor="exampleInputPassword"
                      className="form-label form-input-label forgot-password"
                    >
                      Forgot password ?
                    </Link>
                  </div>

                  <div className="input-icons">
                    <input
                      className="input-field form-control form-input-field"
                      type={type}
                      id="exampleInputPassword"
                      placeholder="Enter your password ..."
                      style={{
                        fontSize: "14px",
                        fontWeight: "300",
                        color: "#7F87B3",
                      }}
                    />
                    <span className="toggle-icon" onClick={handleToggle}>
                      <i
                        className={
                          type == "password" ? "fa fa-eye-slash" : "fa fa-eye"
                        }
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="">
                  <button
                    className="form-control  form-input-field-button"
                    id="exampleInputbutton"
                  >
                    Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default LoginwithChangeUsername;
