// import reviewer1 from "../public/home-img/updatedHome/reviewer-img/reviewer-1.png";

export const paginationUaData =
    [
        {
            id: 1,
            title: "Це велика",
            specialTitle: "Знахідка",
            decription: "Я просто вражений великим набором інструментів, який надає capperauditor для роботи з прогнозами на спорт. Ще один плюс платформи - це зручний та простий інтерфейс. Я легко можу знайти необхідну інформацію та взаємодіяти з іншими користувачами.",
            userName: "Назар Мельничук",
            userImage: "/home-img/updatedHome/reviewer-img/reviewer-1.png"
        },
        {
            id: 2,
            title: "Сайт просто",
            specialTitle: "Чудовий",
            decription: "Я вже давно шукав подібний сайт, де я міг би бачити прогнози та статистику інших людей. З capperauditor це зручно та легко🔥🔥",
            userName: "Андрій Шолох",
            userImage: "/home-img/updatedHome/reviewer-img/reviewer-2.png"
        },
        {
            id: 3,
            title: "Платформа",
            specialTitle: "Ідеальна",
            decription: "Я люблю спорт, тому capperauditor є для мене ідеальним місцем, де я можу отримати всю необхідну інформацію про матчі та зробити правильний прогноз.",
            userName: "Олександр Ткаченко",
            userImage: "/home-img/updatedHome/reviewer-img/reviewer-3.png"
        },
        {
            id: 4,
            title: "Capperauditor",
            specialTitle: "Надзвичайний",
            decription: "Я дуже задоволений, що відкрив для себе capperauditor, тут я можу бачити прогнози інших користувачів на мої улюблені матчі 😍",
            userName: "Влад Мирошниченко",
            userImage: "/home-img/updatedHome/reviewer-img/reviewer-4.png"
        },
        {
            id: 5,
            title: "Кращий сайт на всі",
            specialTitle: "100%",
            decription: "Я впевнений, що capperauditor є найкращою платформою для тих, хто хоче бути в курсі спортивних подій та робити вірні прогнози🔥🔥",
            userName: "Андрій Бєліков",
            userImage: "/home-img/updatedHome/reviewer-img/reviewer-5.png"
        },
        {
            id: 6,
            title: "Просто",
            specialTitle: "Вау!",
            decription: "Як людина, яка любить аналізувати дані, я дуже вражений тим, які дані надає capperauditor. Це дійсно допомагає мені зробити правильний прогноз.",
            userName: "Валерій Боженко",
            userImage: "/home-img/updatedHome/reviewer-img/reviewer-6.png"
        },
        {
            id: 7,
            title: "Сайт, що",
            specialTitle: "Допомагає",
            decription: "Я ціную можливість бачити  прогнози та статистику інших користувачів. Це дозволяє мені бачити різні точки зору та робити більш обдумані прогнози.🔥",
            userName: "Катерина Абрамчук",
            userImage: "/home-img/updatedHome/reviewer-img/reviewer-7.png"
        },
        {
            id: 8,
            title: "Відкриває нові",
            specialTitle: "Можливості",
            decription: "Я дуже радий, що capperauditor надає можливість створювати свої власні прогнози та ділитися ними з іншими користувачами. Це дійсно допомагає розвиватися та вчитися.",
            userName: "Євгенія Симоненко",
            userImage: "/home-img/updatedHome/reviewer-img/reviewer-8.png"
        },
        {
            id: 9,
            title: "Допомагає",
            specialTitle: "Зростати",
            decription: "Я вважаю, що capperauditor - це відмінна платформа для тих, хто хоче збільшити кількість успішних прогнозів.",
            userName: "Владислав Степанюк",
            userImage: "/home-img/updatedHome/reviewer-img/reviewer-9.png"
        },

    ]

export const paginationEnData =
    [
        {
            id: 1,
            title: "It's a wonderful",
            specialTitle: "Discovery",
            decription: "I am simply amazed by the extensive set of tools that capperauditor provides for working with sports forecasts. Another advantage of the platform is its user-friendly and simple interface. I can easily find the information I need and interact with other users.",
            userName: "Nazar Melnychuk",
            userImage: "/home-img/updatedHome/reviewer-img/Nazar-Melnychuk.png"
        },
        {
            id: 2,
            title: "The site is ",
            specialTitle: "Great",
            decription: "I've been looking for a site like this for a long time, where I could see other people's predictions and statistics. With capperauditor it's convenient and easy🔥🔥",
            userName: "Andriy Sholokh",
            userImage: "/home-img/updatedHome/reviewer-img/Andriy-Sholokh.png"
        },
        {
            id: 3,
            title: "The platform is ",
            specialTitle: "Perfect",
            decription: "I love sports, so capperauditor is the perfect place for me to get all the information I need about matches and make the right predictions.",
            userName: "Oleksandr Tkachenko",
            userImage: "/home-img/updatedHome/reviewer-img/Oleksandr-Tkachenko.png"
        },
        {
            id: 4,
            title: "Capperauditor is ",
            specialTitle: "Amazing",
            decription: "I'm very happy to have discovered capperauditor, here I can see other users' predictions for my favorite matches 😍.",
            userName: "Vlad Miroshnichenko",
            userImage: "/home-img/updatedHome/reviewer-img/Vlad-Miroshnichenko.png"
        },
        {
            id: 5,
            title: "The best website by ",
            specialTitle: "100%",
            decription: "I'm sure that capperauditor is the best platform for those who want to keep up with sports events and make correct predictions🔥🔥",
            userName: "Andriy Belikov",
            userImage: "/home-img/updatedHome/reviewer-img/Andriy-Belikov.png"
        },
        {
            id: 6,
            title: "Just",
            specialTitle: "WOW!",
            decription: "As a person who loves analyzing data, I am very impressed with the data capperauditor provides. It really helps me to make the right predictions.",
            userName: "Valery Bozhenko",
            userImage: "/home-img/updatedHome/reviewer-img/Valery-Bozhenko.png"
        },
        {
            id: 7,
            title: "Website that ",
            specialTitle: "Helps",
            decription: "I appreciate the ability to see other users predictions and statistics. It allows me to see different perspectives and make more informed predictions.🔥",
            userName: "Kateryna Abramchuk",
            userImage: "/home-img/updatedHome/reviewer-img/Kateryna-Abramchuk.png"
        },
        {
            id: 8,
            title: "Opens new",
            specialTitle: "Opportunities",
            decription: "I'm very glad that capperauditor allows you to create your own predictions and share them with other users. It really helps to develop and learn.",
            userName: "Yevhenia Symonenko",
            userImage: "/home-img/updatedHome/reviewer-img/Yevhenia-Symonenko.png"
        },
        {
            id: 9,
            title: "Helps to ",
            specialTitle: "Grow",
            decription: "I believe that capperauditor is a great platform for those who want to increase the number of successful predictions.",
            userName: "Vladislav Stepanyuk",
            userImage: "/home-img/updatedHome/reviewer-img/Vladislav-Stepanyuk.png"
        },

    ]
