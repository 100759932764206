import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import { handleAddOdds, handleActiveOdds } from "../../../helpers/prediction";
import {
  clearEvent,
  clearSportEvents,
  getEvent,
  setPredictions,
} from "../../../store/betSlice";
import { STATUS_COMPLETED, STATUS_LOADING } from "../../../consts/StatusConst";

import moment from "moment";

import GamePageLoader from "../../CustomSkeletonLoaders/GamePageLoader";
import GamePageLoaderMobile from "../../CustomSkeletonLoaders/GamePageLoaderMobile";
import NoGamePage from "./NoGamePage";

const GamePage = ({ setShowMobilePredictionNotification }) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [searchParams, setSearchParams] = useSearchParams({});
  const { t, i18n } = useTranslation();

  const { predictions } = useSelector((state) => state.bet);
  const dispatch = useDispatch();

  const [eventData, setEventData] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [country, setCountry] = useState(null);

  const { leagues, event, eventLoadingStatus, leaguesLoadingStatus } =
    useSelector((state) => state.bet);

  const activeSport = searchParams.get("sport");
  const activeSportKey = searchParams.get("key");
  const activeCountry = searchParams.get("country");
  const activeLeague = searchParams.get("league");
  const activeGame = searchParams.get("game");

  useEffect(() => {
    setDataLoaded(false);
    setEventData([]);
    if (eventLoadingStatus === STATUS_COMPLETED) {
      setEventData(event);
      if (!event.game_id) {
        setShowMobilePredictionNotification(false);
      } else {
        setShowMobilePredictionNotification(true);
      }
      updatePredictions(event);
      setDataLoaded(true);
    }
  }, [dispatch, eventLoadingStatus]);

  useEffect(() => {
    setCountry(null);
    if (
      leaguesLoadingStatus === STATUS_COMPLETED &&
      eventLoadingStatus === STATUS_COMPLETED
    ) {
      getCountry();
    }
  }, [dispatch, eventLoadingStatus, leaguesLoadingStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeGame) {
        dispatch(
          getEvent({
            eventId: activeGame,
            activeSportKey,
          })
        );

        let modifiedArray = JSON.parse(JSON.stringify(event));
        const resultArray = compareAndAddChange(
          eventData.game_oc_list,
          event.game_oc_list
        );
        modifiedArray.game_oc_list = resultArray;
        updatePredictions(modifiedArray);
        setEventData(modifiedArray);
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [dispatch, activeGame, event]);

  useEffect(() => {
    dispatch(setPredictions(getPredictionsFromStorage()));
  }, [dispatch]);

  const compareAndAddChange = (oldArray, newArray) => {
    const comparedArray = newArray.map((newItem, index) => {
      const oldItem = oldArray.find(
        (item) => item.group_id === newItem.group_id
      );
      if (!oldItem) return newItem;

      const oc_list = newItem.oc_list.map((newOc, ocIndex) => {
        const oldOc = oldItem.oc_list.find(
          (oc) => oc.oc_name === newOc.oc_name
        );

        if (oldOc && newOc.oc_rate > oldOc.oc_rate) {
          return { ...newOc, change: 1 };
        }

        if (oldOc && newOc.oc_rate < oldOc.oc_rate) {
          return { ...newOc, change: -1 };
        }
        return newOc;
      });
      return { ...newItem, oc_list };
    });
    return comparedArray;
  };

  const getPredictionsFromStorage = () => {
    const storedData = localStorage.getItem("createPrediction");
    if (storedData) {
      return JSON.parse(storedData);
    }
    return [];
  };

  const updatePredictions = (game) => {
    const predictionsData = getPredictionsFromStorage();

    if (
      game.game_id &&
      predictionsData.length > 0 &&
      game.game_oc_list.length > 0
    ) {
      predictionsData.map((prediction) => {
        if (prediction.game_id === game.game_id) {
          for (let i = 0; i < game.game_oc_list.length; i++) {
            const pointer = game.game_oc_list[i].oc_list.find(
              (oc) => oc.oc_pointer === prediction.oc_pointer
            );
            if (pointer) {
              prediction.change = pointer.change || 0;
              prediction.oc_rate = pointer.oc_rate;
            }
          }
        }
      });
    }

    dispatch(setPredictions(predictionsData));
    localStorage.setItem("createPrediction", JSON.stringify(predictionsData));
  };

  const getCountry = () => {
    if (leagues && activeSport && activeCountry) {
      const sport = leagues?.menu.find((item) => item.id == activeSport);
      const country = sport?.sub.find((item) => item.id == activeCountry);
      if (country) {
        setCountry(country.id);
      }
    }
  };

  const handleBackToAddPrediction = () => {
    setSearchParams(
      (prev) => {
        prev.delete("game");
        return prev;
      },
      { replace: true }
    );

    dispatch(clearSportEvents());
    dispatch(clearEvent());
  };

  const getLanguge = () => {
    return localStorage.getItem("language");
  };

  const handleEventOddsData = (item, name, index) => {
    const handleArrowImage = (funct, change) => {
      if (funct && change < 0) {
        return "/decreased arrow.svg";
      } else if (funct && change > 0) {
        return "/increased arrow.svg";
      } else if (change > 0) {
        return "/win arrow up.svg";
      } else if (change < 0) {
        return "/lose arrow down.svg";
      } else {
        return "";
      }
    };

    if (item.group_id === 1) {
      return (
        <>
          <div className="full-time-result">
            <div className="full-time-result-title ps-3">
              {getLanguge() === "en"
                ? item.group_name === "1x2" && "Full-time result"
                : "Повний результат"}
            </div>
            <div className="d-flex full-time-result-data-box">
              {item.oc_list?.map((event, index) => (
                <div
                  className={
                    handleActiveOdds(event.oc_pointer, predictions)
                      ? `col-sm full-time-team-1 full-time-team-1-active`
                      : `col-sm full-time-team-1 ${
                          event?.change > 0
                            ? "increased-odds-div"
                            : event?.change < 0
                            ? "decreased-odds-div"
                            : ""
                        }`
                  }
                  style={{
                    pointerEvents: !event.oc_name && !event.oc_rate && "none",
                  }}
                  onClick={(e) =>
                    handleAddOdds(
                      e,
                      eventData,
                      event,
                      predictions,
                      dispatch,
                      setPredictions,
                      activeSportKey
                    )
                  }
                  key={"o1" + index}
                >
                  <div>{event.oc_name || "-"}</div>
                  <div className="d-flex align-items-center">
                    <img
                      src={handleArrowImage(
                        handleActiveOdds(event.oc_pointer, predictions),
                        event?.change
                      )}
                      className="pe-2"
                      alt=""
                    />
                    {!isNaN(event?.oc_rate)
                      ? parseFloat(event?.oc_rate).toFixed(2)
                      : "-"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      );
    } else if (item.group_id === 8) {
      return (
        <>
          <div className="Double-chance">
            <div className="Double-chance-title ps-3">{item.group_name}</div>
            <div className="d-flex Double-chance-data-box">
              {item.oc_list?.map((event, index) => (
                <div
                  className={
                    handleActiveOdds(event.oc_pointer, predictions)
                      ? `col-sm full-time-team-1 full-time-team-1-active`
                      : `col-sm full-time-team-1 ${
                          event?.change > 0
                            ? "increased-odds-div"
                            : event?.change < 0
                            ? "decreased-odds-div"
                            : ""
                        }`
                  }
                  style={{
                    pointerEvents: !event.oc_name && !event.oc_rate && "none",
                  }}
                  onClick={(e) =>
                    handleAddOdds(
                      e,
                      eventData,
                      event,
                      predictions,
                      dispatch,
                      setPredictions,
                      activeSportKey
                    )
                  }
                  key={"o2" + index}
                >
                  <div>{event.oc_name || "-"}</div>
                  <div className="d-flex align-items-center">
                    <img
                      src={handleArrowImage(
                        handleActiveOdds(event.oc_pointer, predictions),
                        event?.change
                      )}
                      className="pe-2"
                      alt=""
                    />
                    {!isNaN(event?.oc_rate)
                      ? parseFloat(event?.oc_rate).toFixed(2)
                      : "-"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      );
    } else if (item.group_id === 2) {
      const team1 = [];
      const team2 = [];
      item.oc_list?.map((event) => {
        const pointer = event.oc_pointer.split("|");
        if (pointer[2] == 7) {
          team1.push(event);
        } else {
          team2.push(event);
        }
      });
      return (
        <>
          <div className="Handicap-contains" key={"o3" + index}>
            <div className="Handicap-title ps-3">{item.group_name}</div>
            <div className="d-flex mobile-Real-Madrid-title">
              <div
                className="col-sm Real-Madrid-title Real-Madrid-title-desktop "
                style={{ marginLeft: "16px" }}
              >
                <div>
                  {getLanguge() === "en"
                    ? eventData.opp_1_name_en
                    : eventData.opp_1_name}
                </div>
              </div>
              <div className="col-sm Chelsea-title  Chelsea-title-desktop">
                <div>
                  {getLanguge() === "en"
                    ? eventData.opp_2_name_en
                    : eventData.opp_2_name}
                </div>
              </div>
            </div>
            <div className="d-flex pb-3 Handicap-data-box">
              <div className="col">
                {team1.map((event, index) => (
                  <div
                    className={
                      handleActiveOdds(event.oc_pointer, predictions)
                        ? "col-sm Handicap-team1-score Handicap-team1-score-active"
                        : `col-sm Handicap-team1-score ${
                            event?.change > 0
                              ? "increased-odds-div"
                              : event?.change < 0
                              ? "decreased-odds-div"
                              : ""
                          }`
                    }
                    onClick={(e) =>
                      handleAddOdds(
                        e,
                        eventData,
                        event,
                        predictions,
                        dispatch,
                        setPredictions,
                        activeSportKey
                      )
                    }
                    key={"o4" + index}
                  >
                    <div>{event.oc_name.split("Handicap") || "-"}</div>
                    <div className="d-flex align-items-center">
                      <img
                        src={handleArrowImage(
                          handleActiveOdds(event.oc_pointer, predictions),
                          event?.change
                        )}
                        className="pe-2"
                        alt=""
                      />
                      {!isNaN(event?.oc_rate)
                        ? parseFloat(event?.oc_rate).toFixed(2)
                        : "-"}
                    </div>
                  </div>
                ))}
              </div>
              <div className="col-sm Chelsea-title  Chelsea-title-mobile">
                <div>
                  {getLanguge() === "en"
                    ? eventData.opp_2_name_en
                    : eventData.opp_2_name}
                </div>
              </div>
              <div className="col">
                {team2.map((event, index) => (
                  <div
                    className={
                      handleActiveOdds(event.oc_pointer, predictions)
                        ? "col-sm Handicap-team1-score Handicap-team1-score-active"
                        : `col-sm Handicap-team1-score ${
                            event?.change > 0
                              ? "increased-odds-div"
                              : event?.change < 0
                              ? "decreased-odds-div"
                              : ""
                          }`
                    }
                    onClick={(e) =>
                      handleAddOdds(
                        e,
                        eventData,
                        event,
                        predictions,
                        dispatch,
                        setPredictions,
                        activeSportKey
                      )
                    }
                    key={"o5" + index}
                  >
                    <div>{event.oc_name.split("Handicap") || "-"}</div>
                    <div className="d-flex align-items-center">
                      <img
                        src={handleArrowImage(
                          handleActiveOdds(event.oc_pointer, predictions),
                          event?.change
                        )}
                        className="pe-2"
                        alt=""
                      />
                      {!isNaN(event?.oc_rate)
                        ? parseFloat(event?.oc_rate).toFixed(2)
                        : "-"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    } else if (item.group_id === 17) {
      const xb = {};
      for (let i of item.oc_list) {
        const pointer = i.oc_pointer.split("|");
        let name = i.oc_pointer.split("|")[3];
        if (!xb[name]) {
          xb[name] = {};
        }
        if (pointer[2] == 9) {
          xb[name]["Over"] = i;
        }
        if (pointer[2] == 10) {
          xb[name]["Under"] = i;
        }
      }
      let dataArray = [];
      let overArray = [];
      let underArray = [];
      for (const data in xb) {
        dataArray.push(data);
        overArray.push(xb[data].Over);
        underArray.push(xb[data].Under);
      }
      return (
        <>
          <div className="total-odds-contains" key={"o6" + index}>
            <div className="total-odds-title ps-3">{item.group_name}</div>
            <div className="desktop-Total-div-box">
              <div className="d-flex">
                <div className="col-sm RealMadridTotal-teams p-3"></div>
                <div
                  className="col-sm Over-title"
                  style={{ marginLeft: "16px" }}
                >
                  <div>{t("profile.Over")}</div>
                </div>
                <div className="col-sm Under-title">
                  <div>{t("profile.Under")}</div>
                </div>
              </div>
              <div className="d-flex ">
                <div className="col">
                  {dataArray.map((event, index) => (
                    <div
                      className="col-sm mb-3 RealMadridTotal-teams-score"
                      key={"o7" + index}
                      style={{ marginLeft: "16px" }}
                    >
                      <div>{event}</div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {overArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, predictions)
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : `col-sm mb-3 Over-score ${
                              event?.change > 0
                                ? "increased-odds-div"
                                : event?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      style={{ marginLeft: "16px" }}
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          event,
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o8" + index}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={handleArrowImage(
                            handleActiveOdds(event?.oc_pointer, predictions),
                            event?.change
                          )}
                          className="pe-2"
                          alt=""
                        />
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {underArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, predictions)
                          ? "col-sm Under-score mb-3 Under-score-active"
                          : `col-sm mb-3 Under-score ${
                              event?.change > 0
                                ? "increased-odds-div"
                                : event?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      style={{ marginLeft: "16px" }}
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          event,
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o9" + index}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={handleArrowImage(
                            handleActiveOdds(event?.oc_pointer, predictions),
                            event?.change
                          )}
                          className="pe-2"
                          alt=""
                        />
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* mobile- design  */}
            <div className="mobile-Total-div-box 3">
              <div className="col-sm Over-title">
                <div className="pb-3">{t("profile.Over")}</div>
                {dataArray.map((event, index) => (
                  <div key={"o10" + index}>
                    <div className="col-sm mb-3 RealMadridTotal-teams-score">
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          overArray[index]?.oc_pointer,
                          predictions
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : `col-sm mb-3 Over-score ${
                              overArray[index]?.change > 0
                                ? "increased-odds-div"
                                : overArray[index]?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          overArray[index],
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o11" + index}
                    >
                      <div>
                        {!isNaN(overArray[index]?.oc_rate)
                          ? parseFloat(overArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-sm Under-title">
                <div className="pb-3">{t("profile.Under")}</div>
                {dataArray.map((event, index) => (
                  <div key={"o12" + index}>
                    <div className="col-sm mb-3 RealMadridTotal-teams-score">
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          underArray[index]?.oc_pointer,
                          predictions
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : `col-sm mb-3 Over-score ${
                              underArray[index]?.change > 0
                                ? "increased-odds-div"
                                : underArray[index]?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          underArray[index],
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o13" + index}
                    >
                      <div>
                        {!isNaN(underArray[index]?.oc_rate)
                          ? parseFloat(underArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    } else if (item.group_id === 15) {
      const xb = {};
      for (let i of item.oc_list) {
        let name = i.oc_pointer?.split("|")[3];
        let ox = i.oc_pointer?.split("|")[2];
        if (!xb[name]) {
          xb[name] = {};
        }
        if (ox == 11) {
          xb[name]["Over"] = i;
        }
        if (ox == 12) {
          xb[name]["Under"] = i;
        }
      }
      let dataArray = [];
      let overArray = [];
      let underArray = [];
      for (const data in xb) {
        dataArray.push(data);
        overArray.push(xb[data].Over);
        underArray.push(xb[data].Under);
      }
      return (
        <>
          <div className="total-odds-contains">
            <div className="total-odds-title ps-3 ">{eventData.opp_1_name}</div>
            <div className="desktop-Total-div-box">
              <div className="d-flex ">
                <div className="col-sm RealMadridTotal-teams p-3"></div>
                <div
                  className="col-sm Over-title"
                  style={{ marginLeft: "16px" }}
                >
                  <div>{t("profile.Over")}</div>
                </div>
                <div className="col-sm Under-title">
                  <div>{t("profile.Under")}</div>
                </div>
              </div>
              <div className="d-flex">
                <div className="col">
                  {dataArray.map((event, index) => (
                    <div
                      className="col-sm mb-3 RealMadridTotal-teams-score"
                      key={"o14" + index}
                      style={{ marginLeft: "16px" }}
                    >
                      <div>{event}</div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {overArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, predictions)
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : `col-sm mb-3 Over-score ${
                              event?.change > 0
                                ? "increased-odds-div"
                                : event?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      style={{ marginLeft: "16px" }}
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          event,
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o15" + index}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={handleArrowImage(
                            handleActiveOdds(event?.oc_pointer, predictions),
                            event?.change
                          )}
                          className="pe-2"
                          alt=""
                        />
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {underArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, predictions)
                          ? "col-sm Under-score mb-3 Under-score-active"
                          : `col-sm mb-3 Under-score ${
                              event?.change > 0
                                ? "increased-odds-div"
                                : event?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      style={{ marginLeft: "16px" }}
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          event,
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o16" + index}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={handleArrowImage(
                            handleActiveOdds(event?.oc_pointer, predictions),
                            event?.change
                          )}
                          className="pe-2"
                          alt=""
                        />
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* mobile- design  */}
            <div className="mobile-Total-div-box 2">
              <div className="col-sm Over-title">
                <div className="pb-3">{t("profile.Over")}</div>
                {dataArray.map((event, index) => (
                  <div key={"o17" + index}>
                    <div className="col-sm mb-3 RealMadridTotal-teams-score">
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          overArray[index]?.oc_pointer,
                          predictions
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : `col-sm mb-3 Over-score ${
                              overArray[index]?.change > 0
                                ? "increased-odds-div"
                                : overArray[index]?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          overArray[index],
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o18" + index}
                    >
                      <div>
                        {!isNaN(overArray[index]?.oc_rate)
                          ? parseFloat(overArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-sm Under-title">
                <div className="pb-3">{t("profile.Under")}</div>
                {dataArray.map((event, index) => (
                  <div key={"o19" + index}>
                    <div className="col-sm mb-3 RealMadridTotal-teams-score">
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          underArray[index]?.oc_pointer,
                          predictions
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : `col-sm mb-3 Over-score ${
                              underArray[index]?.change > 0
                                ? "increased-odds-div"
                                : underArray[index]?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          underArray[index],
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o20" + index}
                    >
                      <div>
                        {!isNaN(underArray[index]?.oc_rate)
                          ? parseFloat(underArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    } else if (item.group_id === 62) {
      const xb = {};
      for (let i of item.oc_list) {
        let name = i.oc_pointer?.split("|")[3];
        let ox = i.oc_pointer?.split("|")[2];
        if (!xb[name]) {
          xb[name] = {};
        }
        if (ox == 13) {
          xb[name]["Over"] = i;
        }
        if (ox == 14) {
          xb[name]["Under"] = i;
        }
      }
      let dataArray = [];
      let overArray = [];
      let underArray = [];
      for (const data in xb) {
        dataArray.push(data);
        overArray.push(xb[data].Over);
        underArray.push(xb[data].Under);
      }
      return (
        <>
          <div className="total-odds-contains">
            <div className="total-odds-title ps-3">{eventData.opp_2_name}</div>
            <div className="desktop-Total-div-box">
              <div className="d-flex">
                <div className="col-sm RealMadridTotal-teams p-3"></div>
                <div
                  className="col-sm Over-title"
                  style={{ marginLeft: "16px" }}
                >
                  <div>{t("profile.Over")}</div>
                </div>
                <div className="col-sm Under-title">
                  <div>{t("profile.Under")}</div>
                </div>
              </div>
              <div className="d-flex">
                <div className="col">
                  {dataArray.map((event, index) => (
                    <div
                      className="col-sm mb-3 RealMadridTotal-teams-score"
                      key={"o21" + index}
                      style={{ marginLeft: "16px" }}
                    >
                      <div>{event}</div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {overArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, predictions)
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : `col-sm mb-3 Over-score ${
                              event?.change > 0
                                ? "increased-odds-div"
                                : event?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      style={{ marginLeft: "16px" }}
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          event,
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o22" + index}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={handleArrowImage(
                            handleActiveOdds(event?.oc_pointer, predictions),
                            event?.change
                          )}
                          className="pe-2"
                          alt=""
                        />
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col">
                  {underArray.map((event, index) => (
                    <div
                      className={
                        handleActiveOdds(event?.oc_pointer, predictions)
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : `col-sm mb-3 Over-score ${
                              event?.change > 0
                                ? "increased-odds-div"
                                : event?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      style={{ marginLeft: "16px" }}
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          event,
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o23" + index}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <img
                          src={handleArrowImage(
                            handleActiveOdds(event?.oc_pointer, predictions),
                            event?.change
                          )}
                          className="pe-2"
                          alt=""
                        />
                        {!isNaN(event?.oc_rate)
                          ? parseFloat(event?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* mobile- design  */}
            <div className="mobile-Total-div-box 1">
              <div className="col-sm Over-title">
                <div className="pb-3">{t("profile.Over")}</div>
                {dataArray.map((event, index) => (
                  <div key={"o24" + index}>
                    <div className="col-sm mb-3 RealMadridTotal-teams-score">
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          overArray[index]?.oc_pointer,
                          predictions
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : `col-sm mb-3 Over-score ${
                              overArray[index]?.change > 0
                                ? "increased-odds-div"
                                : overArray[index]?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          overArray[index],
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o25" + index}
                    >
                      <div>
                        {!isNaN(overArray[index]?.oc_rate)
                          ? parseFloat(overArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="col-sm Under-title">
                <div className="pb-3">{t("profile.Under")}</div>
                {dataArray.map((event, index) => (
                  <div key={"o26" + index}>
                    <div
                      className="col-sm mb-3 RealMadridTotal-teams-score"
                      key={"o27" + index}
                    >
                      <div>{event}</div>
                    </div>
                    <div
                      className={
                        handleActiveOdds(
                          underArray[index]?.oc_pointer,
                          predictions
                        )
                          ? "col-sm mb-3 Over-score Over-score-active"
                          : `col-sm mb-3 Over-score ${
                              underArray[index]?.change > 0
                                ? "increased-odds-div"
                                : underArray[index]?.change < 0
                                ? "decreased-odds-div"
                                : ""
                            }`
                      }
                      onClick={(e) =>
                        handleAddOdds(
                          e,
                          eventData,
                          underArray[index],
                          predictions,
                          dispatch,
                          setPredictions,
                          activeSportKey
                        )
                      }
                      key={"o28" + index}
                    >
                      <div>
                        {!isNaN(underArray[index]?.oc_rate)
                          ? parseFloat(underArray[index]?.oc_rate).toFixed(2)
                          : "-"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="full-time-result-title ps-3">{item.group_name}</div>
          <div className="row extra-odds-data-list">
            {item.oc_list?.map((event, index) => (
              <div key={"o29" + index} className="col-sm-12 col-md-6 px-2">
                <div
                  className={
                    handleActiveOdds(event?.oc_pointer, predictions)
                      ? ` extra-full-time-team-1 extra-full-time-team-1-active mx-sm-0`
                      : ` extra-full-time-team-1 mx-sm-0 ${
                          event?.change > 0
                            ? "increased-odds-div"
                            : event?.change < 0
                            ? "decreased-odds-div"
                            : ""
                        }`
                  }
                  style={{
                    pointerEvents: !event.oc_name && !event.oc_rate && "none",
                  }}
                  onClick={(e) =>
                    handleAddOdds(
                      e,
                      eventData,
                      event,
                      predictions,
                      dispatch,
                      setPredictions,
                      activeSportKey
                    )
                  }
                  key={"o30" + index}
                >
                  <div>{event.oc_name || "-"}</div>
                  <div className="d-flex align-items-center">
                    <img
                      src={handleArrowImage(
                        handleActiveOdds(event.oc_pointer, predictions),
                        event?.change
                      )}
                      className="pe-2"
                      alt=""
                    />
                    {!isNaN(event?.oc_rate)
                      ? parseFloat(event?.oc_rate).toFixed(2)
                      : "-"}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      );
    }
  };

  return (
    <div>
      {eventLoadingStatus === STATUS_LOADING || !dataLoaded ? (
        <>{isMobile ? <GamePageLoaderMobile /> : <GamePageLoader />}</>
      ) : (
        <div className="extra-odds-page">
          {!event.game_id ? (
            <NoGamePage
              setShowMobilePredictionNotification={
                setShowMobilePredictionNotification
              }
            />
          ) : (
            <div>
              <div className="extra-Champions-League">
                <div className="back-btn" onClick={handleBackToAddPrediction}>
                  <img src="Arrow---Left.svg" alt=""></img>
                </div>
                <div className="Champions-League-name">
                  <span>
                    <img
                      src={
                        country
                          ? "country-logo/" + country + ".png"
                          : "sport-logo/" + eventData?.sport_id + ".svg"
                      }
                      alt=""
                    ></img>
                  </span>
                  {eventData?.tournament_name}
                </div>
              </div>
              {/* time-date-teams */}
              <div className="teamName-data-time">
                <div className="match-date-time">
                  {activeSportKey === "live" ? (
                    <div className="live-champions-score-minute-wrapper">
                      <div className="live-champions-leaue-score-minute">
                        {eventData.game_desk === "Перерыв" ? (
                          <>{t("half_time")}</>
                        ) : (
                          <>
                            {Math.floor(eventData?.timer / 60)}
                            <span className="blinking-animation">'</span>
                            {eventData.extra_time &&
                              eventData.extra_time !== "" && (
                                <div className="extra-time">
                                  <img src="./timer.svg" alt="" />
                                  <span>
                                    {eventData.extra_time} {t("mintute")}
                                  </span>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                      {eventData.period_name && eventData.sport_id === 1 && (
                        <div className="game-period">
                          {eventData.period_name === "2 Тайм" ? (
                            <>{t("period_2")}</>
                          ) : (
                            <>{t("period_1")}</>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      {moment.unix(eventData?.game_start).format("DD/MM/YYYY")}{" "}
                      {moment.unix(eventData?.game_start).format("HH:mm")}
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-center align-items-center opps-names-wrapper">
                  <div className="d-flex align-items-center eventListOpp_1_name">
                    <div className="team-1-name text-right">
                      {eventData?.opp_1_name}
                    </div>
                    <span
                      style={{
                        marginRight: "16px",
                        marginLeft: "16px",
                      }}
                    >
                      <img
                        src={
                          process.env.REACT_APP_teamLogo +
                          eventData?.opp_1_icon
                        }
                        width="64px"
                        height="64px"
                        alt=""
                      />
                    </span>
                  </div>
                  {activeSportKey === "live" ? (
                    <div className="d-flex live-tournament-score">
                      <div className="liveScore-selected-game">
                        {eventData?.score_full}
                      </div>
                    </div>
                  ) : (
                    <div className="minus-btn">-</div>
                  )}
                  <div className="d-flex align-items-center eventListOpp_2_name">
                    <span
                      style={{
                        marginRight: "16px",
                        marginLeft: "16px",
                      }}
                      className=""
                    >
                      <img
                        src={
                          process.env.REACT_APP_teamLogo +
                          eventData?.opp_2_icon
                        }
                        width="64px"
                        height="64px"
                        alt=""
                      />
                    </span>
                    <div className="team-2-name text-left">
                      {eventData?.opp_2_name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="pe-lg-3 pe-sm-0">
                {eventData?.game_oc_list?.map((item, index) =>
                  handleEventOddsData(item, index)
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GamePage;
