import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import NavigationBar from "../Navbar";
import HomePageHeader from "../molecule/homepage/HomePageHeader/HomePageHeader";
import HomePageFooter from "../molecule/homepage/HomePageFooter/HomePageFooter";
import DropDownInput from "../General/DropDownInput/DropDownInput";
import LeaderboardGrid from "./LeaderboardGrid/LeaderboardGrid";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import apiService from "../../services/apiService";

import "./Leaderboard.css";
import { Helmet } from "react-helmet";

const Leaderboard = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const banner = "/Leaderboard-img/banner.png";
  const bannerMobile = "/Leaderboard-img/banner-mobile.png";

  const FILTER_OPTIONS = [
    {
      id: "WEEK",
      text: "Тиждень",
      text_en: "Week",
    },
    {
      id: "MONTH",
      text: "Місяць",
      text_en: "Month",
    },
    {
      id: "ALL_TIME",
      text: "За весь час",
      text_en: "All time",
    },
  ];

  const [filter, setFilter] = useState(FILTER_OPTIONS[2]);
  const [topUsers, setTopUsers] = useState([]);
  const [topFromCurrentUser, setTopFromCurrentUser] = useState([]);

  const getUsersRating = async () => {
    if (localStorage.getItem("token")) {
      const res = await apiService.getUsersRating(filter.id);
      setTopUsers(res.top10Users);
      setTopFromCurrentUser(res.topFromCurrentUser);
    } else {
      const res = await apiService.getTop10Users(filter.id);
      setTopUsers(res.top10Users);
      // setTopFromCurrentUser(res.topFromCurrentUser);
    }
    console.log("test");
  };

  useEffect(() => {
    setTopUsers([]);
    getUsersRating();
  }, [filter]);

  return (
    <div>
      <Helmet>
        <title>{t("Leaderboard.metaTitle")}</title>
        <meta name="description" content={t("Leaderboard.metaDescription")} />
        <meta name="og:title" content={t("Leaderboard.metaSocialTitle")} />
        <meta
          name="og:description"
          content={t("Leaderboard.metaDescription")}
        />

        <meta name="og:url" content="https://capperauditor.com/leaderboard" />
        <meta name="twitter:title" content={t("Leaderboard.metaSocialTitle")} />
        <meta
          name="twitter:description"
          content={t("Leaderboard.metaDescription")}
        />

        <link
          rel="alternate"
          href="<https://capperauditor.com/leaderboard>"
          hreflang="uk"
        />
        <link
          rel="alternate"
          href="<https://capperauditor.com/en/leaderboard>"
          hreflang="en"
        />
        <link
          rel="alternate"
          href="<https://capperauditor.com/leaderboard>"
          hreflang="x-default"
        />
      </Helmet>
      {localStorage.getItem("token") && localStorage.getItem("profile") ? (
        <NavigationBar countData={1} />
      ) : (
        <HomePageHeader />
      )}
      <div className="leaderboard-banner-section">
        <div className="leaderboard-banner__content h-100">
          <div className="banner__text-section">
            <h1 className="banner__title pb-3">{t("Leaderboard.Title")}</h1>
            <div className="banner__sub-title">{t("Leaderboard.SubTitle")}</div>
          </div>

          <div className="gradiant-single-border">
            <img src="/Marketplace-img/Line.png" alt="line" />
          </div>

          <div className="banner-image">
            <img
              src={isMobile ? bannerMobile : banner}
              className="banner-image-data"
              alt="banner"
            />
          </div>

          <div className="leaderboard-banner-image-gradiant"></div>
        </div>
      </div>
      <div className="leaderboard__content-section">
        <div className="leaderboard__content">
          <div className="leaderboard__header">
            <div className="leaderboard__header-title">
              {t("Leaderboard.Grid.title")}
            </div>
            {localStorage.getItem("token") && (
              <DropDownInput
                listItems={FILTER_OPTIONS}
                selectedItem={filter}
                setSelectedItem={setFilter}
              />
            )}
          </div>
          <LeaderboardGrid
            users={topUsers}
            topFromCurrentUser={topFromCurrentUser}
          />
        </div>
      </div>
      <HomePageFooter />
    </div>
  );
};

export default Leaderboard;
