import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { FaRegCircleQuestion } from "react-icons/fa6";

import NavigationBar from "../Navbar";
import HomePageHeader from "../molecule/homepage/HomePageHeader/HomePageHeader";
import ToolTip from "../General/ToolTip/ToolTip";

import "./GameDetail.css";
import HorizontalChart from "../General/HorizontalChart/HorizontalChart";

const GameDetail = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t("Leaderboard.metaTitle")}</title>
        <meta name="description" content={t("Leaderboard.metaDescription")} />

        <meta name="og:title" content={t("Leaderboard.metaSocialTitle")} />
        <meta
          name="og:description"
          content={t("Leaderboard.metaDescription")}
        />
        <meta name="og:url" content="https://capperauditor.com/leaderboard" />
        <meta name="og:type" content="website" />

        <meta name="twitter:title" content={t("Leaderboard.metaSocialTitle")} />
        <meta
          name="twitter:description"
          content={t("Leaderboard.metaDescription")}
        />
        <meta
          name="twitter:image"
          content="https://capperauditor.com/preview-image.png"
        />
        <meta name="twitter:site" content="@Capperauditor" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {localStorage.getItem("token") && localStorage.getItem("profile") ? (
        <NavigationBar countData={1} />
      ) : (
        <HomePageHeader />
      )}
      <div className="game-details__main-section">
        <div className="game-details__left-box">
          <div className="game-details__game-info">
            <div className="game-details__controls">
              <div className="back__btn">
                <img src="/gameDetail/back.svg" alt="back" />
              </div>
              <div className="copy-link__btn">
                <img src="/gameDetail/link.svg" alt="link" />
              </div>
            </div>
            <div className="game-details__teams-info">
              <div className="game-details__teams-imgs">
                <div className="game-details__team-img-wrapper team1-img-wrapper"></div>
                <div className="game-details__team-img-wrapper team2-img-wrapper"></div>
              </div>
              <div className="game-details__teams-name">
                <div className="game-details__team-name-wrapper">
                  <div className="game-details__team-name">Рома</div>
                  <div className="game-details__team-score">1</div>
                </div>
                <div className="game-details__team-name-wrapper">
                  <div className="game-details__team-name">Баварія Мюнхен</div>
                  <div className="game-details__team-score">2</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="game-details__central-box">
          <div className="game-detail__tabs">
            <div className="game-detail__tab">{t("gameDetail.overView")}</div>
            <div className="game-detail__tab">
              {t("gameDetail.predictions")}
            </div>
            <div className="game-detail__tab">{t("gameDetail.analytics")}</div>
          </div>
          <div className="game-details__content">
            <div className="game-details__content-section">
              <div className="game-details__content-title">
                {t("gameDetail.overView")}
              </div>
              <div className="game-details__content-wrapper">
                <div className="game-details__overview">
                  <div className="game-details__overview-item">
                    <ToolTip text={t("gameDetail.totalPredictionsHint")}>
                      <div className="overview-item__title">
                        {t("gameDetail.predictions")}
                        <FaRegCircleQuestion />
                      </div>
                    </ToolTip>
                    <div className="overview-item__info">1</div>
                  </div>
                  <div className="game-details__overview-item">
                    <ToolTip text={t("gameDetail.avgCoefHint")}>
                      <div className="overview-item__title">
                        {t("gameDetail.avgCoef")}
                        <FaRegCircleQuestion />
                      </div>
                    </ToolTip>
                    <div className="overview-item__info">1</div>
                  </div>
                  <div className="game-details__overview-item">
                    <ToolTip text={t("gameDetail.avgPriceHint")}>
                      <div className="overview-item__title">
                        {t("gameDetail.avgPrice")}
                        <FaRegCircleQuestion />
                      </div>
                    </ToolTip>
                    <div className="overview-item__info">1</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="game-details__content-section">
              <div className="game-details__content-title">Прогнози</div>
              <div className="game-details__content-wrapper">
                <div className="game-details__predictions">
                  <div className="game-details__predictions-title">
                    {t("gameDetail.predictionOverview.title")}
                  </div>
                  <div className="game-details__predictions-content">
                    <div className="game-details__predictions-item total-count">
                      <div className="predictions-item__title">
                        {t("gameDetail.predictionOverview.total")}
                      </div>
                      <div className="predictions-item__info">1</div>
                    </div>
                    <div className="game-details__predictions-item">
                      <ToolTip
                        text={t("gameDetail.predictionOverview.beforeHint")}
                      >
                        <div className="predictions-item__title">
                          {t("gameDetail.predictionOverview.before")}
                          <FaRegCircleQuestion />
                        </div>
                      </ToolTip>
                      <div className="predictions-item__info">1</div>
                    </div>
                    <div className="game-details__predictions-item">
                      <ToolTip
                        text={t("gameDetail.predictionOverview.liveHint")}
                      >
                        <div className="predictions-item__title">
                          {t("gameDetail.predictionOverview.live")}
                          <FaRegCircleQuestion />
                        </div>
                      </ToolTip>
                      <div className="predictions-item__info">1</div>
                    </div>
                    <div className="game-details__predictions-item">
                      <ToolTip
                        text={t("gameDetail.predictionOverview.singleHint")}
                      >
                        <div className="predictions-item__title">
                          {t("gameDetail.predictionOverview.single")}
                          <FaRegCircleQuestion />
                        </div>
                      </ToolTip>
                      <div className="predictions-item__info">1</div>
                    </div>
                    <div className="game-details__predictions-item">
                      <ToolTip
                        text={t("gameDetail.predictionOverview.expressHint")}
                      >
                        <div className="predictions-item__title">
                          {t("gameDetail.predictionOverview.express")}
                          <FaRegCircleQuestion />
                        </div>
                      </ToolTip>
                      <div className="predictions-item__info">1</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="game-details__content-section">
              <div className="game-details__content-title">
                {t("gameDetail.analytics")}
              </div>
              <div className="game-details__content-wrapper">
                <HorizontalChart />
              </div>
            </div>
          </div>
        </div>
        <div className="game-details__chat">
          <div className="game-details__chat-title">
            <img src="/gameDetail/chat.svg" alt="chat"></img>
            {t("gameDetail.chat")}
          </div>
          <div className="comming">Comming soon...</div>
        </div>
      </div>
    </div>
  );
};

export default GameDetail;
