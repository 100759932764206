import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";
import { eventBus } from "../../../services/events";
import { CONSTANT } from "../../../common/constant";
import InputButton from "../InputButton";

export const CustomNotification = (
  title,
  subTitle,
  type,
  navigateFunc,
  errorResponse
) => {
  const handleErrorData = (data) => {
    if (data.fullErrorCode == 3) {
      if (localStorage.getItem("language") == "en") {
        return "Result does not exist.";
      } else {
        return "Результат не існує.";
      }
    } else if (data.fullErrorCode == 99) {
      if (localStorage.getItem("language") == "en") {
        return "Unknown error";
      } else {
        return "Невідома помилка";
      }
    } else {
      if (localStorage.getItem("language") == "en") {
        return "Unable to get totals or data is corrupted.";
      } else {
        return "Неможливо отримати підсумки або дані пошкоджені.";
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-start">
          <img
            src={
              type === "isErrorPopup"
                ? "/prediction slipError.svg"
                : "/prediction slipsuccess.svg"
            }
            alt=""
          />
          {/* center */}
          <div className="tooltip-center-part">
            <div
              className={`title ${type === "isErrorPopup" ? "pb-1" : "pb-2"}`}
            >
              {title}
            </div>
            <div className="sub-title">{subTitle}</div>
            {/* only error popup */}
            {type == "isErrorPopup"
              ? errorResponse.map((item, index) => (
                  <div key={index} className="error-msg-teamname-msg">
                    <div className="errorTeam-name">
                      {localStorage.getItem("language") == "en"
                        ? item.opp_1_name_en + " — " + item.opp_2_name_en
                        : item.opp_1_name + " — " + item.opp_2_name}
                    </div>
                    <div className="errorname-sub-title">
                      {handleErrorData(item)}
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </div>
        {/* close btn*/}
        <div className="model-close-btn">
          <span>
            <img
              src="/close-squere-btn.svg"
              alt="close"
              className="close-button close-squere-button"
              // onClick={props.handleHidePopup}
            />
          </span>
        </div>
      </div>
      {type == "isErrorPopup" ? (
        ""
      ) : (
        <InputButton
          type="primary"
          inputLabel={
            localStorage.getItem("language") == "en"
              ? "Go to the prediction"
              : "Перейти до передбачення"
          }
          addBtnClass="go-to-Predictionlist"
          onClick={() => navigateFunc()}
        />
      )}
    </>
  );
};

const ErrorPopup = (props) => {
  const { t, i18n } = useTranslation();
  const [showPopup, setShowPopup] = useState(false);

  eventBus.off(CONSTANT.HIDE_POPUP, () => {
    setShowPopup(false);
  });
  eventBus.off(CONSTANT.SHOW_POPUP, () => {
    setShowPopup(true);
  });
  eventBus.on(CONSTANT.HIDE_POPUP, () => {
    setShowPopup(false);
  });
  eventBus.on(CONSTANT.SHOW_POPUP, () => {
    setShowPopup(true);
  });

  return (
    <div className="prediction-list-error-popup" style={{ zIndex: 9999 }}>
      <NotificationContainer style={{ zIndex: 9999 }} />
    </div>
  );
};

export default ErrorPopup;
