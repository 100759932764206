import React from "react";
import { useMediaQuery } from "react-responsive";

import "./style.css";

const UserRewardsLoader = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const isTablet = useMediaQuery({ maxWidth: 1024 });

  return (
    <div className="user-rewards-wrapper">
      {!isTablet && (
        <div className="user-rewards-title">
          <div className="skeleton-placeholder-light user-rewards-loader-title"></div>
        </div>
      )}
      <div className="user-rewards-list">
        {Array(6)
          .fill(0)
          .map((item) => {
            return (
              <div className="user-rewards-list-item">
                <div className="skeleton-placeholder-light user-rewards-loader-img"></div>
                <div className="skeleton-placeholder-light user-rewards-loader-item-title"></div>
              </div>
            );
          })}
      </div>
      {!isTablet && (
        <div className="wallet-rewards__show-all-wrapper">
          <div className="skeleton-placeholder-light user-rewards-loader-btn"></div>
        </div>
      )}
    </div>
  );
};

export default UserRewardsLoader;
