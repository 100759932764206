import React from "react";
import apiService from "../../../../services/apiService";
import "./dashboard.css";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Users from "../users/Users";
import { useState } from "react";
import UserTable from "../UserTable";
import { useEffect } from "react";
import AdminCardCustomLoader from "../../../Custom loader/AdminCardCustomLoader";
const DashBorad = (props) => {
  const { t, i18n } = useTranslation();
  const [handleUserData, setHandleUserData] = useState(false);
  const [allUserBtn, setAllUserBtn] = useState(true);
  const [cardData, setCardData] = useState({});
  const [showLoader, setShowLoader] = useState(true);

  const handleUserTab = () => {
    setHandleUserData(true);
    props.handleUser();
    setAllUserBtn(false);
  };

  const getTodaysUserAndPrediction = async () => {
    const res = await apiService.getTodaysUserAndPrediction();
    setCardData(res);
    if (res) {
      setShowLoader(false);
    }
  };
  useEffect(() => {
    getTodaysUserAndPrediction();
  }, []);
  return (
    <>
      {handleUserData ? (
        <Users />
      ) : (
        <div className="dashborad-container">
          <div>
            <div className="admin-name pb-1">
              {t("admin.Hello")},{" "}
              {apiService.getProfileFromStorage()?.firstName}
              <span className="ps-2">👋</span>
            </div>
            <div className="Let-check-status">{t("admin.checkStatsToday")}</div>

            {/* card --- */}
            <div className="admin-cards-frame d-flex">
              {showLoader ? (
                <div className="admin-card-user-loader me-3">
                  <AdminCardCustomLoader />
                </div>
              ) : (
                <div className="admin-newuser-cards">
                  <div className="title">{t("admin.NewUsersToday")}</div>
                  <div className="userCount">+ {cardData?.todaysUser}</div>
                  <div className="total-user-count">
                    {cardData?.totalUser} {t("admin.totalUsers")}
                  </div>
                </div>
              )}
              {showLoader ? (
                <div className="admin-card-loader">
                  <AdminCardCustomLoader />
                </div>
              ) : (
                <div className="admin-newprediction-cards">
                  <div className="title">{t("admin.NewpredictionsToday")}</div>
                  <div className="newpredictionCount">
                    + {cardData?.todaysPredictionOrder}
                  </div>
                  <div className="total-prediction-count">
                    {cardData?.totalPredictionOrder}{" "}
                    {t("admin.totalPredictions")}
                  </div>
                </div>
              )}
            </div>
            <div className="dashboard-user-table">
              <div className="d-flex justify-content-between">
                <div className="title">{t("admin.Users")}</div>
                <div className="see-all-user" onClick={handleUserTab}>
                  {t("admin.SeeAll")}
                </div>
              </div>
              <UserTable
                handleRefreshPage={props.handleRefreshPage}
                handleUserTab={handleUserTab}
                allUserBtn={allUserBtn}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default DashBorad;
