import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import apiService from "../../../services/apiService";
const TopPredictionListDetailsModel = (props) => {
  const { t, i18n } = useTranslation();

  const handlePredictionLanguageLocalization = (data) => {
    var localLocale = moment.unix(data);
    localLocale.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
    return (
      localLocale.format("MMMM D").charAt(0).toUpperCase() +
      localLocale.format("MMMM D").slice(1)
    );
  };

  const getOddsMultiply = (odds) => {
    let od = 1;
    for (let i of odds) {
      od = od * parseFloat(i.oc_rate.toFixed(2));
    }
    return parseFloat(od.toFixed(2));
  };
  const handleLanguageLocalization = (data) => {
    var localLocale = moment.unix(data);
    localLocale.locale(localStorage.getItem("language") == "ua" ? "uk" : "en");
    return (
      localLocale.format("MMMM D").charAt(0).toUpperCase() +
      localLocale.format("MMMM D").slice(1)
    );
  };
  const handlePredictionListType = (i) => {
    if (i.betType) {
      if (i.betType == "live") {
        return (
          <span className="live-type-box-div">{t("profile.allLive")}</span>
        );
      } else if (i.betType == "line") {
        return t("feed.PreGame");
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  };
  return (
    <>
      <Modal
        className={`${props.addModelclass} notification-modal `}
        show={props.isModel}
        onHide={props.hide}
      >
        <Modal.Header>
          <Modal.Title>
            <div className="notificationModel-title">
              {t("profile.PredictionDetails")}
            </div>
            <div className="notificationModel-time">
              {t("profile.Postedon")}{" "}
              {handlePredictionLanguageLocalization(
                props.predictionDetail?.createdDate
              )}
              ,{" "}
              {moment
                .unix(props.predictionDetail?.createdDate)
                .format("HH: mm")}
            </div>
          </Modal.Title>
          <div className="model-close-btn" onClick={props.hide}>
            <span>
              <img
                src="/close-squere-btn.svg"
                alt="close"
                className="close-button close-squere-button"
              />
            </span>
          </div>
        </Modal.Header>
        <Modal.Body
          className="mb-3"
        //   className={props.isWalletModel ? "" : "notificationModel-body"}
        >
          <div
          // className={props.isWalletModel ? "" : "notificationModel-scroll"}
          >
            <div
              className={
                "walllet-model-description"
                //   : "notificationModel-body-description"
              }
            >
              {/* prediction-data */}
              <div>
                {/* odds list */}

                <div>
                  <div className="prediction-list-model-scroll">
                    <div className="model-prdiction-card">
                      {props.predictionDetail.odds?.map((item, index) => (
                        <div key={index} className="model-prediction-team-data">
                          <div className="both-team-league-time-sport d-flex pb-3">
                            <span className="d-flex align-items-center">
                              <img
                                src={"/sport-logo/" + item.sport_id + ".svg"}
                                width="16px"
                                height="16px"
                              />
                            </span>
                            {apiService.getProfileFromStorage()?.Language ==
                              "en"
                              ? item.sport_name_en
                                ? item.sport_name_en
                                : item.sport_name
                              : item.sport_name}{" "}
                            .{" "}
                            <span className="earning-coin-tournament_name">
                              {apiService.getProfileFromStorage()?.Language ==
                                "en"
                                ? item.tournament_name_en
                                  ? item.tournament_name_en
                                  : item.tournament_name
                                : item.tournament_name}{" "}
                            </span>
                            |
                            {/* {moment.unix(item.game_start).format("MMMM D")}, */}{" "}
                            <span className="ps-2 d-flex">
                              {handlePredictionLanguageLocalization(
                                item.game_start
                              )}
                              ,{moment.unix(item.game_start).format("HH: mm")}
                            </span>
                          </div>
                          <div className="d-flex align-items-center justify-content-between flex-wrap">
                            <div className="d-flex align-items-center oc_teams_details">
                              <div className=" both-team-logo-odds-data d-flex justify-content-between align-items-center">
                                <div className="both-Team-logo">
                                  <span className="team1-logo">
                                    <img
                                      src={
                                        process.env.REACT_APP_teamLogo +
                                        item?.opp_1_icon
                                      }
                                      height="33.42px"
                                      width="33.42px"
                                      style={{ borderRadius: "50px" }}
                                    />
                                  </span>
                                  <span className="team2-logo">
                                    <img
                                      className="team-2-logo"
                                      src={
                                        process.env.REACT_APP_teamLogo +
                                        item?.opp_2_icon
                                      }
                                      height="33.42px"
                                      width="33.42px"
                                      style={{ borderRadius: "50px" }}
                                    />
                                  </span>
                                </div>
                                <div className="mobile-screen-odds-data d-flex align-items-center pb-3">
                                  <div className="single-prediction-result">
                                    {item?.result.score
                                      ? item?.result.score?.split("(")[0]
                                      : "-:-"}
                                  </div>
                                  <div
                                    className="single-prediction-result ms-3"
                                    style={{
                                      backgroundColor: item.result
                                        ? item.result?.statusText === "Won"
                                          ? "#EEFBFB"
                                          : item.result?.statusText === "Lost"
                                            ? "#FCE8DE"
                                            : item.result?.statusText === "Returned"
                                              ? "#FCF7E4"
                                              : "#F2F3F7"
                                        : "",
                                      color: item.result
                                        ? item.result?.statusText === "Won"
                                          ? "#208B8E"
                                          : item.result?.statusText === "Lost"
                                            ? "#D14343"
                                            : item.result?.statusText === "Returned"
                                              ? "#C09E16"
                                              : "#131520"
                                        : "",
                                    }}
                                  >
                                    {item.oc_rate?.toFixed(2)}
                                  </div>
                                </div>
                              </div>
                              <div className="both-team-contains">
                                <div className="both-team-name d-flex align-items-center">
                                  <div>
                                    <span>
                                      {apiService.getProfileFromStorage()
                                        ?.Language == "en"
                                        ? item?.opp_1_name_en
                                          ? item?.opp_1_name_en
                                          : item?.opp_1_name
                                        : item?.opp_1_name}
                                    </span>
                                    <span>-</span>
                                    <span>
                                      {apiService.getProfileFromStorage()
                                        ?.Language == "en"
                                        ? item?.opp_2_name_en
                                          ? item?.opp_2_name_en
                                          : item?.opp_2_name
                                        : item?.opp_2_name}
                                    </span>
                                  </div>
                                </div>
                                <div className="both-team-league-name d-flex">
                                  {item.oc_group_name === "1x2"
                                    ? "Full-time result"
                                    : item.oc_group_name}
                                  <span className="px-1"> - </span>
                                  <span className="both-team-name">
                                    {item.oc_name}
                                  </span>
                                </div>
                                <div></div>
                              </div>
                            </div>
                            <div className="large-screen-odds-data d-flex align-items-center">
                              <div className="single-prediction-result">
                                {item?.result.score
                                  ? item?.result.score?.split("(")[0]
                                  : "-:-"}
                              </div>
                              <div
                                className="single-prediction-result ms-3"
                                style={{
                                  backgroundColor: item.result
                                    ? item.result?.statusText === "Won"
                                      ? "#EEFBFB"
                                      : item.result?.statusText === "Lost"
                                        ? "#FCE8DE"
                                        : item.result?.statusText === "Returned"
                                          ? "#FCF7E4"
                                          : "#F2F3F7"
                                    : "",
                                  color: item.result
                                    ? item.result?.statusText === "Won"
                                      ? "#208B8E"
                                      : item.result?.statusText === "Lost"
                                        ? "#D14343"
                                        : item.result?.statusText === "Returned"
                                          ? "#C09E16"
                                          : "#131520"
                                    : "",
                                }}
                              >
                                {item.oc_rate?.toFixed(2)}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="">
                    <div className="row prediction-slip-odds-stakedCoin-possibleWin-type-status-div d-flex">
                      {/* odds */}
                      <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-odd-div">
                        <div className="odds-title">{t("feed.TotalOdds")}</div>
                        <div className="data">
                          {props.predictionDetail?.totalOdds?.toFixed(2)}
                        </div>
                      </div>
                      {/* Staked coins */}
                      <div className="col-sm-4  col-4 col-lg-4 col-md-4 predictionlist-Staked-coins">
                        <div className="predictionlist-coins-title">
                          {t("feed.StakedCoins")}
                        </div>
                        <div className="data">
                          {props.predictionDetail?.betTotal
                            ? props.predictionDetail?.betTotal
                            : "-"}
                        </div>
                      </div>
                      {/* possible win */}
                      <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-possible-win">
                        <div className="win-title">{t("feed.PossibleWin")}</div>
                        <div className="data">
                          {!props.predictionDetail?.totalOdds ||
                            !props.predictionDetail?.betTotal
                            ? "-"
                            : (
                              props.predictionDetail?.totalOdds?.toFixed(2) *
                              props.predictionDetail?.betTotal
                            ).toFixed(2)}
                        </div>
                      </div>
                      {/* type */}
                      <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-type-div">
                        <div className="type-title">{t("feed.Type")}</div>
                        <div className="data">
                          {handlePredictionListType(props.predictionDetail)}
                        </div>
                      </div>
                      {/* status */}
                      <div className="col-sm-4 col-4 col-lg-4 col-md-4 predictionlist-status-div">
                        <div className="status-title">{t("feed.Status")}</div>
                        <div
                          className="data"
                          style={{
                            backgroundColor: props.predictionDetail
                              ?.BetHeadDetail
                              ? props.predictionDetail?.BetHeadDetail
                                ?.statusText === "Won"
                                ? "#EEFBFB"
                                : props.predictionDetail?.BetHeadDetail
                                  ?.statusText === "Lost"
                                  ? "#F9DADA"
                                  : props.predictionDetail?.BetHeadDetail
                                    ?.statusText === "Returned"
                                    ? "#FCF7E4"
                                    : "#F2F3F7"
                              : "",
                            color: props.predictionDetail?.BetHeadDetail
                              ?.statusText
                              ? props.predictionDetail?.BetHeadDetail
                                ?.statusText === "Won"
                                ? "#0D393A"
                                : props.predictionDetail?.BetHeadDetail
                                  ?.statusText === "Lost"
                                  ? "#D14343"
                                  : props.predictionDetail?.BetHeadDetail
                                    ?.statusText === "Returned"
                                    ? "#C09E16"
                                    : "#131520"
                              : "#131520",
                          }}
                        >
                          {props.predictionDetail?.BetHeadDetail?.statusText?.toUpperCase()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TopPredictionListDetailsModel;
