import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

import ProgressBar from "../General/ProgressBar/ProgressBar";
import ToolTip from "../General/ToolTip/ToolTip";
import Timer from "../General/Timer";

import Model from "../molecule/model/Model";

import apiService from "../../services/apiService";

const RewardItem = ({
  item,
  category,
  getRewards
}) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [isRewardOver, setIsRewardOver] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [selectedReward, setSelectedReward] = useState({});

  const handleOnClickClaimBtn = (reward, category) => {
    const rewardData = {
      url: `/rewards/${reward.id}.svg`,
      level: reward.user_level,
      reward: reward.id,
      category: item.category ?? category,
      name: reward.name,
      name_en: reward.name_en,
      award: reward.award,
      isExclusive: reward.isExclusive,
    };

    setSelectedReward(rewardData);
    setShowModal(true);
  };

  const handleClaimReward = async () => {
    const res = await apiService.addUserReward(selectedReward);
    if (res?.totalBalance) {
      localStorage.setItem("coinData", res.totalBalance);
    }
    setShowModal(false);
    getRewards();
  };

  const renderCategoryItem = () => {
    const index = item.user_level - 1;
    const imgUrl = `/rewards/${item.id}.svg`;
    return (
      <div className="rewards__category-sub-item">
        <div className="rewards__category-sub-item_img-wrapper">
          <img src={imgUrl} alt=""></img>
          {item.isExclusive && (
            <>
              <img className="medal_1" src="/rewards/Medal_1.svg" alt="" />
            </>
          )}
        </div>
        <div className="rewards__category-sub-item_right-wrapper">
          <div className="rewards__category-sub-item_title-wrapper">
            <div>
              <h1>
                {localStorage.getItem("language") === "ua"
                  ? item.name
                  : item.name_en}
              </h1>
              {item.newLevel ? (
                <button
                  className="claim-revards-btn"
                  onClick={() =>
                    handleOnClickClaimBtn(item, category, item.isExclusive)
                  }
                >
                  {t("rewards.claim")}
                </button>
              ) : (
                <h4>
                  {localStorage.getItem("language") === "ua"
                    ? item.levels[index]["description"]
                    : item.levels[index]["description_en"]}
                </h4>
              )}
            </div>
            {item.ended_at && !isRewardOver && (
              <div className="exclusive-reward__timer-wrapper">
                {item.isWait ?
                  <ToolTip
                    text={t("rewards.rewardWaitTooltip")}
                  >
                    <div className="reward-wait">
                      <img src="/rewards/calendar.svg" alt="" />
                      {t("rewards.rewardWait")}
                    </div>
                  </ToolTip> :
                  <ToolTip text={t("rewards.timeTo")}>
                    <div className="exclusive-reward__timer">
                      <img src="/rewards/Hourglass.svg" alt="" />
                      <Timer endTime={new Date(item.ended_at)} setIsRewardOver={setIsRewardOver} />
                    </div>
                  </ToolTip>
                }
              </div>
            )}
          </div>

          {!item.newLevel && (
            renderInformationSection(index)
          )}
        </div>
      </div>
    );
  };

  const renderMobileCategoryItem = () => {
    const i = item.user_level - 1;
    const imgUrl = `/rewards/${item.id}.svg`;

    return (
      <div className="rewards__category-sub-item">
        <div className="rewards__category-sub-item_img-wrapper">
          {item.ended_at && !isRewardOver && (
            <div className="reward__status-wrapper">
              {item.isWait ?
                <ToolTip
                  text={t("rewards.rewardWaitTooltip")}
                >
                  <div className="reward-wait">
                    <img src="/rewards/calendar.svg" alt="" />
                    {t("rewards.rewardWait")}
                  </div>
                </ToolTip> :
                <ToolTip text={t("rewards.timeTo")}>
                  <div className="exclusive-reward__timer">
                    <img src="/rewards/Hourglass.svg" alt="" />
                    <Timer endTime={new Date(item.ended_at)} setIsRewardOver={setIsRewardOver} />
                  </div>
                </ToolTip>
              }
            </div>
          )}
          <img className="reward-img" src={imgUrl} alt=""></img>
          {item.isExclusive && (
            <>
              <img className="medal_1" src="/rewards/Medal_1.svg" alt="" />
            </>
          )}
        </div>
        <div className="rewards__category-sub-item_right-wrapper">
          <div className="rewards__category-sub-item_title-wrapper">
            <h1>
              {localStorage.getItem("language") === "ua"
                ? item.name
                : item.name_en}
            </h1>
            {item.newLevel ? (
              <button
                className="claim-revards-btn"
                onClick={() =>
                  handleOnClickClaimBtn(item, category, item.isExclusive)
                }
              >
                {t("rewards.claim")}
              </button>
            ) : (
              <h4>
                {localStorage.getItem("language") === "ua"
                  ? item.levels[i]["description"]
                  : item.levels[i]["description_en"]}
              </h4>
            )}
          </div>
          {!item.newLevel && (
            renderInformationSection(i)
          )}
        </div>
      </div>
    );
  };

  const renderInformationSection = (index) => {
    if (item.completed) {
      return <>
        <ToolTip
          text={
            item.isExclusive
              ? t("rewards.levelsCompletedExclusive")
              : t("rewards.levelsCompleted")
          }
        >
          <div className="reward-completed">
            <img src="/rewards/Star_circle.svg" alt="" />
            {t("rewards.rewardCompleted")}
          </div>
        </ToolTip>
      </>
    }

    if (isRewardOver) {
      return <>
        <ToolTip
          text={t("rewards.rewardOverTooltip")}
        >
          <div className="reward-completed">
            <img src="/rewards/calendar_done.svg" alt="" />
            {t("rewards.rewardOver")}
          </div>
        </ToolTip>
      </>
    }

    if (isMobile) {
      return <>
        <div className="rewards__category-sub-item_info-wrapper">
          <div className="rewards__category-sub-item_info-item rewards_level">
            <div className="rewards__category-sub-item_info-title">
              {t("rewards.level")}
            </div>
            <div className="rewards__category-sub-item_info-result">
              {item.user_level} / {item.levels_count}
            </div>
          </div>
          <div className="rewards__category-sub-item_info-item rewards_award">
            <div className="rewards__category-sub-item_info-title">
              {t("rewards.award")}
            </div>
            <div className="rewards__category-sub-item_info-result">
              <img
                className="rewards__coin-icon"
                src="/CAP coin.png"
                alt=""
              />
              {item.levels[index]["award"]}
            </div>
          </div>
        </div>
        <div className="rewards__category-sub-item_info-item rewards_progress">
          <div className="rewards__category-sub-item_info-title">
            {t("rewards.progress")}
          </div>
          <div className="rewards__category-sub-item_info-result">
            <ProgressBar
              progress={item.user_progress}
              end={item.levels[index]["destination"]}
            />
          </div>
        </div>
      </>
    }

    return (<div className="rewards__category-sub-item_info-wrapper">
      <div className="rewards__category-sub-item_info-item rewards_level">
        <div className="rewards__category-sub-item_info-title">
          {t("rewards.level")}
        </div >
        <div className="rewards__category-sub-item_info-result">
          {item.user_level} / {item.levels_count}
        </div>
      </div >
      <div className="rewards__category-sub-item_info-item rewards_progress">
        <div className="rewards__category-sub-item_info-title">
          {t("rewards.progress")}
        </div>
        <div className="rewards__category-sub-item_info-result">
          <ProgressBar
            progress={item.user_progress}
            end={item.levels[index]["destination"]}
          />
        </div>
      </div>
      <div className="rewards__category-sub-item_info-item rewards_award">
        <div className="rewards__category-sub-item_info-title">
          {t("rewards.award")}
        </div>
        <div className="rewards__category-sub-item_info-result">
          <img
            className="rewards__coin-icon"
            src="/CAP coin.png"
            alt=""
          />
          {item.levels[index]["award"]}
        </div>
      </div>
    </div>)
  }

  return <>
    {isMobile ? renderMobileCategoryItem() : renderCategoryItem()}
    <Model
      isModel={showModal}
      modelClassName={"reward-modal"}
      hideModel={() => setShowModal(false)}
    >
      <div>
        <div className="d-flex justify-content-center pb-4">
          <img className="rewards-modal-img" src={selectedReward.url} alt="" />
        </div>
        <div className="successfully-message text-center pb-2">
          {selectedReward.isExclusive
            ? t("rewards.rewardTitleExclusive")
            : t("rewards.rewardTitle", { level: selectedReward.level })}{" "}
          "
          {localStorage.getItem("language") === "ua"
            ? selectedReward.name
            : selectedReward.name_en}
          "
          {localStorage.getItem("language") === "en" &&
            !selectedReward.isExclusive &&
            "achievement"}
        </div>
        <div className="decription-message text-center pb-4">
          {t("rewards.rewardDescription")}
          <span className="coint-award">{selectedReward.award}</span>{" "}
          {t("rewards.capCoins")}
        </div>
        <button
          className="claim-revards-btn claim-btn"
          onClick={() => handleClaimReward()}
        >
          {t("rewards.continueLbl")}
        </button>
      </div>
    </Model>
  </>;
};

export default RewardItem;
